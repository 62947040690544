import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-unlock-videos-lives-streams',
  templateUrl: './unlock-videos-lives-streams.component.html',
  styleUrls: ['./unlock-videos-lives-streams.component.scss']
})
export class UnlockVideosLivesStreamsComponent implements OnInit {

  @Input("purchasedMedias") purchasedMedias: any;

  constructor() { }

  ngOnInit(): void {
  }

}
