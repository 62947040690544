<div class="content">
  
  <div class="header">
		<div class='btns-mobile'>
      <button class='back-btn' mat-button>
        <mat-icon>arrow_back</mat-icon>
        <span (click)="backToProfile()">{{'profile.back-to-profile' | translate}}</span>
      </button>

      <button class='go-to-subscriptions-mobile' (click)="goToSubscriptions()" mat-button>
        <mat-icon>add_circle_outline</mat-icon>
      	<span>{{'profile.subscription-content.add-subscription' | translate}}</span>
      </button>
    </div>

    <div class="title-box">
      <span class="title">{{'profile.subscription-content.title' | translate}}</span>
      <span class="sub-title">{{'profile.subscription-content.subtitle' | translate}}</span>
    </div>
    <button class='go-to-subscriptions' mat-flat-button (click)='goToSubscriptions()'> 
			<mat-icon>add_circle_outline</mat-icon>
      <span>{{'profile.subscription-content.add-subscription' | translate}}</span>
    </button>
  </div>

	<mat-tab-group #tabs (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="selectedIndex" disablePagination="true">
		<mat-tab>
			<ng-template mat-tab-label>
				<img *ngIf='selectedIndex != 0'class='img' src="../../../../assets/icons/credit-card.svg">
				<img *ngIf='selectedIndex == 0'class='img' src="../../../../assets/icons/credit-card-blue.svg">
				<span class='tab' [ngClass]='{"tab-selected": selectedIndex == 0}'>{{'profile.subscription-content.title' | translate}}</span>
			</ng-template>

			<div class="example-loading-shade" *ngIf="loading">
				<mat-spinner></mat-spinner>
			</div>

			<app-user-subscriptions *ngIf="!loading" (updateSubscription)='getSubscriptionsPPVByUser(true)' [subscriptions]='subscriptions'></app-user-subscriptions>
		</mat-tab>

		<mat-tab>
			<ng-template mat-tab-label>
				<img *ngIf='selectedIndex != 1' class='img' src="../../../../assets/icons/unlock-grey.svg">
				<img *ngIf='selectedIndex == 1' class='img' src="../../../../assets/icons/unlock-blue.svg">
				<span class='tab' [ngClass]='{"tab-selected": selectedIndex == 1}'>{{'profile.subscription-content.video-editor-bundles' | translate}}</span>
			</ng-template>

			<div class="example-loading-shade" *ngIf="loading">
				<mat-spinner></mat-spinner>
			</div>

			<app-unlock-videos-editor-bundles *ngIf="!loading" [bundleEditor]='bundleEditor'></app-unlock-videos-editor-bundles>
		</mat-tab>

		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon class='material-icons-outlined play_arrow' [ngClass]='{"tab-selected": selectedIndex == 2}'>play_arrow</mat-icon>
				<span class='tab' [ngClass]='{"tab-selected": selectedIndex == 2}'>{{'profile.subscription-content.videos-live-streams' | translate}}</span>
			</ng-template>

			<div class="example-loading-shade" *ngIf="loading">
				<mat-spinner></mat-spinner>
			</div>

			<app-unlock-videos-lives-streams *ngIf="!loading" [purchasedMedias]='purchasedMedias'></app-unlock-videos-lives-streams>
		</mat-tab>
	</mat-tab-group>
</div>