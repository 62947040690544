import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ExtractionService } from 'src/app/services/extraction.service';


@Component({
  selector: 'app-discard-clip',
  templateUrl: './discard-clip.component.html',
  styleUrls: ['./discard-clip.component.scss']
})
export class DiscardClipComponent implements OnInit {

  constructor(
    public extractionService: ExtractionService,
    private dialogRef: MatDialogRef<DiscardClipComponent>
  ) { }

  ngOnInit(): void {
  }

  discardClip() {
    this.extractionService.disableClip();
    this.dialogRef.close();
  }

}
