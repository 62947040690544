<div class='main-container' *ngIf="notFound">
  <div class='not-found'>
    {{'subscriptions.content.not-found' | translate}}
  </div>
</div>
<div class='main-container' *ngIf="!notFound">
  <div class="example-loading-shade" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <div class='header' id="header" *ngIf="!loading">
    <div class='background' [ngStyle]="{'background-image': 'url(' + thumbnailSubscription + ')'}"></div>
    <div class='color'></div>
    <div class="left">
      <img [src]="thumbnailSubscription">
      <div class='subscription-info'>
        <p class='path'><span (click)="goToPath('')">{{'subscriptions.content.home' | translate}}</span> > <span (click)="goToPath('/subscriptions')">{{'subscriptions.subscriptions' | translate}}</span></p>
        <p class='name'>{{subscriptionData.name}}</p>
        <p class='count'><span class='value'>{{subscriptionData.countVideo}}</span> video<span>{{subscriptionData.countVideo > 1 ? 's' : ''}}</span></p>
        <p class='trial'><span  *ngIf="subscriptionData.trial_days && subscriptionData.trial_days > 0">{{'subscriptions.try-free' | translate}} {{subscriptionData.trial_days}} <span *ngIf='subscriptionData.trial_days > 1'>{{'subscriptions.days' | translate}}</span> <span *ngIf='subscriptionData.trial_days == 0 || subscriptionData.trial_days == 1'>{{'subscriptions.day' | translate}}</span> </span></p>
        
      </div>
    </div>
    <div class='right'>
      <button mat-flat-button class="offer-button" *ngIf="subscriptionData.state_payment == 'available'" (click)="selectPlan()">
        <div class="text">
          <span>{{'subscriptions.content.get-offer' | translate}}</span>
          <span class='recurring' *ngIf="subscriptionData.is_recurring == 0">{{'subscriptions.one-time-payment' | translate}}</span>
          <span class='recurring' *ngIf="subscriptionData.is_recurring == 1"><span>{{billingFrequencyTreatment(subscriptionData)}} {{'subscriptions.pass-recurring' | translate}}</span></span>
        </div>
        <div class="price">${{subscriptionData.price}} USD</div>
      </button>
      <button mat-flat-button class="cancel-button" *ngIf="subscriptionData.state_payment == 'cancel'" (click)="openModalPlan('Cancel')">
        <div class="text">
          <span>{{'subscriptions.content.click-cancel' | translate}}</span>
          <span>{{'subscriptions.content.next-payment' | translate}} {{subscriptionData.end_subscription | dateTimezone:'MMM d, y, h:mm a'}}</span>
        </div>
      </button>
      <button mat-flat-button class="renew-button" *ngIf="subscriptionData.state_payment == 'renew'" (click)="openModalPlan('Renew')">
        <div class="text">
          <span>{{'subscriptions.content.click-renew' | translate}}</span>
          <span>{{'subscriptions.content.access-until' | translate}} {{subscriptionData.end_subscription | dateTimezone:'MMM d, y, h:mm a'}}</span>
        </div>
      </button>
    </div>
  </div>

  <div class='content' *ngIf="!loading">
    <div class='filter'>
      <mat-form-field appearance="outline">
        <mat-label>{{'filters.sort-by.title' | translate}}</mat-label>
        <mat-select [(value)]="orderBy" (selectionChange)="onFilter($event.value)" disableOptionCentering>
          <mat-option value="recent">{{'filters.sort-by.latest' | translate}}</mat-option>
          <mat-option value="older">{{'filters.sort-by.older' | translate}}</mat-option>
          <mat-option value="ascendingCharacters">{{'filters.sort-by.a-to-z' | translate}}</mat-option>
          <mat-option value="descendingCharacters">{{'filters.sort-by.z-to-a' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <mat-tab-group mat-align-tabs="start" class='subscription-tab' (selectedTabChange)="setCurrentTab($event)">
      <mat-tab *ngIf="!onlyVideo" label="All" >
        <div class='videos' *ngIf="!allLoading || allMedias.length != 0">
          <div class='video'  *ngFor="let media of allMedias" >
            <app-thumbnail-video [videoData]="media" [fullWidth]="isMobile"></app-thumbnail-video>
          </div>
        </div>
        <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      ></div>
      <ng-container>
        <div class="more-loading" *ngIf="allLoading">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      </ng-container>
      </mat-tab>
      <mat-tab *ngIf="!onlyVideo" label="Live Now">
        <div class='videos' *ngIf="!liveLoading">
          <div class='video' *ngFor="let media of liveMedias">
            <app-thumbnail-video   [videoData]="media" [fullWidth]="isMobile"></app-thumbnail-video>
          </div>
        </div>
        <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      ></div>
      <ng-container>
        <div class="more-loading" *ngIf="liveLoading">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      </ng-container>
      </mat-tab>
      <mat-tab label="Videos" >
        <div class='videos' *ngIf="!videosLoading || videoMedias.length != 0">
          <div class='video'  *ngFor="let media of videoMedias">
            <app-thumbnail-video [videoData]="media" [fullWidth]="isMobile"></app-thumbnail-video>
          </div>
        </div>
        <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      ></div>
      <ng-container>
        <div class="more-loading" *ngIf="videosLoading">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      </ng-container>
      </mat-tab>
    </mat-tab-group>

  </div>

</div>
