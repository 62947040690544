<div class='videos-container' [ngClass]='{"video-content" : context == "video-content"}'>
  <div class='top'>
    <div class='title' [ngClass]="{'banner': hasBanner}" [ngClass]='{"subchannel": subchannelId}'>
      <div style='display: flex;'>
        <div class='img-container' *ngIf='subchannelLogo'>
          <img [src]='subchannelLogo'>
        </div>
        <div>
          <span *ngIf='subchannelId'>Access Channel</span>
          <h1><span [ngStyle]="{'color': subtitle ? '#010570' : 'initial'}">{{title}}</span> {{subtitle}}</h1>
        </div>
      </div>
      <a *ngIf="!isRelatedVideos" href="{{seeAllLink}}" (click)="goToCategoryDetails($event)">{{'video.see-all' | translate}}
        <img *ngIf='subchannelId' class="external-link" src="../../assets/icons/icons8-external-link.svg" alt="">
        <ng-container *ngIf='!subchannelId'> > </ng-container>
      </a>
    </div>

    <div [ngClass]='{"category-mobile" : !hasThumbnail }' class='steps' *ngIf="steps.length != 1 && !hasBanner">
      <div class='step' (click)="goToStep(step.number)" [ngStyle]="{'background-color': step.active ? '#010570' : '#e4e4ee'}" *ngFor="let step of steps"></div>
    </div>

  </div>

  <div class='videos' [ngClass]="{'less-then-two' : videos.length < 4, 'columns-view' : columnsView}">
    <div class='largest-video' *ngIf="hasBanner">      
      <app-video [data]="largestVideo" [layout]="true"></app-video>
    </div>

    <div [ngClass]="{'carousel' : videos.length > 0, 'category-mobile': !hasThumbnail}">
      <div [ngClass]='{"category-mobile" : !hasThumbnail }' class='steps banner' *ngIf="steps.length != 1 && hasBanner">
        <div class='step' (click)="goToStep(step.number)" [ngStyle]="{'background-color': step.active ? '#010570' : '#e4e4ee'}" *ngFor="let step of steps"></div>
      </div>
      <owl-carousel-o #mainCarousel [options]="customOptions" (dragging)="draggedEvent($event)">
        <ng-template carouselSlide id="slide-{{i}}" *ngFor="let video of videos; let i = index">
          <app-thumbnail-video *ngIf="hasThumbnail" [hideLiveViewers]="hideLiveViewers" [videoData]="video" [recommended]="recommended" [columnsView]="columnsView" [context]='context'></app-thumbnail-video>
          <app-category *ngIf="!hasThumbnail" [category]="video" [firstChild]='i == 0'></app-category>
        </ng-template> 
      </owl-carousel-o>
    </div>

		<div class='category-content-mobile' *ngIf="!hasThumbnail">
			<ng-container *ngFor="let video of videos; let i = index">
				<app-category style='width: 50%;' [category]="video"></app-category>
			</ng-container>
		</div>
      
    <img [ngClass]='{"category-mobile" : !hasThumbnail, "layout" : hasBanner, "recommended" : recommended}' class="previous-arrow" *ngIf="((!isMobile && steps.length != 1 && currentStep != 0) || (isMobile && videos.length > 1 && currentVideoIndex != 0))" src="../../assets/icons/arrow-left.svg" (click)="prev()">
    <img [ngClass]='{"category-mobile" : !hasThumbnail, "layout" : hasBanner, "recommended" : recommended}' class="next-arrow" *ngIf="((!isMobile && steps.length != 1 && currentStep != steps.length - 1) || (isMobile && videos.length > 1 && currentVideoIndex != videos.length - 1))" src="../../assets/icons/arrow-right.svg" (click)="next()">
  </div>
</div>

