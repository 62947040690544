import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../analytics.service';
import { AuthService } from '../auth/auth.service';
import { CategoryService } from '../category.service';
import { CategoriesDialogComponent } from '../main/categories/categories-dialog/categories-dialog.component';
import { ChannelService } from '../services/channel.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  isExpanded: boolean = false;
  categorySlug: any;
  user: any;
  isAdmin: boolean = false;
  clickOnActionButtons: boolean = false;
  highlightFirstCategory: boolean = true;
  isMobile: boolean = false;
  categories: any = [];
  observableServiceSubscription: Subscription;
  channel: any;

  isNewHomepageUrl = /(onlysports\.live\/|usssalive\.com|iswim.tv|vewbie.com\/channel)/.test(window.location.href);
  redirectLocationHref = window.location.protocol + '//' + window.location.host + '/channel';

  @Input() data: any;
  @Input() layout: any;
  @ViewChild("refEl") videoElement: ElementRef;
  hoverInterval: any;
  
  mediaLink: string = '';
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private categoryService: CategoryService,
    private analyticsService : AnalyticsService,
    private channelService : ChannelService,
  ) {}

  ngOnDestroy(){
    if(this.observableServiceSubscription) {
      this.observableServiceSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.user = await this.authService.getSession();
    this.channel = await this.channelService.getCurrentChannel();

    if(window.innerWidth <= 768) {
      this.isMobile = true;
    }

    if(this.user && (this.user.user_admin_type == "ADMIN" || this.user.user_admin_type == "MANAGER")) {
      this.isAdmin = true;
    }

    if(this.data.categories?.length) { //new way to receive categories
      this.categories = this.data.categories;
      this.categories.forEach(category => {
        if (!category.category_thumbnail_url && category.category_name) {
          category.categorySlug = this.categoryService.getInitials(category.category_name);
        }
      });
    }

    if(this.data.redirect_subchannel_media_url || this.data.isFromChildChannel) {
      this.mediaLink = this.getChildChannelVideoUrl();
    } else {
      const mediaType: string = this.data.type == "EVENT" ? '/live' : '/video';
      this.mediaLink = window.location.origin + mediaType + '/' + this.data.media_slug;
    }
  }

  toggleTags() {
    if (!this.isExpanded) {
    document.getElementById('tags').scrollTop = 0;
    }
    this.isExpanded = !this.isExpanded;
  }

  mouseOver() {
    let images = this.data.thumbnail_url_hover;
    let i = 0;

    if(images && images.length > 0) {
      this.videoElement.nativeElement.src = images[i];
      this.videoElement.nativeElement.setAttribute('style', 'opacity: 1');
      
  
      this.hoverInterval = setInterval(() => {
        i++;
        this.videoElement.nativeElement.setAttribute('style', 'opacity: .5');
        setTimeout(() => {
          if(i == images.length) {
            i = 0;
          }
          this.videoElement.nativeElement.src = images[i];
          this.videoElement.nativeElement.setAttribute('style', 'opacity: 1');      
        }, 500);
      }, 2000)
    }

  }

  async openCategoriesModal() {
    this.dialog.open(CategoriesDialogComponent, {
      width: "50%",
      panelClass: "dialog-modal",
      data: this.categories,
      autoFocus: false
    });
  }

  mouseOut() {
    if(this.data.thumbnail_url) {
      this.videoElement.nativeElement.src = this.data.thumbnail_url;
    } else {
      this.videoElement.nativeElement.src = '../../assets/imgs/prism.png';
    }
    this.videoElement.nativeElement.setAttribute('style', 'opacity: .5');
    clearInterval(this.hoverInterval);
  }

  goToVideo(event: Event) {
    // Anchor elements are the ones calling this function, if the anchor element is clicked, we should prevent its default action and proceed with this function
    event.preventDefault();

    if(!this.clickOnActionButtons) {
      if(this.data.redirect_subchannel_media_url || this.data.isFromChildChannel) {
        window.open(this.getChildChannelVideoUrl(), "_blank");
        return;
      }

      if(this.data.type == "EVENT") {
        this.analyticsService.trackingOpenVideo({ slug: this.data.media_slug, media: 'live', path: window.location.href});
        this.router.navigate([`live/${this.data.media_slug}`])
      } else {
        this.analyticsService.trackingOpenVideo({ slug: this.data.media_slug, media: 'video', path: window.location.href});
        this.router.navigate([`video/${this.data.media_slug}`])
      }
    }
  }

  getChildChannelVideoUrl() {
    let mediaLink = ``;

    if(this.data.redirect_subchannel_media_url && 
      ![+this.channel.id, +(this.channel.parent_id ?? 0)].some((id) => id === 11 || id === 438 || id === 211)) {
      mediaLink = this.data.redirect_subchannel_media_url;
    } else {

      // This if bellow should not be commented on onlysportslive branch

      // Onlysports child channels use the same domain, we differentiate channels using the url
      if([+this.channel.id, +(this.channel.parent_id ?? 0)].some((id) => id === 11 || id === 438 || id === 211)) {
        let hostname = this.data.channelHostName ? this.data.channelHostName : this.data.channel_host_name
        mediaLink = window.location.origin + '/channel/' + hostname;
      } else {
        mediaLink = this.data.subchannelUrl;
      }

      // This bellow should be commented on onlysportslive branch
      // mediaLink = this.data.subchannelUrl;

      const mediaType: string = this.data.type == "EVENT" ? '/live' : '/video';
      mediaLink += mediaType + '/' + this.data.media_slug;
    }

    return mediaLink;
  }

  checkOpacityFirstCategory(index){
    if(index == 0) {
      this.highlightFirstCategory = true;
    }
    else {
      this.highlightFirstCategory = false;
    }
  }

  goToCategoryDetails(category) {
    // usssalive.com or onlysports.live or iswim-tv.com or vewbie.com/channel/hostname
    if (
      this.isNewHomepageUrl &&
      category.subchannel_id &&
      category.subchannel_url &&
      category.subchannel_url.includes('vewbie.com')
    ) {
      let hostName = category.hostName ? category.hostName : category.host_name;
      let subchannelUrl = `${this.redirectLocationHref}/${hostName}`;
      window.open(`${subchannelUrl}`, '_blank');
      return;
    }

    if(category.redirect_category_url) {
      window.open(category.redirect_category_url, '_blank')
      return;
    }

    this.router.navigate([`categories/${category.category_slug}`]);
  }

  clipVideo() {
    this.clickOnActionButtons = true;
    let urlParam = "user";
    let channelLink = this.channel.oldDashChannelLink;
    
    if(this.isAdmin) {
      urlParam = "admin";
    }

    if(this.data.channel_link_url) {
      channelLink = this.data.channel_link_url;
    }
    
    window.open(`${channelLink}/${urlParam}/clipextraction/vod/${this.data.media_slug}`);

    setTimeout(() => {
      this.clickOnActionButtons = false;
    }, 1000);
  }  
  
  editVideo() {
    this.clickOnActionButtons = true;
    let urlParam = "video";
    let channelLink = this.channel.oldDashChannelLink;
    
    if(this.data.source == "CLIP") {
      urlParam = "clip";
    }

    if(this.data.channel_link_url) {
      channelLink = this.data.channel_link_url;
    }    
    
    window.open(`${channelLink}/admin/${urlParam}/edit/${this.data.media_slug}`);

    setTimeout(() => {
      this.clickOnActionButtons = false;
    }, 1000);    
  }
}
