import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";
import "videojs-event-tracking";

@Component({
  selector: 'app-ads-tester',
  templateUrl: './ads-tester.component.html',
  styleUrls: ['./ads-tester.component.scss']
})
export class AdsTesterComponent implements OnInit {

  @ViewChild('videoTarget', {static: true}) videoTarget: ElementRef;
  player: VideoJsPlayer & Record<string, any>;
  loading: boolean = false;
  adsForm: FormGroup;
  adTagUrl: string = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/22674031151,154352941/didna/USSSA/usssalive.com/adtest1&description_url=https%3A%2F%2Fusssalive.com&env=vp&impl=s&plcmt=1&correlator=&tfcd=0&npa=0&gdfp_req=1&output=vast&sz=640x480&max_ad_duration=60000&vpmute=0&unviewed_position_start=1&vpa=click&vpos=preroll';


  constructor(
    private snackbar: MatSnackBar,
    ) {
    this.adsForm = new FormGroup({
      url: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.initPlayer("");
  }

  initPlayer(adUrl:string): void {

    let vjsConfig: VideoJsPlayerOptions = {
      preload: "auto",
      poster: "",
      controls: true,
      autoplay: false,
      techOrder: ["html5", "flash"],
      plugins: { eventTracking: true },
      html5: {
        nativeVideoTracks: false,
        nativeAudioTracks: false,
        nativeTextTracks: false,
        vhs : {
          maxPlaylistRetries : 2, //if the selected rendition playlist doesn't load until the max, the player should try another one instead of being hanged
          overrideNative: true
        },
        hls: {
          withCredentials: false,
          overrideNative: true,
          debug: true
        }
      }
    };

    this.player = videojs(this.videoTarget.nativeElement, vjsConfig);

    this.player.src({
      src: "https://content.jwplatform.com/manifests/yp34SRmf.m3u8",
      type: 'application/x-mpegurl'
    });

    let imaOptions = {
      id: 'videoTagId',
      adTagUrl: this.adTagUrl,
      vpaidMode: 2
      // adTagUrl: "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=",
    };
    this.player.ima(imaOptions);
    this.loading = false;

    this.player.on('adstart', () => {
      const adsManager = this.player.ima.getAdsManager();

      if (adsManager) {
        // Check if the ad is VPAID
        const ad = adsManager.getCurrentAd();

        console.log("PLAYING AD INFO", ad);

        if (ad.getAdPodInfo().getIsBumper()) {
          console.log('This is a bumper ad.');
        } else if (ad.isLinear() && ad.getContentType().includes('vpaid')) {
          console.log('This is a VPAID ad.');
        } else {
          console.log('This is a fallback (non-VPAID) ad.');
        }
      }
    });


    // Listen for VPAID-specific events
    this.player.on('aduseracceptinvitation', () => {
      console.log('User accepted the invitation to interact with a VPAID ad.');
    });


  }

  loadAd() {
    if (this.adTagUrl) {
      this.player.src({
        src: "https://content.jwplatform.com/manifests/yp34SRmf.m3u8",
        type: 'application/x-mpegurl'
      });
      this.player.ima.setContentWithAdTag(null, this.adTagUrl, true);
      this.player.ima.initializeAdDisplayContainer();
      this.player.ima.requestAds();
    }
  }



  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

}
