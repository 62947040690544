import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginComponent } from 'src/app/login/login.component';
import { UploadVideoService } from './upload-video.service';
import * as _ from 'lodash';
import { SignupComponent } from 'src/app/signup/signup.component';
import { ForgotPasswordComponent } from 'src/app/forgot-password/forgot-password.component';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss']
})
export class UploadVideoComponent implements OnInit {

  fileForm: FormGroup;

  file: any;
  fileType: any;
  fileDuration: any;
  fileName: any = "Select a File";
  hasFile: boolean = false;

  loading: boolean = false;

  user: any;

  constructor(
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog,
    private uploadVideoService: UploadVideoService
  ) {

    this.fileForm = new FormGroup({
      title: new FormControl(''),
      description: new FormControl('')
    });
   }

  async ngOnInit() {
    this.user = await this.authService.getSession();
  }

  async publish() {
    let videoData = {
      title: this.title.value,
      description: this.description.value,
      fileType: this.fileType,
      duration: this.fileDuration
    }

    this.loading = true;
    let response = await this.uploadVideoService.sendVideoData(videoData);
    this.loading = false;

    if (!response.signed_url) {

      if (response.error) {
        this.openSnackBar(
          `${response.error}.`,
          "snack-bar-danger"
        );
        return;
      }
      
      this.openSnackBar(
        "Please fill out the fields correctly.",
        "snack-bar-danger"
      );
      return;
    }

    if (response.signed_url) {
      this.loading = true;
      await this.uploadVideoService.uploadFileUrl(response.signed_url, this.file);
      this.loading = false;
      
      this.openSnackBar(
        "Video Uploaded Successfully!",
        "snack-bar-success"
      );

      this.file = null;
      this.fileForm.reset();
      this.fileName = 'Select a File';
      this.hasFile = false;
    }
    
  }

  async openSignin(){
    const dialogRef = this.dialog.open(LoginComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {isModal: true},
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if(result == 'success') {
      window.location.reload();
    }
    else if(result == 'signup') {
      this.openSignup();
    }
    else if(result == 'forgot') {
      this.openForgotPassword();
    }
  }

  async openSignup() {
    const dialogRef = this.dialog.open(SignupComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {isModal: true},
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if(result == 'success') {
      window.location.reload();
    }
    else if(result == 'signin') {
      this.openSignin();
    }
  }

  async openForgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {isModal: true},
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if(result == 'signin') {
      this.openSignin();
    }
  }

  onFileChanged(fileInput) {
    if (!fileInput.files.length)
      return;

    let file: File = fileInput.files[0];
    let pattern = /video-*/;
    let fileType = file.type;
    let video = document.createElement('video');

    window.URL = window.URL || window.webkitURL;
    video.preload = 'metadata';
    
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      this.fileDuration = video.duration;
    }
    
    video.src = URL.createObjectURL(file);

    // if (!fileType && !!~file.name.indexOf('.flv')) {
    //   fileType = 'video/flv';
    // }

    if (!fileType.match(pattern) || !fileType) {
      return this.openSnackBar(
        `File ${file.name} is not a video.`,
        "snack-bar-danger"
      );
    }

    this.fileType = fileType.split('/')[1];
    this.file = file;
    this.fileName = file.name;
    this.hasFile = true;
  }

  cleanFileValue(event) {
    event.target.value = '';
  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }

  get title(): AbstractControl {
    return this.fileForm.get('title');
  }

  get description(): AbstractControl {
    return this.fileForm.get('description');
  }

}
