import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../analytics.service';
import { AuthService } from '../auth/auth.service';
import { ChannelService } from '../services/channel.service';
import { VideosService } from '../services/videos.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading: boolean = false;
  loginForm: FormGroup;
  token: any = null;
  createChannelRedirectUrl: string = ''
  allowCreateChildSites: boolean = false; 

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<LoginComponent>,
    public authService: AuthService,
    public videoService : VideosService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private analyticsService : AnalyticsService,
    private channelService: ChannelService
  ) {
    this.analyticsService.trackingSignIn();
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), Validators.required]),
      password: new FormControl('', [Validators.required])
    });

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
   }

  async ngOnInit() {
    await this.authService.createRoutine();
    
    if (this.token && this.router.url.indexOf('login-by-facebook')) {
      await this.authService.loginFacebook(this.token);
    
      this.openSnackBar(
        (<HTMLInputElement>document.getElementById("success")).textContent,
        "snack-bar-success"
      );

      this.router.navigateByUrl("/");
    }
  }

  async ngAfterViewInit() {
    this.createChannelRedirectUrl = await this.channelService.getCreateChannelUrl();
    this.allowCreateChildSites = await this.channelService.allowCreateChildSites();
  }

  async login(){
    if(!this.loginForm.valid) {
      return;
    }

    this.loading = true;
    let session = await this.authService.login(this.loginForm.value);
   
    this.loading = false;

    if (session?.error) {
      if (session.errorMessage) {
        this.openSnackBar(
          `${session.errorMessage}.`,
          "snack-bar-danger"
        );
        return;
      }
      
      this.openSnackBar(
        "Credentials Mismatch.",
        "snack-bar-danger"
      );

      return;
    }
    
    this.analyticsService.trackingLogin();
    this.analyticsService.setUserProperties({session});
    
    this.openSnackBar(
      (<HTMLInputElement>document.getElementById("success")).textContent,
      "snack-bar-success"
    );

    if(this.data.isModal) {
      this.dialogRef.close('success');
      return;
    }

    this.router.navigateByUrl("/");
  }

  goToSignup() {
    if(this.data.isModal) {
      this.dialogRef.close('signup');
      return;
    }

    this.router.navigateByUrl("/signup");
  }
  
  goToForgotPassword() {   
    if(this.data.isModal) {
      this.dialogRef.close('forgot');
      return;
    }
    this.router.navigateByUrl("/forgot-password");
  }

  async signFacebook() {
    this.analyticsService.trackingLoginFacebook();
    window.open(`${environment.api_vms_url}/authenticate/facebook2?domain=${window.location.origin}`, '_self');
  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }

  close() {
		if(this.data.isModal) {
			this.dialogRef.close();
		}
		else {
			this.router.navigateByUrl("/");
		}
  }
}
