<div class='main-container'>
  <div class='section-header'>
    <h1 class='title'>{{'categories.categories-tags' | translate}}</h1>
    <h2 class='subtitle'>{{totalCategories}} {{'categories.available' | translate}}</h2>
    <div *ngIf="popularTags && popularTags.length" class='popular-tags'>
      <h3>{{'categories.popular-tags' | translate}}</h3>
      <a [routerLink]="['/tag/' + tag.tag_slug]" *ngFor="let tag of popularTags">{{tag.tag_name}}</a>
    </div>
  </div>

  <form class='filter-form'>
    <div class="filter-box">
      <mat-form-field class="search" >
        <mat-label>{{'filters.search' | translate}}</mat-label>
        <input matInput type="text" [formControl]="searchTagInput" (keyup)="onSearch($event.target.value)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      
      <mat-form-field appearance="outline">
        <mat-label>{{'filters.sort-by.title' | translate}}</mat-label>
        <mat-select [(value)]="orderBy" (selectionChange)="onFilter($event.value)" disableOptionCentering>
          <mat-option value="recent">{{'filters.sort-by.latest' | translate}}</mat-option>
          <mat-option value="older">{{'filters.sort-by.older' | translate}}</mat-option>
          <mat-option value="ascendingCharacters">{{'filters.sort-by.a-to-z' | translate}}</mat-option>
          <mat-option value="descendingCharacters">{{'filters.sort-by.z-to-a' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div class="example-loading-shade" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  
  <ng-container *ngIf="allTags?.length > 0 && !loading">
    <div class="section-title">{{'categories.tags' | translate}}</div>
    <div class="filtered-tags">
      <a [routerLink]="['/tag/' + tag.tag_slug]" class="tag" *ngFor="let tag of allTags">{{tag.tag_name}}</a>  
    </div>
  </ng-container>

  <ng-container *ngIf='!loading'>
    <div class="section-title">{{'categories.categories' | translate}}</div>
    <div class='content'>
  
      <div class="category" *ngIf="isLive">
        <app-category [category]="live" [pageCategories]="true" [isLive]="true"></app-category>
      </div>
  
      <div class="category" *ngFor="let category of allCategories; let i = index">
        <app-category [category]="category" [pageCategories]="true"></app-category>
      </div>
  
      <div *ngIf='!allCategories.length' class='no-events'>
        {{'categories.no-categories' | translate}}
      </div>
      
      <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
      ></div>
    </div>
  </ng-container>
  <ng-container>
    <div class="more-loading categories" *ngIf="loadingMore">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </ng-container>

</div>
