import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class VideosService {

  constructor(private httpClient: HttpClient, private authService : AuthService) { }

  async getAllPublicPromotedCategories() {

    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/list-all-promoted-categories`, {headers}).toPromise();
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getAllPromotedMediaFromCategory(categoriesId: any) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.post(`${environment.api_vms_url}/promoted-categories`, {categories: categoriesId}, {headers}).toPromise();
      return response.promoted_categories || [];
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getAllPromotedMediaFromSubCategories(categorySlug: string) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/promoted-sub-categories?category_slug=${categorySlug}`, {headers}).toPromise();
      return response.promoted_categories;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getAllPromotedMedias() {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/promoted-medias`, {headers}).toPromise();
      return response.promoted_media;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getAllPromotedMediasByCategory(category_id) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/promoted-medias/category?category_id=${category_id}`, {headers}).toPromise();
      return response || [];
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }
  
  async getAllLives(mediaId?: any) {
    const headers = await this.authService.getHeadersSession();
    let response: any = await this.httpClient.get(`${environment.api_vms_url}/list-all-lives?media_id=${mediaId}`, {headers}).toPromise();
    return response.current_lives || [];
  }

  async getRelatedVideos(filters) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/related_videos`,{
        headers,
        params: filters
      }).toPromise();
      return response.related_videos;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getAllLivesByCategory(category_id) {
    const headers = await this.authService.getHeadersSession();
    let response: any = await this.httpClient.get(`${environment.api_vms_url}/list-all-lives/category?category_id=${category_id}`, {headers}).toPromise();
    return response.current_lives || [];
  }

  async getMostViewedVideos(limit, offset, filters?) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/most-viewed-videos?offset=${offset}&limit=${limit}`, {
        headers,
        params: filters
      }).toPromise();
      return response.most_viewed_videos;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getMostViewedVideosByCategory(category_id) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/most-viewed-videos/category?category_id=${category_id}`, {headers}).toPromise();
      return response.most_viewed_videos;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getRecentVideos() {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/recent-videos`, {headers}).toPromise();
      return response.recent_videos;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getRecentVideosByCategory(category_id) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/recent-videos/category?category_id=${category_id}`, {headers}).toPromise();
      return response.recent_videos;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getVideoData(media_slug) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/video/${media_slug}`, {headers}).toPromise();
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getLiveData(media_slug) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/live/${media_slug}`, {headers}).toPromise();
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getSubscribers(media_id) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-subscribers-from-live/${media_id}`, {headers}).toPromise();
      return response.subscribers;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async updateFavoriteMedia(media_id, isFav) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.put(`${environment.api_vms_url}/favorite-media`, {
        media_id,
        isFav
      }, {headers}).toPromise();
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async updateDownloadCounter(mediaId, qualityName) {
    try {
      const headers = await this.authService.getHeadersSession();
      return await this.httpClient.post(`${environment.api_vms_url}/download-counter`, {
        mediaId,
        qualityName 
      }, {
        headers
      }).toPromise();

    } catch(error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async allowDownloading(mediaId, qualityName) {
    try {
      const headers = await this.authService.getHeadersSession();
      return await this.httpClient.get(`${environment.api_vms_url}/allow-downloading/${mediaId}`, {
        headers,
        params: { qualityName }
      }).toPromise();

    } catch(error) {
      this.authService.verifySession(error);
      return false;
    }
  }

}
