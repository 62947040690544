import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  imageSelected: any;

  constructor(
    public dialogRef: MatDialogRef<ImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.imageSelected = data.photos[data.index];
   }

  ngOnInit(): void {
  }

  selectImage(img) {
    this.imageSelected = img;
  }

  close() {
    this.dialogRef.close();
  }

}
