import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CancelRenewSubscriptionComponent } from 'src/app/main/subscriptions/subscription-content/cancel-renew-subscription/cancel-renew-subscription.component';
import { UserSubscriptionsService } from './user-subscriptions.service';
import { RedirectService } from 'src/app/redirect.service';

@Component({
  selector: 'app-user-subscriptions',
  templateUrl: './user-subscriptions.component.html',
  styleUrls: ['./user-subscriptions.component.scss']
})
export class UserSubscriptionsComponent implements OnInit {

  subscriptionData: any;
  changeCardInProgress: boolean = false;
  subscriptionService: any;
  @Input("subscriptions") subscriptions: any;
  @Output() updateSubscription: EventEmitter<any> =  new EventEmitter();
  
  constructor(
    private dialog: MatDialog,
    private userSubscriptionsService: UserSubscriptionsService,
    private redirectService : RedirectService
  ) { }

  ngOnInit(): void {
  }

  async openModalPlan(fluxTitle, subscription) {
    const dialogRef = this.dialog.open(CancelRenewSubscriptionComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {
        fluxTitle: fluxTitle,
        subscriptionData: subscription,
        thumbnailSubscription: '../../../assets/imgs/sub-thumb.jpg'
      },
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();
  
    if(result) {
      this.updateSubscription.emit();
    }
  }

  goToSubscription(subscription) {
    this.redirectService.goTo({
      domainName: subscription.domain_name,
      hostName : subscription.host_name,
      path : `/subscriptions/${subscription.subscription_slug}`
    })
  }

  billingFrequencyTreatment(plan){
    let frequency = plan.billing_frequency  || plan.offer_period;
    
    if(!frequency){
      return
    }

    if(plan.offer_period) {
      return plan.offer_period;
    }

    switch (frequency.toUpperCase()) {
      case 'YEAR':
        return 'annual';
      case 'MONTH':
        if(plan.billing_interval > 1) {
          return `${plan.billing_interval}-${plan.billing_frequency.toLowerCase()}`
        }
        return 'month';
      case 'DAY':
        return 'day';
      case 'WEEK':
        return 'week';
    }

    if(plan.billing_interval > 1) {
      return `${plan.billing_interval} ${plan.billing_frequency.toLowerCase()}`
    }
  }

  async changeCard(subscription) {
    if (!this.changeCardInProgress) {
      this.changeCardInProgress = true;
      const customerPortal = await this.userSubscriptionsService.getSubscriptionCustomerPortal(subscription.agreement_id);
      window.location.href = customerPortal.session.url;
      this.changeCardInProgress = false;
    }
  }
}
