<div class="container-login">

  <div class="content" [ngClass]='{"content-modal": data.isModal}'>

    <mat-icon class='close-button' (click)='close()'>close</mat-icon>

    <h1>{{'login.title' | translate}}</h1>
    <h3>{{'login.sub-title' | translate}} <a (click)='goToSignup()'>{{'login.signup' | translate}}</a></h3>

    <h3 *ngIf="allowCreateChildSites" >{{'login.create-channel-title' | translate}} <a href="{{createChannelRedirectUrl}}" target="_blank" >{{'login.create-channel' | translate}}</a></h3>

    <button mat-flat-button class="facebook-button" (click)='signFacebook()'> <img src="../../assets/icons/facebook-f-brands.svg" alt="">{{'login.facebook' | translate}}</button>

    <div class="hr-text" data-content="or"></div>

    <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off">

      <mat-form-field appearance="outline" autocomplete="off">
        <mat-label>{{'login.email.text' | translate}}</mat-label>
        <input matInput type="email" formControlName="email">
        <mat-error *ngIf="loginForm.get('email').hasError('required')">
          {{'login.email.email-is' | translate}} <strong>{{'login.email.email-required' | translate}}</strong>
        </mat-error>
        <mat-error *ngIf="loginForm.get('email').hasError('email') || loginForm.get('email').hasError('pattern')">
          {{'login.email.must-be' | translate}} <strong>{{'login.email.email' | translate}}</strong>
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="outline" autocomplete="off">
        <mat-label>{{'login.password.text' | translate}}</mat-label>
        <input matInput type="password" formControlName="password">
        <mat-error *ngIf="loginForm.get('password').hasError('required')">
          {{'login.password.password-is' | translate}} <strong>{{'login.password.password-required' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <a class="forgot-password" (click)="goToForgotPassword()">{{'login.forgot' | translate}}</a>

      <button mat-flat-button class="login-button" type="submit" *ngIf='!loading'>{{'login.login' | translate}}</button>
      <button mat-flat-button color='primary' class='loading login-button' *ngIf='loading'>
        <mat-spinner diameter='30'></mat-spinner>
      </button>
    </form>

  </div>
  <div id="success" style="display: none;">{{'login.success' | translate}}</div>
</div>