import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { AuthService } from '../auth/auth.service';

@Pipe({
  name: 'dateTimezone'
})
export class DateTimezonePipe extends DatePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private authService: AuthService
    )  {
    super(locale);
  }

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number| null | undefined, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: Date | string | number| null | undefined, format = 'mediumDate', timezone?: string, locale?: string): string | null {
    let userTimezone = this.authService.getTimezone();
 
    if (userTimezone) {
      timezone = moment(value).tz(userTimezone).format('Z');
    }

    return super.transform(value, format, timezone, locale );
  }

}
