import { Component, OnInit } from '@angular/core';
import { ChannelService } from 'src/app/services/channel.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  data: any;
  loading: boolean = true;

  constructor(
    private channelService: ChannelService 
  ) { }

  async ngOnInit(){
    this.data = await this.channelService.getChannelAboutUs();
    this.loading = false;
  }
}
