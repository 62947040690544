import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
declare function $vvvInit(containerId, adId, streamUrl, streamType, posterUrl): void;


import { ExtractionService } from 'src/app/services/extraction.service';
import { Subscription } from 'rxjs';
import { TaggingService } from 'src/app/services/tagging.service';


@Component({
  selector: 'app-snack-player',
  templateUrl: './snack-player.component.html',
  styleUrls: ['./snack-player.component.scss']
})
export class SnackPlayerComponent implements OnInit, AfterViewInit {

  private player: any;
  @Input() urls;
  @Input() mediaData;
  // @Input() gamePlays;
  @Input() channel;
  @Input() hlsURL: any;
  @Input() playerSettings: any;
  @Input() poster;
  @Input() isLive: boolean = false;

  private controlBarSubscription: Subscription;

  constructor(
    private extractionService: ExtractionService,
    private taggingService: TaggingService
  ) { }

  ngOnInit(): void {
    this.loadScript();
  }

  loadScript(): void {
    const script = document.createElement('script');
    script.src = 'https://probid.ai/video?domain=usssalive.com';
    script.async = true;
    script.onload = () => this.initializeVVV(); // Run this function once the script is loaded
    document.body.appendChild(script);
  }

  initializeVVV(): void {
    (window as any).$vvv = (window as any).$vvv || {};
    (window as any).$vvv.queue = (window as any).$vvv.queue || [];
    (window as any).$vvv.queue.push(() => {
      this.player = $vvvInit(
        "vvv-video-container",
        "snackmedia-videoplayer-instream/usssalive.com-instream",
        [this.hlsURL],
        "instream",
        [this.poster]
      )
      this.player.then((instance) => {
        console.log("Testing the instance: ", instance);
        // instance.on('timeupdate', () => {
        //   console.log("=====>>>> Time is updating continously");
        // })

        this.taggingService.seekToTime$.subscribe((duration) => {
          instance.currentTime(duration - this.mediaData.recording_start_utc_ts);
        });

        instance.on('error', () => {
          console.log(instance.error);
          this.extractionService.getVideoDuration(false);
        });

        instance.on('loadedmetadata', () => {
          const videoDuration = instance.duration();
          this.extractionService.getVideoDuration(videoDuration)

          // Verify the markers plugin is available before applying markers

          instance.ready(() => {
            if (instance.markers) {
              instance.markers({
                markers: [
                  { time: 0, duration: 3600 },
                ],
              });
            } else {
              console.error("Markers plugin is not available on the player instance.");
            }
          });

        });

        this.extractionService.videoTime$.subscribe(([newTime, newWidth]) => {
          instance.ready(() => {
            instance.currentTime(newTime);
            // this.sliderWidth = newWidth;
            // this.slideMarker(newTime,newWidth);
            instance.play();
          });
        });

        this.controlBarSubscription = this.extractionService.videoControls$.subscribe((show) => {
          if (show) {
            instance.on('userinactive', () => {
              const newClass = 'vjs-user-active';
              instance.el().classList.remove('vjs-user-inactive');
              instance.el().classList.add(newClass);
            });
          } else {
            const newClass = 'vjs-user-inactive';
            instance.el().classList.remove('vjs-user-active');
            instance.el().classList.add(newClass);
          }
        });

      })
    });
  }

  ngAfterViewInit(): void {
    // Optional: Any additional setup after view initialization can go here


  }

}
