<div class="content-terms">
  <div class="panel">
    <p>{{'privacy-policy.title' | translate}}</p>
  </div>
  <div class="channel-info">
    <div class="text">
      <p>{{'privacy-policy.description' | translate}}</p>
      <h4>{{'privacy-policy.collection' | translate}}</h4>
      <p>{{'privacy-policy.collection-description-1' | translate}}</p>
      <p>{{'privacy-policy.collection-description-2' | translate}}</p>
      <p>{{'privacy-policy.collection-description-3' | translate}}</p>
      <h4>{{'privacy-policy.use-personal-information' | translate}}</h4>
      <p>{{'privacy-policy.personal-info-description-1' | translate}}</p>
      <p>{{'privacy-policy.personal-info-description-2' | translate}}</p>
      <p>{{'privacy-policy.personal-info-description-3' | translate}}</p>
      <p>{{'privacy-policy.personal-info-description-4' | translate}}</p>
      <p>{{'privacy-policy.personal-info-description-5' | translate}}</p>
      <p>{{'privacy-policy.personal-info-description-6' | translate}}</p>
      <h4>{{'privacy-policy.use-of-cookies' | translate}}</h4>
      <p>{{'privacy-policy.use-of-cookies-description-1' | translate}}</p>
      <p>{{'privacy-policy.use-of-cookies-description-2' | translate}}</p>
      <p>{{'privacy-policy.use-of-cookies-description-3' | translate}}</p>
      <h4>{{'privacy-policy.security' | translate}}</h4>
      <p>{{'privacy-policy.security-description' | translate}}</p>
      <h4>{{'privacy-policy.children-underage' | translate}}</h4>
      <p>{{'privacy-policy.children-underage-description' | translate}}</p>
      <h4>{{'privacy-policy.disconnecting-account' | translate}}</h4>
      <p>{{'privacy-policy.disconnecting-account-description' | translate}}</p>
      <h4>{{'privacy-policy.changes-statement' | translate}}</h4>
      <p>{{'privacy-policy.changes-statement-description' | translate}}</p>
      <p>{{'privacy-policy.effective' | translate}} January 1, 2018</p>
    </div>    
  </div>
</div>