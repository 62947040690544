<div class="content">
  <div class="panel">
    <p>{{'tv-apps.title' | translate}}</p>
  </div>
  <div class="tvapps-info">
    <ng-container *ngIf="!loading">
      <div class="tablen-img">
        <img src="../../../assets/imgs/tv-tablen.png" />
      </div>
      <div class="apps-img">
        <a [href]="data?.ott_roku" *ngIf="data?.ott_roku" target="_blank"><img src="../../../assets/imgs/roku_app_store_icon.png" /></a>
        <a [href]="data?.ott_apple" *ngIf="data?.ott_apple" target="_blank"><img src="../../../assets/imgs/apple_app_store_icon.png" /></a>
        <a [href]="data?.ott_fire" *ngIf="data?.ott_fire" target="_blank"><img src="../../../assets/imgs/amazon_app_store_icon.png" /></a>
      </div>
    </ng-container>

    <div class="example-loading-shade" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>