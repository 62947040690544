<div class='dialog-title'>
	<div class='wider'></div>
	<p>{{'request-help.modal-title' | translate}}</p>
	<mat-icon (click)='close()'>close</mat-icon>
</div>

<form [formGroup]="form" (ngSubmit)="sendRequest()" autocomplete="off">

	<mat-form-field appearance="outline" autocomplete="off">
		<mat-label>{{'request-help.full-name' | translate}}</mat-label>
		<input matInput type="email" formControlName="fullname" maxlength="100">
		<mat-error *ngIf="form.get('fullname').hasError('required')">
			{{'request-help.full-name-is' | translate}} <strong>{{'request-help.required' | translate}}</strong>
		</mat-error>
	</mat-form-field>

	<mat-form-field appearance="outline" autocomplete="off">
		<mat-label>{{'request-help.email-text' | translate}}</mat-label>
		<input matInput type="email" formControlName="email" maxlength="100">
		<mat-error *ngIf="form.get('email').hasError('required')">
			{{'request-help.email-is' | translate}} <strong>{{'request-help.required' | translate}}</strong>
		</mat-error>
		<mat-error *ngIf="form.get('email').hasError('pattern')">
			{{'request-help.must-be' | translate}} <strong>{{'request-help.email' | translate}}</strong>
		</mat-error>
	</mat-form-field>

	<mat-form-field appearance="outline" autocomplete="off">
		<mat-label>{{'request-help.reason' | translate}}</mat-label>
		<mat-select formControlName="reason" disableOptionCentering>
			<mat-option [value]="reason" *ngFor='let reason of reasons'>{{reason}}</mat-option>
		</mat-select>
		<mat-error *ngIf="form.get('reason').hasError('required')">
			{{'request-help.reason-is' | translate}} <strong>{{'request-help.required' | translate}}</strong>
		</mat-error>
	</mat-form-field>

	<mat-form-field appearance="outline" autocomplete="off">
		<mat-label>{{'request-help.enquiry-details' | translate}}</mat-label>
		<textarea rows='8' matInput formControlName="enquiryDetails" maxlength="500"></textarea>
		<mat-error *ngIf="form.get('enquiryDetails').hasError('required')">
			{{'request-help.enquiry-details-is' | translate}} <strong>{{'request-help.required' | translate}}</strong>
		</mat-error>
	</mat-form-field>

	<div class='recaptcha'>
		<ngx-recaptcha2 #captchaElem
			[siteKey]="siteKey"
			[useGlobalDomain]="false"
			formControlName="recaptcha">
		</ngx-recaptcha2>
	</div>

	<div style="display: flex; justify-content: center; padding-bottom: 24px;">
		<button [disabled]='!form.valid' mat-flat-button class="send-request-btn" type="submit" *ngIf='!loading'>{{'request-help.send' | translate}}</button>
		<button mat-flat-button class='loading send-request-btn' *ngIf='loading'>
			<mat-spinner diameter='30'></mat-spinner>
		</button>
	</div>
</form>
