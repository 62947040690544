<div class="container-signup">

  <div class="content">

    <!-- <mat-icon class='close-button' (click)='close()'>close</mat-icon> -->

    <h1>{{'reset-password.title' | translate}}</h1>
    <h3>{{'reset-password.sub-title' | translate}}</h3>

    <form [formGroup]="resetPasswordForm" (ngSubmit)="reset()" autocomplete="off">
  
      <mat-form-field appearance="outline" autocomplete="off">
        <mat-label>{{'reset-password.password.text' | translate}}</mat-label>
        <input matInput type="password" formControlName="password">
        <!-- <mat-hint>Must be at least 8 characters</mat-hint> -->
        <mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">
          {{'reset-password.password.password-is' | translate}} <strong>{{'reset-password.password.password-required' | translate}}</strong>
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.get('password').hasError('minlength')">
          {{'reset-password.password.must-be' | translate}} <strong>{{'reset-password.password.char' | translate}}</strong>
        </mat-error>
      </mat-form-field>
      
      <mat-form-field appearance="outline" autocomplete="off">
        <mat-label>{{'reset-password.confirm-password.text' | translate}}</mat-label>
        <input matInput type="password" formControlName="confirmPassword">
        <mat-error *ngIf="resetPasswordForm.get('confirmPassword').hasError('required')">
          {{'reset-password.confirm-password.password-is' | translate}} <strong>{{'reset-password.confirm-password.password-required' | translate}}</strong>
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.get('confirmPassword').hasError('minlength')">
          {{'reset-password.password.must-be' | translate}} <strong>{{'reset-password.password.char' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <p class='error' *ngIf="errorMessage">{{errorMessage}}</p>
      
      <button mat-flat-button class="signup-button" type="submit"  *ngIf='!loading'>{{'reset-password.reset' | translate}}</button>
      <button mat-flat-button color='primary' class='loading signup-button' *ngIf='loading'>
        <mat-spinner diameter='30'></mat-spinner>
      </button>
    </form>

  </div>
</div>
