import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  lang: any;

  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<LanguageComponent>
    ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') || 'en';
  }

  changeLang(lang: any) {
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  
}
