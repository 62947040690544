<div class='dialog-title'>
	<div class='wider'></div>
	<p>{{'video.share-content.title' | translate}}</p>
	<mat-icon (click)='close()'>close</mat-icon>
</div>

<div class='content'>
	<ng-container *ngIf='!isEmbed'>
		<div class='share-link'>
			<p class='title'>{{'video.share-content.link' | translate}}</p>
			<div class='platforms'>
				<img (click)='toggleEmbedCode()' src='../../../../assets/icons/tag-code.svg'>
				<a href='https://www.facebook.com/sharer/sharer.php?u={{router}}' target="_blank"><img src='../../../../assets/icons/circle-facebook.svg'></a>
				<a href="https://twitter.com/intent/tweet?source=tweetbutton&url={{router}}" target="_blank"><img src='../../../../assets/icons/circle-twitter.svg'></a>
			</div>
		</div>
	
		<div class='copy-link'>
			<p class='title'>{{'video.share-content.copy-link' | translate}}</p>
			<div class='link-content'>
				<span class='link'>{{router}}</span>
				<span class='copy-btn copied' [cdkCopyToClipboard]="router" (click)='copiedToClipboard()' *ngIf='copied'>{{'video.share-content.copied' | translate}}</span>
				<span class='copy-btn' [cdkCopyToClipboard]="router" (click)='copiedToClipboard()' *ngIf='!copied'>{{'video.share-content.copy' | translate}}</span>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf='isEmbed'>
		<div class='embed'>
			<p class='title'>{{'video.share-content.embed-code' | translate}}</p>
			<div class='link-content'>
				<span>{{embedCode}}</span>
			</div>

			<form [formGroup]="form">
				<mat-form-field appearance="outline" autocomplete="off" (keydown.e)="false" (keydown.-)="false" (keydown.E)="false" (input)='changeIframe()'>
					<mat-label>{{'video.share-content.width' | translate}}</mat-label>
					<input matInput formControlName="width" type='number' min="0">
				</mat-form-field>

				<mat-form-field appearance="outline" autocomplete="off" (keydown.e)="false" (keydown.-)="false" (keydown.E)="false" (input)='changeIframe()'>
					<mat-label>{{'video.share-content.height' | translate}}</mat-label>
					<input matInput formControlName="height" type='number' min="0">
				</mat-form-field>

        <mat-checkbox formControlName='autoPlay' color="primary" class='auto-play-checkbox' (input)='changeIframe()'>
          Auto play
        </mat-checkbox>     

				<div class='buttons'>
					<button mat-stroked-button class='cancel' (click)='toggleEmbedCode()'>{{'video.share-content.cancel' | translate}}</button>
					<button mat-flat-button class='copy copied' (click)='copiedToClipboard()' [cdkCopyToClipboard]="embedCode" *ngIf='copied'>{{'video.share-content.copied' | translate}}</button>
					<button mat-flat-button class='copy' (click)='copiedToClipboard()' [cdkCopyToClipboard]="embedCode" *ngIf='!copied'>{{'video.share-content.copy' | translate}}</button>
				</div>
			</form>
		</div>
	</ng-container>
</div>
