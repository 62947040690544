<div class="content">
  <div class="panel">
    <p>{{'cookie-policy.title' | translate}}</p>
  </div>
  <div class="channel-info">
    <div class='cookie-text'>
      <p>{{'cookie-policy.about' | translate}} <a href="https://vewbie.com/">https://vewbie.com</a></p>
      <h4>{{'cookie-policy.what-are' | translate}}</h4>
      <p>{{'cookie-policy.what-are-description' | translate}}</p>
      <h4>{{'cookie-policy.how-use' | translate}}</h4>
      <p>{{'cookie-policy.how-use-description' | translate}}</p>
      <h4>{{'cookie-policy.disabling' | translate}}</h4>
      <p>{{'cookie-policy.disabling-description' | translate}}</p>
      <h4>{{'cookie-policy.cookies-set' | translate}}</h4>
      <p>{{'cookie-policy.cookies-set-description' | translate}}</p>
      <ul>
        <li>{{'cookie-policy.topic-account' | translate}}</li>
        <ul>
          <li>
            {{'cookie-policy.topic-account-description' | translate}}
          </li>
        </ul>

        <li>{{'cookie-policy.topic-login' | translate}}</li>
        <ul>
          <li>
            {{'cookie-policy.topic-login-description' | translate}}
          </li>
        </ul>

        <li>{{'cookie-policy.topic-site' | translate}}</li>
        <ul>
          <li>
            {{'cookie-policy.topic-site-description' | translate}}
          </li>
        </ul>
      </ul>
      <h4>{{'cookie-policy.more-information' | translate}}</h4>
      <p>{{'cookie-policy.more-information-description' | translate}}</p>
      <p>{{'cookie-policy.looking-more-information' | translate}}</p>

      <ul>
        <li>
          {{'cookie-policy.what-uses' | translate}}
        </li>

        <ul>
          <li>{{'cookie-policy.email' | translate}} admin@vewbie.com</li>
          <li>{{'cookie-policy.visiting' | translate}} <a href="https://vewbie.com/">https://vewbie.com</a></li>
        </ul>
      </ul>
    </div>
  </div>
</div>