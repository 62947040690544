<div class="content">
    <div class="refund">
      <h2 class="refund-header"> {{'refund-policy.title' | translate}} </h2>
      <p>{{'refund-policy.description-1' | translate}}</p>
      <p>{{'refund-policy.description-2' | translate}}</p>
      <p>{{'refund-policy.description-3' | translate}}</p>
      <p>{{'refund-policy.description-4' | translate}}</p>
      <p>{{'refund-policy.description-5' | translate}}</p>
      <p>{{'refund-policy.description-6' | translate}}</p>
      <p>{{'refund-policy.description-7' | translate}}</p> 
      <label>{{'refund-policy.description-8' | translate}}</label>
      <ul type="circle">
        <li>{{'refund-policy.description-9' | translate}}</li>
        <li>{{'refund-policy.description-10' | translate}}</li>
        <ol type="1">
          <li>{{'refund-policy.description-11' | translate}}</li>
          <li>{{'refund-policy.description-12' | translate}} </li>
          <li>{{'refund-policy.description-13' | translate}}</li>
          <li>{{'refund-policy.description-14' | translate}}</li>
          <li>{{'refund-policy.description-15' | translate}}</li>
        </ol>
      </ul>
      <p><strong>{{'refund-policy.description-16' | translate}}</strong></p>
      <p>{{'refund-policy.description-17' | translate}}</p>
      <p>{{'refund-policy.description-18' | translate}}</p>
      <p><strong>{{'refund-policy.description-19' | translate}}</strong></p>
      <p>{{'refund-policy.description-20' | translate}}</p>
      <label>{{'refund-policy.description-21' | translate}}</label>
      <label>{{'refund-policy.description-22' | translate}}</label>
      <p>{{'refund-policy.description-23' | translate}}</p>
      <p>{{'refund-policy.description-24' | translate}}</p>
      <ol type="1">
        <li><strong>{{'refund-policy.general' | translate}}</strong></li>
        <ol type="1">
          <li>{{'refund-policy.general-description-1' | translate}}</li>
          <li>{{'refund-policy.general-description-2' | translate}}</li>
          <li>{{'refund-policy.general-description-3' | translate}}</li>
          <li>{{'refund-policy.general-description-4' | translate}}</li>
        </ol>
        <li><strong>{{'refund-policy.account-content-provisions' | translate}}</strong></li>
        <ol type="1">
          <li>{{'refund-policy.account-content-provisions-description-1' | translate}}</li>
          <li>{{'refund-policy.account-content-provisions-description-2' | translate}}</li>
        </ol>
        <li><strong>{{'refund-policy.service' | translate}}</strong></li>
        <ol type="1">
          <li>{{'refund-policy.service-description-1' | translate}}</li>
          <li>{{'refund-policy.service-description-2' | translate}}</li>
          <li>{{'refund-policy.service-description-3' | translate}}</li>
          <li>{{'refund-policy.service-description-4' | translate}}</li>
        </ol>
        <li><strong>{{'refund-policy.costs' | translate}}</strong></li>
        <ol type="1">
          <li>{{'refund-policy.costs-description-1' | translate}}</li>
          <li>{{'refund-policy.costs-description-2' | translate}}</li>
          <li>{{'refund-policy.costs-description-3' | translate}}</li>
        </ol>
        <li><strong>{{'refund-policy.intellectual-property' | translate}}</strong></li>
        <ol type="1">
          <li>{{'refund-policy.intellectual-property-description' | translate}}</li>
        </ol>
        <li><strong>{{'refund-policy.billing-payment-refunds' | translate}}</strong></li>
        <ol type="1">
          <li>{{'refund-policy.billing-payment-refunds-description-1' | translate}}</li>
          <li>{{'refund-policy.billing-payment-refunds-description-2' | translate}}</li>
          <li>{{'refund-policy.billing-payment-refunds-description-3' | translate}}</li>
          <li>{{'refund-policy.billing-payment-refunds-description-4' | translate}}</li>
        </ol>
        <li><strong>{{'refund-policy.privacy' | translate}}</strong></li>
        <ol type="1">
          <li>{{'refund-policy.privacy-description-1' | translate}}</li>
          <li>{{'refund-policy.privacy-description-2' | translate}}</li>
          <ol type="1">
            <li>{{'refund-policy.privacy-description-2.1' | translate}}</li>
            <li>{{'refund-policy.privacy-description-2.2' | translate}}</li>
            <li>{{'refund-policy.privacy-description-2.3' | translate}}</li>
            <li>{{'refund-policy.privacy-description-2.4' | translate}}</li>
          </ol>
          <li>{{'refund-policy.privacy-description-3' | translate}}</li>
          <p>{{'refund-policy.privacy-description-3-sub' | translate}}</p>
          <ol type="1">
            <li>{{'refund-policy.privacy-description-3.1' | translate}}</li>
            <li>{{'refund-policy.privacy-description-3.2' | translate}}</li>
            <li>T{{'refund-policy.privacy-description-3.3' | translate}}</li>
          </ol>
          <li>{{'refund-policy.privacy-description-4' | translate}}</li>
          <p>{{'refund-policy.privacy-description-4-sub' | translate}}</p>
          <ol type="1">
            <li>{{'refund-policy.privacy-description-4.1' | translate}}</li>
            <li>{{'refund-policy.privacy-description-4.2' | translate}}</li>
            <li>{{'refund-policy.privacy-description-4.3' | translate}}</li>
          </ol>
          <li>{{'refund-policy.privacy-description-5' | translate}}</li>
          <p>{{'refund-policy.privacy-description-5-sub' | translate}}</p>
          <li>{{'refund-policy.privacy-description-6' | translate}}</li>
          <li>{{'refund-policy.privacy-description-7' | translate}}</li>
          <li>{{'refund-policy.privacy-description-8' | translate}}</li>
          <li>{{'refund-policy.privacy-description-9' | translate}}</li>
          <li>{{'refund-policy.privacy-description-10' | translate}} <p>{{'refund-policy.privacy-description-10-sub' | translate}}</p>
          </li>
          <li>{{'refund-policy.privacy-description-11' | translate}} <p>{{'refund-policy.privacy-description-11-sub' | translate}}</p>
          </li>
          <li>{{'refund-policy.privacy-description-12' | translate}}</li>
          <li>{{'refund-policy.privacy-description-13' | translate}}</li>
          <li>{{'refund-policy.privacy-description-14' | translate}}</li>
          <li>{{'refund-policy.privacy-description-15' | translate}} <p>{{'refund-policy.privacy-description-15-sub' | translate}}</p>
          </li>
          <li>{{'refund-policy.privacy-description-16' | translate}}</li>
        </ol>
        <li><strong>{{'refund-policy.termination' | translate}}</strong></li>
        <ol type="1">
          <li>{{'refund-policy.termination-description-1' | translate}}</li>
          <li>{{'refund-policy.termination-description-2' | translate}}</li>
        </ol>
        <li><strong>{{'refund-policy.miscellaneous' | translate}}</strong></li>
        <ol type="1">
          <li>{{'refund-policy.miscellaneous-description-1' | translate}}</li>
          <li>{{'refund-policy.miscellaneous-description-2' | translate}}</li>
          <li>{{'refund-policy.miscellaneous-description-3' | translate}}</li>
          <li>{{'refund-policy.miscellaneous-description-4' | translate}}</li>
        </ol>
        <li><strong>{{'refund-policy.contact-us' | translate}}</strong></li>
        <p>{{'refund-policy.contact-us-description-1' | translate}}</p>
        <p>{{'refund-policy.contact-us-description-2' | translate}}</p>
        <p>{{'refund-policy.contact-us-description-3' | translate}}</p> 
        <span>{{'refund-policy.contact-us-description-4' | translate}}</span>
      </ol>
    </div>
    <div class="buttons">
      <button mat-stroked-button (click)="cancel()">{{'refund-policy.disagree' | translate}}</button>
      <button mat-flat-button (click)="agree()">{{'refund-policy.agree' | translate}}</button>
    </div>
</div>