import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VideoContentComponent } from 'src/app/main/video/video-content/video-content.component';
import { VideosService } from 'src/app/services/videos.service';

@Component({
  selector: 'app-download-button-list',
  templateUrl: './download-button-list.component.html',
  styleUrls: ['./download-button-list.component.scss'],
})
export class DownloadButtonList implements OnInit {
  
  @Input() mediaData: any = {};
  downloadAvaliable: boolean = false;

  @Output()
  subscription: EventEmitter<any> = new EventEmitter<any>();

  @Output() addToCart: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(
    private videoService: VideosService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.isDownloadAvailable();
  }

  isDownloadAvailable() {
    if (this.mediaData.content_access_type === 'DOWNLOAD' || this.mediaData.content_access_type === 'VIEW_DOWNLOAD') {
      this.downloadAvaliable = this.mediaData.downloadEnable && this.mediaData.isPaid;
    } else if (this.mediaData.content_access_type === 'VIEW') {
      this.downloadAvaliable = false;
    } else {
      this.downloadAvaliable = (this.mediaData?.videoFiles?.length > 0) && this.mediaData.downloadEnable;
    }
  }

  addToCartClicked(mediaData: any) {
    this.addToCart.emit(mediaData);
  }

  async incrementDownloadCounter(videoFile) {

    let response: any = await this.videoService.allowDownloading(this.mediaData.stream, videoFile.name);
    
    if(!response?.isDownloadAllowed) {

      this.openSnackBar(
        "We apologize, but you have exceeded the maximum number of downloads allowed for this video. Please note that there is a limit to the number of times you can download this file in order to ensure the security and integrity of the system. If you still require access to this file, please contact our customer support team and they will assist you in finding a solution. Thank you for your understanding ",
        "snack-bar-danger"
      );

      return;

    }

    const link = document.createElement('a');
    link.setAttribute('target', '_self');
    link.setAttribute('href', videoFile.downloadUrl);
    link.setAttribute('download', videoFile.name);
    document.body.appendChild(link);

    link.click();
    link.remove();
    
    await this.videoService.updateDownloadCounter(this.mediaData.stream, videoFile.name);
    
  }

  getSubscription() {
    this.subscription.emit();
  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }
}
