import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  async getUserData(){
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/profile`,{headers}).toPromise();
      return response;  
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  getTimeZones() {
    return [
      "Pacific/Midway",
      "Pacific/Apia",
      "Pacific/Honolulu",
      "America/Anchorage",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Phoenix",
      "America/Denver",
      "America/Chihuahua",
      "America/Mazatlan",
      "America/Mexico_City",
      "America/Monterrey",
      "America/Regina",
      "America/Chicago",
      "America/New_York",
      "America/Indiana/Indianapolis",
      "America/Bogota",
      "America/Lima",
      "America/Caracas",
      "America/Halifax",
      "America/La_Paz",
      "America/Santiago",
      "America/St_Johns",
      "America/Argentina/Buenos_Aires",
      "Atlantic/Stanley",
      "Atlantic/Azores",
      "Atlantic/Cape_Verde",
      "Africa/Casablanca",
      "Europe/Dublin",
      "Europe/Lisbon",
      "Europe/London",
      "Africa/Monrovia",
      "Europe/Amsterdam",
      "Europe/Belgrade",
      "Europe/Berlin",
      "Europe/Bratislava",
      "Europe/Brussels",
      "Europe/Budapest",
      "Europe/Copenhagen",
      "Europe/Ljubljana",
      "Europe/Madrid",
      "Europe/Paris",
      "Europe/Prague",
      "Europe/Rome",
      "Europe/Sarajevo",
      "Europe/Skopje",
      "Europe/Stockholm",
      "Europe/Vienna",
      "Europe/Warsaw",
      "Europe/Zagreb",
      "Europe/Athens",
      "Europe/Bucharest",
      "Africa/Cairo",
      "Africa/Harare",
      "Europe/Helsinki",
      "Europe/Istanbul",
      "Asia/Jerusalem",
      "Europe/Kiev",
      "Europe/Minsk",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Vilnius",
      "Asia/Baghdad",
      "Asia/Kuwait",
      "Africa/Nairobi",
      "Asia/Riyadh",
      "Europe/Moscow",
      "Asia/Tehran",
      "Asia/Baku",
      "Europe/Volgograd",
      "Asia/Muscat",
      "Asia/Tbilisi",
      "Asia/Yerevan",
      "Asia/Kabul",
      "Asia/Karachi",
      "Asia/Tashkent",
      "Asia/Kolkata",
      "Asia/Kathmandu",
      "Asia/Yekaterinburg",
      "Asia/Almaty",
      "Asia/Dhaka",
      "Asia/Novosibirsk",
      "Asia/Bangkok",
      "Asia/Jakarta",
      "Asia/Krasnoyarsk",
      "Asia/Hong_Kong",
      "Asia/Kuala_Lumpur",
      "Australia/Perth",
      "Asia/Singapore",
      "Asia/Taipei",
      "Asia/Ulaanbaatar",
      "Asia/Urumqi",
      "Asia/Irkutsk",
      "Asia/Seoul",
      "Asia/Tokyo",
      "Australia/Adelaide",
      "Australia/Darwin",
      "Asia/Yakutsk",
      "Australia/Brisbane",
      "Australia/Canberra",
      "Pacific/Guam",
      "Australia/Hobart",
      "Australia/Melbourne",
      "Pacific/Port_Moresby",
      "Australia/Sydney",
      "Asia/Vladivostok",
      "Asia/Magadan",
      "Pacific/Auckland",
      "Pacific/Fiji",
      "UTC"
    ];
  }
  
  async updateProfilePicture(url, file : File){
    try {
      // not touch
      const headers = new HttpHeaders({
        'Access-Control-Allow-Origin':'*',
        'Content-Type': '',
        'Accept':'*/*',
        // "Domain-Channel": window.location.href.includes('localhost') ? `${environment.domainChannel}` : ''
        "Domain-Channel": `${environment.domainChannel}`
      });

      const data = await new Promise((resolve) => {
        let fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.readAsArrayBuffer(file);
      });

      let response: any = await this.httpClient.put(url, data , {headers}).toPromise();
      return response;  
    } catch (error) {
      console.log('>>> error', error);
      this.authService.verifySession(error);
      return error;
    }
  }

  async updateUserData(userData: any, file: any){
    try {
      const session: any = await this.authService.getSession();

      const token = session ? session.token : "";
      const lang = localStorage.getItem('lang')  || 'en';
      
      const headers = new HttpHeaders({
        'Accept-Language': lang,
        'Authorization' : token ? "Bearer " + token : "",
        // "Domain-Channel": window.location.href.includes('localhost') ? `${environment.domainChannel}` : ''
        "Domain-Channel": `${environment.domainChannel}`
      });

      userData.profileImage = false;
      if(file){
        userData.profileImage = true;
      }

      let response: any = await this.httpClient.put(`${environment.api_vms_url}/update-profile`,userData, {headers}).toPromise();
      return response;  
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getSubscriptionsPPVByUser(){
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-subscriptions-ppv-by-user`,{headers}).toPromise();
      return response;  
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getSubscriptionsVEByUser(){
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-subscriptions-ve-by-user`,{headers}).toPromise();
      return response;  
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getMediasPurchasesByUser(){
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-medias-purchases-by-user`,{headers}).toPromise();
      return response;  
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getUserMediaByUser(params?: any){
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-user-media-by-user`,{headers, params: params}).toPromise();
      return response;  
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getRecommendedVideos(){
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/recommended-videos`, {headers}).toPromise();
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  // async getMyClips(){
  //   alert("ads");
  //   try {
  //     const headers = await this.authService.getHeadersSession();
  //     let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-user-media-by-user?columnOrder=timestamp&order=desc&tab=clips`, {headers}).toPromise();
  //     return response;
  //   } catch (error) {
  //     this.authService.verifySession(error);
  //     return error;
  //   }
  // }

}
