<a
  (click)="openCategoryLink($event)"
  class="category"
  [ngClass]="{ 'auto-width': pageCategories }"
>
  <img
    *ngIf="category.path_thumbnail"
    [src]="category.path_thumbnail"
    [ngClass]="{ 'first-child': firstChild }"
  />
  <div
    *ngIf="!category.path_thumbnail && isLive"
    class="no-image"
    [ngClass]="{ 'first-child': firstChild }"
    [ngStyle]="{ background: '#E91916' }"
  >
    {{ "categories.initial-live" | translate }}
  </div>
  <div
    *ngIf="!category.path_thumbnail && !isLive"
    class="no-image"
    [ngClass]="{ 'first-child': firstChild }"
    [ngStyle]="{ background: '#010570' }"
  >
    {{ categorySlug }}
    <img
      *ngIf="category.subchannel_id && category.subchannel_url"
      class="external-link"
      src="../../assets/icons/icons8-external-link.svg"
      alt=""
    />
  </div>
</a>

<a (click)="openCategoryLink($event)" class="name-subcategories">
  <h1 *ngIf="isLive">{{ "categories.live" | translate }}</h1>
  <h1 *ngIf="!isLive">{{ category.category_name }}</h1>
  <span *ngIf="category.subcategories > 0" class="subcategories"
    >{{ category.subcategories }}
    {{ "categories.subcategories" | translate }}</span
  >
</a>

<a (click)="openCategoryLink($event)" class="category-content-mobile">
  <img *ngIf="category.path_thumbnail" [src]="category.path_thumbnail" />
  <div *ngIf="!category.path_thumbnail" class="no-image">
    {{ categorySlug }}
  </div>
  <h1>{{ category.category_name }}</h1>
</a>