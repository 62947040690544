import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-videos-manager',
  templateUrl: './videos-manager.component.html',
  styleUrls: ['./videos-manager.component.scss']
})
export class VideosManagerComponent implements OnInit {
  @Output() selectedOption: EventEmitter<any> = new EventEmitter();

  selectedIndex: any = 0;
  recommendedView: any = 'grid';
  userMedias: any = [];
  userFavoritiesMedias: any = [];
  loading: boolean = true;
  paginationLoading: boolean = false;
  orderBy: string = 'recent';
  filter: any = {
    columnOrder: 'timestamp',
    order: 'desc',
    offset: 0,
    limit: 20
  };

  recentVideos: any;
  myClips: any;
  moreUserMediasAvailable: boolean = true;
  moreUserFavoritesAvailable: boolean = true;
  moreMyClipsAvailable: boolean = true;

  constructor(
    private profileService: ProfileService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true;
    let promises = [
      this.getUserMedias(true), 
      this.getUserFavoritesMedias(true), 
      this.getRecommendedVideos(), 
      this.getMyClips(true)
    ];
    await Promise.all(promises);
    this.loading = false;
  }

  tabChanged(e) {
    this.selectedIndex = e.index;
    switch (e.index) {
      case 0:
        this.selectedOption.emit('videos');
        break;
      case 1:
        this.selectedOption.emit('favorite-videos');
        break;
      case 2:
        this.selectedOption.emit('recommended-videos');
        break;
      case 3:
        this.selectedOption.emit('my-clips');
        break;
    }
  }

  backToProfile() {
    this.selectedOption.emit('');
  }

  async getUserMedias(reset: boolean = false) {
    if (reset) {
      this.filter.offset = 0;
      this.userMedias = [];
      this.moreUserMediasAvailable = true;
    }
    if (!this.moreUserMediasAvailable) return;

    this.paginationLoading = true;
    const newMedias = await this.profileService.getUserMediaByUser(this.filter);
    if (newMedias.length < this.filter.limit) {
      this.moreUserMediasAvailable = false;
    }
    this.userMedias = [...this.userMedias, ...newMedias];
    this.paginationLoading = false;
  }

  async getUserFavoritesMedias(reset: boolean = false) {
    if (reset) {
      this.filter.offset = 0;
      this.userFavoritiesMedias = [];
      this.moreUserFavoritesAvailable = true;
    }
    if (!this.moreUserFavoritesAvailable) return;

    this.paginationLoading = true;
    const newFavorites = await this.profileService.getUserMediaByUser({ ...this.filter, tab: 'favorities' });
    if (newFavorites.length < this.filter.limit) {
      this.moreUserFavoritesAvailable = false;
    }
    this.userFavoritiesMedias = [...this.userFavoritiesMedias, ...newFavorites];
    this.paginationLoading = false;
  }

  async getRecommendedVideos() {
    this.recentVideos = await this.profileService.getRecommendedVideos();
  }

  async getMyClips(reset: boolean = false) {
    if (reset) {
      this.filter.offset = 0;
      this.myClips = [];
      this.moreMyClipsAvailable = true;
    }
    if (!this.moreMyClipsAvailable) return;

    this.paginationLoading = true;
    const newClips = await this.profileService.getUserMediaByUser({ ...this.filter, tab: 'clips' });
    if (newClips.length < this.filter.limit) {
      this.moreMyClipsAvailable = false;
    }
    this.myClips = [...this.myClips, ...newClips];
    this.paginationLoading = false;
  }

  async onFilter(value: string) {
    this.orderBy = value;
    switch (this.orderBy) {
      case 'recent':
        this.filter.columnOrder = 'timestamp';
        this.filter.order = 'desc';
        break;
      case 'older':
        this.filter.columnOrder = 'timestamp';
        this.filter.order = 'asc';
        break;
      case 'ascendingCharacters':
        this.filter.columnOrder = 'title';
        this.filter.order = 'asc';
        break;
      case 'descendingCharacters':
        this.filter.columnOrder = 'title';
        this.filter.order = 'desc';
    }

    await this.loadInitialData();
  }

  uploadVideo() {
    this.router.navigate(['upload-video']);
  }

  onScroll(): void {
    if (this.paginationLoading) return;

    this.filter.offset += 20;
    if (this.selectedIndex === 1) {
      this.getUserFavoritesMedias();
    } else if (this.selectedIndex === 3) {
      this.getMyClips();
    } else {
      this.getUserMedias();
    }
  }
}
