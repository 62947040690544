import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentComponent } from './payment/payment.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomepageComponent } from './main/homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { RefundComponent } from './payment/refund/refund.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from './signup/signup.component';
import { AdsTesterComponent } from './ads-tester/ads-tester.component';
import { SnackPlayerComponent } from './snack-player/snack-player.component';
import { SubscriptionsComponent } from './main/subscriptions/subscriptions.component';
import { AuthGuard } from './auth/auth.guard';
import { SubscriptionContentComponent } from './main/subscriptions/subscription-content/subscription-content.component';
import { ScheduleComponent } from './main/schedule/schedule.component';
import { CategoriesComponent } from './main/categories/categories.component';
import { SearchComponent } from './main/search/search.component';
import { CategoryContentComponent } from './main/categories/category-content/category-content.component';
import { VideoContentComponent } from './main/video/video-content/video-content.component';
import { ProfileComponent } from './main/profile/profile.component';
import { OverviewComponent } from './main/profile/overview/overview.component';
import { AboutUsComponent } from './main/about-us/about-us.component';
import { CookiePolicyComponent } from './main/cookie-policy/cookie-policy.component';
import { TvAppsComponent } from './main/tv-apps/tv-apps.component';
import { UploadVideoComponent } from './main/upload-video/upload-video.component';
import { PrivacyPolicyComponent } from './main/privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './main/terms-and-condition/terms-and-condition.component';
import { SeeAllComponent } from './main/seeAll/seeAll.component';
import { CartComponent } from './main/cart/cart.component';

const routes: Routes = [
  { path: 'forgot-password', component: ForgotPasswordComponent },  
  { path: 'reset-password', component: ResetPasswordComponent },  
  { path: 'refund', component: RefundComponent},
  { path: 'login', component: LoginComponent },  
  { path: 'login-by-facebook', component: LoginComponent },  
  { path: 'signup', component: SignupComponent },  
  { path: 'ads-tester', component: AdsTesterComponent },  
  { path: 'snack-player', component: SnackPlayerComponent },  
  { path: "", component: MainComponent, children:[
    {
      path:'',
      component: HomepageComponent,
    },
    {
      path: 'upload-video',
      component: UploadVideoComponent,
    },
    {
      path: 'about-us',
      component: AboutUsComponent,
    },
    {
      path: 'tv-apps',
      component: TvAppsComponent,
    },
    {
      path: 'cookie-policy',
      component: CookiePolicyComponent,
    },
    {
      path: 'privacy-policy',
      component: PrivacyPolicyComponent,
    },
    {
      path: 'terms-and-condition',
      component: TermsAndConditionComponent,
    },
    {
      path:'user',
      component: ProfileComponent,
      children: [
        {
          path:':option',
          component: ProfileComponent,
        }
      ],
      canActivate: [AuthGuard]
    },
    {
      path:'subscriptions',
      component: SubscriptionsComponent,
    },
    {
      path:'subscriptions/:slug',
      component: SubscriptionContentComponent,
    },
    {
      path:'schedule',
      component: ScheduleComponent,
    },
    {
      path:'categories',
      component: CategoriesComponent,
    },
    {
      path:'see-all/recent-videos',
      pathMatch: 'full',
      component: SeeAllComponent,
    },
    {
      path:'see-all/live',
      pathMatch: 'full',
      component: SeeAllComponent,
    },
    {
      path:'see-all/most-viewed',
      pathMatch: 'full',
      component: SeeAllComponent,
    },
    {
      path:'categories/:categorySlug',
      pathMatch: 'full',
      component: CategoryContentComponent,
    },
    {
      path:'tag/:tagSlug',
      pathMatch: 'full',
      component: CategoryContentComponent,
    },
    {
      path:'video/:mediaSlug',
      component: VideoContentComponent,
    },
    {
      path:'live/:mediaSlug',
      component: VideoContentComponent,
    },
    {
      path:'search',
      component: SearchComponent,
      children: [
        {
          path:':search_query',
          component: SearchComponent,
        }
      ]
    },
    {
      path:':categorySlug',
      pathMatch: 'full',
      component: HomepageComponent,
    },
  ] },
  {
    path:'payment', 
    children:[
      {
        path:':token',
        component: PaymentComponent,
      },
      {
        path:':token/:status/:checkout_id',
        component: PaymentComponent,
      },
      {
        path:':token/:status',
        component: PaymentComponent,
      },
    ],
  },
  {
    path:'cart', 
    children:[
      {
        path:':token',
        component: CartComponent,
      },
      {
        path:':token/:status/:checkout_id',
        component: CartComponent,
      },
      {
        path:':token/:status',
        component: CartComponent,
      },
    ],
  },   
  { path: '**', component: MainComponent },  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [PaymentComponent, RefundComponent]
