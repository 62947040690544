import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private httpClient: HttpClient,
    private authService: AuthService) { }

  async getAllSubscriptions(channelId, type, token, subscriptionsIds: any = []){
    subscriptionsIds = subscriptionsIds.toString();
    const headers = AuthService.getHeaders()
    let response: any = await this.httpClient.get(`${environment.api_payment_url}/get-all-subscriptions`, {
      headers,
      params: {
        channelId,
        type,
        token,
        subscriptionsIds
      }
    }).toPromise();
    return response || [];
  }
  
  async listAllSubscriptions(offset, limit, filter){
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/subscriptions?offset=${offset}&limit=${limit}&paymentType=${filter}`,{headers}).toPromise();
      return response.subscriptions || [];  
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  
  async getPaymentToken(body) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.post(`${environment.api_vms_url}/authentication-payment`, body, {headers}).toPromise();
      return response.paymentToken || null;
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getTotalCountSubscriptionsPaid(filter){
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/subscriptions-count?paymentType=${filter}`,{headers}).toPromise();
      return response || [];
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  
  async getSubscriptionData(slug){    
    try {
      const headers =  await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/subscription/${slug}`, {
        headers
      }).toPromise();
    return response || {};
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getMediasFromSubscription(filters){
    try {
      filters.displayType = filters.displayType == "live" ? "livematches" : filters.displayType;
      let {slug, displayType, limit, page, subscription_id, filter} = filters;    
      const headers =  await this.authService.getHeadersSession();
      let response: any = await this.httpClient.post(`${environment.api_vms_url}/subscription/${slug}/${displayType}?limit=${limit}&offset=${page}&filter=${filter}`, {subscription_id}, {
        headers,
      }).toPromise();
      return response.subscription_medias || [];
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }


  async validateCupon(params){
    try {
      const headers = AuthService.getHeaders();
      let response: any = await this.httpClient.get(`${environment.api_payment_url}/coupon-activate`, {
        headers,
        params: params
      }).toPromise();
      return response || {};
    } catch (error) {
      return {error: true};
    }
  }

  async getMediaByMediaId(params){
    
    const headers = AuthService.getHeaders()
    let response: any = await this.httpClient.get(`${environment.api_payment_url}/get-media-by-media-id`, {
      headers,
      params: params
    }).toPromise();
    return response || {};
  }
  
  async getMediaByMediaIdsArray(params){
    
    const headers = AuthService.getHeaders()
    let response: any = await this.httpClient.get(`${environment.api_payment_url}/get-media-by-media-ids-array`, {
      headers,
      params: params
    }).toPromise();
    return response || {};
  }
  
  async getSubscriptionBySlug(params){
    
    const headers = AuthService.getHeaders()
    let response: any = await this.httpClient.get(`${environment.api_payment_url}/get-subscription-by-slug`, {
      headers,
      params: params
    }).toPromise();
    return response || {};
  }

  async buySubscription(data){
    let url = ``
    try {
      let response: any = await this.httpClient.post(`${environment.api_payment_url}/buy-subscription`, data,
        {headers: { Authorization : 'Bearer vK9H%PD1$y!84ylJ' }}).toPromise();
      
      url = `${window.location.protocol}//${window.location.host}/payment/${response.token}/success`;
      window.location.href = url;
    } catch (error) {
      window.location.href = `${window.location.protocol}//${window.location.host}/payment/${data.token}/error`;
    }
  }

  async buyMultipleMedias(data){    
    let response: any = await this.httpClient.post(`${environment.api_payment_url}/buy-subscription`, data,
      {headers: { Authorization : 'Bearer vK9H%PD1$y!84ylJ' }}).toPromise();        
      return response;
  }

  async getSubscriptionsByMediaId(params){
    
    const headers = AuthService.getHeaders()
    let response: any = await this.httpClient.get(`${environment.api_payment_url}/get-subscriptions-by-media-id`, {
      headers,
      params: params
    }).toPromise();
    return response || {};
  }

}
