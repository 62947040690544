import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-subscription-manager',
  templateUrl: './subscription-manager.component.html',
  styleUrls: ['./subscription-manager.component.scss']
})
export class SubscriptionManagerComponent implements OnInit {

  @Output() selectedOption: EventEmitter<any> =  new EventEmitter();
  @ViewChild('tabs') tabs;

  selectedIndex: any = 0;
  subscriptions: any;
  bundleEditor: any;
  purchasedMedias: any;
  loading: boolean = true;

  constructor(private router: Router, private profileService: ProfileService) { 
  }

  ngOnInit(): void {
  }

  async ngAfterViewInit(){
    let promises = [this.getSubscriptionsPPVByUser(), this.getSubscriptionsVEByUser(), this.getMediasPurchasesByUser()];
    await Promise.all(promises);
    this.loading = false;
  }

  async getSubscriptionsPPVByUser(cancelRenew?){
    if(cancelRenew) {
      setTimeout(async () => {
        this.subscriptions = await this.profileService.getSubscriptionsPPVByUser();
      }, 800);
    }
    else {
      this.subscriptions = await this.profileService.getSubscriptionsPPVByUser();
    }

    this.subscriptions.sort((a, b) => { //https://stackoverflow.com/questions/2784230/how-do-you-sort-an-array-on-multiple-columns
      const compareName = b.payment_type.localeCompare(a.payment_type);
      const compareTitle = a.name.localeCompare(b.name);
    
      return compareName || compareTitle;
    });
  }

  backToProfile() {
    this.selectedOption.emit('');
  }

  async getSubscriptionsVEByUser(){
    this.bundleEditor = await this.profileService.getSubscriptionsVEByUser();
  }

  async getMediasPurchasesByUser(){
    this.purchasedMedias = await this.profileService.getMediasPurchasesByUser();
  }

  tabChanged(e) {
    this.tabs.realignInkBar();
    switch(e.index) {
      case 0:
        this.selectedOption.emit('subscriptions');
        break;
      case 1:
        this.selectedOption.emit('unlock-video-bundles');
        break;
      case 2:
        this.selectedOption.emit('unlock-video-lives');
        break;
    }
  }

  goToSubscriptions(){
    this.router.navigate([`/subscriptions`])
  }
}
