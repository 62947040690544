import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  @Input() category: any;
  @Input() pageCategories: any;
  @Input() isLive: boolean;
  @Input() firstChild: boolean;
  categorySlug: string;
  categoryLink: string = '';
  isNewHomepageUrl = /(onlysports\.live\/|usssalive\.com|iswim.tv|vewbie.com\/channel)/.test(window.location.href);

  redirectLocationHref =
    window.location.protocol + '//' + window.location.host + '/channel';

  constructor(
    private router: Router,
    private categoryService: CategoryService
  ) {}

  ngOnInit(): void {
    if (!this.category.thumbnail_url && this.category.category_name) {
      this.categorySlug = this.categoryService.getInitials(
        this.category.category_name
      );
    }

    if (
      this.category.hasOwnProperty('thumbnail_url') ||
      this.category.hasOwnProperty('subchannel_logo')
    ) {
      this.category.path_thumbnail =
        this.category.thumbnail_url || this.category.subchannel_logo;
    }
  }

  openCategoryLink(event: Event) {
    // Anchor elements are the ones calling this function, if the anchor element is clicked, we should prevent its default action and proceed with this function
    event.preventDefault();

    // usssalive.com or onlysports.live or iswim-tv.com or vewbie.com/channel/hostname
    if (
        this.isNewHomepageUrl &&
        this.category.subchannel_id &&
        this.category.subchannel_url &&
        this.category.subchannel_url.includes('vewbie.com')
    ) {
      let hostName = this.category.hostName ? this.category.hostName : this.category.host_name;
      let subchannelUrl = `${this.redirectLocationHref}/${hostName}`;
      window.open(`${subchannelUrl}`, '_blank');
      return;
    }
    
    // hostname.app.vewbie.com
    if (this.category.subchannel_id && this.category.subchannel_url) {
      let url = this.category.subchannel_url;
      if (!url.startsWith('https://')) {
        url = 'https://' + url;
      }
      window.open(url, '_blank');
      return;
    }
    
    // hostname.app.vewbie.com || vewbie.com/channel/hostname || custom domains
    if (!this.isLive) {
      this.router.navigate([`categories/${this.category.category_slug}`]);
    } else {
      this.router.navigateByUrl('see-all/live');
    }
  }
}
