import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { VideosService } from '../services/videos.service';
@Component({
  selector: 'app-group-videos',
  templateUrl: './group-videos.component.html',
  styleUrls: ['./group-videos.component.scss']
})
export class GroupVideosComponent implements OnInit {

  @ViewChild("mainCarousel") mainCarousel: any;
  @Input() videos: any;
  @Input() subchannelId: any;
  @Input() subchannelUrl: any;
  @Input() subchannelLogo: any;
  @Input() context: any;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() layout: any;
  @Input() seeAllUrl: string;
  @Input() hasThumbnail: boolean = true;
  @Input() isRelatedVideos: boolean = false;
  @Input() recommended: boolean = false;
  @Input() columnsView: boolean = false;
  @Input() hideLiveViewers: boolean;

  steps: any = [];
  numberOfSteps: number;
  currentVideoIndex: number = 0;

  largestVideo: any = [];
  hasBanner: boolean = false;

  currentStep: number = 0;
  customOptions: any;
  isMobile: boolean = false;

  seeAllLink: string = '';

  isNewHomepageUrl = /(onlysports\.live\/|usssalive\.com|iswim.tv|vewbie.com\/channel)/.test(window.location.href);

  redirectLocationHref =
    window.location.protocol + '//' + window.location.host + '/channel';

  constructor(
    public videoService: VideosService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(window.innerWidth <= 768) {
      this.isMobile = true;
    }

    if(this.layout == 'HORIZONTAL_5_BOXED' && !this.isMobile ) {
      this.largestVideo = this.videos[0];
      this.videos.shift();
      this.hasBanner = true;
    }

    this.numberOfSteps = Math.ceil(this.videos.length / 4);

    if(this.largestVideo && this.videos.length == 0){
      this.numberOfSteps++
    }

    for (let index = 0; index < this.numberOfSteps; index++) {
      this.steps.push({
        'number': index,
        'active': index == 0 ? true : false
      })
    }

    this.customOptions = {
      loop: false,
      pullDrag: false,
      dots: false,
      nav: false,
      navSpeed: 200,
      margin: 16,
      rewind: false,
      stagePadding: 10,
      responsive: {
        0: {
          items: 1.2,
          touchDrag: true,
          mouseDrag: true,
          slideBy: 1,
          center: false,
          autoWidth: true
        },
        500: {
          items: 3.2,
          touchDrag: true,
          mouseDrag: true,
          slideBy: 3,
          center: false,
          autoWidth: true
        },
        768: {
          items: 4.2,
          mouseDrag: false,
          touchDrag: false,
          slideBy: 4,
          center: false,
          autoWidth: true
        },
        1680: {
          items: 5.2,
          mouseDrag: false,
          touchDrag: false,
          slideBy: 5,
          center: false,
          autoWidth: true
        }
      },
    }

    if (this.isNewHomepageUrl && this.subchannelId && this.subchannelUrl) {
      let subchannelLink = this.subchannelUrl.slice(this.subchannelUrl.indexOf("//") + 2, this.subchannelUrl.indexOf("."))
      this.seeAllLink = `${this.redirectLocationHref}/${subchannelLink}`
    }
    else if (this.subchannelId && this.subchannelUrl) {
      let url = this.subchannelUrl;
      if (!url.startsWith('https://')) {
        url = 'https://' + url;
      }

      this.seeAllLink = url;
    } else {
      this.seeAllLink = window.location.href + this.seeAllUrl;
    };
  }

  async ngAfterViewInit() {
  }

  next() {
    this.mainCarousel.next();
    
    if(!this.isMobile) {
      this.steps[this.currentStep].active = false;
      this.currentStep++;
      this.steps[this.currentStep].active = true;  
    } else {
      this.currentVideoIndex++;
    }
  }
  
  prev() {
    this.mainCarousel.prev();

    if(!this.isMobile) {
      this.steps[this.currentStep].active = false;
      this.currentStep--;
      this.steps[this.currentStep].active = true;
    } else {
      this.currentVideoIndex--;
    }
  }

  async goToStep(numberOfStep) {
    this.steps[this.currentStep].active = false;
    this.currentStep = numberOfStep;
    this.steps[this.currentStep].active = true;

    switch (numberOfStep) {
      case 0:
        this.mainCarousel.to(`slide-0`);
        break;
      case 1:
        this.mainCarousel.to(`slide-4`);
        break;
      case 2:
        this.mainCarousel.to(`slide-8`);
        break;
      case 3:
        this.mainCarousel.to(`slide-12`);
        break;
      case 4:
        this.mainCarousel.to(`slide-16`);
        break;
      
      default:
        break;
    }
  }

  draggedEvent(dragData: any) {
    if(!dragData.dragging) {
      this.currentVideoIndex = dragData.data.startPosition;
    } 
  }

  goToCategoryDetails(event: Event) {
    // Anchor elements are the ones calling this function, if the anchor element is clicked, we should prevent its default action and proceed with this function
    event.preventDefault();

    if(this.isNewHomepageUrl && this.subchannelId && this.subchannelUrl) {
      window.open(`${this.seeAllLink}`, '_blank');
      return;
    }
    
    if(this.subchannelId && this.subchannelUrl) {
      let url = this.subchannelUrl;
      if (!url.startsWith('https://')) {
        url = 'https://' + url;
      }
      
      window.open(`${url}`, '_blank');
      return;
    }

    if(this.seeAllUrl) {
      this.router.navigate([`${this.seeAllUrl}`])
    }
  }
}
