<div class="tagging-row" [style.height.px]="containerHeight">
  <mat-accordion [multi]="false">
    <mat-expansion-panel *ngFor="let item of gamePlays; let i = index" [expanded]="expandedPanels[i]" (opened)="togglePanel(i)">
      <mat-expansion-panel-header>
        <mat-panel-title>{{item.awayName}} vs {{item.homeName}} </mat-panel-title>
      </mat-expansion-panel-header>
      <ol class="plays" [style.height.px]="playsHeight">
        <li *ngFor="let play of item.plays">
          <a href="javascript:;" (click)="jumpToVideo(play.timestamputc)">{{play.description}}</a>
        </li>
      </ol>
    </mat-expansion-panel>
  </mat-accordion>
</div>
