
<div class='main-content'>
	<mat-icon class='close-button' (click)='close()'>close</mat-icon>
	<div class='image-selected'>
		<img [src]='imageSelected.url'>

		<div class='description'>
			<p class='title'>Description</p>

			<p class='content'>{{imageSelected.description ? imageSelected.description : 'No information'}}</p>
		</div>
	</div>

	<div class='images-content'>
		<img *ngFor='let img of data.photos' [src]='img.url' (click)='selectImage(img)' [ngClass]='{"selected" : imageSelected.url == img.url}'>
	</div>
</div>
