<div class='content-subscription' *ngIf='subscriptions.length'>
	<div *ngFor='let subscription of subscriptions' class='subscription'>
    <div class="container">
      <div class="row">
        <div class="column">
          <div class="details">
            <div class="sub-redirect">
              <span class="title">{{subscription.subscription_slug}}</span>
              <mat-icon (click)="goToSubscription(subscription)">open_in_new</mat-icon>
            </div>
            <span class="value">${{subscription.price}} USD / {{billingFrequencyTreatment(subscription) | titlecase}}</span>
            <span class="started">{{'profile.subscription-content.user-subscription.started-at' | translate}} {{subscription.subscriber_start_date | dateTimezone: 'MMM d, y h:mm a'}}</span>
          </div>
        </div>
    
        <div class="column">
          <div class="details align">
            <span class="started">{{'profile.subscription-content.user-subscription.subscription-from' | translate}}</span>
            <span class="title">{{subscription.host_name}}</span>
          </div>
        </div>
    
        <div class="column column-mobile">
          <div class="btn-group">
            <button class="change-credit-card" mat-flat-button *ngIf="subscription.payment_method == 'STRIPE' && subscription.type_subscription == 'subscription'" (click)="changeCard(subscription)">
              <div class="text">
                <span>{{'profile.subscription-content.user-subscription.change-credit-card' | translate}}</span>
              </div>
            </button>
            <button class="cancel" mat-flat-button *ngIf="subscription.state_payment == 'cancel'" (click)="openModalPlan('Cancel', subscription)">
              <div class="text">
                <span>{{'profile.subscription-content.user-subscription.click-cancel' | translate}}</span>
                <span>{{'profile.subscription-content.user-subscription.next-payment' | translate}} <span class="date">{{subscription.subscriber_end_date | dateTimezone: 'MMM d, y h:mm a'}}</span></span>
              </div>
            </button>
          </div>
        </div>
    
        <div class="column column-mobile">
          <div class="btn-group">
            <button class="renew" mat-flat-button *ngIf="subscription.state_payment == 'renew'" (click)="openModalPlan('Renew', subscription)">
              <div class="text">
                <span>{{'profile.subscription-content.user-subscription.click-renew' | translate}}</span>
                <span>{{'profile.subscription-content.user-subscription.access-until' | translate}} <span class="date">{{subscription.subscriber_end_date | dateTimezone: 'MMM d, y h:mm a'}}</span></span>
              </div>
            </button>
    
            <div class="expired" *ngIf="subscription.payment_type == 'season'">
              <span>{{'profile.subscription-content.user-subscription.expired' | translate}} {{(subscription.subscriber_end_date || subscription.subscription_end_date) | dateTimezone: 'MMM d, y h:mm a'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</div>

<div class='no-events profile' *ngIf='!subscriptions.length'>
	{{'profile.no-results' | translate}}
</div>