<div class='dialog'>
	<div class='dialog-title'>
		<p>Categories</p>
		<mat-icon (click)='close()'>close</mat-icon>
	</div>
	
	<p class='description'>You can select one of the options below to see the videos from the selected category</p>
	
	<div class='categories-listing'>
		<div *ngFor='let category of categories' class='category' (click)='goToCategoryDetails(category)'>
			<img *ngIf="category.category_thumbnail_url" [src]="category.category_thumbnail_url">
			<div *ngIf="!category.category_thumbnail_url" class="no-image">{{category.categorySlug}}</div>
			
			<div class='content'>
				<span class='name'>{{category.category_name}}</span>
				<span class='videos'>{{category.total_videos}} videos</span>
			</div>
		</div>
	</div>
</div>