<div class='main-container'>
  <div class='section-header'>
    <h1 class='title'>{{'schedule.title' | translate}}</h1>
    <h2 class='subtitle'>{{'schedule.subtitle' | translate}}</h2>
  </div>

  <div class="example-loading-shade" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <div class='content' *ngIf='!loading'>
    <div class='event-grouped' *ngFor="let group of groupedDates; let i = index">
      <h1 class='date'>{{group | dateTimezone:'MMM d, y'}} <span class='week-day'>({{group | dateTimezone:'EEEE'}})</span></h1>
      <div class='events'>
        <div class='event' *ngFor="let event of groupedEvents[i]">
          <app-thumbnail-video [fromSchedule]="true" [fullWidth]="isMobile" [videoData]="event"></app-thumbnail-video>
        </div>
      </div>
    </div>

		<div *ngIf='!groupedDates.length' class='no-events'>
      {{'schedule.no-events' | translate}}
		</div>
    
    <div
    class="search-results"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
    ></div>
    <ng-container>
    <div class="more-loading" *ngIf="loadingMore">
      <mat-spinner  diameter="50"></mat-spinner>
    </div>
    </ng-container>
  </div>
</div>