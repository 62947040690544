import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-favorited-videos',
  templateUrl: './favorited-videos.component.html',
  styleUrls: ['./favorited-videos.component.scss']
})
export class FavoritedVideosComponent implements OnInit {

  constructor() { }

  @Input("userFavoritiesMedias") userFavoritiesMedias: any = [];
  // @Input() userMedias: any[] = [];
  @Input() paginationLoading: boolean = false;
  @Output() scrolled = new EventEmitter<void>();

  onScroll(event: any): void {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      this.scrolled.emit();
    }
  }

  ngOnInit(): void {
  }

}
