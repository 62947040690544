<div class='main-container'>
  <div class='section-header'>
    <h1 class='title'>{{'subscriptions.title' | translate}}</h1>
    <h2 class='subtitle'>{{'subscriptions.subtitle' | translate}}</h2>
  </div>
  
  <div class="example-loading-shade" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  
  <div class='content' *ngIf="!loading">
    <div class='top'>
      <div class='top-info'>
        <h3>{{'subscriptions.subscriptions' | translate}}</h3>
        <span>{{countTotalSubscriptions}} {{'subscriptions.available' | translate}}</span>
      </div>
      <div class="filter">
        <form [formGroup]="filtersForm">
          <mat-form-field appearance="outline">
            <mat-label>{{'subscriptions.filter-by' | translate}}</mat-label>
            <mat-select formControlName="type" (selectionChange)="onFilter($event.value)">
              <mat-option value="">{{'subscriptions.all-subscriptions' | translate}}</mat-option>
              <mat-option value="subscription">{{'subscriptions.only-subscriptions' | translate}}</mat-option>
              <mat-option value="season">{{'subscriptions.only-season-pass' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
  
    </div>
    <div class='paid-count' *ngIf="countPaidSubscriptions >= 1" (click)='goToUserSubscriptions()'>
      <div>
        <mat-icon>credit_card</mat-icon>
        <p>{{'subscriptions.you-have' | translate}} <span>{{countPaidSubscriptions}} {{'subscriptions.paid-subscriptions' | translate}}</span></p>
      </div>
      <mat-icon>arrow_forward</mat-icon>
    </div>
  
    <div class='cards'>
      <div class='subscriptions'  *ngIf="groupedSubscriptions.subscription && groupedSubscriptions.subscription.length">
        <h1>{{'subscriptions.subscription' | translate}}</h1>
        <div class='card'  (click)="goToSubscription(subscription)" *ngFor="let subscription of groupedSubscriptions.subscription">
          <div class='left'>
            <div class='logo'>
              <div>{{'subscriptions.subscription-caps' | translate}}</div>
            </div>
    
            <div class='plan-info'>
              <span class='name'>{{subscription.name}}</span>
              <span class='value'>${{subscription.price}} USD / {{billingFrequencyTreatment(subscription) | titlecase}}</span>
              <span class='trial' *ngIf="subscription.trial_days && subscription.trial_days > 0">{{'subscriptions.try-free' | translate}} {{subscription.trial_days}} <span *ngIf='subscription.trial_days > 1'>{{'subscriptions.days' | translate}}</span> <span *ngIf='subscription.trial_days == 0 || subscription.trial_days == 1'>{{'subscriptions.day' | translate}}</span></span>
            </div>
  
            <div class='view-content'>{{'subscriptions.view-content' | translate}}</div>
          </div>
          <button mat-flat-button class="offer-button" (click)="selectPlan(subscription)">
            <div class="text">
              <span>{{'subscriptions.click-select' | translate}}</span>
              <span *ngIf="subscription.is_recurring == 0">{{'subscriptions.one-time-payment' | translate}}</span>
              <span *ngIf="subscription.is_recurring == 1">{{'subscriptions.renews-automatically' | translate}}</span>
            </div>
            <div class="price">${{subscription.price}} USD</div>
          </button>
        </div>
      </div>
      
      <div class='season-pass'  *ngIf="groupedSubscriptions.season && groupedSubscriptions.season.length">
        <h1>{{'subscriptions.season-pass' | translate}}</h1>
        <div class='card' (click)="goToSubscription(subscription)" *ngFor="let subscription of groupedSubscriptions.season" >
          <div class='left'>
            <div class='logo'>
              <div>{{'subscriptions.season-pass-caps' | translate}}</div>
            </div>
    
            <div class='plan-info'>
              <span class='name'>{{subscription.name}}</span>
              <span class='value'>${{subscription.price}} USD / {{subscription.end_date ? (subscription.end_date | dateTimezone: 'MM/dd/yyyy') : (billingFrequencyTreatment(subscription) | titlecase)}}</span>
            </div>
  
            <div class='view-content' >{{'subscriptions.view-content' | translate}}</div>
          </div>
          <button mat-flat-button class="offer-button" (click)="selectPlan(subscription)">
            <div class="text">
              <span>{{'subscriptions.click-select' | translate}}</span>
              <span *ngIf="subscription.is_recurring == 0">{{'subscriptions.one-time-payment' | translate}}</span>
              <span *ngIf="subscription.is_recurring == 1">{{'subscriptions.renews-automatically' | translate}}</span>
            </div>
            <div class="price">${{subscription.price}} USD</div>
          </button>
        </div>
      </div>
    </div>

  
  </div>
</div>