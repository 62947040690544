import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-clips',
  templateUrl: './my-clips.component.html',
  styleUrls: ['./my-clips.component.scss']
})
export class MyClipsComponent implements OnInit {

  @Input() myClips: any;

  categories: any = [];

  videosByCategories: any = [];

  constructor() { }

  ngOnInit(): void {
    this.myClips.map(rv => {
      rv.categories.map(ct => {
        if(!this.categories.find(el => el == ct.category_name)){
          this.categories.push(ct.category_name);
        }
      });
    });

    this.categories.forEach((element) => {
      let videos = this.myClips.filter(rv => rv.categories.find(ct => ct.category_name == element));
      this.videosByCategories.push(videos);
      //https://love2dev.com/blog/javascript-remove-from-array/
      videos.forEach(element => {
        this.myClips = this.arrayRemove(this.myClips, element);
      });
    });
  }

  arrayRemove(arr, value) { 
    return arr.filter(function(newArr){ 
        return newArr != value; 
    });
  }

}
