import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-share-video',
  templateUrl: './share-video.component.html',
  styleUrls: ['./share-video.component.scss']
})
export class ShareVideoComponent implements OnInit {

  router: String = window.location.href;
  isEmbed: boolean = false;
  copied: boolean = false;
  embedCode: String = ''
  embedUrl: String = ''
  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ShareVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    
    this.form = new FormGroup({
      width: new FormControl('320'),
      height: new FormControl('180'),
      autoPlay: new FormControl(false)
    });

    if(data.embedUrl) {
      this.embedUrl = data.embedUrl
    }

    this.embedCode = `<iframe frameborder="0" scrolling="0" height="180" width="320" src="${this.embedUrl}" allowfullscreen allow="autoplay; fullscreen"></iframe>`
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close(false);
  }

  copiedToClipboard() {
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }

  toggleEmbedCode(){
    this.isEmbed = !this.isEmbed;
  }

  changeIframe() {
    let autoplay = this.form.get('autoPlay').value;
    this.embedCode = `<iframe frameborder="0" scrolling="0" height="${this.form.get('height').value}" width="${this.form.get('width').value}" src="${this.embedUrl}${autoplay ? '&auto_play=true' : ''}" allowfullscreen allow="autoplay; fullscreen"></iframe>`
  }

}
