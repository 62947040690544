import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../analytics.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../services/user.service';
import { VideosService } from '../services/videos.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  loading: boolean = false;
  captchaSuccess: boolean = false;
  submitted: boolean = false;
  signupForm: FormGroup;
  siteKeyv2: string = environment.recaptchaSiteKey;

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<SignupComponent>,
    private readonly userService : UserService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public videoService : VideosService,
    private analyticsService : AnalyticsService
  ) { }

  async ngOnInit() {
    this.signupForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.email,
        Validators.pattern(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
        Validators.required,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      policy: new FormControl(''),
    });
    this.analyticsService.trackingSignUp();
    await this.authService.createRoutine();

  }

  async signup() {
    
    this.submitted = true;

    if(!this.signupForm.valid || !this.captchaSuccess) {
      return;
    }

    this.loading = true;

    const viewerSession = await this.userService.createViewer({
      full_name: this.signupForm.get('name').value,
      email: this.signupForm.get('email').value,
      password: this.signupForm.get('password').value,
      terms: this.signupForm.get('policy').value ? 'accepted' : ''
    })

    this.loading = false;
    
    if (viewerSession?.errorMessage) {

      setTimeout(() => {
        this.openSnackBar(viewerSession.errorMessage, 'snack-bar-danger');
      }, 1000);
      
      if (viewerSession.isConflict) {
        return this.goToLogin();
      }
      
      return;
    }
    
    this.openSnackBar(
      (<HTMLInputElement>document.getElementById("success")).textContent,
      "snack-bar-success"
    );

    if(this.data.isModal) {
      this.dialogRef.close('success');
      return;
    }

    this.router.navigate([``]);
    setTimeout(() => {
      window.location.reload();
    }, 2000)

  }

  goToLogin() {
    if(this.data.isModal) {
      this.dialogRef.close('signin');
      return;
    }
    this.router.navigateByUrl("/login");
  }

  getCaptchaSuccess(captchaSuccess: boolean): void {
    this.captchaSuccess = captchaSuccess;
  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }

  close() {
		if(this.data.isModal) {
			this.dialogRef.close();
		}
		else {
			this.router.navigateByUrl("/");
		}
  }

}
