import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CartService } from './cart.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { StripeService } from 'src/app/stripe.service';
import { SubscriptionService } from 'src/app/subscription.service';
import { RefundComponent } from 'src/app/payment/refund/refund.component';
import { AuthService } from 'src/app/auth/auth.service';
import { ChannelService } from 'src/app/services/channel.service';
import { Subscription } from 'rxjs';
import jwt_decode from "jwt-decode";
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  isFromModal = false;
  isFromStripe = false;

  purchaseForm: FormGroup;
  loadingSave: boolean = false;
  loadingCoupon: boolean = false;

  couponCode: string;
  discountPrice = null;
  couponData = null;
  alternativePurchaseMode = false;
  invalidCoupon: boolean = false;

  payment_type: string = 'fixed';

  channel: any;
  session: any;

  token: any;
  medias: Record<string, any>[] = [];

  getAccess: boolean = false;
  success: boolean = false;
  failed: boolean = false;

  backDropClickSubscription: Subscription;
  routeParams: Record<string, any>;

  constructor(
    public dialogRef: MatDialogRef<CartComponent>,
    public cartService: CartService,
    public stripeService: StripeService,
    private readonly subscriptionService : SubscriptionService,
    private dialog: MatDialog,
    private authService: AuthService,
    private channelService: ChannelService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.isFromModal = data.isFromModal;    

    if(this.isFromModal) {
      this.backDropClickSubscription = this.dialogRef.backdropClick().subscribe(() => {
        this.goBack();
      });
    }
    
    this.routeParams = this.route.snapshot.params;
  }

  async ngOnInit() {

    await this.cartService.initData();

    this.getForm();

    if(!this.isFromModal){
      this.isFromStripe = true;

      const {
        discount
      } : any = jwt_decode( decodeURIComponent(this.routeParams.token));

      this.success = this.routeParams.status === 'success';
      this.failed = !this.success;

      if(discount) {
        this.couponCode = discount.coupon_code;
        this.discountPrice = Number(this.totalPrice * (100 - discount.discount) / 100).toFixed(2);
      }
    }
 
  }

  ngOnDestroy() {
    if(this.backDropClickSubscription){
      this.backDropClickSubscription.unsubscribe();
    }
  }

  async updateTokenData() {
    this.token = await this.generateTokenData();
  }

  async ngAfterViewInit() {
    this.session = await this.authService.getSession();
    this.channel = await this.channelService.getCurrentChannel();
    
    if(this.isFromStripe){
      return;
    }

    await this.updateTokenData();

    this.couponCode = this.cartData.couponCode;
    if(this.couponCode) {
      await this.activateCoupon();
    }
  }

  get cartData() {
    return this.cartService.cartData;
  }

  get totalPrice() {
    if(this.medias.length){
      return this.medias.reduce((total, item) => total + Number(item.price), 0);
    }
    return this.cartService.cartData.totalCost;
  } 

  get cartItems() {
    if(this.medias.length){
      return this.medias;
    } 
    return this.cartService.cartItems;
  }

  getForm() {
    this.purchaseForm = new FormGroup({
      payment : new FormControl('stripe', [Validators.required]),
      agreeTerms: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  async openRefund() {
    const dialogRef = this.dialog.open(RefundComponent, {
      maxHeight: "80vh",
      maxWidth: "70vw",
      panelClass: "refund-policy",
	    autoFocus : false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if (!result) {
      this.purchaseForm.get('agreeTerms').setValue(false);
    }
  }

  async generateTokenData() {
    return await this.subscriptionService.getPaymentToken({
      channelId: this.channel.id,
      content_access_type: "VIEW_DOWNLOAD",
      domainUrl: window.location.host,
      fixedPrice: true,
      from: "/",
      mediaId: this.cartItems.map((item) => item.id),
      method: "fixed",
      payment_type: "fixed",
      ppvType: "PPV",
      user_id: this.session.userId + '',
    });
  }

  async activateCoupon() {

    this.loadingCoupon = true;

    this.discountPrice = null;
    this.couponData = null;
    this.alternativePurchaseMode = false;

    if (!this.couponCode) {
      this.loadingCoupon = false;
      return;
    }
    
    let cuponValidate = await this.subscriptionService.validateCupon({
      coupon_code: this.couponCode,
      ids: this.cartItems.map((item) => item.id),
      type: 'fixed',
      userId: this.session.userId,
      channelId: this.channel.id,
      token: this.token
    });

    if (cuponValidate && cuponValidate.error) {
      this.invalidCoupon = true;
      this.loadingCoupon = false;
      return;
    }

    this.discountPrice = cuponValidate.discount_price;
    this.couponData = cuponValidate;
    this.cartService.addCoupon(this.couponCode);
    
    if(this.discountPrice == 0 && this.couponCode){
      this.alternativePurchaseMode = true
    }

    this.invalidCoupon = false;     
    this.loadingCoupon = false;
  }

  removeCoupon() {
    this.invalidCoupon = false;
    this.discountPrice = null;
    this.alternativePurchaseMode = false;
    this.couponCode = null;
    this.couponData = null;
    this.cartService.removeCoupon();
  }

  changePayment(method) {
    this.purchaseForm.get('payment').setValue(method);
  }

  async buySubscription(){

    this.getAccess = true;
    this.loadingCoupon = true;

    try {
      await this.subscriptionService.buyMultipleMedias(
        {
          payment_type : 'fixed',
          token: this.token,
          channelId: this.channel.id,
          user_id: this.session.userId + '',
          coupon_code: this.couponCode,
          id: this.cartItems.map((item) => item.id),
          method: "fixed",
          subscriptionSlug: undefined
        }
      );

      this.success = true;
      this.medias = this.cartItems;
      this.loadingCoupon = false;
      await this.cartService.resetCartData();
      
    } catch (error) {
      this.failed = true;
      console.log('error', error);
    } finally {
      this.getAccess = false;
    }
  }

  async save() {

    this.loadingSave = true;

    if(!this.cartItems.length){
      return;
    }

    let data = this.cartItems.map((item) => {
      return {
        subscription_id: item.id,
        media_id: item.mediaId || item.media_id,
        channelId: this.channel.id,
      };
    });
    
    if(this.payment.value == 'stripe') {
      
      try {
        await this.stripeService.loadStripeInstance();
        const stripeSession = await this.stripeService.createMultipleSessions({
          data,
          token: this.token,
          user_id: this.session.userId + '',
          payment_type : "fixed",
          coupon_code: this.discountPrice ? this.couponCode : null
        });
        await this.stripeService.redirectToCheckout(stripeSession);
      } catch (error) {
        this.failed = true;
      }
    
    }
    this.loadingSave = false;
  }

  async tryAgain() {
    this.failed = false;
    this.isFromStripe = false;
    this.medias = [];

    await this.updateTokenData();
    await this.activateCoupon();
  }

  async goBack(){
    if(this.getAccess){
      return;
    }

    if(this.isFromModal) {
      this.dialogRef?.close?.({reload: this.success});
    } else {
      this.router.navigate(['/']);
    }

    if(this.success){
      await this.cartService.resetCartData();
    }
  }

  async removeFromCart(item: any) {
    await this.cartService.removeItemFromCart(item);
    await this.updateTokenData();
    await this.activateCoupon();
  }

  get payment(): AbstractControl {
    return this.purchaseForm.get('payment');
  }

}
