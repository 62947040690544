<div class='content-user-media' *ngIf='userMedias.length' (scroll)="onScroll($event)">
	<ng-container *ngFor='let media of userMedias'>
		<app-thumbnail-video [videoData]="media" [recommended]="true" [ngClass]='{"left-none": userMedias.length % 4 == 1 || userMedias.length % 4 == 0, "left-3": userMedias.length % 4 == 2, "left-2": userMedias.length % 4 == 3}'></app-thumbnail-video>
	</ng-container>
</div>

<div class="pagination-loading" *ngIf="paginationLoading"><mat-spinner diameter="40"></mat-spinner></div>

<div class='no-events user-videos' *ngIf='!userMedias.length'>
	{{'profile.no-results' | translate}}
</div>
