import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadVideoService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  async uploadFileUrl(url, file : File){
    try {
      const headers = new HttpHeaders({
        'Access-Control-Allow-Origin':'*',
        'Content-Type': '',
        'Accept':'*/*',
        // "Domain-Channel": window.location.href.includes('localhost') ? `${environment.domainChannel}` : ''
        "Domain-Channel": `${environment.domainChannel}`
      });

      const data = await file.arrayBuffer();

      let response: any = await this.httpClient.put(url, data , {headers}).toPromise();
      return response;  
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async sendVideoData(userData: any){
    try {
      const session: any = await this.authService.getSession();

      const token = session ? session.token : "";
      const lang = localStorage.getItem('lang')  || 'en';
      
      const headers = new HttpHeaders({
        'Accept-Language': lang,
        'Authorization' : token ? "Bearer " + token : "",
        // "Domain-Channel": window.location.href.includes('localhost') ? `${environment.domainChannel}` : ''
        "Domain-Channel": `${environment.domainChannel}`
      });

      let response: any = await this.httpClient.post(`${environment.api_vms_url}/upload-public-video`,userData, {headers}).toPromise();
      return response;  
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }
}
