import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<RefundComponent>,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    await this.authService.createRoutine();
  }

  cancel() {
    this.dialogRef.close(false);
  }

  agree() {
    this.dialogRef.close(true);
  }
}
