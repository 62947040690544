import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { LanguageComponent } from '../language/language.component';
import { LoginComponent } from '../login/login.component';
import { SignupComponent } from '../signup/signup.component';
import { AuthService } from '../auth/auth.service';
import { CategoryService } from '../category.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ChannelService } from '../services/channel.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RequestHelpComponent } from './request-help/request-help.component';
import { CartComponent } from './cart/cart.component'; 
import { AnalyticsService } from '../analytics.service';
import { ObservableService } from '../services/observable.service';
import { CartService } from './cart/cart.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {

  @ViewChild('categoriesMenuTrigger') categoriesMenuTrigger: MatMenuTrigger;
  @ViewChild('moreMenuTrigger') moreMenuTrigger: MatMenuTrigger;
  @ViewChild('profileMenuTrigger') profileMenuTrigger: MatMenuTrigger;
  @ViewChild('searchInputMobile') searchInputMobile: ElementRef;
  @ViewChild('searchInputDesktop') searchInputDesktop: ElementRef;

  isNewHomepageUrl = /(onlysports\.live\/|usssalive\.com|iswim.tv|vewbie.com\/channel)/.test(window.location.href);
  redirectLocationHref =
    window.location.protocol + '//' + window.location.host + '/channel';

  isOtt: boolean = false;
  isLogged: boolean = false;
  loading: boolean = false;
  menuCategories: any = [];
  menuCategoriesSlice: any = [];
  allCategories: any = [];
  subCategories: any = [];
  menuOpened: boolean = false;
  channelBrand: any;
  session: any;
  categoryOnHoverMenu: any;
  hostName: any;

  categoriesOpen: boolean = false;
  moreOpen: boolean = false;
  profileOpen: boolean = false;
  loadedContent: boolean = false;
  searchMenu: boolean = false;

  showCookieWarning: boolean = false;
  showNewPlatformWarning: boolean = true;
  isMobile: boolean = false;
  mobileOpened: boolean = false;
  dashChannelUrl: any;
  observableServiceSubscription: Subscription;
  routeSubscription: Subscription;

  languages: any = [
    { id: 'en', name: 'English', source: '../../assets/icons/flags/usa.png'},
    { id: 'de', name: 'Deutsch', source: '../../assets/icons/flags/germany.png'},
    { id: 'it', name: 'Italian', source: '../../assets/icons/flags/italy.png'},
    { id: 'zh-CN', name: '官话', source: '../../assets/icons/flags/china.png'},
    { id: 'zh-TW', name: '客家语', source: '../../assets/icons/flags/hong-kong.png'},
  ];

  selectedLang: any = 'en';
  
  categorySlug: string;
  categoryName: string;

  allowCreateChildSites: boolean = false;

  originUrl: string = window.location.origin;
  allCategoriesLink: string = this.originUrl + '/categories';
  liveNowLink: string = this.originUrl + '/see-all/live';
  channelInfo;

  constructor(
    private dialog: MatDialog,
    public authService: AuthService,
    private categoryService: CategoryService,
    public channelService: ChannelService,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService : AnalyticsService,
    private observableService : ObservableService,
    private cartService: CartService
  ) { 
    this.observableServiceSubscription = this.observableService.getCurrentSessionObservable().subscribe((data)=>{
      this.session = data;
      this.isLogged = data;
    })

    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let categorySlug = this.router.url.split('/')[1].toString();
        
        let category = this.menuCategories.filter(category => category.category_slug == categorySlug);
        if(!category.length) {
          this.categorySlug = "";
        } else {
          this.categorySlug = category[0].category_slug;
        }

        setTimeout(() => {
          this.getCategorySlug(this.menuCategories);
        }, 300);  
      }
    });
    this.authService.loadTimezone();
  }

  ngOnDestroy(){
    if(this.observableServiceSubscription) {
      this.observableServiceSubscription.unsubscribe();
    }

    if(this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    this.channelInfo = await this.getCurrentChannel();

    if(window.innerWidth <= 768) {
      this.isMobile = true;
    }

    this.getCookiePolicy();

    await this.authService.createRoutine();
    this.session = await this.authService.getSession();
   
    this.isLogged = this.session;

    this.selectedLang = await this.authService.getLanguage();

    this.selectedLang = (this.languages.filter(lang => lang.id === this.selectedLang))[0];
  }

  getCookiePolicy() {
    
    let cookiePolicy = this.authService.getCookie('cookie-policy');

    if(!cookiePolicy) {
      let urlSplit = this.router.url.split('/')[1].toString();    
  
      if(urlSplit == 'cookie-policy') {
        this.showCookieWarning = false;
      } else {
        this.showCookieWarning = true;
      }
    }
  }

  goToNewPlatform(){
    this.authService.eraseCookie('new-platform');
    if(this.hostName == 'venkatesh') {
      window.location.href = 'https://venkatesh-tv.vewbie.com/';
    } else if(this.hostName == 'usssa') {
      window.location.href = 'https://usssalive.com/';
    }
  }

  async getCurrentChannel(){
    let result = await this.channelService.getCurrentChannel();
    this.isOtt = result?.is_ott;
    this.hostName = result?.host_name;
    this.dashChannelUrl = result.newDashChannelLink;

    return result;
  }

  async goToCart(){
    const dialogRef = this.dialog.open(CartComponent, {
      panelClass: "dialog-modal",
      maxWidth: '100vw',
      width: "95%",
      height: "95%",
      autoFocus: false,
      disableClose: true,
      data: {
        isFromModal: true
      }
    });
    const data = await dialogRef.afterClosed().toPromise();
    if(data.reload){
      window.location.reload();
    }
  }

  get cartItems() {
    return this.cartService.cartItems;
  }

  async requestHelp(){
    this.observableService.setChangeCarouselByArrowObservable(false);
    const dialogRef = this.dialog.open(RequestHelpComponent, {
      panelClass: "dialog-modal",
      width: "768px",
      autoFocus: false
    });

    await dialogRef.afterClosed().toPromise();
    this.observableService.setChangeCarouselByArrowObservable(true);
  }

  async ngAfterViewInit() {
    this.loading = true;

    let promises = [this.getCategories(), this.getChannelBrand(), this.getCurrentChannel()];
    await Promise.all(promises);

		if(this.menuCategoriesSlice?.length && this.menuCategoriesSlice[0]) {
			this.subCategories = this.menuCategoriesSlice[0].sub_categories;
		}

    this.allowCreateChildSites = await this.channelService.allowCreateChildSites();

		this.loadedContent = true;
    this.loading = false;
  }

  menuType(condition) {
    this.subCategories = condition.sub_categories; 
    this.categoryOnHoverMenu = condition;
  }

  async getCategories() {
    this.allCategories = await this.categoryService.getAllCategories('priority', 'asc');

    if(this.allCategories) {
      this.menuCategories = this.allCategories.slice(0, 5); 
      this.menuCategoriesSlice = this.allCategories.slice(5);

      this.categoryOnHoverMenu = this.menuCategoriesSlice[0];
      
      this.getCategorySlug(this.allCategories);
    }

  }

  getCategorySlug(categories) {
    let categorySlug = this.router.url.split('/')[1].toString();    
    let category = categories.find(category => category.category_slug == categorySlug);
    
    if(category) {
      this.categorySlug = category.category_slug;
      this.categoryName = category.category_name;
      this.menuCategoriesSlice = category.sub_categories;
      this.categoryOnHoverMenu = this.menuCategoriesSlice[0];
      this.menuCategories = this.menuCategories.filter(item => item.category_slug != categorySlug);
      this.menuCategories.unshift(category);

    } else if(this.allCategories){
      this.menuCategoriesSlice = this.allCategories.slice(5);
    }

    if(this.menuCategoriesSlice?.length && this.menuCategoriesSlice[0]) {
			this.subCategories = this.menuCategoriesSlice[0].sub_categories;
		}
  }

  async getChannelBrand() {
    this.channelBrand = await this.channelService.getChannelBrand();
  }

  async openSignin() {
    this.observableService.setChangeCarouselByArrowObservable(false);
    const dialogRef = this.dialog.open(LoginComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {isModal: true},
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if(result == 'success') {
      window.location.reload();
    } 
    else if(result == 'signup') {
      this.openSignup();
    }
    else if(result == 'forgot') {
      this.openForgotPassword();
    }
    else {
      this.observableService.setChangeCarouselByArrowObservable(true);
    }
  }

  async logout() {
    await this.authService.clearSession();
    window.location.reload();
  }

  async openSignup() {
    this.observableService.setChangeCarouselByArrowObservable(false);
    const dialogRef = this.dialog.open(SignupComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {isModal: true},
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if(result == 'signin') {
      this.openSignin();
    }
    else if(result == 'success') {
      window.location.reload();
    }
    else {
      this.observableService.setChangeCarouselByArrowObservable(true);
    }
  }

  async openForgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {isModal: true},
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if(result == 'signin') {
      this.openSignin();
    }
    else {
      this.observableService.setChangeCarouselByArrowObservable(true);
    }
  }

  async changeLanguage() {
    const dialogRef = this.dialog.open(LanguageComponent, {
      width: "25%",
      panelClass: "dialog-modal",
      data: {isModal: true},
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();
  }

  async ping(){
    this.authService.ping();
  }

  closeContinue(menu) {
    if (!this.isMobile) {
      switch (menu) {
        case 'categories':
					if(this.menuCategoriesSlice.length && this.menuCategoriesSlice[0]) {
            setTimeout(() => {
              this.categoryOnHoverMenu = this.menuCategoriesSlice[0];
            }, 300);
						this.subCategories = this.menuCategoriesSlice[0].sub_categories;
					}
          this.categoriesMenuTrigger?.closeMenu();
          break;
        case 'more':
          this.moreOpen = false;
          this.moreMenuTrigger.closeMenu();
          break;
        case 'profile':
          this.profileOpen = false;
          this.profileMenuTrigger.closeMenu();
          break;
      }
    }
  }

  redirectIfNotMobile(url) {
    if (!this.isMobile){
      const usssaChannel = this.channelInfo && (this.channelInfo.id == 11 || this.channelInfo.parent_id == 11);
      const usssaSubchannel = (window.location.href).indexOf('/channel/') != -1;

      if(usssaChannel && usssaSubchannel) {
        const subChannelHostName = window.location.href.replace(window.location.origin + '/channel/', '').split('/')[0];
        url = this.originUrl + '/channel/' + subChannelHostName;
      }

      window.location.href = url
    }
  }

  openMenuMobile() {
    this.mobileOpened = !this.mobileOpened;
  }

  openMobileCategories() {
    this.categoriesOpen = !this.categoriesOpen;
  }

  showSubCategorie(category) {
    category.showSubs = !category.showSubs;
  }

  focusSearch() {
    setTimeout(() => {
      this.searchMenu = !this.searchMenu;
      this.searchMenu ? setTimeout(() => this.searchInputMobile.nativeElement.focus(), 100) : null;
    }, 100);
  }

  getSubChannelCategoryUrl(category) {
    let categoryUrl;

    if(this.channelInfo && (this.channelInfo.id == 11 || this.channelInfo.parent_id == 11)) {
      categoryUrl = this.originUrl + '/channel/' + category.hostName;
    } else {
      categoryUrl = category.subchannel_url;
    }

    return categoryUrl;
  }
  
  goToCategoryHomepage(category?, event?: Event) {
    // Anchor elements are the ones calling this function, if the anchor element is clicked, we should prevent its default action and proceed with this function
    event?.preventDefault();
    
    this.subCategories = [];

    if(this.isMobile) {
      this.categoriesMenuTrigger?.closeMenu();
    }

    if(category && category.subchannel_id && category.subchannel_url) {
      let url = category.subchannel_url;
      if (!url.startsWith('https://')) {
        url = 'https://' + url;
      }
      window.open(url, '_blank');
      return;
    }

    let url = category ? category.category_slug : "";

    if(category && (this.categorySlug == category.category_slug || !category.category_slug)) {
      this.categorySlug = "";
      url = "";    
    }

    const usssaChannel = this.channelInfo && (this.channelInfo.id == 11 || this.channelInfo.parent_id == 11);
    const usssaSubchannel = (window.location.href).indexOf('/channel/') != -1;

    if(!category && usssaChannel && usssaSubchannel) {
      const subChannelHostName = window.location.href.replace(window.location.origin + '/channel/', '').split('/')[0];
      url = 'channel/' + subChannelHostName;
    }
    
    this.router.navigate([`${url}`]);
    this.analyticsService.trackingCategoryPromotedNavBar(category?.category_slug);
    setTimeout(() => {
      this.getCategorySlug(this.menuCategories);
    }, 300);
  }

  goToSchedule() {
    if(this.categoriesMenuTrigger) {
      this.categoriesMenuTrigger.closeMenu();
    }
    this.categorySlug = "";
    
    this.router.navigate([`/schedule`])

    setTimeout(() => {
      this.getCategorySlug(this.menuCategories);
    }, 300);
  }
  

  goHome(){
    this.goToCategoryHomepage();
  }

  goToSubscriptions() {
    this.goToPath(`/subscriptions`);
  }
  
  goToAllCategories(event: Event) {
    // Anchor elements are the ones calling this function, if the anchor element is clicked, we should prevent its default action and proceed with this function
    event.preventDefault();

    this.goToPath(`/categories`);
  }

  getCategoryUrl(category): string {
    let categoryUrl: string = '';
    
    if(!category) {
      return categoryUrl
    }
    
    if(category.subchannel_id && category.subchannel_url) {
      categoryUrl = this.getSubChannelCategoryUrl(category);
    } else {
      categoryUrl = window.location.origin;

      if(!category.promoted) {
        categoryUrl += '/categories';
      }

      categoryUrl += `/${category.category_slug}`;
    }

    return categoryUrl;
  }
  
  goToCategoryDetails(category, event: Event) {
    // Anchor elements are the ones calling this function, if the anchor element is clicked, we should prevent its default action and proceed with this function
    event.preventDefault();

    if (
      this.isNewHomepageUrl &&
      category.subchannel_id &&
      category.subchannel_url &&
      category.subchannel_url.includes('vewbie.com')
    ) {
      let subchannelUrl = `${this.redirectLocationHref}/${category.hostName}`;
      window.open(`${subchannelUrl}`, '_blank');
      return;
    }

    if (category.subchannel_id && category.subchannel_url) {
      let url = category.subchannel_url;

      if (!category.subchannel_url.startsWith('https://')) {
        url = 'https://' + category.subchannel_url;
      }

      window.open(url, '_blank');
      return;
    }
    
    this.goToPath(`/categories/${category.category_slug}`);
  }

  goToDashboard() {
    if(this.dashChannelUrl){
      window.open(this.dashChannelUrl);
    }
  }

  async goToCreateChannel() {
    const url = await this.channelService.getCreateChannelUrl();
    window.open(url);
  }

  goToProfile(option) {
    this.goToPath(`/user/${option}`);
  }

  goToLive(event?: Event) {
    // Anchor elements are the ones calling this function, if the anchor element is clicked, we should prevent its default action and proceed with this function
    event?.preventDefault();

    this.analyticsService.trackingLiveNow();
    this.goToPath(`/see-all/live`);
  }

  goToPath(path) {
    if(this.categoriesMenuTrigger) {
      this.categoriesMenuTrigger.closeMenu();
    }

    this.categorySlug = "";

    this.router.navigate([`${path}`]);
  }

  search() {
    this.router.navigate([`/search`],{queryParams: {search_query: this.isMobile ? this.searchInputMobile.nativeElement.value : this.searchInputDesktop.nativeElement.value}});
  }

  acceptCookiePolicy() {
    this.authService.setCookie("cookie-policy", true, null);
    this.showCookieWarning = false;
  }
}
