import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AnalyticsService } from 'src/app/analytics.service';
import { AuthService } from 'src/app/auth/auth.service';
import { CategoryService } from 'src/app/category.service';
import { CategoriesDialogComponent } from 'src/app/main/categories/categories-dialog/categories-dialog.component';
import { RedirectService } from 'src/app/redirect.service';
import { ChannelService } from 'src/app/services/channel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-thumbnail-video',
  templateUrl: './thumbnail-video.component.html',
  styleUrls: ['./thumbnail-video.component.scss']
})
export class ThumbnailVideoComponent implements OnInit {
  @Input() videoData: any;
  @Input() context: any;
  @Input() fromSchedule: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() recommended: boolean = false;
  @Input() columnsView: boolean = false;
  @Input() hideLiveViewers: boolean;

  categories: any = [];

  categorySlug: any;
  @ViewChild("refEl") videoElement: ElementRef;
  hoverInterval: any;
  tooltipTags: any = '';
  user: any;
  isAdmin = false;

  clickOnActionButtons: boolean = false;
  isMobile: boolean = false;

  highlightFirstCategory: boolean = true;
  channel: any;

  mediaLink: string = '';

  isNewHomepageUrl = /(onlysports\.live\/|usssalive\.com|iswim.tv|vewbie.com\/channel)/.test(window.location.href);

  redirectLocationHref =
    window.location.protocol + '//' + window.location.host + '/channel';

  constructor(
    private router: Router,
    private authService: AuthService,
    private categoryService: CategoryService,
    private analyticsService : AnalyticsService,
    private dialog: MatDialog,
    private channelService: ChannelService,
    private redirectService: RedirectService
  ) { }

  async ngOnInit(){    
    this.user = await this.authService.getSession();
    this.channel = await this.channelService.getCurrentChannel();

    if(window.innerWidth <= 768) {
      this.isMobile = true;
    }

    if(this.user && (this.user.user_admin_type == "ADMIN" || this.user.user_admin_type == "MANAGER")) {
      this.isAdmin = true;
    }

    if(this.videoData.categories?.length) { //new way to receive categories
      this.categories = this.videoData.categories;
      this.categories.forEach(category => {
        if (!category.category_thumbnail_url && category.category_name) {
          category.categorySlug = this.categoryService.getInitials(category.category_name);
        }
      });
    }

    if(this.router.url.includes('/categories/')){
      let categorySelected: any = this.router.url.split('/')[2];

      let indexIdentify = this.categories.findIndex(category => category.category_slug == categorySelected);

      //https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
      let element = this.categories[indexIdentify]; 
      this.categories.splice(indexIdentify, 1);
      this.categories.splice(0, 0, element);
    }

    if(this.videoData.hasOwnProperty("thumbnails")) {
      this.videoData.thumbnail_url = this.videoData.thumbnails;
    } 

    if(this.videoData.event_date) {
      this.videoData.event_date = moment(this.videoData.event_date).toDate();
    }

    if(this.videoData.end_datetime) {
      this.videoData.end_datetime = moment(this.videoData.end_datetime).toDate();
    }

    if(this.videoData.start_datetime) {
      this.videoData.start_datetime = moment(this.videoData.start_datetime).toDate();
    }

    if(this.videoData.timestamp) {
      this.videoData.timestamp = moment(this.videoData.timestamp).toDate();
    }

    this.tooltipTagsConstruct();

    if(this.videoData.redirect_subchannel_media_url || this.videoData.isFromChildChannel) {
      this.mediaLink = this.getChildChannelVideoUrl();
    } else {
      const mediaType: string = this.videoData.type == "EVENT" ? '/live' : '/video';
      this.mediaLink = window.location.origin + mediaType + '/' + this.videoData.media_slug;
    }
  }
  
  tooltipTagsConstruct() {
    let tags = this.videoData.tags?.map(tag => tag.tag_name);
    this.tooltipTags = tags?.splice(3, this.videoData.tags.length - 1).join(', ');
  }

  mouseOver() {
    let images = this.videoData.thumbnail_url_hover;
    let i = 0;

    if(images && images.length > 0) {
      this.videoElement.nativeElement.src = images[i];
      this.videoElement.nativeElement.setAttribute('style', 'opacity: 1');
  
      this.hoverInterval = setInterval(() => {
        i++;
        this.videoElement.nativeElement.setAttribute('style', 'opacity: .5');
        setTimeout(() => {
          if(i == images.length) {
            i = 0;
          }
          this.videoElement.nativeElement.src = images[i];
        this.videoElement.nativeElement.src = images[i];      
          this.videoElement.nativeElement.src = images[i];
          this.videoElement.nativeElement.setAttribute('style', 'opacity: 1');      
        }, 500);
      }, 2000)
    }

  }

  mouseOut() {
    if(this.videoData.thumbnail_url) {
      this.videoElement.nativeElement.src = this.videoData.thumbnail_url;
    } else {
      this.videoElement.nativeElement.src = '../../assets/imgs/prism.png';
    }
    this.videoElement.nativeElement.setAttribute('style', 'opacity: .5');
    clearInterval(this.hoverInterval);
  }

  getChildChannelVideoUrl() {
    let mediaLink = ``;

    if(this.videoData.redirect_subchannel_media_url && 
      ![+this.channel.id, +(this.channel.parent_id ?? 0)].some((id) => id === 11 || id === 438 || id === 211)) {
      mediaLink = this.videoData.redirect_subchannel_media_url;
    } else {

      // This if bellow should not be commented on onlysportslive branch

      // Onlysports child channels use the same domain, we differentiate channels using the url
      if (
        [+this.channel.id, +(this.channel.parent_id ?? 0)].some(
          (id) => id === 11 || id === 438 || id === 211
        ) || this.isNewHomepageUrl
      ) {
        let hostname = this.videoData.channelHostName
          ? this.videoData.channelHostName
          : this.videoData.channel_host_name;
        mediaLink = window.location.origin + '/channel/' + hostname;
      } else {
        mediaLink = this.videoData.subchannelUrl;
      }

      // This bellow should be commented on onlysportslive branch
      // mediaLink = this.videoData.subchannelUrl;

      const mediaType: string = this.videoData.type == "EVENT" ? '/live' : '/video';
      mediaLink += mediaType + '/' + this.videoData.media_slug;
    }

    return mediaLink;
  }
  
  goToVideo(event: Event) {
    // Anchor elements are the ones calling this function, if the anchor element is clicked, we should prevent its default action and proceed with this function
    event.preventDefault();

    if(!this.clickOnActionButtons) {
      if(this.videoData.redirect_subchannel_media_url || this.videoData.isFromChildChannel) {
        window.open(this.getChildChannelVideoUrl(), "_blank");
        return;
      }

      if(this.videoData.type == "EVENT") {
        this.analyticsService.trackingOpenVideo({ slug: this.videoData.media_slug, media: 'live', path: window.location.href});
        this.router.navigate([`live/${this.videoData.media_slug}`])
      } else {
        this.analyticsService.trackingOpenVideo({ slug: this.videoData.media_slug, media: 'video', path: window.location.href});
        this.router.navigate([`video/${this.videoData.media_slug}`])
      }
    }
  }

  redirectToPath(path ?: string){
    this.redirectService.goTo({
      domainName: this.videoData.domain_name,
      hostName: this.videoData.host_name,
      path: path
    })
  }

  goToChannel(){
    this.redirectToPath()
  }

  goToCategoryDetails(category) {

    // usssalive.com or onlysports.live or iswim-tv.com or vewbie.com/channel/hostname
    if (
      this.isNewHomepageUrl &&
      category.subchannel_id &&
      category.subchannel_url &&
      category.subchannel_url.includes('vewbie.com')
    ) {
      let hostName = category.hostName ? category.hostName : category.host_name;
      let subchannelUrl = `${this.redirectLocationHref}/${hostName}`;
      window.open(`${subchannelUrl}`, '_blank');
      return;
    }

    if(category.redirect_category_url) {
      window.open(category.redirect_category_url, '_blank')
      return;
    }
    
    // hostname.app.vewbie.com
    if (category.subchannel_id && category.subchannel_url) {
      let url = category.subchannel_url;
      if (!url.startsWith('https://')) {
        url = 'https://' + url;
      }
      window.open(url, '_blank');
    }
    
    this.router.navigate([`categories/${category.category_slug}`]);
  }

  async openCategoriesModal() {
    this.dialog.open(CategoriesDialogComponent, {
      width: "50%",
      panelClass: "dialog-modal",
      data: this.categories,
      autoFocus: false
    });
  }

  checkOpacityFirstCategory(index){
    if(index == 0) {
      this.highlightFirstCategory = true;
    }
    else {
      this.highlightFirstCategory = false;
    }
  }

  goToTagDetails(tag?, event?: Event) {
    event?.preventDefault();

    this.clickOnActionButtons = true;

    if(tag?.redirect_tag_url) {
      window.open(tag.redirect_tag_url, '_blank');
      return;
    }

    if(tag) {
      this.router.navigate([`tag/${tag.tag_slug}`]);
    }
    setTimeout(() => {
      this.clickOnActionButtons = false;
    }, 200);
  }

  clipVideo() {
    this.clickOnActionButtons = true;
    let urlParam = "user";
    let channelLink = this.channel.oldDashChannelLink;
    
    if(this.isAdmin) {
      urlParam = "admin";
    }

    if(this.videoData.channel_link_url) {
      channelLink = this.videoData.channel_link_url;
    }
    
    window.open(`${channelLink}/${urlParam}/clipextraction/vod/${this.videoData.media_slug}`);

    setTimeout(() => {
      this.clickOnActionButtons = false;
    }, 1000);
  }  
  
  edit() {
    this.clickOnActionButtons = true;
    let urlParam = "video";
    let channelLink = this.channel.oldDashChannelLink;

    if(this.videoData.type == "EVENT") {
      urlParam = "events"
    }
    
    if(this.videoData.source == "CLIP") {
      urlParam = "clip";
    }

    if(this.videoData.channel_link_url) {
      channelLink = this.videoData.channel_link_url;
    }
    
    window.open(`${channelLink}/admin/${urlParam}/edit/${this.videoData.media_slug}`);

    setTimeout(() => {
      this.clickOnActionButtons = false;
    }, 1000);    
  }
}
  