<div class='main-container' [ngClass]='{"more-width": !adblockActivated || isPlaysMode}'>
  <div class="example-loading-shade" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class='content' *ngIf="!loading">
    <div class='row'>
      <div class='column-video' [ngClass]="{'full-video': adblockActivated || isPlaysMode, 'clip-screen': isClipMode }">
        <div class='video-area'>
          <div [ngClass]='{"clip-mode": isClipMode}'>

            <div class="player-main-container" [ngClass]='{"plays-mode": isPlaysMode && !isClipMode}'>
              <div class="player-container">

                <div class='player' [ngClass]="{'live' : isLive}" [ngStyle]="{'display': mediaAvailable && ((isLive && mediaData.online) || !isLive) && ((isLive && isToday) || !isLive) ? 'block' : 'none'}" >
                  <div class='video-container' #videoContainer>
                    <!-- <app-video-player [channel]='channel' [playerSettings]="mediaData.player_settings" [isLive]="isLive" [urls]="mediaData.videoFiles" [hlsURL]="mediaData.player_settings.hlsURL" [poster]="mediaData.poster.image" [mediaData]="mediaData" [gamePlays]="gamePlays"></app-video-player> -->
                    <app-snack-player [channel]='channel' [playerSettings]="mediaData.player_settings" [isLive]="isLive" [urls]="mediaData.videoFiles" [hlsURL]="mediaData.player_settings.hlsURL" [poster]="mediaData.poster.image" [mediaData]="mediaData" [gamePlays]="gamePlays"></app-snack-player>
                  </div>
                </div>

                
                <div class='player' [ngStyle]="{'display': !mediaAvailable || (!mediaData.online && isLive) ? 'block' : 'none'}" >
                  <div class='blocked-video'>
                    <div class='gradient'>
                      <div class='block-message' *ngIf="!mediaAvailable">
                        <img src="../../../../assets/icons/lock.svg">
                        <ng-container>
                          <p class='title'>{{'video.continue-watching' | translate}}</p>
                          <p class='subtitle'>{{'video.video-restricted' | translate}}</p>
                          <div class='button' (click)="getSubscription()"> 
                            {{getLabelBtnForRestrictedVideo() | translate}}
                          </div>
                        </ng-container>
                      </div>

                      <div class='block-message' *ngIf="mediaAvailable && mediaData.statusCountDown && isLive">
                        <img *ngIf="mediaData.statusCountDown && isLive" src="../../../../assets/icons/calendar.svg">
                        <p class='title'>{{'video.starts' | translate}} {{countDownText}}</p>
                      </div>
                      <div class='block-message' *ngIf="mediaAvailable && !mediaData.online && isLive && !mediaData.statusCountDown && (mediaData.isFree || mediaData.isPaid || (!mediaData.isFree && mediaData.isPaid))">
                        <p class='title'>{{'video.waiting' | translate}}</p>
                      </div>
                    </div>
                    <img *ngIf="mediaData.poster?.image" [src]="mediaData.poster.image">
                  </div>
                </div>


                <div *ngIf="!isClipMode" class='video-info'>
                  <div class='left'>
                    <div class="category" *ngIf="mediaData.categories.length">
                      <ng-container *ngIf='mediaData.categories.length <= 3 && !isMobile'>
                        <div style='display: flex;'>
                          <a class="category-image" (mouseenter)='checkOpacityFirstCategory(i)' (mouseleave)='checkOpacityFirstCategory(0)' [ngClass]='{"first": i == 0 && highlightFirstCategory}' (click)="goToCategoryDetails(category)" target="_blank" *ngFor='let category of mediaData.categories; let i = index;' matTooltipClass="tooltip-class-strong" [matTooltip]="category.category_name" matTooltipPosition="above">
                            <div *ngIf="category.category_thumbnail_url" class="image" [ngStyle]="{'background-image': 'url(' + category.category_thumbnail_url + ')'}"></div>
                            <div *ngIf="!category.category_thumbnail_url " class="no-image">{{category.categorySlug}}</div>
                          </a>
                        </div>
                      </ng-container>

                      <ng-container *ngIf='mediaData.categories.length > 3 || isMobile'>
                        <div class="no-image categories-length" (click)='openCategoriesModal()'>{{mediaData.categories.length + (mediaData.categories.length > 1 ? ' Categories' : ' Category')}}</div>
                      </ng-container>
                    </div>
                    <div class='info'>
                      <span class='title'>{{mediaData.title}}</span>
                      <span class='date'>{{mediaData.player_date | dateTimezone: 'MM/dd/yy hh:mm aa'  }}</span>
                    </div>
                  </div>
                  <div class='right'>
                    <div class='action users' *ngIf="mediaData.isLive && !channel.hide_live_viewers">
                      <img data-test="counter_icon" src="../../../../assets/icons/users.svg"> {{subscribers}}
                    </div>

                    <div class='action fav' [ngClass]="{'remove-fav': mediaData.isFav}" (click)="favoriteMedia()"  matTooltipClass="tooltip-class-strong" [matTooltip]="mediaData.isFav ? 'Remove from Favorites' : 'Add to Favorites'" matTooltipPosition="above">
                      <img [src]="mediaData.isFav ? '../../../../assets/icons/heart-solid.svg' : '../../../../assets/icons/heart.svg'" >
                    </div>

                    <div *ngIf="mediaData.content_access_type == 'VIEW_DOWNLOAD' && mediaData.buyByMedia && !mediaAvailable && !isLive" (click)="addToCart(mediaData)" class="action size">
                      <img class="cart" src='../../assets/icons/cart.svg'>
                      {{'video.add-to-cart-download' | translate}}              
                    </div>

                    <div *ngIf="showDownloadButton" class="action" [matMenuTriggerFor]="aboveMenu">
                      <mat-icon class="material-icons-outlined download-icon">file_download</mat-icon>{{ 'video.download' | translate }}
                      <mat-menu #aboveMenu="matMenu" yPosition="above">
                        <app-download-button-list (addToCart)="addToCart($event)" (subscription)="getSubscription(true)" [mediaData]="mediaData"></app-download-button-list>
                      </mat-menu>
                    </div>


                    <div class='action' *ngIf="isAdmin && mediaData.is_video_editor" (click)="edit()">
                      <img src="../../../../assets/icons/edit-2.svg"> {{'video.edit' | translate}} {{isLive ? 'Live' : 'Video'}}
                    </div>

                    <ng-container *ngIf="!isLive">
                      <div class='action' *ngIf="mediaData.is_video_editor && (mediaData.isPaid && isAdmin || (mediaData.isFree && isAdmin)) && mediaData.source != 'CLIP' && user" (click)="clipVideo()">
                        <img src="../../../../assets/icons/scissors.svg"> {{'video.clip' | translate}}
                      </div>

                      <button type="button" class='action' (click)='enableClipExtraction()' [disabled]="!isVideoLoaded" *ngIf="isClipVideo && user && (mediaData.isPaid && !isAdmin || (mediaData.isFree && !isAdmin))" [matTooltip]="clipBtnTitle" matTooltipClass="tooltip-class-strong" matTooltipPosition="above">
                        <i class="fa fa-spinner fa-spin" *ngIf="!isVideoLoaded"></i> <img src="../../../../assets/icons/scissors.svg" *ngIf="isVideoLoaded"> {{'video.clip' | translate}}
                      </button>

                    </ng-container>

                    <div class='action' (click)='shareVideo()'>
                      <img src="../../../../assets/icons/share.svg"> {{'video.share' | translate}}
                    </div>

                  </div>
                  <div class='subscribers-mobile' *ngIf="mediaData.isLive && !channel.hide_live_viewers">
                    <img src="../../../../assets/icons/users.svg"> {{subscribers}}
                  </div>
                </div>



                <p *ngIf="!isClipMode && mediaData.description" class='description' [innerHTML]="mediaData.description"></p>

                <div *ngIf="!isClipMode" id='tags' [ngClass]="{'expanded' : isExpanded}">
                  <span class='tag live' *ngIf="mediaData.isLive && mediaData.online">{{'video.live' | translate}}</span>
                  <span class='tag offline' *ngIf="mediaData.isLive && !mediaData.online">{{'video.offline' | translate}}</span>
                  <a class='tag' [routerLink]="['/tag/' + tag.tag_slug]" *ngFor="let tag of tagsToShow" [ngClass]="{'expanded' : isExpanded}">{{tag.tag_name}}</a>
                </div>
                <span *ngIf="!isClipMode && tags.length > limitTagsMobile" class="tags-count" (click)="toggleTags()">
                  <span *ngIf='isExpanded'>{{'video.show-less' | translate}}</span>
                  <span *ngIf='!isExpanded'>{{'video.show-more' | translate}}</span>
                </span>
              </div>
              <div class="tagging-mode" *ngIf="isPlaysMode && !isClipMode">
                <app-tagging [gamePlays]="gamePlays" [containerHeight]="containerHeight"></app-tagging>
              </div>
            </div>

            <ng-container *ngIf="!isClipMode && tags.length > limitTags">
              <p class='show-more' *ngIf="!showMoreFlag" (click)="showMore()">{{'video.show-more' | translate}}</p>
              <p class='show-more' *ngIf="showMoreFlag" (click)="showMore()">{{'video.show-less' | translate}}</p>
            </ng-container>

            <div class="clip-extractor" *ngIf="isClipMode">
              <div class="ads-message" *ngIf="isAdPlaying">
                <div class="ad-msg-inr">
                  <h5>Continue clipping after ad finishes</h5>
                  <p>Can't create clip while ad is playing</p>
                </div>
              </div>
              <h3>Create Clip</h3>
              <button type="button" class="close-clip" (click)="disableClipExtraction()" *ngIf="!formLoading"><mat-icon matPrefix>close</mat-icon></button>
              <form [formGroup]="form" (ngSubmit)="createClip()" (keydown.enter)="preventSubmit($event)" autocomplete="off">
                <div class="title-textbox">
                  <input type="text" formControlName="title" class="form-control" [ngClass]='{"inp-error": inputError}' placeholder="Enter clip title..." (focusout)="validate($event)" (keyup)="validate($event)">
                </div>
                <app-clip-extractor></app-clip-extractor>
                <div class="clip-footer">
                  <button mat-button class="text-white" type="button" (click)="cancelExtraction()" *ngIf="!formLoading">Cancel</button>
                  <button mat-raised-button class="dis-btn" type="button" disabled *ngIf="btnDisabled">
                    Create Clip
                  </button>
                  <button mat-raised-button color='primary' type="submit" [disabled]='formLoading' *ngIf="!btnDisabled">
                    <mat-spinner diameter='20' *ngIf="formLoading"></mat-spinner> Create Clip
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class='actions-mobile'>
          <div class='action' (click)="favoriteMedia()">
            <img [src]="mediaData.isFav ? '../../../../assets/icons/black-heart-solid.svg' : '../../../../assets/icons/black-heart.svg'" >
            <p class="text-mobile">{{mediaData.isFav ? 'Remove' : 'Add'}}</p>
          </div>

          <div *ngIf="showDownloadButton" class="action" [matMenuTriggerFor]="aboveMenu">
            <mat-icon class="material-icons-outlined download-icon">file_download</mat-icon>
            <p class="text-mobile">{{ 'video.download' | translate }}</p>
            <mat-menu #aboveMenu="matMenu" yPosition="above">
              <app-download-button-list (addToCart)="addToCart($event)" (subscription)="getSubscription(true)" [mediaData]="mediaData"></app-download-button-list>
            </mat-menu>
          </div>

          <div class='action' *ngIf="isAdmin && mediaData.is_video_editor" (click)="edit()">
            <img src="../../../../assets/icons/edit-2.svg">
            <p class="text-mobile">{{'video.edit' | translate}}</p>
          </div>

          <ng-container *ngIf="!isLive ">
            <div class='action' *ngIf="mediaData.is_video_editor && (mediaData.isPaid && isAdmin || (mediaData.isFree && isAdmin)) && mediaData.source != 'CLIP' && user" (click)="clipVideo()">
              <img src="../../../../assets/icons/scissors.svg">
              <p class="text-mobile">{{'video.clip' | translate}}</p>
            </div>
            <button type="button" class='action' (click)='enableClipExtraction()' [disabled]="!isVideoLoaded" *ngIf="isClipVideo && user && (mediaData.isPaid && !isAdmin || (mediaData.isFree && !isAdmin))" [matTooltip]="clipBtnTitle" matTooltipClass="tooltip-class-strong" matTooltipPosition="above">
              <i class="fa fa-spinner fa-spin" *ngIf="!isVideoLoaded"></i> <img src="../../../../assets/icons/scissors.svg" *ngIf="isVideoLoaded"> {{'video.clip' | translate}}
            </button>
          </ng-container>

          <div class='action' (click)='shareVideo()'>
            <img src="../../../../assets/icons/share.svg">
            <p class="text-mobile">{{'video.share' | translate}}</p>
          </div>

          <div *ngIf="mediaData.content_access_type == 'VIEW_DOWNLOAD' && mediaData.buyByMedia && !mediaAvailable && !isLive" class='action' (click)="addToCart(mediaData)">
            <img class="cart" src='../../assets/icons/cart.svg'>
            <p class="text-mobile">{{'video.download' | translate}}</p>
          </div>
        </div>

        <div class='photos-content' *ngIf='showPhotos'>
          <div class='title'>
            <p>Photos</p>
            <p>{{mediaData.photos.length}} images</p>
          </div>

          <div class='photos'>
            <div *ngFor="let photo of mediaData.photos; let i = index" class='img'>
              <img [src]='photo.photoUrl' (click)='goToImageDetails(i)'>
            </div>
          </div>
        </div>

        <!-- Snack Ads -->

        <div [ngClass]='isMobile ? "snack-mldb" : "snack-ldb"' *ngIf='(!adblockActivated) && (hostName == "usssa" || hostName == "iswimtv")' class='mt-12 mb-12'>
          <div id='snack_mpu' *ngIf='!isMobile'></div>
          <div id='snack_mob_mid' *ngIf='isMobile'></div>
        </div>
        <!-- Snack Ads -->
       
        <div *ngIf='lives && lives.length' class='content-group'>
          <div class='group-videos'>
            <app-group-videos [hideLiveViewers]="channel.hide_live_viewers" context='video-content' title="Live Now" seeAllUrl="/see-all/live" [videos]="lives"></app-group-videos>
          </div>
        </div>        

        <div *ngIf='relatedVideos && relatedVideos.length' class='content-group'>
          <div class='group-videos'>
            <app-group-videos context='video-content' title="Related Videos" [isRelatedVideos]="true" [videos]="relatedVideos"></app-group-videos>
          </div>
        </div>

        <disqus *ngIf="mediaData?.player_settings?.disqus && mediaData.isChat" [identifier]="mediaData?.id" [shortName]="mediaData?.player_settings?.disqus" [title]="mediaData?.title" [language]="'en'"></disqus>
      </div>

      <div class='ad-content' *ngIf='!adblockActivated && !isPlaysMode'>
        <div class='fixed'>
          <!-- Snack Ads -->
          <div [ngClass]='isMobile ? "snack-mpu" : "snack-dpmu"' class='mt-12 mb-12'>
            <div id="snack_dmpu" *ngIf="!isMobile"></div>
            <div id="snack_mob_bot" *ngIf="isMobile"></div>
          </div>
          <!-- Snack Ads -->
        </div>
      </div>
      
    </div>
  </div>
</div>
