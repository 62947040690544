
<div class="content">
  <div class="panel"></div>


  <div class="user-info">
    <div class="profile" *ngIf="(isMobile && !labelSelected) || !isMobile">
      <div class="user-information">
        <div *ngIf="!session?.profile_picture" class="user-image">
        </div>
        <div *ngIf="session?.profile_picture" class="user-image">
          <img [src]="session?.profile_picture" alt="">
        </div>
        <div class="user-name">
          <div class="name">{{session?.full_name}}</div>
          <div class="my-profile">{{'profile.my-profile' | translate}}</div>
        </div>
      </div>
  
      <div class="options">
        <div (click)='goToOption("overview")' class="option" [ngClass]="{'selected': labelSelected=='overview'}">
          <img *ngIf="labelSelected!='overview'" src="../../../assets/icons/profile.svg" alt="">
          <img *ngIf="labelSelected=='overview'" src="../../../assets/icons/profile-blue.svg" alt="">
          <span [ngClass]="{'selected': labelSelected=='overview'}">{{'profile.overview' | translate}}</span>
        </div>
        <div (click)='goToOption("subscriptions")' class="option" [ngClass]="{'selected': labelSelected=='subscriptions'}">
          <img *ngIf="labelSelected!='subscriptions'" src="../../../assets/icons/credit-card.svg" alt="">
          <img *ngIf="labelSelected=='subscriptions'" src="../../../assets/icons/credit-card-blue.svg" alt="">
          <span [ngClass]="{'selected': labelSelected=='subscriptions'}">{{'profile.subscription' | translate}}</span>
        </div>
        <div (click)='goToOption("unlock-video-bundles")' class="option small" [ngClass]="{'selected': labelSelected=='unlock-video-bundles'}">
          <span [ngClass]="{'selected': labelSelected=='unlock-video-bundles'}">{{'profile.unlocked-video-editor-bundles' | translate}}</span>
        </div>
        <div (click)='goToOption("unlock-video-lives")' class="option small" [ngClass]="{'selected': labelSelected=='unlock-video-lives'}">
          <span [ngClass]="{'selected': labelSelected=='unlock-video-lives'}">{{'profile.unlocked-video-streams' | translate}}</span>
        </div>
        <div (click)='goToOption("videos")' class="option" [ngClass]="{'selected': labelSelected=='videos'}">
          <img *ngIf="labelSelected!='videos'" src="../../../assets/icons/camera.svg" alt="">
          <img *ngIf="labelSelected=='videos'" src="../../../assets/icons/camera-blue.svg" alt="">
          <span [ngClass]="{'selected': labelSelected=='videos'}">{{'profile.videos' | translate}}</span>
        </div>
        <div (click)='goToOption("favorite-videos")' class="option small" [ngClass]="{'selected': labelSelected=='favorite-videos'}">
          <span [ngClass]="{'selected': labelSelected=='favorite-videos'}">{{'profile.favorites' | translate}}</span>
        </div>
        <div (click)='goToOption("recommended-videos")' class="option small" [ngClass]="{'selected': labelSelected=='recommended-videos'}">
          <span [ngClass]="{'selected': labelSelected=='recommended-videos'}">Recommended Videos</span>
        </div>
        <div (click)='goToOption("my-clips")' class="option small" [ngClass]="{'selected': labelSelected=='my-clips'}">
          <span [ngClass]="{'selected': labelSelected=='my-clips'}">My Clips</span>
        </div>
      </div>
    </div>
  
    <div class="label" [ngClass]='{"hide": (isMobile && !labelSelected)}'>
      <ng-container *ngIf="labelSelected == 'overview'">
        <app-overview (selectedOption)='goToOption($event)'></app-overview>
      </ng-container>

      <ng-container *ngIf="labelSelected == 'subscriptions' || labelSelected == 'unlock-video-bundles' || labelSelected == 'unlock-video-lives'">
        <app-subscription-manager (selectedOption)='goToOption($event)'></app-subscription-manager>
      </ng-container>

      <ng-container *ngIf="labelSelected == 'videos' || labelSelected == 'favorite-videos' || labelSelected == 'recommended-videos' || labelSelected == 'my-clips'">
        <app-videos-manager (selectedOption)='goToOption($event)'></app-videos-manager>
      </ng-container>
    </div>
  </div>

</div>