import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient, private authService : AuthService) { }

  async cancelSubscription({ agreement_id, payment_method, immediately  }) {
    try {
      let response: any = await this.httpClient.post(`${environment.api_payment_url}/cancel-subscription`, {agreement_id, payment_method, immediately}).toPromise();
      return response;
    } catch (error) {      
      this.authService.verifySession(error);
      return { error:true };
    }
  }

  async renewSubscription({ agreement_id, payment_method }) {
    try {
      let response: any = await this.httpClient.post(`${environment.api_payment_url}/renewal-subscription`, { agreement_id, payment_method }).toPromise();
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      return { error: true };
    }
  }

}
