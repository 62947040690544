// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   api_payment_url: 'https://develop.payment.api.vewbie.com/api',
//   api_vms_url: 'https://develop.vms.api.vewbie.com/api',
//   // api_vms_url: 'https://vms.api.vewbie.com/api',
//   stripe_publishable_key: "pk_test_CsTYJTYYyeYehXgLGyTVDZP4",
//   api_url: 'https://develop.payment.api.vewbie.com/api',
//   token: 'vK9H%PD1$y!84ylJ',
//   vewbie_app_url: 'https://develop.app.vewbie.com',
//   vewbie_new_url: 'https://vewbie.com/channel',
//   debug: false,
//   intervalCheckToken: 2*1000*60,
//   recaptchaSiteKey: "6LefAIAoAAAAANb8jthfclEcNsUW6F4sLR415tmw",
//   pubsub_url: "wss://pubsub-dev.vewbie.com/",
//   domainChannel: "https://usssalive.com/",
//   createChannelUrl: "https://devteam.nerdiva.com.br/vbw/",
//   amplitude : {
//     projectId: '341968',
//     apiKey: '9a0294d3789cb7dea84db56bec97cc22'
//   },
//   ENVIRONMENT_SENTRY: 'develop'
// };

// export const environment = {
//   production: true,
//   stripe_publishable_key: "pk_live_8n8igWdvEpAvsjuHFIfYQQBj",
//   api_payment_url: 'https://beta.payment.api.vewbie.com/api',
//   api_vms_url: 'https://vms.api.vewbie.com/api',
//   vewbie_app_url: 'https://app.vewbie.com/',
//   vewbie_new_url: 'https://vewbie.com/channel',
//   token: 'vK9H%PD1$y!84ylJ',
//   debug: true,
//   intervalCheckToken: 2*1000*60,
//   recaptchaSiteKey: "6LefAIAoAAAAANb8jthfclEcNsUW6F4sLR415tmw",
//   pubsub_url: 'wss://pubsub.vewbie.com/',
//   domainChannel: "https://usssalive.com/",
//   createChannelUrl: "https://subchannel.vewbie.com/create-your-account/",
//    amplitude : {
//     projectId: '341967',
//     apiKey: 'bb60d41c43960995bf350e4f7c79f987'
//   },
//   ENVIRONMENT_SENTRY: 'production'
// };


export const environment = {
  production: true,
  stripe_publishable_key: "pk_live_8n8igWdvEpAvsjuHFIfYQQBj",
  api_payment_url: 'https://beta.payment.api.vewbie.com/api',
  api_vms_url: 'https://vms.api.vewbie.com/api',
  vewbie_app_url: 'https://app.vewbie.com/',
  vewbie_new_url: 'https://vewbie.com/channel',
  token: 'vK9H%PD1$y!84ylJ',
  debug: true,
  intervalCheckToken: 2*1000*60,
  recaptchaSiteKey: "6LefAIAoAAAAANb8jthfclEcNsUW6F4sLR415tmw",
  pubsub_url: 'wss://pubsub.vewbie.com/',
  domainChannel: "https://usssalive.com/",
  createChannelUrl: "https://subchannel.vewbie.com/create-your-account/",
   amplitude : {
    projectId: '341967',
    apiKey: 'bb60d41c43960995bf350e4f7c79f987'
  },
  ENVIRONMENT_SENTRY: 'production'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
