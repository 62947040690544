import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import _ from 'lodash';
import { SubscriptionService } from '../../subscription.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { AnalyticsService } from 'src/app/analytics.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  subscriptions: any = [];
  subscriptionsClone: any = [];
  groupedSubscriptions: any = [];
  loading: boolean = false;
  countPaidSubscriptions: number = 0;
  countTotalSubscriptions: number = 0;
  filtersForm: FormGroup;
  page: number = 0;
  limit: number = 15;
  blockScroll: boolean = false;
  loadingMore: boolean;
  filter: string = "";
  showPaymentComponent: boolean;
  routeParamsSubscription: Subscription;


  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private location: Location,
    private analyticsService : AnalyticsService
    ) { }



  async ngOnInit() {
    this.filtersForm = new FormGroup({
      type : new FormControl(''),
    });
    
    this.loading = true;
    await this.getSubscriptions();
    this.loading = false;

    this.routeParamsSubscription = this.route.queryParams.subscribe(params => {
      if(params.success == "true") {
        this.openSnackBar(
          'The subscription was successfully purchased! In a moment it should be updated.',
          "snack-bar-success"
          );
        }
        let url = this.router.createUrlTree(['/subscriptions']).toString();
        this.location.go(url);
    });
  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 8000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }

  ngOnDestroy() {
    if(this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe()
    }
  }

  goToUserSubscriptions(){
    this.router.navigate([`/user/subscriptions`]);
  }

  async getSubscriptions(onScroll: boolean = false) {
    let subsCount = await this.subscriptionService.getTotalCountSubscriptionsPaid(this.filter);
    this.countTotalSubscriptions = subsCount.total;
    this.countPaidSubscriptions = subsCount.total_subs;

    this.subscriptions = await this.subscriptionService.listAllSubscriptions(this.page, this.limit ,this.filter);

    this.groupedSubscriptions =  _.groupBy(this.subscriptions, 'type');
  }

  billingFrequencyTreatment(plan){
    let frequency = plan.billing_frequency  || plan.offer_period;
    
    if(!frequency){
      return
    }

    if(plan.offer_period) {
      return plan.offer_period;
    }

    switch (frequency.toUpperCase()) {
      case 'YEAR':
        return 'annual';
      case 'MONTH':
        if(plan.billing_interval > 1) {
          return `${plan.billing_interval}-${plan.billing_frequency.toLowerCase()}`
        }
        return 'month';
      case 'DAY':
        return 'day';
      case 'WEEK':
        return 'week';
    }

    if(plan.billing_interval > 1) {
      return `${plan.billing_interval} ${plan.billing_frequency.toLowerCase()}`
    }


  }

  async onFilter(filterValue) {
    this.filter = filterValue;

    this.loading = true;
    await this.getSubscriptions();
    this.loading = false;
  }

  async selectPlan(plan) {
    this.showPaymentComponent = true;
    let tokenData = {
      channelId: plan.channel_id,
      method: plan.type,
      subscriptionSlug: plan.subscription_slug,
      ppvType: "PPV",
      payment_type: plan.type,
      hideSelectAnotherSubscription: true,
      from: '/subscriptions',
      shouldGoBack: true
    }
      
    let session = await this.authService.getSession();

    if(session) {
      let token = await this.subscriptionService.getPaymentToken(tokenData);  
      this.analyticsService.trackingOpenSubcription(tokenData);
      this.router.navigateByUrl(`/payment/${token}`);
    } else {
      
      this.router.navigateByUrl(`/login`);
    }
    
  }

  goToSubscription(subscription) {
    setTimeout(() => {
      if(!this.showPaymentComponent) {
        this.router.navigateByUrl(`/subscriptions/${subscription.subscription_slug}`);
      }
    }, 200);
  }

  async onScroll() {
    // if(!this.blockScroll) {
    //   this.loadingMore = true;
    //   this.page += this.limit;
    //   await this.getSubscriptions(true);
    //   this.loadingMore = false;

    // }
  }
}
