import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChannelService } from 'src/app/services/channel.service';


@Component({
  selector: 'app-request-help',
  templateUrl: './request-help.component.html',
  styleUrls: ['./request-help.component.scss']
})
export class RequestHelpComponent implements OnInit {

  form: FormGroup;
  reasons: any = [ 
    'I made a purchase but I cannot access the content',
    'I cannot login',
    'I am having streaming issues',
    'Other'
  ];
  loading: boolean = false;
  siteKey = environment.recaptchaSiteKey;

  constructor(
    private dialogRef: MatDialogRef<RequestHelpComponent>,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private channelService : ChannelService,
  ) { 

    this.form = new FormGroup({
      fullname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), Validators.required]),
      reason: new FormControl('', [Validators.required]),
      enquiryDetails: new FormControl('', [Validators.required]),
      recaptcha: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close(false);
  }

  async sendRequest(){
    let channel = await this.channelService.getCurrentChannel();
    this.loading = true;
    this.form.disable();
    try{
      await this.authService.sendUserVerification({form : this.form.value,channel});

      this.snackbar.open('Support mail sent successfully!\nWe will evaluate your message and give you feedback as soon as possible!', '', {
        duration: 4000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
        panelClass: 'snack-bar-success'
      });

      this.dialogRef.close(true);
      
    }
    catch(err) {
      if(err.error.errorMessage == "INVALID_CAPTCHA") {
        this.snackbar.open('Invalid Captcha', '', {
          duration: 4000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
          panelClass: 'snack-bar-danger'
        });
      } else {
        this.snackbar.open('Something went wrong. Try again later!', '', {
          duration: 4000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
          panelClass: 'snack-bar-danger'
        });
      }
    }
    finally {
      this.form.enable();
      this.loading = false;
    }
  }

}
