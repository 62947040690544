<div class="recaptcha">
  <form [formGroup]="aFormGroup" autocomplete="off">
    <ngx-recaptcha2
      matInput
      #captchaElem
      [siteKey]="siteKey"
      (reset)="handleReset()"
      (expire)="handleExpire()"
      (load)="handleLoad()"
      (success)="handleSuccess($event)"
      [useGlobalDomain]="false"
      [size]="size"
      [hl]="lang"
      [theme]="theme"
      [type]="type"
      formControlName="recaptcha"
    >
    </ngx-recaptcha2>
    <mat-error *ngIf="aFormGroup.get('recaptcha').hasError('required') && submitted">
      Please check the recaptcha
    </mat-error>
  </form>
</div>
