<div class="container-login">

  <ng-container *ngIf="!submitted">
    <div class="content" [ngClass]='{"content-modal": data.isModal}'>

      <mat-icon class='close-button' (click)='close()'>close</mat-icon>

      <h1>{{'forgot-password.title' | translate}}</h1>
      <h3>{{'forgot-password.sub-title' | translate}} <a (click)='goToLogin()'>{{'forgot-password.signin' | translate}}</a></h3>

      <form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()" autocomplete="off">

        <mat-form-field appearance="outline" autocomplete="off">
          <mat-label>{{'forgot-password.email.text' | translate}}</mat-label>
          <input matInput type="email" formControlName="email">
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
            {{'forgot-password.email.email-is' | translate}} <strong>{{'forgot-password.email.email-required' | translate}}</strong>
          </mat-error>
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
            {{'forgot-password.email.must-be' | translate}} <strong>{{'forgot-password.email.email' | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <button mat-flat-button class="submit-button" type="submit"  *ngIf='!loading'>{{'forgot-password.send' | translate}}</button>
        <button mat-flat-button color='primary' class='loading submit-button' *ngIf='loading'>
          <mat-spinner diameter='30'></mat-spinner>
        </button>
      </form>


    </div>
  </ng-container>
  <ng-container *ngIf='submitted'>
    <div id="check-email" class="content check" [ngClass]='{"content-modal": data.isModal}'>
      <div class='check-email'>
        <h1>{{'forgot-password.check-email' | translate}}</h1>
        <div class='info'>
          <p class='text align-center'>{{'forgot-password.info.sent' | translate}} <span class='bold'>{{email}}</span> {{'forgot-password.info.link' | translate}}</p>
          <p class='text bold'>{{'forgot-password.info.didnt-get' | translate}}</p>
          <p class='text'>{{'forgot-password.info.dont-see' | translate}}</p>
        </div>

        <ul>
          <li class='text'><p>{{'forgot-password.list.one' | translate}}<br><span>({{'forgot-password.list.one-span' | translate}})</span></p></li>
          <li class='text'><p>{{'forgot-password.list.two' | translate}}<br><span>({{'forgot-password.list.two-span' | translate}})</span></p></li>
          <li class='text'><p>{{'forgot-password.list.three' | translate}}<br><span>({{'forgot-password.list.three-span' | translate}})</span></p></li>
          <li class='text'><p>{{'forgot-password.list.four' | translate}}<br><span>({{'forgot-password.list.four-span' | translate}})</span></p></li>
        </ul>

        <button mat-flat-button class="submit-button" (click)="goToLogin()">{{'forgot-password.login' | translate}}</button>

      </div>
    </div>
  </ng-container>
</div>