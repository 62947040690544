import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  private mediaSearchSubject: Subject<string> = new Subject();
  private mediaSearchSubscription: Subscription;

  startDateRange: Date = null;
  endDateRange: Date = null;
  subCategories: any = [];
  searchValue: string = "";
  orderBy: string = "";
  subCategoriesSelected: any = [];
  categoriesSelected: any = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FiltersComponent>,
  ) { }

  ngOnInit(): void {
    this.startDateRange = this.data.filters.startDateRange;
    this.endDateRange = this.data.filters.endDateRange;
    this.orderBy = this.data.filters.orderBy;
    this.subCategoriesSelected = this.data.filters.subCategories;
    this.searchValue = this.data.filters.searchValue;

    if(this.data.categories?.length) {
      this.data.subCategories = this.data.categories;
    }

    this.mediaSearchSubscription = this.mediaSearchSubject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {
      this.onType(searchTextValue);
    });
  }

  onSearch(searchTextValue){
    this.mediaSearchSubject.next(searchTextValue);
  }

   changeDate(event, value) {
    setTimeout(() => {
      if(this.endDateRange && this.startDateRange) {
        this.endDateRange = moment(this.endDateRange).endOf("day").toDate();
        this.startDateRange = moment(this.startDateRange).startOf("day").toDate();
      }
    }, 400);
  }

  onSort(value: string) {
    this.orderBy = value;
  }

  onFilter(value) {
    this.subCategoriesSelected = value;
  }

  onFilterCategories(value) {
    this.categoriesSelected = value;
  }

  close() {
    this.dialogRef.close();
  }

  onType(searchValue) {
    this.searchValue = searchValue;
  }

  search(reset: boolean = false) {
    let endDateRange: Date = null;
    let startDateRange: Date = null;
    let subCategories: any = [];
    let orderBy: string = "recent";
    let searchValue: string = "";
    let selectedCategory: string = "";

    if(!reset) {
      endDateRange = this.endDateRange;
      startDateRange = this.startDateRange;
      subCategories = this.subCategoriesSelected;
      orderBy = this.orderBy;
      searchValue = this.searchValue;
      selectedCategory = this.categoriesSelected;
    }

    this.dialogRef.close({
      endDateRange,
      startDateRange,
      subCategories,
      orderBy,
      searchValue,
      reset,
      selectedCategory
    });
  }
}
