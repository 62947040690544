import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(
    private httpClient: HttpClient,
    private snackbar: MatSnackBar,
    private router: Router,
    private authService: AuthService
  ) { }

  async startSession({media_id, type, ref}){
    const headers =  await this.authService.getHeadersSession();

    try {
      let response: any = await this.httpClient.post(`${environment.api_vms_url}/start-session`,{media_id, type, ref}, {headers}).toPromise();      
      return response;
    } catch (error) {

      return {error: true, errorMessage: error.error.errorMessage};
    }
  }

  async updateSession({media_id, type, ref, play_time}){
    const headers =  await this.authService.getHeadersSession();

    try {
      let response: any = await this.httpClient.put(`${environment.api_vms_url}/update-session`,{media_id, type, ref, play_time}, {headers}).toPromise();      
      return response;
    } catch (error) {

      return {error: true, errorMessage: error.error.errorMessage};
    }
  }
}
