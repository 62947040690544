import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CategoriesService } from './categories.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {

  private playerSearchSubject: Subject<string> = new Subject();
  private playerSearchSubscription: Subscription;
  
  searchTagInput = new FormControl('');
  
  orderBy: string = 'recent';
  filter: any = {
    columnOrder: 'timestamp',
    order: 'asc'
  };

  loading: boolean = false;
  loadingMore: boolean = false;

  limit: number = 12;
  offset: number = 0;
  blockScroll: boolean = false;

  totalCategories: number = 0;
  categoriesGroup: any = [];

  allCategories: any = [];
  allTags: any = [];
  popularTags: any = [];

  live: any = {
    category_name: "Live",
  };

  isLive: boolean = false;

  constructor(
    private categoriesService: CategoriesService,
    private router: Router
  ) { }

  async ngOnInit() {

    this.playerSearchSubscription = this.playerSearchSubject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {
      this.search(searchTextValue);
    });

    this.loading = true;
    
    let promises = [this.getTotalCategories(), this.getPopularTags()];
    await Promise.all(promises);

    this.allCategories.forEach(element => {
      this.totalCategories++;
      if (element.subcategories > 0) {
        this.totalCategories += +element.subcategories;
      }
    });

    this.loading = false;
  }

  ngOnDestroy() {
    if (this.playerSearchSubscription) {
      this.playerSearchSubscription.unsubscribe();
    }
  }

  async getTotalCategories(onScroll: boolean = false) {
    this.offset += this.limit;

    if(!onScroll) {
      this.offset = 0;
      this.blockScroll = false;
    }

    let newCategories = await this.categoriesService.getCategories(this.filter, this.limit, this.offset);

    if(!newCategories || newCategories.categories.length < this.limit) {
      this.blockScroll = true;
    }

    this.allCategories = this.allCategories.concat(newCategories.categories);
    this.allTags = newCategories.tags;

    this.isLive = this.allCategories.some(elem => elem.online);
  }

  async getPopularTags() {
    this.popularTags = await this.categoriesService.getPopularTags();
  }

  async onScroll() {
    if(!this.blockScroll && !this.loadingMore) {
      this.loadingMore = true;
      await this.getTotalCategories(true);
      this.loadingMore = false;
    }
  }

  goToCategory(category) {
    if(category.subchannel_id && category.subchannel_url) {
      window.open(category.subchannel_url, "_blank");
      return;
    }

    this.router.navigate([`/categories/${category.category_slug}`])
  }
  
  onSearch(searchTextValue){
    this.playerSearchSubject.next(searchTextValue);
  }

  async onFilter(value: string) {
    this.orderBy = value;

    if (value == 'recent' || value == 'older') {
      this.filter.columnOrder = 'timestamp';
    }
    else {
      this.filter.columnOrder = 'category_name';
    }

    if (value == 'ascendingCharacters' || value == 'recent') {
      this.filter.order = 'ASC';
    } 
    else {
      this.filter.order = 'DESC';
    }
    this.allCategories = [];
    this.loading = true;
    await this.getTotalCategories();
    this.loading = false;
  }

  async search(searchTextValue){
    
    this.filter.search = searchTextValue;
    
    this.loading = true;

    this.allCategories = [];

    await this.getTotalCategories();
    
    this.loading = false;
  }

}