import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha/lib/components/recaptcha-2.component';
import { environment } from 'src/environments/environment';

declare var hljs: any;

@Component({
  selector: 'app-recaptcha-v2',
  templateUrl: './recaptcha-v2.component.html',
  styleUrls: ['./recaptcha-v2.component.scss'],
})
export class RecaptchaV2Component implements OnInit {
  siteKey: string = environment.recaptchaSiteKey;

  captchaIsExpired = false;
  captchaResponse?: string;
  
  theme: 'light' | 'dark' = 'light';
  size: 'compact' | 'normal' = 'normal';
  lang = 'en';
  type: 'image' | 'audio' = 'image';
  useGlobalDomain: boolean = false;
  
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;
  
  aFormGroup: FormGroup;
  
  @Input() submitted: boolean = false;
  
  @Output()
  captchaIsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  captchaSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.aFormGroup = new FormGroup({
      recaptcha: new FormControl('', Validators.required),
    });
  }

  ngAfterViewInit(): void {
    this.highlight();
  }

  handleReset(): void {
    this.captchaSuccess.emit(false);
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess.emit(true);
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded.emit(true);
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess.emit(false);
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  setLanguage(): void {
    this.lang = this.langInput.nativeElement.value;
  }

  setUseGlobalDomain(use: boolean): void {
    this.useGlobalDomain = use;
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert('There is no response - have you submitted captcha?');
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }
}
