import { Component, OnInit, ViewChild } from '@angular/core';
import { SubscriptionManagerComponent } from './subscription-manager/subscription-manager.component';
import { VideosManagerComponent } from './videos-manager/videos-manager.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { ObservableService } from 'src/app/services/observable.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @ViewChild(SubscriptionManagerComponent) subscriptionManager: SubscriptionManagerComponent;
  @ViewChild(VideosManagerComponent) videosManager: VideosManagerComponent;

  selected: boolean = true;
  labelSelected: any = 'overview';

  session: any;
  observableServiceSubscription: Subscription;
  routeSubscription: Subscription;
  isMobile: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private observableService: ObservableService,
  ) { 
    this.observableServiceSubscription = this.observableService.getCurrentSessionObservable().subscribe((data)=>{
      this.session = data;
    });

    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.labelSelected = this.route.firstChild.snapshot.params['option'];
        this.goToOption(this.labelSelected);
      }
    });
  }

  ngOnDestroy(){
    if(this.observableServiceSubscription) {
      this.observableServiceSubscription.unsubscribe();
    }

    if(this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  async ngOnInit() {
    
    this.session = await this.authService.getSession();
    this.labelSelected = this.route.firstChild.snapshot.params['option'];
    
    if(window.innerWidth <= 768) {
      this.isMobile = true;
    }
    this.goToOption(this.labelSelected);
  }

  goToOption(selectedOption?){
    this.labelSelected = selectedOption;


    let url: any;
    setTimeout(() => {

      switch(this.labelSelected) {
        case 'overview':
          url = this.router.createUrlTree(['overview'], {relativeTo: this.route});
          break;
        case 'subscriptions':
          this.subscriptionManager.selectedIndex = 0;
          url = this.router.createUrlTree(['subscriptions'], {relativeTo: this.route});
          break;
        case 'unlock-video-bundles':
          this.subscriptionManager.selectedIndex = 1;
          url = this.router.createUrlTree(['unlock-video-bundles'], {relativeTo: this.route});
          break;
        case 'unlock-video-lives':
          this.subscriptionManager.selectedIndex = 2;
          url = this.router.createUrlTree(['unlock-video-lives'], {relativeTo: this.route});
          break;
        case 'videos':
          this.videosManager.selectedIndex = 0;
          url = this.router.createUrlTree(['videos'], {relativeTo: this.route});
          break;
        case 'favorite-videos':
          this.videosManager.selectedIndex = 1;
          url = this.router.createUrlTree(['favorite-videos'], {relativeTo: this.route});
          break;
        case 'recommended-videos':
          this.videosManager.selectedIndex = 2;
          url = this.router.createUrlTree(['recommended-videos'], {relativeTo: this.route});
          break;
        case 'my-clips':
          this.videosManager.selectedIndex = 3;
          url = this.router.createUrlTree(['my-clips'], {relativeTo: this.route});
          break;
        case '':
          this.labelSelected = "";
          url = this.router.createUrlTree(['/user'], {relativeTo: this.route});
          break;
      }

      if(url) {
        this.location.go(url);
      }

    }, 100);

  }
}
