import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { SubscriptionService } from 'src/app/subscription.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CancelRenewSubscriptionComponent } from './cancel-renew-subscription/cancel-renew-subscription.component';
import { AnalyticsService } from 'src/app/analytics.service';

@Component({
  selector: 'app-subscription-content',
  templateUrl: './subscription-content.component.html',
  styleUrls: ['./subscription-content.component.scss']
})
export class SubscriptionContentComponent implements OnInit {
  subscriptionSlug: string = "";
  subscriptionData: any;
  loading: boolean = false;
  limit: number = 15;
  
  allPage: number = 0;
  videosPage: number = 0;
  livePage: number = 0;
  
  currentTab: string = "all";
  
  allBlockScroll: boolean = false;
  liveBlockScroll: boolean = false;
  videosBlockScroll: boolean = false;

  orderBy: string = 'recent';

  allMedias: any = [];
  liveMedias: any = [];
  videoMedias: any = [];

  thumbnailSubscription: string;

  allLoading: boolean = false;
  livemachtesLoading: boolean = false;
  videosLoading: boolean = false;
  isMobile: boolean = false;
  onlyVideo : boolean = false;

  notFound: boolean = false;

  routeParamsSubscription: Subscription;

  constructor(
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private location: Location,
    private dialog: MatDialog,
    private analyticsService : AnalyticsService

  ) { 
  }

  async ngOnInit() {
    if(window.innerWidth <= 768) {
      this.isMobile = true;
    }

    this.routeParamsSubscription = this.route.queryParams.subscribe(params => {
      if(params.success == "true") {
        this.openSnackBar(
          'The subscription was successfully purchased! In a moment it should be updated.',
          "snack-bar-success"
        );
      }

    });

    this.subscriptionSlug = this.router.url.split('/')[2].toString();
    this.subscriptionSlug = this.subscriptionSlug.split('?')[0];

    let url = this.router.createUrlTree([`/subscriptions/${this.subscriptionSlug}`]).toString();
    this.location.go(url);

    this.loading = true;

    await this.getSubscriptionData()

    if(this.subscriptionData.errorCode) {
      this.notFound = true;
      return;
    }
    
    if(this.subscriptionData.type_subscription == "subscription") {
      this.thumbnailSubscription = "../../assets/imgs/sub-thumb.jpg";
    } else {
      this.thumbnailSubscription = "../../assets/imgs/season-thumb.jpg";
    }

    await this.getSubscriptionMedia(false);
    this.loading = false;

    setTimeout(() => {
      window.onscroll = function() {myFunction()};
  
      var header = document.getElementById("header");
      var sticky = header.offsetTop + 100;
  
      function myFunction() {
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else if(window.pageYOffset < 20){
          header.classList.remove("sticky");
        }
      }
    }, 200);
  }

  ngOnDestroy() {
    if(this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe()
    }
  }

  async getSubscriptionData() {
    this.subscriptionData = await this.subscriptionService.getSubscriptionData(this.subscriptionSlug);    
  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 8000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }

  async getSubscriptionMedia(onScroll: boolean, changeTab: boolean = false) {
    if(!onScroll) {
      this.allPage = 0;
      this.livePage = 0;
      this.videosPage = 0;

      this.allBlockScroll = false;
      this.liveBlockScroll = false;
      this.videosBlockScroll = false;

      this.allMedias = [];
      this.liveMedias = [];
      this.videoMedias = [];
    } else if(!changeTab) {
      this[`${this.currentTab}Page`] += this.limit;
    }

    let filters = {
      slug: this.subscriptionSlug, 
      displayType: this.currentTab, 
      page: this[`${this.currentTab}Page`], 
      limit: this.limit,
      subscription_id: this.subscriptionData.id,
      filter: this.orderBy
    }

    this[`${this.currentTab}Loading`] = true;
    let medias = await this.subscriptionService.getMediasFromSubscription(filters);
    this[`${this.currentTab}Loading`] = false;

    if(!medias){
      medias = [];
    }

    if(!medias || medias.length == 1 || medias.length < this.limit) {
      this[`${this.currentTab}BlockScroll`] = true;
    } 
    
    const existLiveOnline = medias.find(media => media.online);

    if(this.currentTab == 'all' && !existLiveOnline ) {
      this.onlyVideo = !existLiveOnline;
      this.currentTab = 'videos';
    }


    switch (this.currentTab) {
      case "all":
        this.allMedias = this.allMedias.concat(medias); 
        break;
      case "live":
        this.liveMedias = this.liveMedias.concat(medias); 
        break;
      case "videos":
        this.videoMedias = this.videoMedias.concat(medias); 
        break;
    }

  }

  goToPath(path) {
    this.router.navigateByUrl(path);
  }

  billingFrequencyTreatment(plan){
    let frequency = plan.billing_frequency  || plan.offer_period;
    
    if(!frequency){
      return
    }

    switch (frequency.toUpperCase()) {
      case 'YEAR':
        return 'Annual';
      case 'MONTH':
        return 'Month';
      case 'DAY':
        return 'Day';
      case 'WEEK':
        return 'Week';
    }

    if(plan.billing_interval > 1) {
      return `${plan.billing_interval} ${plan.billing_frequency.toLowerCase()}`
    }

  }

  setCurrentTab(tab) {
    let blockScroll: boolean;
    switch (tab.index) {
      case 0:
        this.currentTab = 'all';
        blockScroll = this.allBlockScroll;
        break;
      case 1:
        if(!this.onlyVideo) {
          this.currentTab = 'live';
          blockScroll = this.liveBlockScroll;
        } else {
          this.currentTab = 'videos';
          blockScroll = this.videosBlockScroll;
        }
        break;
      case 2:
        this.currentTab = 'videos';
        blockScroll = this.videosBlockScroll;
        break;
    }

    if(!blockScroll) {
      this.getSubscriptionMedia(false, true);
    }
  }

  async onScroll() {
    let blockScroll:boolean = this[`${this.currentTab}BlockScroll`];
 
    if(!blockScroll && !(this.allLoading || this.livemachtesLoading || this.videosLoading) ) {
      await this.getSubscriptionMedia(true);
    }
  }

  onFilter(value: string) {
    this.orderBy = value;
    this.getSubscriptionMedia(false);
  }

  async selectPlan() {
    let plan = this.subscriptionData;
    let tokenData = {
      channelId: plan.channel_id,
      method: plan.type_subscription,
      subscriptionSlug: plan.subscription_slug,
      ppvType: plan.type,
      payment_type: plan.type_subscription,
      hideSelectAnotherSubscription: true,
      from: `/subscriptions/${this.subscriptionSlug}`,
      shouldGoBack: true
    }
      
    let session = await this.authService.getSession();

    if(session) {
      this.analyticsService.trackingOpenSubcription(tokenData);
      let token = await this.subscriptionService.getPaymentToken(tokenData);  
      this.router.navigateByUrl(`/payment/${token}`);
    } else {
      this.router.navigateByUrl(`/login`);
    }
    
  }

  async openModalPlan(fluxTitle) {
    const dialogRef = this.dialog.open(CancelRenewSubscriptionComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {
        fluxTitle: fluxTitle,
        subscriptionData: this.subscriptionData,
        thumbnailSubscription: this.thumbnailSubscription
      },
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();
  
    if(result) {
      await this.getSubscriptionData();
      await this.getSubscriptionMedia(false);
    }
  }
 }
