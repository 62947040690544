import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-recommended-videos',
  templateUrl: './recommended-videos.component.html',
  styleUrls: ['./recommended-videos.component.scss']
})
export class RecommendedVideosComponent implements OnInit {

	@Input() recommendedVideos: any;

	categories: any = [];

	videosByCategories: any = [];

  constructor() { }

  ngOnInit(): void {

		this.recommendedVideos.map(rv => {
			rv.categories.map(ct => {
				if(!this.categories.find(el => el == ct.category_name)){
					this.categories.push(ct.category_name);
				}
			});
		});

		this.categories.forEach((element) => {
			let videos = this.recommendedVideos.filter(rv => rv.categories.find(ct => ct.category_name == element));
			this.videosByCategories.push(videos);
			//https://love2dev.com/blog/javascript-remove-from-array/
			videos.forEach(element => {
				this.recommendedVideos = this.arrayRemove(this.recommendedVideos, element);
			});
		});
	}

	arrayRemove(arr, value) { 
		return arr.filter(function(newArr){ 
				return newArr != value; 
		});
	}
}
