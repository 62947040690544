<div class="container">

  <div class="subscription-box" [ngClass]='{"finish-view" : subscriptionFinished}'>
    <ng-container *ngIf="offerSelected">
      <div class="dialog-header" [ngClass]='{"mobile" : offerSelected, "remove" : subscriptionFinished}'>  
        <span class="arrow-back" *ngIf="!hasError && !subscriptionFinished" (click)="returnListingPlans()">
          <mat-icon>arrow_back</mat-icon>
          {{'payment.go-back' | translate}}  
        </span>
      </div>
      <div class="content">
        <div class="select-subscription">
          <div class="title">{{'payment.selected' | translate}} {{getTitle()}}</div>
  
          <div class="select-subscription-box" *ngIf="!isCoupon" [ngClass]='{"error": hasError || subscriptionFinished}'>
  
            <div class="subscription-type">
              <img *ngIf="planSelected.is_recurring == 1 && planSelected.type == 'PPV'" src="./../../assets/images/sub-thumb.jpg">
              <img *ngIf="planSelected.is_recurring == 0 && planSelected.type == 'PPV'" src="./../../assets/images/season-thumb.jpg">
              <div>
                <h3>{{planSelected.name || planSelected.title}} <span *ngIf='planSelected.is_recurring == "1"'>{{'payment.recurring' | translate}}</span> <span *ngIf='planSelected.is_recurring != "1"'>{{'payment.not-recurring' | translate}}</span></h3>                          
                <small *ngIf="!invalidCoupon && coupon && discount_price" >${{planSelected.price}} USD</small>
                <h2>${{discount_price ? discount_price : planSelected.price}} USD <span>/ {{planSelected.type}}</span></h2>        
                <p class='trial'><span  *ngIf="planSelected.trial_days && planSelected.trial_days > 0">{{'payment.try-free-for' | translate}} {{planSelected.trial_days}} <span *ngIf='planSelected.trial_days > 1'>{{'payment.days' | translate}}</span> <span *ngIf='planSelected.trial_days == 0 || planSelected.trial_days == 1'>{{'payment.day' | translate}}</span></span></p>        
              </div>
            </div>
            
            <div class="message" 
            *ngIf='alternativePurchaseMode'>
              {{'payment.one-time-payment' | translate}}
            </div>
            <div class="message" 
            *ngIf='planSelected.is_recurring == "1" && !alternativePurchaseMode'
            [disabled]="subscriptionFinished && alternativePurchaseMode">
              {{billingFrequencyTreatment(planSelected) | titlecase}} 
              {{'payment.plan-recurring' | translate}}
            </div>
            <div class="notice" 
            *ngIf='planSelected.is_recurring == "1" && !alternativePurchaseMode'
            [disabled]="subscriptionFinished && alternativePurchaseMode">
              <b>{{'payment.notice' | translate}}</b> 
              {{'payment.recurring-subscription' | translate}}
            </div>                 

            <button 
              *ngIf="!coupon && !subscriptionFinished" 
              mat-stroked-button 
              (click)="reedemCoupon()" 
              [disabled]="hasError || subscriptionFinished">
                {{'payment.redeem-coupon' | translate}}
            </button>
            <button 
              *ngIf="!invalidCoupon && coupon && !subscriptionFinished  && !couponDisabled" mat-stroked-button (click)="removeCoupon()" 
              [disabled]="hasError || subscriptionFinished" 
              class="remove-coupon">
                {{'payment.remove-coupon' | translate}}
            </button>
            <button 
              *ngIf="!invalidCoupon && coupon && subscriptionFinished  && !couponDisabled" mat-stroked-button 
              [disabled]="hasError || subscriptionFinished" 
              class="valid-coupon">
                <div>{{'payment.coupon' | translate}}</div>
                <div>{{coupon}}</div>
            </button>
          </div>
  
          <div class="select-subscription-box" *ngIf="isCoupon">
  
            <div class="coupon"><mat-icon (click)="isCoupon = !isCoupon; invalidCoupon = !invalidCoupon;coupon = null;">arrow_back</mat-icon> {{'payment.coupon-uppercase' | translate}}</div>
            
            <div  class="coupon-form">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{'payment.your-coupon' | translate}}</mat-label>
                <input matInput [(ngModel)]="coupon" type="text">
              </mat-form-field>
            </div>
            <button mat-flat-button  (click)='activateCoupon()' >{{'payment.activate' | translate}}</button>
            <div class="invalid-coupon" *ngIf="invalidCoupon">{{'payment.invalid-coupon' | translate}}</div>            
          </div>          
        </div>
        <div class="purchase-box">
          <mat-horizontal-stepper linear #stepper>
            <mat-step label="Purchase" [editable]="!subscriptionFinished" [completed]="subscriptionFinished">
              
              <div *ngIf="alternativePurchaseMode">
                <div class="title">
                  {{'payment.full-access' | translate}}
                </div>
                <button
                  mat-flat-button class="mat-flat-button-full-access" 
                  (click)='buySubscription()' >
                    {{'payment.get-access' | translate}}
                </button>
              </div>
              
              <form [formGroup]="purchaseForm" *ngIf="!hasError && !alternativePurchaseMode">
                <div class="purchase-subscription-box">
                  <div class="title">{{'payment.purchase-using' | translate}}</div>
    
                  <!-- <div class="payment-method" [ngClass]='{"selected": selectedPayment == "paypal"}' (click)='changePayment("paypal")'>
                    <img src="../../assets/icons/paypal.png" alt="paypal-icon">
                    <div>{{'payment.redirect' | translate}} <b> {{'payment.paypal' | translate}} </b> {{'payment.complete-payment' | translate}}</div>
                  </div> -->
      
                  <div class="payment-method" [ngClass]='{"selected": selectedPayment == "stripe"}' (click)='changePayment("stripe")'>
                    <span class="offer-title">
                      <img src="../../assets/icons/credit-card.png" style="width: 48px; margin: unset;" alt="stripe-icon">
                      <span>{{'payment.credit-card' | translate}}</span>
                    </span>
                    <div>
                      {{'payment.redirect' | translate}} 
                      <b>{{'payment.credit-card-company' | translate}}</b> 
                      {{'payment.complete-payment' | translate}}
                    </div>
                  </div>
    
                  <section>
                    <mat-checkbox formControlName='agreeTerms' color="primary">
                      {{'payment.read-agree' | translate}}
                      <a (click)="openRefund()">
                        {{'payment.refund-policy' | translate}}
                      </a>
                    </mat-checkbox>                    
                    <mat-checkbox 
                    *ngIf='planSelected.is_recurring == "1"' 
                    formControlName='agreePlan' 
                    color="primary">
                      {{'payment.i-understand' | translate}} 
                      <span *ngIf='planSelected.billing_interval > 1'>
                        {{'payment.renew-every' | translate}}
                      </span> 
                      <span *ngIf='planSelected.billing_interval == 0 || planSelected.billing_interval == 1'>
                        {{'payment.be-renewed' | translate}}
                      </span> 
                      <b style="margin-left: 2px;">
                        {{billingFrequencyTreatment(planSelected)}}
                      </b> 
                      {{'payment.until-cancel' | translate}}
                    </mat-checkbox>
                  </section>
                  
                  <button 
                  mat-flat-button [disabled]="purchaseForm.invalid" 
                  (click)='save()' 
                  *ngIf='!loadingSave'>
                    {{'payment.continue' | translate}}
                  </button>
                  <button mat-flat-button *ngIf='loadingSave'>
                    <mat-spinner diameter='30'></mat-spinner>
                  </button>
                </div>
              </form>

              <div class="error-form" *ngIf="hasError">
                <div class="purchase-subscription-box error">
                  <img src="../../assets/icons/Exclamation_flat_icon.svg" alt="">
                  <h1>{{'payment.error' | translate}}</h1>
                  <h3>{{'payment.cancel-subscription' | translate}}</h3>
  
                  <div class="buttons">
                    <button mat-stroked-button class="cancel" (click)="closePopUp('false')">{{'payment.cancel' | translate}}</button>
                    <button mat-stroked-button class="try-again" (click)="tryAgain()">{{'payment.try-again' | translate}}</button>
                  </div>
                </div>
              </div>
  
            </mat-step>
            <mat-step label="Done" editable="false" [completed]="subscriptionFinished">
              <div class="purchase-subscription-box done">
                <img src="../../assets/icons/Flat_tick_icon.svg" alt="">
                <h1>{{'payment.thank-you' | translate}}</h1>
                <h3>{{'payment.successfully-payment' | translate}}</h3>
                <h4>{{'payment.successfully-note-1' | translate}}<br>{{'payment.successfully-note-2' | translate}}</h4>

                <button mat-flat-button (click)="closePopUp('true')" >{{'payment.back-media' | translate}}</button>
              </div>
            </mat-step>
          </mat-horizontal-stepper>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!offerSelected">
      <div class="dialog-header offer-header">
        <span class="arrow-back" (click)="goBack()" *ngIf='shouldGoBack'>
          <mat-icon>arrow_back</mat-icon>
          {{'payment.go-back' | translate}}         
        </span>
        <h1>{{'payment.please-select' | translate}}</h1>
      </div>
      <div class="content content-offers" [ngClass]='{"space" : shouldGoBack}'>
        <div class="example-loading-shade" *ngIf="loading">
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="!hideSubscriptions">
          <div class="offer" *ngFor="let plan of plans" >
            <div class="offer-description">
              <div>{{plan.name}}</div>
              <span *ngIf="plan.is_recurring == 0 && plan.type == 'VE'">{{plan.total_clips}} {{'payment.clips' | translate}}</span>
              <span *ngIf="plan.is_recurring == 0 && plan.type == 'PPV'">{{'payment.access-for' | translate}} {{plan.offer_period}}</span>
              <span *ngIf="plan.is_recurring == 1">{{billingFrequencyTreatment(plan) | titlecase}} {{'payment.plan-recurring' | translate}}</span>
            </div>
            <button mat-flat-button (click)='selectOffer(plan)' class="offer-button">
              <div class="text">
                <span>{{'payment.click-to-select' | translate}}</span>
                <span *ngIf="plan.is_recurring == 0">{{'payment.one-time-payment' | translate}}</span>
                <span *ngIf="plan.is_recurring == 1">{{'payment.renews-automatically' | translate}}</span>
              </div>
              <div class="price">${{plan.price}} USD</div>
            </button>
          </div>
        </div>
        <div class='offer' *ngIf="media">
          <div class="offer-description">
            <div>{{ getTitleOfDownloadButton() | translate}}</div>
            <span>{{ getDescriptionOfDownloadButton() | translate }}</span>
          </div> 
          <button mat-flat-button (click)='selectMedia(media)' class="offer-button">
            <div class="text">
              <span>{{'payment.click-to-select' | translate}}</span>
              <span>{{'payment.one-time-payment' | translate}}</span>
            </div>
            <div class="price">${{media.price}} USD</div>
          </button>
        </div>
      </div>

    </ng-container>
    <div class="dialog-footer">
      <a href="https://vewbie.com/terms-and-condition" target="_blank">{{'payment.terms-conditions' | translate}}</a> | <a href="https://vewbie.com/terms-of-service" target="_blank">{{'payment.terms-service' | translate}}</a> | <a href="https://vewbie.com/privacy-policy" target="_blank">{{'payment.privacy-policy' | translate}}</a>
    </div>
  </div>
</div>
