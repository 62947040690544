<div class="content">
  <mat-radio-group [(ngModel)]="lang" (change)="changeLang($event.value)" color="primary">
    <mat-radio-button value="en" [ngClass]='{"selected": lang == "en"}'>
      <span>English</span>
      <span>
        <img src="../../assets/icons/flags/england.png" alt="">
        <img src="../../assets/icons/flags/usa.png" alt="">
      </span>
    </mat-radio-button>
    <mat-radio-button value="de" [ngClass]='{"selected": lang == "de"}'>
      <span>Deutsch</span>
      <img src="../../assets/icons/flags/germany.png" alt="">
    </mat-radio-button>
    <mat-radio-button value="it" [ngClass]='{"selected": lang == "it"}'>
      <span>Italian</span>
      <img src="../../assets/icons/flags/italy.png" alt="">
    </mat-radio-button>
    <mat-radio-button value="zh-CN" [ngClass]='{"selected": lang == "zh-CN"}'>
      <!-- simplificado -->
      <span>官话</span>
      <img src="../../assets/icons/flags/china.png" alt="">
    </mat-radio-button>
    <mat-radio-button value="zh-TW" [ngClass]='{"selected": lang == "zh-TW"}'> 
      <!-- tradicional -->
      <span>客家语</span>
      <img src="../../assets/icons/flags/hong-kong.png" alt="">
    </mat-radio-button>
  </mat-radio-group>
</div>
