import { Injectable } from '@angular/core';

declare function killSlot(adSlots: string[]): void;
declare function refreshBid(adSlots: string[]): void;

@Injectable({
  providedIn: 'root'
})
export class SnackAdsService {

  constructor() { }

  setupHomePageAds(){
    console.log("setupHomePageAds 1")
    const snack = this.getSnackInstance();

    if(!snack){
      return;
    }

    killSlot(['snack_ldb','snack_mpu','snack_dmpu','snack_dex1','snack_dex2','snack_dex3','snack_dex4','snack_dex7', 'snack_dex8', 'snack_dex9', 'snack_dex10','snack_dex11','snack_mob_top','snack_mob_mid','snack_mob_bot','snack_mex1','snack_mex2','snack_mex3','snack_mex4', 'snack_mex6','snack_mex7', 'snack_mex8', 'snack_mex9', 'snack_mex10', 'snack_mex11', 'snack_mex12']);
    
    if(window.innerWidth <= 768) {
      refreshBid(['snack_mob_top','snack_mob_mid','snack_mob_bot','snack_mex1','snack_mex2','snack_mex3','snack_mex4']);
    } else {
      refreshBid(['snack_ldb','snack_mpu','snack_dmpu','snack_dex1','snack_dex2','snack_dex3','snack_dex4']);
    }
  }

  setupVideoContentAds(){
    console.log("setupVideoContentAds")
    const snack = this.getSnackInstance();
    if(!snack){
      return;
    }

    killSlot(['snack_ldb','snack_mpu','snack_dmpu','snack_dex1','snack_dex2','snack_dex3','snack_dex4','snack_dex7', 'snack_dex8', 'snack_dex9', 'snack_dex10','snack_dex11','snack_mob_top','snack_mob_mid','snack_mob_bot','snack_mex1','snack_mex2','snack_mex3','snack_mex4', 'snack_mex6','snack_mex7', 'snack_mex8', 'snack_mex9', 'snack_mex10', 'snack_mex11', 'snack_mex12']);
    if(window.innerWidth <= 768) {
      refreshBid(['snack_mob_top','snack_mob_mid','snack_mob_bot','snack_mex1','snack_mex2','snack_mex3','snack_mex4']);
    } else {
      refreshBid(['snack_ldb','snack_mpu','snack_dmpu','snack_dex1','snack_dex2','snack_dex3','snack_dex4']);
    }
  }

  setupCategoriesAds(){
    console.log("setupCategoriesAds getSnackInstance")
    const snack = this.getSnackInstance();
    if(!snack){
      return;
    }

    killSlot(['snack_ldb','snack_mpu','snack_dmpu','snack_dex1','snack_dex2','snack_dex3','snack_dex4','snack_dex7', 'snack_dex8', 'snack_dex9', 'snack_dex10','snack_dex11','snack_mob_top','snack_mob_mid','snack_mob_bot','snack_mex1','snack_mex2','snack_mex3','snack_mex4', 'snack_mex6','snack_mex7', 'snack_mex8', 'snack_mex9', 'snack_mex10', 'snack_mex11', 'snack_mex12']);
    if(window.innerWidth <= 768) {
      refreshBid(['snack_mob_top','snack_mob_mid','snack_mob_bot','snack_mex1','snack_mex2','snack_mex3','snack_mex4','snack_mex6']);
    } else {
      refreshBid(['snack_ldb','snack_mpu','snack_dmpu','snack_dex1','snack_dex2','snack_dex3','snack_dex4', 'snack_dex7']);
    }
  }

  removeAllAds(){
  
  }

  getSnackInstance(){
    const snack = (<any>window).snackSettings;
    if(!snack){
      console.log("Snack not loaded")
      return false;
    }
    return snack;
  }
}
