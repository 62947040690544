import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as localForage from 'localforage';
import jwt_decode from 'jwt-decode'
import { Router } from '@angular/router';
import psl from 'psl';
import { AnalyticsService } from '../analytics.service';
import { ObservableService } from '../services/observable.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  routineCheckToken = null;
  private userTimezone = null;

  constructor(
    private httpClient: HttpClient,
    private snackbar: MatSnackBar,
    private router: Router,
    private analyticsService : AnalyticsService,
    private observableService : ObservableService,
  ) { }

  static getHeaders(){    
    return {
      'Authorization': `Bearer ${environment.token}`
    }
  }

  getTimezone(){
    return this.userTimezone;
  }

  async loadTimezone() {
    let session = await this.getSession();
    this.userTimezone = session?.time_zone;
  }

  async getHeadersSession(requiredToken = true){

    const session: any = await this.getSession();

    const token = session ? session.token : "";
    const lang = localStorage.getItem('lang')  || 'en';
    
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      'Accept-Language': lang,
      'Authorization' : token ? "Bearer " + token : "",
      // "Domain-Channel": window.location.href.includes('localhost') ? `${environment.domainChannel}` : ''
      "Domain-Channel": `${environment.domainChannel}`
    });
    
   
    return headers;
  }

  async getSession(){
    let session: any = await localForage.getItem('session');
    if(session && session.token) {
      let tokenByCookie = this.getCookie('token');
      if(tokenByCookie) {
        try {
          jwt_decode(tokenByCookie);
          if(tokenByCookie != session.token) {
            await this.clearSession();
            return false;
          }
        } catch(error) { 
        }
      }

      this.setCookie('token', session.token, null);
    };

    if(!session) {
      let tokenByCookie = this.getCookie('token');
      if(tokenByCookie) {
        try {
          session = jwt_decode(tokenByCookie);
          session.token = tokenByCookie;
          
          await this.setSession(session);
        } catch(error) {
          session = null;
        }
      }
    }

    return session;
  }

  async verifySession(error){
    if(error && error.status === 401){
     
      clearInterval(this.routineCheckToken);
      this.openSnackBar(
        "Logged in on another device",
        "snack-bar-danger"
      );
      await setTimeout(() => {
        this.logout();
      }, 3000);
    }
    else if(error && error.status === 402) {
      this.openSnackBar(
        "Something went wrong, try again later!",
        "snack-bar-danger"
      );
      
      this.router.navigate(['']);
    }
  }

  async logout() {
    await this.clearSession();
    await this.router.navigate([``]);
    window.location.reload();
  }

  async login({email, password}){
    try {
      clearInterval(this.routineCheckToken);
      const headers: any = await this.getHeadersSession(false);
      let response: any = await this.httpClient.post(`${environment.api_vms_url}/authentication`, {email, password}, {headers}).toPromise();
      
      if (response.error) {
        return response;
      }

      await this.setToken(response.authentication);

      return response;
    } catch (error) {

      return {error: true, errorMessage: error.error.errorMessage};
    }
  }

  async loginFacebook(token){
    try {
      
      let decoded: any = jwt_decode(token);
      decoded.token = token;
      await this.setSession(decoded);
    } catch (error) {
      console.log(error);
      
      return {error: true, errorMessage: error};
    }
  }

  async setToken(token) {
    let decoded: any = jwt_decode(token);
    decoded.token = token;    
    await this.setSession(decoded);
  }

  async checkToken(){
    try {
      
      const headers: any = await this.getHeadersSession(true);
      
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/check-token`, {headers}).toPromise();
      
      
      return response.message;

    } catch (error) {
      this.verifySession(error);
    }
  }

  async createRoutine(){
   
    if(! await this.getSession()){
      return
    }
    if(this.routineCheckToken) {
      return;
    }

    this.routineCheckToken = setInterval( async () => {
      
      await this.checkToken();
       
    }, environment.intervalCheckToken);
  }

  async getLanguage(){
    return localStorage.getItem('lang') || 'en';
  }

  async setSession(value){
    this.setCookie('token', value.token, null);
    await localForage.removeItem('session');
    this.observableService.setCurrentSessionObservable(value);

    this.userTimezone = value.time_zone;
    
    return await localForage.setItem('session', value);
  }

  async setRedirectUserPurchase(value){
    return await localForage.setItem('redirectUserPurchase', value);
  }

  async getRedirectUserPurchase(){
    return await localForage.getItem('redirectUserPurchase');
  }

  async clearSession(){
    this.eraseCookie('token');
    this.observableService.setCurrentSessionObservable(null);
    await localForage.clear();
  }

  async forgotPassword(email) {
    try {
      const headers: any = await this.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/forgot-password?email=${encodeURIComponent(email)}`,
      {headers}).toPromise();
      return response;
    } catch (error) {

      return {error: true, errorMessage: 'Email invalid'};
    }
  }

  async updatePassword(password, token) {
    let response: any = await this.httpClient.put(`${environment.api_vms_url}/reset-password?token=${token}`, {password}).toPromise();
    return response;
  }
  
  async ping(){
    try {
      const headers: any = await this.getHeadersSession();
      
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/ping`, {headers}).toPromise();
      
   
      return response.message;

    } catch (error) {
      this.verifySession(error);
    }
  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }

  setCookie(name, value, days) {
    let domain = psl.get(window.location.host) || '';
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/; SameSite=None; Secure; Domain=" + domain + ";";
  }

  getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
      let c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  eraseCookie(name) {
    let domain = psl.get(window.location.host) || '';
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Secure; Domain=' + domain + ';';
  }

  async sendUserVerification(payload) {
    let response: any = await this.httpClient.post(`${environment.api_vms_url}/send-help-email`, payload).toPromise();
    return response;
  }
}
