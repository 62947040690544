import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import * as amplitude from 'amplitude-js';
import psl from 'psl';

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {

  private WssTimestamp = new BehaviorSubject<number>(0);
  wssTimestamp$ = this.WssTimestamp.asObservable();
  setWssTimestamp(timestamp: any) {
    this.WssTimestamp.next(timestamp);
  }

  
  constructor() {
    amplitude.getInstance().init(environment.amplitude.apiKey);
  }

  setUserProperties(user) {
    if(!environment.production){
      return;
    }
    amplitude.getInstance().setUserId(user.id);
    amplitude.getInstance().setUserProperties({
      userName : user.name,
      companyName : user.companyName,
      userEmail : user.email
    })

  }

  trackingEvent(event, property: any = {}) {
    try {
      property.domainUrl = window.location.host;
      console.log('>>> property.domainUrl', property.domainUrl);
      amplitude.getInstance().logEvent.apply(amplitude.getInstance(), arguments);
    } catch (error) {
      console.log('>>> error', error); 
    }
  }

  trackingIncrement(property: String, value){
    try {
      let identify = new amplitude.Identify().add(property, value);
    amplitude.identify(identify);
    } catch (error) {
     console.log('>>> error', error); 
    }
  }

  trackingOpenVideo(videoData) {
    this.trackingEvent('Open Media', videoData);
  }

  trackingPlayVideo(videoData) {
    this.trackingEvent('Play Media', videoData);
  }

  trackingOpenSubcription(Subcription) {
    this.trackingEvent('Open Subcription', Subcription);
  }

  trackingOpenHomePage() {
    this.trackingEvent('Open Home Page', {});
  }

  trackingLiveNow() {
    this.trackingEvent('Live Now', {});
  }

  trackingCategoryPromotedNavBar(category) {
    this.trackingEvent('Go Promoted Category NavBar', {category});
  }

  trackingCategoryPromotedCarousel() {
    this.trackingEvent('Go Promoted Category Carousel', {});
  }


  trackingSignIn() {
    this.trackingEvent('Open Sign In', {});
  }

  trackingSignUp() {
    this.trackingEvent('Open Sign Up', {});
  }

  trackingLogin() {
    this.trackingEvent('Login', {});
  }

  trackingLoginFacebook() {
    this.trackingEvent('Login Facebook', {});
  }

}
