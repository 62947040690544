<div class='cancel-renew-content'>
  <p class='cancel' (click)="cancel()"> <mat-icon>arrow_back</mat-icon> {{'cancel-renew.cancel' | translate}}</p>
  <h1 class='title'>{{dataModal.fluxTitle}} {{'cancel-renew.subscription' | translate}}</h1>
  <div class='subscription-area'>
    <div class='subscription-info'>
      <img [src]="dataModal.thumbnailSubscription" alt="">
      <div class='data'>
        <p class='name'>{{dataModal.subscriptionData.name}}</p>
        <p class='value'>${{dataModal.subscriptionData.price}} USD <span>/ {{billingFrequencyTreatment(dataModal.subscriptionData) | titlecase}}</span></p>
      </div>
    </div>
    <p class='plan-info'>{{'cancel-renew.annual-plan' | translate}}</p>
    <p class='notice'><span>{{'cancel-renew.notice' | translate}}</span> {{'cancel-renew.recurring' | translate}}</p>
  </div>  
  <p class='continue-text' >{{'cancel-renew.click-continue' | translate}}</p>
  <div class='button' (click)="action()" [ngClass]='{"cancel": dataModal.fluxTitle == "Cancel", "renew": dataModal.fluxTitle == "Renew"}'>{{'cancel-renew.continue' | translate}}</div>
  <p class='terms'>{{'cancel-renew.terms' | translate}}</p>
</div>