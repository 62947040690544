<div class="tester-container">
	<div class="heading">
		<h3>Debug your ads tag</h3>
	</div>
	<div>
		<form [formGroup]="adsForm" (ngSubmit)="loadAd()" autocomplete="off">

			<mat-form-field appearance="outline" autocomplete="off">
				<mat-label>Url</mat-label>
				<textarea matInput placeholder="Enter ad tag..." formControlName="url" rows="4" [(ngModel)]="adTagUrl"></textarea>

				<mat-error *ngIf="adsForm.get('url').hasError('required')">
					URL is <strong>required</strong>
				</mat-error>
			</mat-form-field>

			<button mat-flat-button class="ad-button" type="submit" *ngIf='!loading'>Apply</button>
			<button mat-flat-button color='primary' class='loading ad-button' *ngIf='loading'>
				<mat-spinner diameter='30'></mat-spinner>
			</button>
		</form>
	</div>
	<div class="video-container">
		<video #videoTarget playsinline ref="video" class="video-js vjs-big-play-centered"></video>
	</div>
</div>

