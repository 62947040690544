import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private httpClient: HttpClient, private authService : AuthService) { }

  async getSearchedData(filters, limit, offset) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/search?offset=${offset}&limit=${limit}`, {
        headers,
        params : filters
      }).toPromise();
      return response;
    }
    catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getCategories() {
    let filters = {
      columnOrder: 'category_name',
      order: 'ASC',
      categoryWithoutParent: true
    }
    const headers = await this.authService.getHeadersSession();
    let response: any = await this.httpClient.get(`${environment.api_vms_url}/list-all-categories`, {
      headers,
      params : filters
    }).toPromise();
    return response.categories || [];
  }
}
