import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { SubscriptionService } from 'src/app/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionsService {

  constructor(
    private httpClient: HttpClient,
    private subscriptionService: SubscriptionService,
    private authService: AuthService
  ) { }

  async getSubscriptionCustomerPortal(agreementId){
    try {
      const session = await this.authService.getSession();
      const tokenData = {
        channelId: session.channel_host_id,
        user_id: session.userId
      }
      const token = await this.subscriptionService.getPaymentToken(tokenData);  
      const returnURL = window.location.href;

      let response: any = await this.httpClient.get(`${environment.api_payment_url}/get-stripe-portal`,{params: {agreementId, returnURL, token}}).toPromise();
      return response; 
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }
}
