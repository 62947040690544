
<div class='main-container'>
  <form class='filter-form'>
    <div class="filter-box">
      <mat-form-field class="search" >
        <mat-label>{{'filters.search' | translate}}</mat-label>
        <input matInput type="text" [formControl]="searchTagInput" (keyup)="onSearch($event.target.value)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
  
      <mat-form-field *ngIf="categories.length">
        <mat-label>{{'filters.categories' | translate}}</mat-label>
        <mat-select multiple (selectionChange)="onFilterCategories($event.value)">
          <mat-option  *ngFor="let category of categories" [value]="category.category_slug">{{category.category_name}}</mat-option>
        </mat-select>
      </mat-form-field>
  
  
  
      <mat-form-field (click)="picker.open()" class="date">
        <mat-label>{{'filters.enter-date' | translate}}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker" >
          <input matStartDate formControlName="start" placeholder="Start date" readonly>
          <input matEndDate formControlName="end" placeholder="End date" readonly (dateChange)="changeDate('change', $event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      
        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'filters.invalid-start-date' | translate}}</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'filters.invalid-end-date' | translate}}</mat-error>
      </mat-form-field>
    </div>
  </form>
  
  <div class='open-filter' (click)="openFilterModal()" [ngClass]="{'active': filterActive}" *ngIf="!loading">
    <mat-icon>filter_list</mat-icon>
  </div>
  
  <div class='content' *ngIf="!loading">    
    <div class='filter'>
      <mat-form-field appearance="outline">
        <mat-label>{{'filters.sort-by.title' | translate}}</mat-label>
        <mat-select [(value)]="orderBy" (selectionChange)="onFilter($event.value)" disableOptionCentering>
          <mat-option value="recent">{{'filters.sort-by.latest' | translate}}</mat-option>
          <mat-option value="older">{{'filters.sort-by.older' | translate}}</mat-option>
          <mat-option value="ascendingCharacters">{{'filters.sort-by.a-to-z' | translate}}</mat-option>
          <mat-option value="descendingCharacters">{{'filters.sort-by.z-to-a' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <mat-tab-group mat-align-tabs="start" class='subscription-tab' (selectedTabChange)="setCurrentTab($event)">
      <mat-tab *ngIf="!onlyVideo" label="All" >
        <div class='videos'>
          <app-thumbnail-video [hideLiveViewers]="channel.hide_live_viewers" [fullWidth]="fullWidth" *ngFor="let media of allData" [videoData]="media"></app-thumbnail-video>
        </div>
        <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      ></div>
      <ng-container>
        <div class="more-loading" *ngIf="allLoading">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      </ng-container>
      <ng-container *ngIf="!allLoading && !allData.length">
        <div class="no-events" >
          {{'main.no-media' | translate}}
        </div>
      </ng-container>
      </mat-tab>
      <mat-tab *ngIf="!onlyVideo" label="Live Now">
        <div class='videos'>
          <app-thumbnail-video [hideLiveViewers]="channel.hide_live_viewers" [fullWidth]="fullWidth"  *ngFor="let media of livesData" [videoData]="media"></app-thumbnail-video>
        </div>
        <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      ></div>
      <ng-container>
        <div class="more-loading" *ngIf="livematchesLoading">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      </ng-container>
      <ng-container *ngIf="!livematchesLoading && !livesData.length">
        <div class="no-events" >
          {{'main.no-media' | translate}}
        </div>
      </ng-container>
      </mat-tab>
      <mat-tab label="Videos" >
        <div class='videos'>
          <app-thumbnail-video [fullWidth]="fullWidth" *ngFor="let media of videosData" [videoData]="media"></app-thumbnail-video>
        </div>
        <div
        class="search-results"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
      ></div>
      <ng-container>
        <div class="more-loading" *ngIf="videosLoading">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      </ng-container>
      <ng-container *ngIf="!videosLoading && !videosData.length">
        <div class="no-events" >
          {{'main.no-media' | translate}}
        </div>
      </ng-container>
      </mat-tab>
    </mat-tab-group>
    
  </div>
  
  <ng-container *ngIf="loading">
    <div class="more-loading" >
      <mat-spinner diameter="70"></mat-spinner>
    </div>
  </ng-container>
</div>