<div class="content" [ngClass]="{'loading': loading}">
  <div class="panel">
    <p class="title">{{'upload-video.title' | translate}}</p>
    <p class="subtitle">{{'upload-video.subtitle' | translate}}</p>
  </div>
  <div class="channel-info">
    <ng-container *ngIf="!loading">
      <div class='file-form'>
        <form [formGroup]="fileForm" autocomplete="off">
          <mat-form-field class="file" appearance="standard" (click)="user ? fileInput.click() : openSignin()">
            <mat-label>{{'upload-video.file' | translate}}</mat-label>
            <input matInput readonly placeholder="Placeholder" [value]="fileName">
            <mat-icon matSuffix>file_upload</mat-icon>
          </mat-form-field>
  
          <mat-form-field appearance="standard">
            <mat-label>{{'upload-video.title-optional' | translate}}</mat-label>
            <input matInput type="text" autocomplete="off" formControlName="title" placeholder="Enter a title" maxlength="250">
          </mat-form-field>
  
          <mat-form-field appearance="standard">
            <mat-label>{{'upload-video.description-optional' | translate}}</mat-label>
            <textarea matInput type="text" autocomplete="off" formControlName="description" placeholder="Enter description" maxlength="500" rows="8"></textarea>
          </mat-form-field>
        </form>
  
        <button mat-flat-button class="publish" [ngClass]="{'filled': hasFile}" (click)="publish()">{{'upload-video.publish' | translate}}</button>
  
        <div class="file-input">
          <input style="display: none;" (change)="onFileChanged(fileInput)" (click)='cleanFileValue($event)' type="file" #fileInput name="avatar" accept="video/*">
        </div>
      </div>
    </ng-container>

    <div class="example-loading-shade" *ngIf="loading">
      <mat-spinner diameter="80"></mat-spinner>
      <div>{{'upload-video.uploading' | translate}}</div>
    </div>
  </div>
</div>
