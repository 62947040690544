<div class="container">

  <div class="box-header">  
    <span class="arrow-back" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
      {{'payment.go-back' | translate}}  
    </span>
  </div>

  <div class="empty-cart" *ngIf="cartItems.length === 0">
    <div class="gray-circle">
      <img  src='../../assets/icons/media.svg'>
    </div>
    <span class="items-title">{{'cart.selected' | translate}}</span>
    <span class="items">{{'cart.you-have' | translate}} <strong>0 {{'cart.item' | translate}}</strong> {{'cart.in-your-cart' | translate}}</span>
  </div>

  <div class="cart-container" *ngIf="cartItems.length > 0">
    <div class="cart-items-box">
      <div class="cart-items">
        <div class="container-titles">
          <span class="items-title">{{'cart.selected' | translate}}</span>
          <span *ngIf="cartItems.length === 1" class="cart-length">{{'cart.you-have' | translate}} <strong>1 {{'cart.item' | translate}}</strong> {{'cart.in-your-cart' | translate}}</span>
          <span *ngIf="cartItems.length > 1" class="cart-length">{{'cart.you-have' | translate}} <strong>{{cartItems.length}} {{'cart.items' | translate}}</strong> {{'cart.in-your-cart' | translate}}</span>
        </div>
        <div class="added-content" *ngFor="let item of cartItems">
          <span class="media-title">{{ item.title }}</span>
          <div class="price-box">
            <span class="price">${{ item.price }} USD</span>
            <button *ngIf="!success && !failed" class="remove-btn" (click)="removeFromCart(item)">
              <img  src='../../assets/icons/remove.svg'>
              {{'cart.remove' | translate}}
            </button>
          </div>
        </div>
      </div>

      <div class="total">
        <span class="total-title">{{'cart.total' | translate}}</span>
        <span class="small" *ngIf="!invalidCoupon && couponCode && discountPrice">${{totalPrice}} USD</span>
        <span class="value">${{discountPrice ? discountPrice : totalPrice}} USD</span>
      </div>
    </div>

    <div class="payment-container">
      <form [formGroup]="purchaseForm" *ngIf="purchaseForm && !alternativePurchaseMode && !success && !failed">
        <div class="purchase-subscription-box">
          <div class="title">{{'payment.purchase-using' | translate}}</div>

          <div class="payment-method" [ngClass]='{"selected": payment.value == "stripe"}' (click)='changePayment("stripe")'>
            <span class="offer-title">
              <img src="../../assets/icons/credit-card.png" style="width: 48px; margin: unset;" alt="stripe-icon">
              <span>{{'payment.credit-card' | translate}}</span>
            </span>
            <div>
              {{'payment.redirect' | translate}} 
              <b>{{'payment.credit-card-company' | translate}}</b> 
              {{'payment.complete-payment' | translate}}
            </div>
          </div>

          <section>
            <mat-checkbox formControlName='agreeTerms' color="primary">
              {{'payment.read-agree' | translate}}
              <a (click)="openRefund()">
                {{'payment.refund-policy' | translate}}
              </a>
            </mat-checkbox>
          </section>
          
          <button
            [disabled]="purchaseForm.invalid" 
            mat-flat-button 
            (click)='save()' 
            *ngIf='!loadingSave'>
            {{'payment.continue' | translate}}
          </button>
          <button mat-flat-button *ngIf='loadingSave'>
            <mat-spinner diameter='30'></mat-spinner>
          </button>
        </div>
      </form>

      <div *ngIf="!alternativePurchaseMode && !couponData && !success && !failed" class="coupon-container">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{'payment.your-coupon' | translate}}</mat-label>
          <input matInput [(ngModel)]="couponCode" type="text">
        </mat-form-field>
        <button *ngIf='!loadingCoupon' [disabled]="!couponCode" mat-flat-button (click)='activateCoupon()' >{{'payment.activate' | translate}}</button>
        <button mat-flat-button *ngIf='loadingCoupon'>
          <mat-spinner diameter='30'></mat-spinner>
        </button>
        <div class="invalid-coupon" *ngIf="invalidCoupon">{{'payment.invalid-coupon' | translate}}</div> 
      </div>

      <div *ngIf="alternativePurchaseMode && !success && !failed" class="coupon-container alternative">
        <div class="title">
          {{'payment.full-access' | translate}}
        </div>
        <button *ngIf="!loadingCoupon" [disabled]="getAccess"
          mat-flat-button class="mat-flat-button-full-access" 
          (click)='buySubscription()' >
            {{'payment.get-access' | translate}}
        </button>
        <button mat-flat-button *ngIf='loadingCoupon'>
          <mat-spinner diameter='30'></mat-spinner>
        </button>
      </div>

      <div *ngIf="couponData && !success && !failed" class="coupon-container">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{'payment.your-coupon' | translate}}</mat-label>
          <input disabled matInput [(ngModel)]="couponCode" type="text">
        </mat-form-field>
        <button class="remove-coupon" mat-flat-button (click)='removeCoupon()' >{{'payment.remove-coupon' | translate}}</button>
      </div>

      <div *ngIf="success" class="purchase-subscription-box done">
        <img src="../../assets/icons/Flat_tick_icon.svg" alt="">
        <h1>{{'payment.thank-you' | translate}}</h1>
        <h3>{{'payment.successfully-payment' | translate}}</h3>
        <h4>{{'payment.successfully-note-1' | translate}}<br>{{'payment.successfully-note-2' | translate}}</h4>
  
        <button mat-flat-button (click)="goBack()">{{'payment.go-back' | translate }}</button>
      </div>

      <div class="error-form" *ngIf="failed">
        <div class="purchase-subscription-box error">
          <img src="../../assets/icons/Exclamation_flat_icon.svg" alt="">
          <h1>{{'payment.error' | translate}}</h1>
          <h3>{{'payment.cancel-subscription' | translate}}</h3>

          <div class="buttons">
            <button mat-stroked-button class="cancel" (click)="goBack()">{{'payment.cancel' | translate}}</button>
            <button mat-stroked-button class="try-again" (click)="tryAgain()">{{'payment.try-again' | translate}}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
  
  <div class="dialog-footer">
    <a href="https://vewbie.com/terms-and-condition" target="_blank">{{'payment.terms-conditions' | translate}}</a> | <a href="https://vewbie.com/terms-of-service" target="_blank">{{'payment.terms-service' | translate}}</a> | <a href="https://vewbie.com/privacy-policy" target="_blank">{{'payment.privacy-policy' | translate}}</a>
  </div>
</div>


