import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private httpClient: HttpClient, private authService : AuthService
  ) { }

  
  async getAllCategories(columnOrder, order) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/list-all-categories?columnOrder=${columnOrder}&order=${order}`, {headers}).toPromise();
      return response.categories;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }
  async getCategoryIdBySlug(categorySlug: string) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-category-id-by-category-slug?category_slug=${categorySlug}`, {headers}).toPromise();
      return response.category_id;
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }

  }
  async getCategoryDataBySlug(categorySlug: string) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/categories/${categorySlug}`, {headers}).toPromise();
      return response || {};
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getTagyDataBySlug(tagSlug: string) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/tags/${tagSlug}`, {headers}).toPromise();
      return response || {};
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  async getMediasFromCategory(filters){
    try {
      this.refactoringFilter(filters);
      let {slug, displayType, limit, offset, category_id, filter, sub_categories_slug, end_date, start_date, search, columnOrder, order} = filters;
      
      const headers =  await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/categories/${slug}/${displayType}`, {
        headers,
        params: {
          limit,
          offset,
          sub_categories_slug,
          start_date,
          end_date,
          search,
          columnOrder,
          order,
        }
      }).toPromise();
      return response.categories_medias  || [];
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getMediasFromTag(filters){
    try {
      this.refactoringFilter(filters);
      let {slug, displayType, limit, offset, category_id, filter, end_date, start_date, search} = filters;    
      
      const headers =  await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(
        `
          ${environment.api_vms_url}/medias-by-tag/${slug}/${displayType}?limit=${limit}&offset=${offset}
          &start_date=${start_date}&end_date=${end_date}&search=${search}&columnOrder=${filters.columnOrder}&order=${filters.order}`, {
        headers,
      }).toPromise();
      return response  || [];
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getSubcategoriesFromCategory(categorySlug: string) {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/sub-categories/${categorySlug}`, {headers}).toPromise();
      return response || [];
    } catch (error) {
      this.authService.verifySession(error);
      return null;
    }
  }

  private refactoringFilter(filters) {
    filters.displayType = filters.displayType == "live" ? "livematches" : filters.displayType;

    switch(filters.orderBy) {
      case 'recent':
        filters.columnOrder = 'timestamp';
        filters.order = 'desc';
        break;
      case 'older':
        filters.columnOrder = 'timestamp';
        filters.order = 'asc';
        break;
      case 'ascendingCharacters':
        filters.columnOrder = 'title';
        filters.order = 'asc';
        break;
      case 'descendingCharacters':
        filters.columnOrder = 'title';
        filters.order = 'desc';
    }
  }

  public getInitials(string) {
    if(!string){
      return '';
    }
    string = string.replace(/[^A-Za-z0-9\s]/gi, '').trim(); // removes any character other than: Alphanumeric character from basic Latin alphabet, \s White space character.
    let names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

}
