<div class='main-container'>
  <div class="example-loading-shade" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class='header' id="header" *ngIf="!loading && !isTagContext">
    <div class='background' [ngStyle]="{'background-image': 'url(' + infoData.path_thumbnail + ')'}"></div>
    <div class='color'></div>
    <div class="left">
      <img *ngIf="infoData.path_thumbnail" [src]="infoData.path_thumbnail">
      <div *ngIf="!infoData.path_thumbnail" class="no-image" [ngStyle]="{'background' : isLive ? '#E91916' : '#666666'}">{{categoryInitials}}</div>
      <div class='category-info'>
        <p class='path'><span (click)="goToPath('')">{{'categories.home' | translate}}</span> > <span (click)="goToPath('/categories')">{{'categories.categories' | translate}}</span> <span *ngFor="let categoryPath of path"> > <span (click)="goToPathCategory(categoryPath.slug)">{{categoryPath.name}}</span></span></p>
        <p class='name'>{{infoData.category_name}}</p>
        <p class='count'>
          <span *ngIf="infoData.subcategories > 0"><span class='value'>{{infoData.subcategories}}</span> subcategor<span>{{infoData.subcategories > 1 ? 'ies' : 'y'}}</span></span>
          <span *ngIf="infoData.subcategories > 0 && infoData.videos > 0" class='separator'>|</span>
          <span *ngIf="infoData.videos > 0"><span class='value'>{{infoData.videos}} </span> <span *ngIf='infoData.videos > 1'>{{'categories.videos' | translate}}</span> <span *ngIf='infoData.videos == 0 || infoData.videos == 1'>{{'categories.video' | translate}}</span></span>
          <span *ngIf="infoData.videos > 0 && infoData.tags > 0"class='separator'>|</span>
          <span *ngIf="infoData.tags > 0"><span class='value'>{{infoData.tags}} </span> <span *ngIf='infoData.tags > 1'>{{'categories.tags-lowercase' | translate}}</span> <span *ngIf='infoData.tags == 0 || infoData.tags == 1'>{{'categories.tag-lowercase' | translate}}</span></span>
        </p>
      </div>
    </div>
  </div>

  <div class='header' id="header" *ngIf="!loading && isTagContext">
    <div class='color'></div>
    <div class="left">
      <div class="no-image" [ngStyle]="{'background' : isLive ? '#E91916' : '#666666'}">{{tagInitials}}</div>
      <div class='category-info'>
        <p class='path'><span (click)="goToPath('')">{{'categories.home' | translate}}</span> > <span (click)="goToPath('/categories')">{{'categories.categories' | translate}}</span> <span> > <span>{{'categories.tag' | translate}}</span> > <span (click)="goToPathCategory(infoData.tag_slug)">{{infoData.tag_name}}</span></span></p>
        <p class='name'>{{infoData.tag_name}}</p>
        <p class='count'>
          <span *ngIf="infoData.videos > 0"><span class='value'>{{infoData.videos}}</span> <span *ngIf='infoData.videos > 1'>{{'categories.videos' | translate}}</span> <span *ngIf='infoData.videos == 0 || infoData.videos == 1'>{{'categories.video' | translate}}</span></span>
        </p>
      </div>
    </div>
  </div>

  <div class='filter-background' *ngIf="!loading">
    <div class='filter'>
      <mat-form-field class="search" [ngClass]="{'extended': infoData.subcategories == 0 || isTagContext }">
        <mat-label>{{'filters.search' | translate}}</mat-label>
        <input matInput type="text" placeholder="Find Your Video / Event" (keyup)="onSearch($event.target.value)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <mat-form-field class='category-filter' appearance="standard" *ngIf="infoData.subcategories > 0">
        <mat-label>{{'filters.categories' | translate}}</mat-label>
        <mat-select multiple (selectionChange)="onFilter($event.value)">
          <mat-option *ngFor="let subCategory of subCategories" [value]="subCategory.category_slug">{{subCategory.category_name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class='date-filter' appearance="standard">
        <mat-label>{{'filters.enter-date' | translate}}</mat-label>
        <mat-date-range-input
          [rangePicker]="campaignOnePicker"
          [comparisonStart]="startDateRange"
          [comparisonEnd]="endDateRange">
          <input matStartDate placeholder="Start date" readonly [(ngModel)]="startDateRange">
          <input matEndDate placeholder="End date" readonly [(ngModel)]="endDateRange" (dateChange)="changeDate('change', $event)">
        </mat-date-range-input>
        <mat-datepicker-toggle disabled="false" matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
        <mat-date-range-picker disabled="false" #campaignOnePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

  <!-- Snack Ads -->

  <div [ngClass]="isMobile ? 'snack-mldb' : 'snack-ldb'" *ngIf='!adblockActivated' class="mt-12 mb-12">
    <div id="snack_dex7" *ngIf='!isMobile'></div>
    <div id="snack_mex6" *ngIf='isMobile'></div>
  </div>
  <!-- Snack Ads -->
  
  <section class="sub-categories-section" *ngIf="!loading && popularSubCategories.length">
    <div class="section-title">{{'categories.popular-related-categories' | translate}}</div>
    <div class="sub-categories-box">
      <div class="sub-category" *ngFor="let sub of popularSubCategories" (click)="redirectCategory(sub)">
        <div class="sub-category-image" *ngIf="sub.subchannel_logo || sub.category_thumbnail"><img [src]="sub.subchannel_logo || sub.category_thumbnail"></div>
        <div class="sub-category-image" *ngIf="!sub.category_thumbnail && !sub.subchannel_logo">
          <div class="no-thumbail">{{getCategorySlug(sub.category_name)}}</div>
        </div>
        <div class="description">
          <span class="name">{{sub.category_name}}</span>
          <div class="videos">{{sub.videos}} <span *ngIf='sub.videos > 1'>{{'categories.videos' | translate}}</span> <span *ngIf='sub.videos == 0 || sub.videos == 1'>{{'categories.video' | translate}}</span></div>
        </div>
      </div>
    </div>
  </section>

  <div class='open-filter' (click)="openFilterModal()" [ngClass]="{'active': filterActive}" *ngIf="!loading">
    <mat-icon>filter_list</mat-icon>
  </div>

  <div class='content' *ngIf="(!loading && !noMedias) || isMobile">
    <mat-tab-group #mainTabGroup mat-align-tabs="start" class='category-tab' [(selectedIndex)]="selectedTab" (selectedTabChange)="setCurrentTab($event)">
      <mat-tab [disabled]="firstLoading" label="All" [aria-labelledby]="onlyVideo ? 'hidden' : 'null'">
        <div class='videos' *ngIf="!allLoading || allMedias.length != 0">
          <div class='video'  *ngFor="let media of allMedias" >
            <app-thumbnail-video [hideLiveViewers]="channel.hide_live_viewers" [fullWidth]="fullWidth" [videoData]="media"></app-thumbnail-video>
          </div>
        </div>
        <div
          class="search-results"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
        ></div>
        <ng-container>
          <div class="more-loading" *ngIf="allLoading">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab [disabled]="firstLoading" label="Live Now" [aria-labelledby]="onlyVideo ? 'hidden' : 'null'">
        <div class='videos' *ngIf="!livemachtesLoading">
          <div class='video'  *ngFor="let media of liveMedias">
            <app-thumbnail-video [hideLiveViewers]="channel.hide_live_viewers" [fullWidth]="fullWidth" [videoData]="media"></app-thumbnail-video>
          </div>
        </div>
        <div
          class="search-results"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
        ></div>
        <ng-container>
          <div class="more-loading" *ngIf="livemachtesLoading">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="Videos" [disabled]="firstLoading">
        <div class='videos' *ngIf="!videosLoading || videoMedias.length != 0">
          <div class='video' *ngFor="let media of videoMedias">
            <app-thumbnail-video [fullWidth]="fullWidth" [videoData]="media"></app-thumbnail-video>
          </div>
        </div>
        <div
          class="search-results"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
        ></div>
        <ng-container>
          <div class="more-loading" *ngIf="videosLoading">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>

    <mat-form-field appearance="outline" class='sort-filter' *ngIf="!noMedias">
      <mat-label>{{'filters.sort-by.title' | translate}}</mat-label>
      <mat-select [(value)]="orderBy" (selectionChange)="onSort($event.value)" disableOptionCentering>
        <mat-option value="recent">{{'filters.sort-by.latest' | translate}}</mat-option>
        <mat-option value="older">{{'filters.sort-by.older' | translate}}</mat-option>
        <mat-option value="ascendingCharacters">{{'filters.sort-by.a-to-z' | translate}}</mat-option>
        <mat-option value="descendingCharacters">{{'filters.sort-by.z-to-a' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class='no-content' *ngIf="!loading && !allLoading && !livemachtesLoading && !videosLoading && noMedias">
    {{'categories.no-media' | translate}}
  </div>
</div>