import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";
import "videojs-event-tracking";
import { Router } from '@angular/router';
import { StatisticsService } from '../statistics.service';
import { AnalyticsService } from '../analytics.service';

import 'videojs-markers';

import { ExtractionService } from 'src/app/services/extraction.service';
import { Subscription } from 'rxjs';
import { TaggingService } from 'src/app/services/tagging.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('videoTarget', {static: true}) videoTarget: ElementRef;

  player: VideoJsPlayer & Record<string, any>;
  @Input() urls;
  @Input() mediaData;
  // @Input() gamePlays;
  @Input() channel;
  @Input() hlsURL: any;
  @Input() playerSettings: any;
  @Input() poster;
  @Input() isLive: boolean = false;

  playerType: string = "PLYR"
  auto_play = false;
  loop = false;
  seek: any = 0;
  mute: any = false;

  markers: any = [];
  currentVideoUTC: number;

  //stats
  startOnce: boolean = false;
  lastUpdatePosition: number = 0;
  bufferCounter: number = 0;
  updateTimer: number; //30s
  isPlaying: boolean = false;
  currentTime: number = 0;
  initTime: any;
  startLatency: any;
  sendLatency: boolean = true;
  playTime: any;
  playLatency: number;

  isAdsEnabled:boolean = true;

  healthCheckInterval: any;
  healthCheckIntervalSeconds: number = 20;
  adInterval: any;

  sessionInterval: any;

  selectedPlayerSource: boolean = false;
  playedOnAdendedPreroll: boolean = false;
  adblockActivated: boolean = false;


  vidCurrentTime: number = 0;
  videoDuration: number = 0;

  markerWidth: number = 30;
  markerIndex: number = 0;

  sliderWidth:number;

  currentMarker:number;
  newTime:number;
  markWidth:number;

  isClipMode:boolean = false;

  isMarkTime:boolean = true;

  markersVisible:boolean = false;

  // isPlaysVideos:boolean = true;
  // playsStartTime:number = 120;
  // playsEndTime:number = 180;

  private previousTime: number;

  playingChunkTimestamp:any;
  websocketTimestamp:number;
  isAdPlaying:boolean = false;
  isiPhone: boolean;

  isPlaysMode:boolean = true;

  private controlBarSubscription: Subscription;

  tooltip: HTMLElement;
  gamePlays:any[] = [];

  clipMarkers: any[] = [
    { time: 0, duration: 10 },
  ];


  constructor(
    public router: Router,
    public statisticsService: StatisticsService,
    private analyticsService : AnalyticsService,
    private extractionService: ExtractionService,
    private taggingService: TaggingService,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {

    this.isiPhone = this.detectiPhone();
    this.updateTimer = this.isLive ? 60000 : 30000;

    if (this.mediaData.isFree) {
      this.isAdsEnabled = true;
    } else {
      this.isAdsEnabled = this.mediaData.isAds;
    }

    if(this.playerSettings.vast_ads_uri) {
      this.playerSettings.vast_ads_uri = this.playerSettings.vast_ads_uri.replace("correlator=", "correlator=" + Date.now().valueOf());
    }
  }




  ngAfterViewInit(): void {
    this.initVideoJsPlayer();
  }

  healthCheck() {
    if(this.player.readyState() < 4) { //off
      console.log("Start Connection refreshed")
      this.player.reset();
      this.selectPlayerSource();
      setTimeout(() => this.player.play(), 100);
      console.log("End Connection refreshed")
    }
  }

  initVideoJsPlayer() {
    let vjsConfig: VideoJsPlayerOptions = {
      preload: "metadata",
      poster: this.poster,
      controls: true,
      autoplay: false,
      techOrder: ["html5", "flash"],
      plugins: { eventTracking: true },
      playbackRates: [0.5, 1, 1.5, 2, 4],
      html5: {
        nativeVideoTracks: false,
        nativeAudioTracks: false,
        nativeTextTracks: false,
        vhs : {
          maxPlaylistRetries : 2, //if the selected rendition playlist doesn't load until the max, the player should try another one instead of being hanged
          overrideNative: true
        },
        hls: {
          withCredentials: false,
          overrideNative: true,
          debug: true
        }
      }
    };

    this.updateSession();

    this.player = videojs(this.videoTarget.nativeElement, vjsConfig);

    this.player.ready(() => {
      if (!this.isPlaysMode && this.isClipMode) {
        this.toggleMarkersVisibility(false);
      }
    });


    this.player.httpSourceSelector();

    this.getGamePlays(this.mediaData.mediaId);

    this.player.on('loadedmetadata', () => {
      this.videoDuration = this.player.duration();
      this.extractionService.getVideoDuration(this.videoDuration)
      if (!this.isPlaysMode && this.isClipMode) {
        this.toggleMarkersVisibility(false);
      }

      this.extractionService.clipMode$.subscribe((show) => {
        this.isClipMode = show;
        if (!this.isPlaysMode && this.isClipMode) {
          this.toggleMarkersVisibility(show);
        }
        if (show === true) {
          this.isPlaysMode = false;
          this.player.play();
          this.extractionService.getVideoCurrentTime(this.player.currentTime());
          this.updateMarkers();

        } else {
          this.isPlaysMode = true;
          this.updateMarkers();

        }

      });

    });

    this.analyticsService.wssTimestamp$.subscribe((timestamp) => {
      this.isAdPlaying = false;
      this.websocketTimestamp = timestamp;
    });

    this.extractionService.videoTime$.subscribe(([newTime,newWidth]) => {
      this.player.ready(() => {
        this.player.currentTime(newTime);
        this.sliderWidth = newWidth;
        this.slideMarker(newTime,newWidth);
        this.player.play();
      });
    });

    this.taggingService.seekToTime$.subscribe((duration) => {
      if (duration > 0) {
        this.seekToDuration(duration);
      }
    });

    this.controlBarSubscription = this.extractionService.videoControls$.subscribe((show) => {
      if (this.player) {
        this.player.on('userinactive', () => {
          const newClass = 'vjs-user-active';
          this.player.el().classList.remove('vjs-user-inactive');
          this.player.el().classList.add(newClass);
        });
      }
    });


    if(this.isLive) {
      if (!this.isiPhone) {
        this.healthCheckInterval = setInterval(() => {
          this.healthCheck();
        }, this.healthCheckIntervalSeconds * 1000);
      }
    }
    setTimeout(() => {
      this.initialPlayerSource();
    }, 1000 * 3);

    this.runAds();
    let currentTime: number = 0;

    this.player.on('adend', () => {  // mid roll ads
      if(this.isLive && this.player.paused()) {
        this.player.play();
      }


      if(this.checkCondictionsToRunAds() && (this.playerSettings.isMediaAds == "PREROLL_INTERVAL" || this.playerSettings.isMediaAds == "NOPREROLL_INTERVAL")) {
        this.player.currentTime(currentTime);

        if(!this.adInterval) {
          this.adInterval = setInterval(() => {
            currentTime = this.player.currentTime();
            this.runAdMidRoll();
          }, this.playerSettings.adsIntervalTime * 1000)
        }
      }
    });

    this.adblockActivated = !(window as any).google?.ima;
    console.log("adblockActivated ", this.adblockActivated);

    if(this.adblockActivated) {
      // releasing the source of the video if the user has adblock turned on
      this.initialPlayerSource();
    }

    this.videoJsEventHandling();
  }

  async getGamePlays(media_id:string) {

    var plays = await this.taggingService.getGamePlays(media_id);

    for (var i=0; i<plays.length; i++) {
      if (plays[i].utcEnd > this.mediaData.recording_end_utc_ts) {
        plays[i].utcEnd = this.mediaData.recording_end_utc_ts;
      }
    }

    this.gamePlays = plays;

    this.addMarkers();
  }

  seekToDuration(duration: any) {
    const playTime = duration - this.currentVideoUTC;
    this.player.currentTime(playTime);
  }

  toggleMarkersVisibility(visible) {
    if (this.player.markers) {
      const markersContainer = this.el.nativeElement.querySelector('.vjs-marker');
      const taggingMarkers = this.el.nativeElement.querySelector('.tagging');
      if (markersContainer) {
        if (!visible) {
          markersContainer.style.display = 'none';
          taggingMarkers.style.display = 'block';
        } else {
          markersContainer.style.display = 'block';
          taggingMarkers.style.display = 'none';
        }
      }
    }
  }

  detectiPhone(): boolean {
    const userAgent = window.navigator.userAgent;
    return /iPhone/.test(userAgent);
  }

  async addMarkers() {

    // this.player.markers.add({ time: 0, duration: 30 });

    // if (this.isClipMode) {
    //   this.player.markers({
    //     markers: [
    //       { time: 0, duration: 10 }
    //       ],
    //   });
    // }

    if (this.isPlaysMode) {

      this.tooltip = this.renderer.createElement('div');
      this.renderer.addClass(this.tooltip, 'marker-tooltip');
      this.renderer.appendChild(this.el.nativeElement, this.tooltip);

      this.currentVideoUTC = this.mediaData.recording_start_utc_ts;

      await this.loadMarkers();

      this.player.markers({
        markers: this.markers
      });

      this.markers.forEach((marker: any) => {
        const markerElement = this.el.nativeElement.querySelector(
          `.vjs-marker[data-marker-key="${marker.key}"]`
        );
        if (markerElement) {
          markerElement.setAttribute('title', marker.text);
          this.renderer.listen(markerElement, 'mouseover', (event) => this.showTooltip(event, marker.text));
          this.renderer.listen(markerElement, 'mouseout', () => this.hideTooltip());
        }
      });


    }

    // setTimeout(()=>{
    // }, 3000);

  }

  updateMarkers(): void {
    this.player.markers.removeAll();
    const markers = this.getActiveMarkers();
    this.player.markers.add(markers);
  }

  getActiveMarkers(): any[] {
    if (this.isClipMode) {
      return this.clipMarkers;
    } else if (this.isPlaysMode) {
      return this.markers;
    }
    return this.markers;
  }

  showTooltip(event: MouseEvent, text: string) {
    this.tooltip.innerText = text;
    this.tooltip.style.display = 'block';
    this.tooltip.style.left = `${event.pageX}px`;
    this.tooltip.style.top = `${event.pageY - 40}px`; // Adjust the Y position to place the tooltip above the cursor
  }

  hideTooltip() {
    this.tooltip.style.display = 'none';
  }

  async loadMarkers(): Promise<void> {

    this.markers = this.gamePlays.map((gamePlay: any) => ({
      time: gamePlay.utcStart - this.currentVideoUTC,
      duration: gamePlay.utcEnd - gamePlay.utcStart,
      text: `${gamePlay.homeName} vs ${gamePlay.awayName}`
    }));

    this.markers.forEach(marker => {
      marker.class = "tagging";
    });

  }

  removeMarkers() {
    this.player.markers.removeAll();
  }

  isVideoPlaying() {
    return !this.player.paused();
  }

  slideMarker(newTime: number, newWidth:number) {

    if (!this.isPlaysMode && this.isClipMode) {
      this.newTime = newTime;
      this.markWidth = newWidth;

      const markers = this.player.markers.getMarkers();

      if (this.markerIndex >= 0 && this.markerIndex < markers.length) {
        markers[this.markerIndex].time = newTime;
        markers[this.markerIndex].duration = newWidth / 2;
        // markers[this.markerIndex].startTime = 400;
        // markers[this.markerIndex].endTime = 700;

        // markers[this.markerIndex].markerStyle.width = "300px";
        this.player.markers.updateTime();
      }
    }

  }

  runAdMidRoll() {

    if (!this.isAdsEnabled) {
      return;
    }

    /* if(this.mediaData.host_name != 'usssa' && this.mediaData.host_name != 'iswimtv') {
      this.playerSettings.vast_ads_uri = `https://googleads.g.doubleclick.net/pagead/ads?ad_type=video_text_image&client=ca-video-pub-4298243284054330&description_url=http%3A%2F%2Fusssalive.com&videoad_start_delay=0&max_ad_duration=30000&correlator=${Math.floor(Math.random() * 10000)+1}`;
    } */

    this.isAdPlaying = true;

    this.player.ima.setContentWithAdTag(null, this.playerSettings.vast_ads_uri, true);
    this.player.ima.initializeAdDisplayContainer();
    this.player.ima.requestAds();

  }

  runAds() {

    let isEnableAds = this.checkCondictionsToRunAds();
    if (!isEnableAds) {
      return;
    }

    // if(this.mediaData.host_name != 'usssa' && this.mediaData.host_name != 'iswimtv') {
    //   this.playerSettings.vast_ads_uri = `https://googleads.g.doubleclick.net/pagead/ads?ad_type=standardvideo_image&client=ca-video-pub-4298243284054330&description_url=${encodeURIComponent(window.location.href)}&videoad_start_delay=0&max_ad_duration=30000&correlator=${Math.floor(Math.random() * 10000)+1}`;
    //   // this.playerSettings.vast_ads_uri = `https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=`; // google sample for local tests
    // }

    let imaOptions = {
      id: 'videoTagId',
      adTagUrl: this.playerSettings.vast_ads_uri,
      vpaidMode: 2
      // adTagUrl: "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=",
    };
    this.player.ima(imaOptions);
    this.player.ima.setAdWillAutoPlay(false);
  }

  private initialPlayerSource() {
    if(this.mediaData.mediaAvailable && !this.selectedPlayerSource) {
      this.selectPlayerSource();
      this.selectedPlayerSource = true;
    }
  }

  private videoJsEventHandling() {

    this.player.on('waiting', () => {
      console.log('waiting');
      this.player.addClass("vjs-custom-waiting");
      this.extractionService.updateVideoPlayingState(false);
    });
    this.player.on('playing', () => {
      console.log('playing');
      this.extractionService.adPlaying(false);
      this.player.removeClass("vjs-custom-waiting");
      this.extractionService.updateVideoPlayingState(true);
    });

    this.player.on('play', (e) => {
      this.extractionService.adPlaying(false);
      console.log("videojs play")
      this.isPlaying = true;

      if (!this.startOnce) {
        this.playTime = new Date().getTime();
        this.analyticsService.trackingPlayVideo(this.mediaData);
        this.setLatencyTime();
        this.startOnce = true;
        this.statisticsService.startSession({
          media_id: this.mediaData.stream,
          type: this.mediaData.type,
          ref: window.location.href,
        });
      }
      this.extractionService.updateVideoPlayingState(true);
    });





    // start videojs-contrib-ads events
    this.player.on('seeking', () => {

      this.isMarkTime = false;

      if (this.player.currentTime() < this.newTime || this.player.currentTime() > (this.newTime + (this.sliderWidth / 2))) {
        if (this.player.currentTime() < this.videoDuration - (this.sliderWidth / 2)) {
          this.slideMarker(this.player.currentTime(),this.sliderWidth);
        } else {
          this.slideMarker(this.player.currentTime() - (this.sliderWidth / 2),this.sliderWidth);
        }

      }

      setTimeout(() => {
        this.isMarkTime = true;
      }, 500);

    });
    this.player.on('seeked', () => {
    });

    this.player.on('timeupdate', (e) => {

      this.currentTime = this.player.currentTime();
      const currentTime = this.player.currentTime();
      // Check if a jump occurred

      if (!this.isPlaysMode) {

        if (this.previousTime && Math.abs(currentTime - this.previousTime) > 1) {
          if (this.player.currentTime()< this.newTime || this.player.currentTime() > (this.newTime + (this.sliderWidth / 2))) {
            this.extractionService.scrollTimeline(this.player.currentTime());
          }
        }

        this.previousTime = currentTime;

      }

      this.player.ready(() => {
        if (this.isLive) {
          this.setPlayingChunkTime();
        }
      });


      if (this.isClipMode) {
        if (this.isMarkTime && this.player.currentTime() > this.newTime + (this.markWidth / 2)) {
          this.player.currentTime(this.newTime);
        }
        this.extractionService.setCurrentTime(this.player.currentTime(),this.newTime);
        this.isMarkTime = false;
        setTimeout(() => {
          this.isMarkTime = true;
        }, 500);

      }

    });

    this.player.on('pause', (e) => {
      this.isPlaying = false;
      this.extractionService.updateVideoPlayingState(false);
    });

    // start videojs-contrib-ads events
    this.player.on('adscomplete', () => {
      console.log('adscomplete');
      this.player.play();
    });

    this.player.on('adserror', () => {
      console.log('adserror');
      this.player.error(null); // Limpa o erro para permitir a reprodução do vídeo
      // releasing the source of the video in case there is an error in the ads
      this.initialPlayerSource();
      this.player.play();
    });

    this.player.on('adtimeout', () => {
      console.log('adtimeout');
      this.player.play();
    });

    this.player.on('adscanceled', () => {
      console.log('adscanceled');
      this.player.play();
    });

    this.player.on('adsready', () => {
      console.log('adsready');
      // starting player source after adsready to not let the user play the video before the ads are ready
      this.initialPlayerSource();
    });
    this.player.on('adended', () => {
      console.log('adended');
      // added due to unexpected behavior on ios
      if(!this.playedOnAdendedPreroll) {
        this.player.addClass("vjs-custom-waiting");
        this.playedOnAdendedPreroll = true;
      }
    });

    this.player.on('adskip', () => {
      console.log('Ad skipped');
      // Your custom logic for adSkip event
    });

    this.player.on('ads-ad-started', () => {
      console.log('ads-ad-started');
      this.extractionService.adPlaying(true);
    });
    // end videojs-contrib-ads events

    this.player.on('error', () => {
      console.log(this.player.error);
      this.extractionService.getVideoDuration(false);
    });

    this.player.on('tracking:buffered', (e, data) => console.log('tracking:buffered1', data))
    this.player.on('tracking:performance', (e, data) => console.log('tracking:performance1', data))

    this.player.tech().on('usage', (e) => {
      console.log("tech", e.name);
    });

    this.player.on('tracking:firstplay', (e, data) => { // set de highest quality
      console.log('tracking:firstplay', data)

      //this.setTheHighestQualityPossibleAsDefault();
    });
  }

  setPlayingChunkTime() {
    this.currentTime = this.player.currentTime();
    let tracks = this.player.textTracks();
    // console.log(tracks);
    if (tracks.length > 0) {
      let segmentMetadataTrack;
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].label === 'segment-metadata') {
          segmentMetadataTrack = tracks[i];
        }
      }
      this.playingChunkTimestamp = segmentMetadataTrack.tech_.vhs.playlists.media_.dateTimeString;
    } else {
      const chunkDuration = 10;
      if (this.currentTime % chunkDuration < 1) {
        const hlsPlaylistUrl = this.player.currentSrc();
        fetch(hlsPlaylistUrl)
        .then(response => response.text())
        .then(data => {
          const lines = data.split('\n');
          let mediaPlaylistUrl;
          lines.forEach(line => {
            if (line.startsWith('#EXT-X-STREAM-INF')) {
              mediaPlaylistUrl = lines[lines.indexOf(line) + 1].trim();
            }
          });

          if (mediaPlaylistUrl) {
            const newChunkListUrl = this.player.currentSrc().replace("playlist.m3u8", mediaPlaylistUrl);
            fetch(newChunkListUrl)
              .then(response => response.text())
              .then(mediaData => {
                const mediaLines = mediaData.split('\n');
                mediaLines.forEach(line => {
                  if (line.startsWith('#EXT-X-PROGRAM-DATE-TIME')) {
                    const programDateTime = line.replace("#EXT-X-PROGRAM-DATE-TIME:","");
                    this.playingChunkTimestamp = programDateTime
                  }
                });
              })
              .catch(error => {
                console.error('Error fetching media playlist:', error);
              });
          } else {
            console.error('No media playlist URL found in the main playlist');
          }
        })
        .catch(error => {
          console.error('Error fetching HLS playlist:', error);
        });
      }
    }

    const chunkTimestamp = new Date(this.playingChunkTimestamp).getTime() / 1000;
    const playingChunkTimestamp = Math.floor(chunkTimestamp);

    if (this.websocketTimestamp != 0 && !this.isAdPlaying && playingChunkTimestamp >= this.websocketTimestamp && playingChunkTimestamp <= this.websocketTimestamp + 10) {
      this.runAdMidRoll();
    }
  }

  checkCondictionsToRunAds() {
    return this.channel.ads == "IMA_ADS" && this.isAdsEnabled && (this.playerSettings.isMediaAds == 'PRE_ROLL' || this.playerSettings.isMediaAds == 'PREROLL_INTERVAL' || this.playerSettings.isMediaAds == "NOPREROLL_INTERVAL");
  }

  selectPlayerSource() {

    let hlsURL = this.hlsURL;

    // if (!this.isLive){
    //   hlsURL += '?v=' + new Date().getTime();
    // }

    this.player.src({
      src: hlsURL
    });
  }

  setLatencyTime() {
    let now = (new Date).getTime();
    this.startLatency = now - this.initTime;
    this.playLatency = now - this.playTime;
  }

  triggerAdInterval() {
    setTimeout(() => {
      // this.player.playAd(this.playerSettings.vast_ads_uri)
      // this.player.playAd(this.tag)
      this.player.playAd("https://pubads.g.doubleclick.net/gampad/ads?iu=/170737076/Video/usssa/usssalive.com&description_url=https%3A%2F%2Fusssalive.com&env=vp&impl=s&correlator=__random__&tfcd=0&npa=0&gdfp_req=1&output=xml_vast4&sz=640x480&max_ad_duration=30000&unviewed_position_start=1")
    }, this.playerSettings.adsIntervalTime);
  }

  updateSession() {
    this.sessionInterval = setInterval(() => {
      if (!this.isPlaying) {
        return;
      }

      let pt = this.currentTime - this.lastUpdatePosition;

      if (Math.round(pt) === 0) {
        return;
      }

      this.lastUpdatePosition = this.currentTime;

      this.sendLatency = false;

      let updateSessionData = {
        media_id: this.mediaData.stream,
        type: this.mediaData.type,
        ref: window.location.href,
        play_time: Math.round(pt),
      };

      this.statisticsService.updateSession(updateSessionData);
    }, this.updateTimer);
  }

  ngOnDestroy() {
    if(this.healthCheckInterval){
      clearInterval(this.healthCheckInterval);
    }

    if(this.sessionInterval){
      clearInterval(this.sessionInterval);
    }

    if(this.player){
      this.player.dispose()
    }

  }

  setTheHighestQualityPossibleAsDefault(){
    let qualityLevels = this.player.qualityLevels();

    //qualityLevels is not a javascript array
    let qualities = []
    for (var i = 0; i < qualityLevels.length; i++) {
      qualities.push(qualityLevels[i]);
    }

    qualities.forEach((quality, index) => quality.enabled = (index == (qualities.length - 1))); //set the video quality as the highest posible
  }
}
