<div class="menu">
  <a (click)="redirectIfNotMobile(originUrl)" class="app-logo" [ngClass]='{"category-selected" : categorySlug && isMobile}'>
    <div class="mobile" [ngClass]='{"change" : categoriesMenuTrigger.menuOpen}' [matMenuTriggerFor]="categories" #categoriesMenuTrigger="matMenuTrigger">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    <img (click)="goHome()" *ngIf="(!isMobile && channelBrand?.branded_url) || (isMobile && !categorySlug) && channelBrand?.branded_url" [src]="channelBrand?.branded_url" alt="">
    <div (click)="goHome()" *ngIf="(!isMobile && !channelBrand?.branded_url)  || (isMobile && !categorySlug) && !channelBrand?.branded_url" class="channel-name">{{channelBrand?.host}}</div>
    <span *ngIf="categorySlug && isMobile" class='category-name'>{{categoryName}}</span>
  </a>
  <div class="trapeze"  [ngClass]='{"category-selected": categorySlug && !isMobile, "category-selected-mobile": categorySlug && isMobile}'></div>
  <div class="menu-itens">
    <div class="explore" [ngClass]='{"category-selected": categorySlug}' [matMenuTriggerFor]="categories" #categoriesMenuTrigger="matMenuTrigger" *ngIf='menuCategoriesSlice?.length || (categorySlug && menuCategoriesSlice?.length)'>
      <span *ngIf="!categorySlug">{{'main.header.explore' | translate}}</span> 
      <img *ngIf="!categorySlug" src="../../assets/icons/arrow-down.svg">
      <span *ngIf="categorySlug"> {{categoryName}} </span> 
      <img *ngIf="categorySlug" src="../../assets/icons/arrow-down-white.svg"> 
    </div>
    <div class="explore category-selected" *ngIf='categorySlug && !menuCategoriesSlice?.length'>
      <span *ngIf="categorySlug"> {{categoryName}} </span> 
    </div>
    <div class="trapeze category-selected" *ngIf='categorySlug && !isMobile'></div>
    <div class="explore" *ngIf='menuCategoriesSlice && !menuCategoriesSlice.length && !categorySlug'>{{('main.header.explore-categories' | translate)}}</div>
    <a href="{{liveNowLink}}" class="categories live-now" (click)="goToLive($event)">Live Now</a>
    <ng-container *ngFor="let category of menuCategories">
      <a class="categories" href="{{getCategoryUrl(category)}}" (click)="goToCategoryHomepage(category, $event)">{{categorySlug && category?.category_slug == categorySlug ? 'Home' : category?.category_name}}</a>
    </ng-container>
    <div class="search-form">
      <input id='search-input' #searchInputDesktop type="text" (keyup.enter)="search()">
      <div class="search-icon" (click)="search()">
        <img src="../../assets/icons/search.svg" class="search-mobile">
      </div>
    </div>
    <div *ngIf="isMobile" class="search-icon" (click)="focusSearch()">
      <img src="../../assets/icons/search.svg" class="search-mobile">
    </div>
    <button mat-flat-button class="signin-button more" [matMenuTriggerFor]="more" #moreMenuTrigger="matMenuTrigger">
      {{'main.more' | translate}} <img src="../../assets/icons/arrow-down.svg"></button>
      <button *ngIf="!isLogged" mat-flat-button class="signin-button" (click)="openSignin()">{{'main.sign' | translate}}</button>
      <div *ngIf="isLogged" class="user-image" [matMenuTriggerFor]="profile" #profileMenuTrigger="matMenuTrigger"
      matTooltipClass="tooltip-profile" matTooltip="{{session.full_name}}&#13;{{session.email}}" matTooltipPosition="above">
      <img class="no-image" *ngIf="!session.profile_picture" src="../../assets/icons/user.png">
      <img *ngIf="session.profile_picture" [src]="session.profile_picture">
    </div>
  </div>
  <div *ngIf="isMobile && searchMenu" class="search-menu">
    <div class="search-box">
      <input #searchInputMobile type="text" (keyup.enter)="search()" (blur)="focusSearch()">
      <div class="search-icon" (click)="search()">
        <img src="../../assets/icons/search.svg" class="search-mobile">
      </div>
    </div>
  </div>
</div>
<mat-menu #categories="matMenu" class="customize category" [hasBackdrop]="true" backdropClass="categories">
  <div class="menu-opened">
    <div id="category-div" class="menu-categories" (click)="$event.stopPropagation()">
      <ng-container  *ngIf="!isMobile">
        <a *ngFor="let category of menuCategoriesSlice" href="{{getCategoryUrl(category)}}" [ngClass]="{'on-hover': categoryOnHoverMenu?.category_slug == category.category_slug}" mat-menu-item (mouseenter)="menuType(category)" (click)="goToCategoryDetails(category, $event)">{{category.category_name}}</a>
      </ng-container>
      <ng-container *ngIf="isMobile">
        <button mat-menu-item (click)="goToLive()">Live Now</button>
        <button *ngFor="let category of menuCategories" mat-menu-item (mouseenter)="menuType(category)" (click)="goToCategoryHomepage(category)">{{categorySlug && category?.category_slug == categorySlug ? 'Home' : category?.category_name}}</button>
        <div class="hr-text"></div>
      </ng-container>
      <a href="{{allCategoriesLink}}" *ngIf="!isMobile" class="explore-all" mat-menu-item (click)="goToAllCategories($event)">Explore all Categories</a>
      <button *ngIf="isMobile" (click)="openMobileCategories()" class="explore-all mobile" mat-menu-item>
        <span>{{'main.header.explore' | translate}}</span>
        <img *ngIf src="../../assets/icons/arrow-right-black.svg" alt="">
        <div class="arrow" [ngClass]="{'down': categoriesOpen}" alt=""></div>
      </button>
      <div *ngIf="categoriesOpen" class="open-categories">
        <div *ngFor="let category of menuCategoriesSlice" class="categories" mat-menu-item (mouseenter)="menuType(category)">
          <button mat-menu-item disableRipple (click)="showSubCategorie(category)">
            <span>{{category.category_name}}</span>
            <a href="{{getCategoryUrl(category)}}" *ngIf="category.showSubs" (click)="goToCategoryDetails(category, $event)" class="see-more">See More</a>
            </button>
          <div *ngIf="category.showSubs" class="sub-categories">
            <a *ngFor="let sub of category.sub_categories" href="{{getCategoryUrl(sub)}}" (click)="goToCategoryDetails(sub, $event)" mat-menu-item disableRipple>{{sub.category_name}}</a>
          </div>
        </div>
      </div>
      <div class="hr-text" *ngIf="isMobile"></div>
      <div class="more-menu">
        <button class="title" disabled mat-menu-item >{{'menu.overview' | translate}}</button>
        <button mat-menu-item (click)="goToSchedule()">{{'menu.schedule' | translate}}</button>
        <button mat-menu-item (click)="goToSubscriptions()">{{'menu.subscriptions' | translate}}</button>
        <div class="hr-text"></div>
        <button class="title" disabled mat-menu-item>{{'menu.more-informations' | translate}}</button>
        <button mat-menu-item><a href="https://vewbie.com/support" target="_blank" rel="noopener noreferrer">{{'menu.faqs' | translate}}</a></button>
        <button mat-menu-item *ngIf="isOtt" (click)='goToPath("tv-apps")'>{{'menu.tv-apps' | translate}}</button>
        <button mat-menu-item (click)='goToPath("about-us")'>{{'menu.about-us' | translate}}</button>
        <button mat-menu-item (click)='goToPath("privacy-policy")'>{{'menu.privacy-policy' | translate}}</button>
        <button mat-menu-item (click)='goToPath("terms-and-condition")'>{{'menu.terms-and-condition' | translate}}</button>
      </div>

    </div>
  
    <div id="sub-div" class="sub">
      <ng-container *ngFor="let sub of subCategories">
        <a href="{{getCategoryUrl(sub)}}" mat-menu-item (click)="goToCategoryDetails(sub, $event)">{{sub.category_name}}</a>
        <div class="sub-child"  *ngFor="let item of sub.sub_categories">
          <a href="{{getCategoryUrl(item)}}" mat-menu-item (click)="goToCategoryDetails(item, $event)">{{item.category_name}}</a>
        </div>
      </ng-container>
      <a *ngIf="subCategories && subCategories.length == 0" href="{{getCategoryUrl(categoryOnHoverMenu)}}" (click)="goToCategoryDetails(categoryOnHoverMenu, $event)" mat-menu-item>Go To Category</a>
    </div>
  </div>
</mat-menu>
<mat-menu #more="matMenu" class="customize more" [hasBackdrop]="true" backdropClass="more" focusFirstItem="false">
  <div class="more-menu" (mouseleave)="closeContinue('more')">
    <button class="title" disabled mat-menu-item >{{'menu.overview' | translate}}</button>
    <button mat-menu-item (click)="goToSchedule()">{{'menu.schedule' | translate}}</button>
    <button mat-menu-item (click)="goToSubscriptions()">{{'menu.subscriptions' | translate}}</button>
    <div class="hr-text"></div>
    <button class="title" disabled mat-menu-item>{{'menu.more-informations' | translate}}</button>
    <button mat-menu-item><a href="https://vewbie.com/support" id="faq" target="_blank" rel="noopener noreferrer">{{'menu.faqs' | translate}}</a></button>
    <button mat-menu-item *ngIf="isOtt" (click)='goToPath("tv-apps")'>{{'menu.tv-apps' | translate}}</button>
    <button mat-menu-item [routerLink]="['about-us']">{{'menu.about-us' | translate}}</button>
  </div>
</mat-menu>

<mat-menu #profile="matMenu" class="customize profile" [hasBackdrop]="true" backdropClass="profile">
  <div class="profile-menu" (mouseleave)="closeContinue('profile')">
    <button class="title" (click)="goToProfile('overview')"  mat-menu-item>{{'menu.profile' | translate}}</button>
    <div class="hr-text"></div>
    <button *ngIf="allowCreateChildSites" class="title" (click)="goToCreateChannel()" mat-menu-item>{{'menu.create-channel' | translate}}</button>
    <div *ngIf="allowCreateChildSites" class="hr-text"></div>
    <button *ngIf="session?.user_admin_type == 'ADMIN' || session?.user_admin_type == 'MANAGER'" (click)="goToDashboard()" class="title" mat-menu-item>{{'menu.dashboard' | translate}}</button>
    <div *ngIf="session?.user_admin_type == 'ADMIN' || session?.user_admin_type == 'MANAGER'" class="hr-text"></div>
    <button class="title" mat-menu-item (click)="goToProfile('subscriptions')">{{'menu.my-subscriptions' | translate}}</button>
    <button mat-menu-item (click)="goToProfile('unlock-video-bundles')">{{'menu.unlocked-video-editor-bundles' | translate}}</button>
    <button mat-menu-item (click)="goToProfile('unlock-video-lives')">{{'menu.unlocked-video-streams' | translate}}</button>
    <div class="hr-text"></div>
    <button class="title" mat-menu-item (click)="goToProfile('videos')">{{'menu.videos' | translate}}</button>
    <button mat-menu-item (click)="goToPath('upload-video')">{{'menu.upload-video' | translate}}</button>
    <button mat-menu-item (click)="goToProfile('favorite-videos')">{{'menu.favorites' | translate}}</button>
    <button mat-menu-item (click)="goToProfile('recommended-videos')">Recommended Videos</button>
    <button mat-menu-item (click)="goToProfile('my-clips')">My Clips</button>
    <!-- Traduzir textos acima -->
    <div class="hr-text"></div>
    <button class="title" mat-menu-item (click)="logout()">{{'menu.logout' | translate}}</button>
  </div>
</mat-menu>

<router-outlet></router-outlet>

<div class='cookie-warning' *ngIf="showCookieWarning">
  <span>{{'footer.cookie.message' | translate}}</span>
  <div class='options'>
    <span class='button' (click)="acceptCookiePolicy()">{{'footer.cookie.i-know' | translate}}</span>
    <a [routerLink]="['/cookie-policy']" class='button'>{{'footer.cookie.read-more' | translate}}</a >
  </div>
</div>

<div *ngIf="isLogged" class='request-help first-element' (click)="goToCart()">
  <img class="cart-svg" src='../../assets/icons/cart.svg'>
  <span class="notification">{{cartItems.length}}</span>
  <span>{{'footer.cart' | translate}}</span>
</div>

<div class='request-help' (click)='requestHelp()'>
  <img src='../../assets/icons/email-letter.svg'>
  <span>{{'footer.request-help' | translate}}</span>
</div>

<div class="footer">
	<div class='copyright'>
		<p><mat-icon>copyright</mat-icon> Skoresheet Inc.</p>
    <a [routerLink]="['/privacy-policy']"><mat-icon>lock</mat-icon> {{'footer.privacy-policy' | translate}}</a>
		<a [routerLink]="['/terms-and-condition']"><mat-icon>list_alt</mat-icon> {{'footer.terms-and-condition' | translate}}</a>
	</div>
  <button mat-flat-button (click)="changeLanguage()"><img [src]="selectedLang.source">{{selectedLang.name}}</button>
</div>

<!--<div class='new-platform' *ngIf="showNewPlatformWarning && (hostName == 'venkatesh' || hostName == 'usssa')">
  <div class='content'>
    <img src='../../assets/icons/announcement.svg'>

    <div class='welcome'>
      <p>Welcome to the new USSSA Live!</p>
      <span>This is the new USSSA Live platform, with a new design created for you - the same functionalities, but with a new face. You can switch back to the previous system at any time!</span>
    </div>
  </div>

  <div class='buttons'>
    <button mat-stroked-button class="previous-system" (click)='goToNewPlatform()'>Go to previous system</button>
    <button mat-flat-button class="close-message" (click)='showNewPlatformWarning = false'>Close message</button>
  </div>
</div>-->
