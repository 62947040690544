<div class='content-favorities-media' *ngIf='userFavoritiesMedias.length' (scroll)="onScroll($event)">
	<ng-container *ngFor='let media of userFavoritiesMedias' >
		<app-thumbnail-video [videoData]="media" [ngClass]='{"left-none": userFavoritiesMedias.length % 4 == 1 || userFavoritiesMedias.length % 4 == 0, "left-3": userFavoritiesMedias.length % 4 == 2, "left-2": userFavoritiesMedias.length % 4 == 3}'></app-thumbnail-video>
	</ng-container>
</div>

<div class="pagination-loading" *ngIf="paginationLoading"><mat-spinner diameter="40"></mat-spinner></div>

<div class='no-events user-videos' *ngIf='!userFavoritiesMedias.length'>
	{{'profile.no-results' | translate}}
</div>