import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  private stripeInstance;

  constructor(
    private httpClient: HttpClient
    ) { }

  async loadStripeInstance(){
    this.stripeInstance = await loadStripe(environment.stripe_publishable_key);
  }

  async createStripeSession(data : any){
    let request: any = await this.httpClient.post(`${environment.api_payment_url}/stripe/create_session`, data,
    {headers: { Authorization : 'Bearer vK9H%PD1$y!84ylJ' }}).toPromise();
    return request.response.sessionId;
  }

  async createMultipleSessions(data : any){
    let request: any = await this.httpClient.post(`${environment.api_payment_url}/stripe/create_session_multiples`, data,
    {headers: { Authorization : 'Bearer vK9H%PD1$y!84ylJ' }}).toPromise();
    return request.response.sessionId;
  }

  async redirectToCheckout(sessionId: string){
    this.stripeInstance.redirectToCheckout({ sessionId: sessionId })
  }
}
