<div class="content-terms">
  <div class="panel">
    <p>{{'terms-conditions.title' | translate}}</p>
  </div>
  <div class="channel-info">
    <div class="text">
      <p>{{'terms-conditions.description-1' | translate}}</p>
      <p>{{'terms-conditions.description-2' | translate}}</p>
      <p>{{'terms-conditions.description-3' | translate}}</p>
      <h4>{{'terms-conditions.privacy' | translate}}</h4>
      <p>{{'terms-conditions.privacy-description' | translate}}</p>
      <h4>{{'terms-conditions.eletronic-communications' | translate}}</h4>
      <p>{{'terms-conditions.eletronic-communications-description' | translate}}</p>
      <h4>{{'terms-conditions.your-account' | translate}}</h4>
      <p>{{'terms-conditions.your-account-description-1' | translate}}</p>
      <p>{{'terms-conditions.your-account-description-2' | translate}}</p>
      <h4>{{'terms-conditions.cancellation-refund' | translate}}</h4>
      <p>{{'terms-conditions.cancellation-refund-description' | translate}}</p>
      <h4>{{'terms-conditions.third-party' | translate}}</h4>
      <p>{{'terms-conditions.third-party-description-1' | translate}}</p>
      <p>{{'terms-conditions.third-party-description-2' | translate}}</p>
      <p>{{'terms-conditions.third-party-description-3' | translate}}</p>
      <h4>{{'terms-conditions.no-unlawful' | translate}}</h4>
      <p>{{'terms-conditions.no-unlawful-description-1' | translate}}</p>
      <p>{{'terms-conditions.no-unlawful-description-2' | translate}}</p>
      <p>{{'terms-conditions.no-unlawful-description-3' | translate}}</p>
      <p>{{'terms-conditions.no-unlawful-description-4' | translate}}</p>
      <h4>{{'terms-conditions.third-party-accounts' | translate}}</h4>
      <p>{{'terms-conditions.third-party-accounts-description' | translate}}</p>
      <h4>{{'terms-conditions.international-users' | translate}}</h4>
      <p>{{'terms-conditions.international-users-description' | translate}}</p>
      <h4>{{'terms-conditions.indemnification' | translate}}</h4>
      <p>{{'terms-conditions.indemnification-description' | translate}}</p>
      <h4>{{'terms-conditions.arbitration' | translate}}</h4>
      <p>{{'terms-conditions.arbitration-description' | translate}}</p>
      <h4>{{'terms-conditions.class-action-waiver' | translate}}</h4>
      <p>{{'terms-conditions.class-action-waiver-description' | translate}}</p>
      <h4>{{'terms-conditions.liability-disclaimer' | translate}}</h4>
      <p>{{'terms-conditions.liability-disclaimer-description-1' | translate}}</p>
      <p>{{'terms-conditions.liability-disclaimer-description-2' | translate}}</p>
      <p>{{'terms-conditions.liability-disclaimer-description-3' | translate}}</p>
      <p>{{'terms-conditions.liability-disclaimer-description-4' | translate}}</p>
      <p>{{'terms-conditions.liability-disclaimer-description-5' | translate}}</p>
      <h4>{{'terms-conditions.changes-terms' | translate}}</h4>
      <p>{{'terms-conditions.changes-terms-description' | translate}}</p>

    </div>    
  </div>
</div>