import { Injectable } from '@angular/core';
import * as localForage from 'localforage';
import { AuthService } from 'src/app/auth/auth.service';
import { BehaviorSubject } from 'rxjs';

type CartData = {
  couponCode: string | null;
  totalCost: number;
  cartItems: any[];
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  initalCartData: CartData = {
    couponCode: null,
    totalCost: 0,
    cartItems: [],
  };

  localStorageKey: string = 'cart_data';
  cartDataSubject = new BehaviorSubject<CartData>(this.initalCartData);

  constructor(
    private authService: AuthService,
  ) { 
    this.initData();
  }

  async initData() {
    const session = await this.authService.getSession();
    this.localStorageKey = `cart_data_${session.userId}`;
    await this.loadCartData();
  };

  get cartData() {
    return this.cartDataSubject.getValue();
  }

  get cartItems() {
    return this.cartDataSubject.getValue().cartItems;
  }

  addCoupon(couponCode: string) {
    this.cartDataSubject.next({
      ...this.cartData,
      couponCode,
    });
    this.saveCartData();
  }

  removeCoupon() {
    this.cartDataSubject.next({
      ...this.cartData,
      couponCode: null,
    });
    this.saveCartData();
  }

  async addItemToCart(item: any) {
    const existingItem = this.cartItems.find((cartItem) => cartItem.mediaId === item.mediaId);
    if (!existingItem) {
      const newItems = [...this.cartItems, item];
      this.cartDataSubject.next({
        ...this.cartData,
        cartItems: newItems,
        totalCost: this.calcTotalCost(newItems)
      });
      await this.saveCartData();
    }
  }

  async removeItemFromCart(item: any) {
    const index = this.cartItems.findIndex((i) => i.mediaId === item.mediaId);
    if (index !== -1) {
      const newCartItems = this.cartItems;
      newCartItems.splice(index, 1);
      this.cartDataSubject.next({
        ...this.cartData,
        cartItems: newCartItems,
        totalCost: this.calcTotalCost(newCartItems)
      });
      await this.saveCartData();
    }
  }

  calcTotalCost(items: any[]){
    return items.reduce((total, item) => total + Number(item.price), 0);
  }

  async saveCartData() {
    await localForage.setItem(this.localStorageKey, this.cartData);
  }

  async loadCartData() {
    const storedData = await localForage.getItem<CartData>(this.localStorageKey);
    if (storedData) {
      this.cartDataSubject.next(storedData);
    }
  }

  async updateCartData(items: any[], coupon: string | null) {
    this.cartDataSubject.next({cartItems: items, couponCode: coupon, totalCost: this.calcTotalCost(items)});
    this.saveCartData();
  }

  async resetCartData() {
    this.cartDataSubject.next(this.initalCartData);
    localForage.setItem(this.localStorageKey, this.initalCartData);
  }
}
