import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ExtractionService {

  constructor(private httpClient: HttpClient, private authService : AuthService) { }

  private videoTimeSource = new BehaviorSubject<[number,number]>([0, 0]);
  private videoShowControls = new BehaviorSubject<boolean>(false);
  private VideoDurationTotal = new BehaviorSubject<number>(0);
  private VideoCurrentTime = new BehaviorSubject<any>(0);
  private timelineLeft = new BehaviorSubject<number>(0);
  private isVideoPlayingSubject = new BehaviorSubject<boolean>(false);
  private isClipMode = new BehaviorSubject<boolean>(false);
  private isAdStarted = new BehaviorSubject<boolean>(false);
  private markersEnabled = new BehaviorSubject<boolean>(false);
  private timeUpdate = new BehaviorSubject<[number,number]>([0, 0]);


  private disableClipExtraction = new Subject<void>();

  videoTime$ = this.videoTimeSource.asObservable();
  videoControls$ = this.videoShowControls.asObservable();
  totalVideoDuration$ = this.VideoDurationTotal.asObservable();
  currentTime$ = this.VideoCurrentTime.asObservable();
  currentTimeUpdate$ = this.timeUpdate.asObservable();
  scrollTimelineLeft$ = this.timelineLeft.asObservable();
  clipMode$ = this.isClipMode.asObservable();
  adStarted$ = this.isAdStarted.asObservable();
  markers$ = this.markersEnabled.asObservable();
  disableClip$ = this.disableClipExtraction.asObservable();

  isVideoPlaying$: Observable<boolean> = this.isVideoPlayingSubject.asObservable();

  private markers: any[] = [];

  addMarker(marker: any) {
    this.markers.push(marker);
  }

  getMarkers() {
    return this.markers;
  }


  // Update the video playing state
  updateVideoPlayingState(isPlaying: boolean) {
    this.isVideoPlayingSubject.next(isPlaying);
  }


  setVideoTime(time: number, width: number) {
    this.videoTimeSource.next([time,width]);
  }

  getVideoDuration(duration: any) {
    this.VideoDurationTotal.next(duration);
  }

  getVideoCurrentTime(time: number) {
    this.VideoCurrentTime.next(time);
  }

  setCurrentTime(time: number, startTime: number) {
    this.timeUpdate.next([time,startTime]);
  }
  
  scrollTimeline(left: number) {
    this.timelineLeft.next(left);
  }

  showControls(show: boolean) {
    this.videoShowControls.next(show);
  }

  enableClipMode(show: boolean) {
    this.isClipMode.next(show);
  }

  adPlaying(isAdPlaying: boolean) {
    this.isAdStarted.next(isAdPlaying);
  }

  enableMarkers(enable: boolean) {
    this.markersEnabled.next(enable);
  }

  disableClip() {
    this.disableClipExtraction.next();
  }

  async createClip(data){
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.post(`${environment.api_vms_url}/create-clip`, data, {headers}).toPromise();
      
      if (response.error) {
        return response;
      }

      return response;
    } catch (error) {

      

      if (error.status === 402) {

        return {error: true, code: error.status, data: error.error.subscriptionIds, errorMessage: error.error.message};
      }
      
      return {error: true, code: error.status, errorMessage: error.error.errorMessage};
    }
  }
  
}
