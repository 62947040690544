import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { FiltersComponent } from 'src/app/filters/filters.component';
import { SearchService } from '../search/search.service';
import { VideosService } from 'src/app/services/videos.service';
import { ChannelService } from 'src/app/services/channel.service';

@Component({
  selector: 'app-seeAll',
  templateUrl: './seeAll.component.html',
  styleUrls: ['./seeAll.component.scss']
})
export class SeeAllComponent implements OnInit {
  private mediaSearchSubject: Subject<string> = new Subject();
  private mediaSearchSubscription: Subscription;

  @ViewChild('mainTabGroup', {static: false}) mainTabGroup: MatTabGroup;

  loading: boolean = true;
  moreLoading: boolean = false;

  limit: number = 15;
  offset: number = 0;
  nextRequestSearch = null;

  isMobile: boolean = false;
  fullWidth: boolean = false;

  currentTab: string = "livematches";

  blockScroll: boolean = false;

  noMedias: boolean = false;

  seeAllInitials: string;

  routeSubscription: Subscription;
  filterSelected : any = null;
  startDateRange: Date = null;
  endDateRange: Date = null;

  searchValue: string = "";

  filterActive: boolean = false;

  type: string = '';
  data: any = [];

  channel: any;

  filter: any = {
    columnOrder: 'timestamp',
    order: 'DESC'
  };

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private searchService: SearchService,
    private videosService: VideosService,
    private channelService : ChannelService,
  ) {}

  async ngOnInit() {

    this.channel = await this.channelService.getCurrentChannel();

    this.mediaSearchSubscription = this.mediaSearchSubject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {
      this.search(searchTextValue);
    });

    this.type = await this.route.routeConfig.path.split('/')[1];
    if(this.type == 'live'){
      this.filterSelected = 'mostSeen';
      this.filter.columnOrder = 'subscribers';
    }


    if(window.innerWidth <= 768) {
      this.isMobile = true;
      this.fullWidth = true;
    }
   
    this.getAllData();

    this.seeAllInitials = this.getInitials(this.type);
  }

  ngOnDestroy() {
    if(this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.mediaSearchSubscription) {
      this.mediaSearchSubscription.unsubscribe();
    }
  }

  async search(searchTextValue){
    this.searchValue = searchTextValue;

    clearTimeout(this.nextRequestSearch); 
    this.nextRequestSearch = setTimeout(async ()=> {
      await this.getMedias(false);
    }, 1000);
  }

  async getAllData() {
    this.loading = true;
    await this.getMedias(false);
    this.loading = false;
  }

  async getMedias(onScroll: boolean) {
    if(!onScroll) {
      this.offset = 0;
      this.blockScroll = false;

      this.noMedias = false;

      this.data = [];
    } else {
      this.offset += this.limit;
    }

    let filters = {      
      columnFilter: this.type == 'live' ? 'livematches' : 'videos', 
      columnOrder: this.filter.columnOrder, 
      order: this.filter.order,
      start_date: this.startDateRange ? moment(this.startDateRange).startOf('day').toISOString() : null,
      end_date: this.endDateRange ? moment(this.endDateRange).endOf('day').toISOString() : null,
      search: this.searchValue,
    }

    let medias: any;

    if(this.type == 'live') {
      filters['online'] = true;
      filters["seeAllMoreLives"] = true;
    }

    this.moreLoading = true;

    if(this.type == 'most-viewed') {
      filters["columnOrder"] = this.filter.columnOrder;
      delete filters["columnFilter"];

      medias = await this.videosService.getMostViewedVideos(this.limit, this.offset, filters);
    } else {
      medias = await this.searchService.getSearchedData(filters, this.limit, this.offset);
    }

    this.moreLoading = false;

    if(!medias.length && !onScroll) {
      this.noMedias = true;
    }

    if(!medias || medias.length == 1 || medias.length < this.limit) {
      this.blockScroll = true;
    } 

    if(!medias){
      medias = [];
    }

    this.data = this.data.concat(medias); 
  }

  async onFilter(value: string) {
    this.filterSelected = value;
    this.filter.columnOrder = 'title';

    if (value == 'mostSeen') {
      this.filter.order = 'DESC';
      this.filter.columnOrder = 'subscribers';
    }
    else if (value == 'ascendingCharacters' || value == 'ascendingCategories') {
      this.filter.order = 'ASC';
    } 
    else {
      this.filter.order = 'DESC';
    }

    if(value == 'ascendingCategories' || value == 'descendingCategories'){
      this.filter.columnOrder = 'categories';
    }

    if (value == 'total_unique_views') {
      this.filter.columnOrder = value;
    }

    clearTimeout(this.nextRequestSearch); 
    this.nextRequestSearch = setTimeout(async ()=> {
      await this.getMedias(false);
    }, 1000);
  }

  onSearch(searchTextValue){
    this.mediaSearchSubject.next(searchTextValue);
  }

  getInitials(string) {
    let names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  async onScroll() {
    let blockScroll:boolean = this.blockScroll;
    
    if(!blockScroll && !this.moreLoading) {
      await this.getMedias(true);
    }
  }

  async changeDate(event, value) {
    setTimeout(async () => {
      if(this.endDateRange && this.startDateRange) {
        this.endDateRange = moment(this.endDateRange).endOf("day").toDate();
        this.startDateRange = moment(this.startDateRange).startOf("day").toDate();

        clearTimeout(this.nextRequestSearch); 
        this.nextRequestSearch = setTimeout(async ()=> {
          await this.getMedias(false);
        }, 1000);
        if(!this.data.length) {
          this.noMedias = true;
        }
      }
    }, 400);
  }

  async openFilterModal() {
    const dialogRef = this.dialog.open(FiltersComponent, {
      panelClass: "dialog-modal",
      data: {
        simpleFilter: this.type,
        filters: {
          searchValue: this.searchValue,
          endDateRange: this.endDateRange,
          startDateRange: this.startDateRange,
          orderBy: this.filter.order == 'ASC' ? 'ascendingCharacters' : 'descendingCharacters'
        }
      },
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if(result) {
      this.filterActive = true;

      this.searchValue = result.searchValue;
      this.endDateRange = result.endDateRange;
      this.startDateRange = result.startDateRange;

      if (this.type != 'recent-videos') {
        this.filter.columnOrder = 'title';
      }

      if (result.orderBy == 'ascendingCharacters') {
        this.filter.order = 'ASC';
      } 
      else {
        this.filter.order = 'DESC';
      }

      if (result.orderBy == 'total_unique_views') {
        this.filter.columnOrder = result.orderBy;
      }

      clearTimeout(this.nextRequestSearch); 
      this.nextRequestSearch = setTimeout(async ()=> {
        await this.getMedias(false);
      }, 1000);

      if(result.reset) {
        this.filterActive = false;
      }
    }
  }

  goToPath(path) {
    this.router.navigateByUrl(path);
  }
}
