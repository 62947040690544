<div class="content">
  <div class="panel">
    <p>{{'about-us.title' | translate}}</p>
  </div>
  <div class="channel-info">
    <ng-container *ngIf="!loading">
      <div class="channel-contact-info">
        <h1 class="text-who-are-we">{{'about-us.who-are-we' | translate}}</h1>
        <span class="email">{{data?.email}}</span>
        <span class="text-contact-info">{{'about-us.email' | translate}}</span>
        <span class="homepage"><a [href]="data?.website" target="_blank">{{data?.website}}</a></span>
        <span class="text-contact-info">{{'about-us.homepage' | translate}}</span>
        <span class="phone">{{data?.phone}}</span>
        <span class="text-contact-info">{{'about-us.phone-number' | translate}}</span>
      </div>
      <div class="channel-about-message">{{data?.about_us}}</div>
    </ng-container>

    <div class="example-loading-shade" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    
  </div>
</div>