import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { CategoryService } from 'src/app/category.service';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FiltersComponent } from 'src/app/filters/filters.component';
import { CategoriesService } from '../categories.service';
import * as _ from 'lodash';
import { MatTabGroup } from '@angular/material/tabs';

import { SnackAdsService } from 'src/app/snack-ads.service';
import { ChannelService } from 'src/app/services/channel.service';


@Component({
  selector: 'app-category-content',
  templateUrl: './category-content.component.html',
  styleUrls: ['./category-content.component.scss']
})
export class CategoryContentComponent implements OnInit {
  private mediaSearchSubject: Subject<string> = new Subject();
  private mediaSearchSubscription: Subscription;

  @ViewChild('mainTabGroup', {static: false}) mainTabGroup: MatTabGroup;

  categorySlug: string;
  infoData: any;
  loading: boolean = false;

  limit: number = 15;

  allPage: number = 0;
  videosPage: number = 0;
  livePage: number = 0;

  currentTab: string = "all";
  selectedTab: number = 0;

  allBlockScroll: boolean = false;
  liveBlockScroll: boolean = false;
  videosBlockScroll: boolean = false;

  noMedias: boolean = false;

  orderBy: string = 'recent';

  allMedias: any = [];
  liveMedias: any = [];
  videoMedias: any = [];

  allLoading: boolean = false;
  livemachtesLoading: boolean = false;
  videosLoading: boolean = false;

  firstLoading: boolean;
  isMobile: boolean = false;
  onlyVideo : boolean = false;

  categoryInitials: string;

  path: any = [];

  notFound: boolean = false;
  routeSubscription: Subscription;
  subCategories: any = [];

  subCategoriesSelected: any = [];
  popularSubCategories: any = [];
  isSubCategory: boolean = false;

  startDateRange: Date = null;
  endDateRange: Date = null;

  searchValue: string = "";

  isTagContext: boolean = false;
  adblockActivated: boolean = false;

  filterActive: boolean = false;

  fullWidth: boolean = false;
  tagSlug: string;
  tagInitials: any;

  channel: any;

  constructor(
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private router: Router,
    private dialog: MatDialog,
    private categoriesService: CategoriesService,
    private snackAdsService : SnackAdsService,
    private channelService : ChannelService

  ) { }

  async ngOnInit() {

    this.channel = await this.channelService.getCurrentChannel();
    this.loadAds();
    this.mediaSearchSubscription = this.mediaSearchSubject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {
      this.search(searchTextValue);
    });

    this.routeSubscription = this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        this.currentTab = "all";
        this.subCategories = [];
        this.subCategoriesSelected = [];
        this.popularSubCategories = [];

        this.getAllData();
        if(!this.isTagContext) {
          this.getPopularSubCategories();
        }
        
      }
    });

    if(window.innerWidth <= 768) {
      this.isMobile = true;
      this.fullWidth = true;
    }

    if(!(<any>window).canRunAds){
      this.adblockActivated = true;
    }

    this.getAllData();

    if(!this.isTagContext) {
      this.getPopularSubCategories();
    }

  }

  ngOnDestroy() {
    if(this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.mediaSearchSubscription) {
      this.mediaSearchSubscription.unsubscribe();
    }
  }

  async search(searchTextValue){
    this.searchValue = searchTextValue;
    this.currentTab = "all";

    await this.getMedias(false);
    if(!this.allMedias.length && !this.videoMedias.length && !this.liveMedias.length) {
      this.noMedias = true;
    }
  }


  async getAllData() {

    this.categorySlug = this.route.snapshot.paramMap.get('categorySlug');

    if(!this.categorySlug) {
      this.tagSlug = this.route.snapshot.paramMap.get('tagSlug');
      this.isTagContext = true;
    }

    await this.getInfoDetails();

    if(!this.isTagContext) {

      let categoryPath = {slug: this.categorySlug, name: this.infoData.category_name};
      let index = _.findIndex(this.path, categoryPath);

      if(index >= 0) {
        this.path = this.path.slice(0, index + 1)
      } else {
        this.path.push(categoryPath);
      }

      await this.getSubcategories();

      if(!this.noMedias) {
        this.firstLoading = true;
        await this.getMedias(false);
        this.firstLoading = false;
      }
    } else {

      if(!this.noMedias) {
        this.firstLoading = true;
        await this.getMedias(false);
        this.firstLoading = false;
      }

    }


  }

  async getSubcategories() {
    this.subCategories = await this.categoryService.getSubcategoriesFromCategory(this.categorySlug);
  }

  async getInfoDetails() {
    this.noMedias = false;

    this.loading = true;

    if(!this.isTagContext) {
      this.infoData = await this.categoryService.getCategoryDataBySlug(this.categorySlug);
    } else {
      this.tagInitials = this.categoryService.getInitials(this.tagSlug);
      this.infoData = await this.categoryService.getTagyDataBySlug(this.tagSlug);
    }

    if(!this.isTagContext && !this.infoData.path_thumbnail) {
      this.categoryInitials = this.categoryService.getInitials(this.categorySlug);
    }


    if(this.infoData.videos == 0) {
      this.noMedias = true;
    }

    this.loading = false;
  }

  async getMedias(onScroll: boolean, changeTab: boolean = false) {
    if(!onScroll) {
      this.allPage = 0;
      this.livePage = 0;
      this.videosPage = 0;

      this.allBlockScroll = false;
      this.liveBlockScroll = false;
      this.videosBlockScroll = false;

      this.noMedias = false;

      this.allMedias = [];
      this.liveMedias = [];
      this.videoMedias = [];
    } else if(!changeTab) {
      this[`${this.currentTab}Page`] += this.limit;
    }

    let filters: any = {
      displayType: this.currentTab,
      offset: this[`${this.currentTab}Page`],
      limit: this.limit,
      sub_categories_slug: this.subCategoriesSelected.length ? JSON.stringify(this.subCategoriesSelected) : [],
      start_date: this.startDateRange ? moment(this.startDateRange).startOf('day').toISOString() : null,
      end_date: this.endDateRange ? moment(this.endDateRange).endOf('day').toISOString() : null,
      search: this.searchValue,
      orderBy: this.orderBy
    }


    this.allLoading = true;
    this.livemachtesLoading = true;
    this.videosLoading = true;
    let medias: any;

    if(!this.isTagContext) {
      filters.slug = this.categorySlug;
      if(this.infoData && this.infoData.id){
        filters.category_id = this.infoData.id;
      }

      medias = await this.categoryService.getMediasFromCategory(filters);
    } else {
      filters.slug = this.tagSlug;
      medias = await this.categoryService.getMediasFromTag(filters);
    }
    this.allLoading = false;
    this.livemachtesLoading = false;
    this.videosLoading = false;


    if(!medias.length && !onScroll) {
      this.noMedias = true;
    }

    if(!medias || medias.length == 1 || medias.length < this.limit) {
      this[`${this.currentTab}BlockScroll`] = true;
    }

    if(!medias){
      medias = [];
    }

    const existLiveOnline = medias.find(media => media.online);

    if(!!existLiveOnline) {
      this.onlyVideo = false;
    }

    if(this.currentTab == 'all') {
      this.selectedTab = 0;
    }

    if(this.currentTab == 'all' && !existLiveOnline ) {
      this.onlyVideo = true;
      this.selectedTab = 2;
      //resize event to update mat-tab's mat-ink-bar
      this.mainTabGroup.realignInkBar();
      this.currentTab = 'videos';
    }

    switch (this.currentTab) {
      case "all":
        this.allMedias = this.allMedias.concat(medias);
        break;
      case "live":
        this.liveMedias = this.liveMedias.concat(medias);
        break;
      case "videos":
        this.videoMedias = this.videoMedias.concat(medias);
        break;
    }

  }

  goToPath(path) {
    this.router.navigateByUrl(path);
  }

  goToPathCategory(slug) {
    this.router.navigateByUrl(`/categories/${slug}`);
  }

  redirectCategory(category) {
    if(category.subchannel_id && category.subchannel_url) {
      window.open(category.subchannel_url, '_blank');
      return;
    }

    this.goToPath('categories/' + category.category_slug)
  }

  setCurrentTab(tab) {
    let blockScroll: boolean;
    switch (tab.index) {
      case 0:
        this.currentTab = 'all';
        blockScroll = this.allBlockScroll;
        break;
      case 1:
        if(!this.onlyVideo) {
          this.currentTab = 'live';
          blockScroll = this.liveBlockScroll;
        } else {
          this.currentTab = 'videos';
          blockScroll = this.videosBlockScroll;
        }
        break;
      case 2:
        this.currentTab = 'videos';
        blockScroll = this.videosBlockScroll;
        break;
    }

    if(!blockScroll) {
      this.getMedias(false, true);
    }
  }

  async onScroll() {
    let blockScroll:boolean = this[`${this.currentTab}BlockScroll`];

    if(!blockScroll && !(this.allLoading || this.livemachtesLoading || this.videosLoading) ) {
      await this.getMedias(true);
    }
  }

  async onFilter(value) {
    this.subCategoriesSelected = value;
    this.currentTab = "all";
    if(!(this.allLoading || this.livemachtesLoading || this.videosLoading)) {
      await this.getMedias(false);
    }
  }

  async changeDate(event, value) {
    setTimeout(async () => {
      if(this.endDateRange && this.startDateRange) {
        this.endDateRange = moment(this.endDateRange).endOf("day").toDate();
        this.startDateRange = moment(this.startDateRange).startOf("day").toDate();
        this.currentTab = "all";

        await this.getMedias(false);
        if(!this.allMedias.length && !this.videoMedias.length && !this.liveMedias.length) {
          this.noMedias = true;
        }
      }
    }, 400);
  }


  onSearch(searchTextValue){
    this.mediaSearchSubject.next(searchTextValue);
  }

  async onSort(value: string) {
    this.orderBy = value;
    this.currentTab = "all";
    await this.getMedias(false);
  }

  async openFilterModal() {
    const dialogRef = this.dialog.open(FiltersComponent, {
      panelClass: "dialog-modal",
      data: {
        category: this.infoData,
        subCategories: this.subCategories,
        filters: {
          searchValue: this.searchValue,
          endDateRange: this.endDateRange,
          startDateRange: this.startDateRange,
          subCategories: this.subCategoriesSelected,
          orderBy: this.orderBy,
        }
      },
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();

    this.currentTab = "all";

    if(result) {
      this.filterActive = true;

      this.searchValue = result.searchValue;
      this.endDateRange = result.endDateRange;
      this.startDateRange = result.startDateRange;
      this.subCategoriesSelected = result.subCategories;
      this.orderBy = result.orderBy;

      await this.getMedias(false);

      if(result.reset) {
        this.filterActive = false;
      }
    }
  }

  async getPopularSubCategories() {
    let limit = this.isMobile ? 6 : 12;
    this.popularSubCategories = await this.categoriesService.getPopularSubCategories(this.categorySlug, limit);
  }

  getCategorySlug(value) {
    return this.categoryService.getInitials(value);
  }

  loadAds(){
    setTimeout(()=>{
      this.snackAdsService.setupCategoriesAds();
    }, 2000);
  }

}
