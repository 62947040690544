import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

interface Viewer {
  full_name? : string,
  email? : string,
  password? : string,
  terms? : string,
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient, private authService : AuthService) { }

  async createViewer(viewer: Viewer){

    const lang = localStorage.getItem('lang')  || 'en';

    const headers = new HttpHeaders({
      'Accept-Language': lang,
      // "Domain-Channel": window.location.href.includes('localhost') ? `${environment.domainChannel}` : ''
        "Domain-Channel": `${environment.domainChannel}`
    });

    try {
      let response: any = await this.httpClient.post(`${environment.api_vms_url}/create-viewer`, viewer, {headers}).toPromise();
      if (response.error) {
        return response;
      }
      await this.authService.setToken(response.token);
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      console.log('>>> createViewer error', error.error.errorMessage);
      return error.error;
    }
  }

}
