<div class='main-container'>
  <div class="example-loading-shade" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class='header' id="header" *ngIf="!loading">
    <div class='color'></div>
    <div class="left">
      <div class="no-image" [ngStyle]="{'background' : type == 'live' ? '#E91916' : '#666666'}">{{seeAllInitials}}</div>
      <div class='info'>
        <p class='path'><span (click)="goToPath('')">Home</span> > <span>{{type}}</span></p>
        <p class='name'>{{type == 'live' ? 'Live now' : type}}</p>
      </div>
    </div>
  </div>

  <div class='filter-background' *ngIf="!loading">
    <div class='filter'>
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <input matInput type="text" placeholder="Find Your Video / Event" (keyup)="onSearch($event.target.value)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <mat-form-field class='date-filter' appearance="standard" *ngIf="type == 'recent-videos'">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input
          [rangePicker]="campaignOnePicker"
          [comparisonStart]="startDateRange"
          [comparisonEnd]="endDateRange">
          <input matStartDate placeholder="Start date" readonly [(ngModel)]="startDateRange">
          <input matEndDate placeholder="End date" readonly [(ngModel)]="endDateRange" (dateChange)="changeDate('change', $event)">
        </mat-date-range-input>
        <mat-datepicker-toggle disabled="false" matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
        <mat-date-range-picker disabled="false" #campaignOnePicker></mat-date-range-picker>
      </mat-form-field>
      
      <mat-form-field class='date-filter' appearance="standard" *ngIf="type != 'recent-videos'">
        <mat-label>Sort by</mat-label>
        <mat-select [(value)]='filterSelected' (selectionChange)="onFilter($event.value)" disableOptionCentering>
          <mat-option *ngIf="type == 'live'" value="mostSeen">Most Viewed</mat-option>
          <mat-option value="ascendingCharacters">A to Z</mat-option>
          <mat-option value="descendingCharacters">Z to A</mat-option>
          <mat-option *ngIf="type != 'most-viewed'" value="ascendingCategories">Sort By Category (ascending)</mat-option>
          <mat-option *ngIf="type != 'most-viewed'" value="descendingCategories">Sort By Category (descending)</mat-option>
          <mat-option *ngIf="type == 'most-viewed'" value="total_unique_views">Most Viewed</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class='open-filter' [ngClass]="{'active': filterActive}" *ngIf="!loading">
    <mat-icon (click)="openFilterModal()">filter_list</mat-icon>
  </div>

  <div class='content' *ngIf="(!loading && !noMedias) || isMobile">
    <mat-tab-group  mat-align-tabs="start" class='category-tab'>
      <mat-tab [label]="type == 'live' ? 'Live Now' : 'Videos'">
        <div class='videos'>
          <div class='video'  *ngFor="let video of data" >
            <app-thumbnail-video [hideLiveViewers]="channel.hide_live_viewers" [fullWidth]="fullWidth" [videoData]="video"></app-thumbnail-video>
          </div>
        </div>
        <div
          class="search-results"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
        ></div>
        <ng-container>
          <div class="more-loading" *ngIf="moreLoading">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class='no-content' *ngIf="!moreLoading && !data.length">
    No media to show
  </div>
</div>