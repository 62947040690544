import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from "@angular/router";

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RefundComponent } from './payment/refund/refund.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginComponent } from './login/login.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SignupComponent } from './signup/signup.component';
import { MainComponent } from './main/main.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatRadioModule } from '@angular/material/radio';
import { LanguageComponent } from './language/language.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GroupVideosComponent } from './group-videos/group-videos.component';
import { ThumbnailVideoComponent } from './group-videos/thumbnail-video/thumbnail-video.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VideoComponent } from './video/video.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CategoryComponent } from './category/category.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SubscriptionsComponent } from './main/subscriptions/subscriptions.component';
import { MatSelectModule}  from '@angular/material/select';
import { MatTabsModule}  from '@angular/material/tabs';
import { HomepageComponent } from './main/homepage/homepage.component';
import { SubscriptionContentComponent } from './main/subscriptions/subscription-content/subscription-content.component';
import { MatMenuModule } from '@angular/material/menu';
import { CancelRenewSubscriptionComponent } from './main/subscriptions/subscription-content/cancel-renew-subscription/cancel-renew-subscription.component';
import { ScheduleComponent } from './main/schedule/schedule.component';
import { CategoriesComponent } from './main/categories/categories.component';
import { SearchComponent } from './main/search/search.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CategoryContentComponent } from './main/categories/category-content/category-content.component';
import { PaymentComponent } from './payment/payment.component';
import { FiltersComponent } from './filters/filters.component';
import { VideoContentComponent } from './main/video/video-content/video-content.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { ProfileComponent } from './main/profile/profile.component';
import { OverviewComponent } from './main/profile/overview/overview.component';
import { ShareVideoComponent } from './main/video/share-video/share-video.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SubscriptionManagerComponent } from './main/profile/subscription-manager/subscription-manager.component';
import { UnlockVideosEditorBundlesComponent } from './main/profile/subscription-manager/unlock-videos-editor-bundles/unlock-videos-editor-bundles.component';
import { UnlockVideosLivesStreamsComponent } from './main/profile/subscription-manager/unlock-videos-lives-streams/unlock-videos-lives-streams.component';
import { UserSubscriptionsComponent } from './main/profile/subscription-manager/user-subscriptions/user-subscriptions.component';
import { VideosManagerComponent } from './main/profile/videos-manager/videos-manager.component';
import { UserVideosComponent } from './main/profile/videos-manager/user-videos/user-videos.component';
import { FavoritedVideosComponent } from './main/profile/videos-manager/favorited-videos/favorited-videos.component';
import { AboutUsComponent } from './main/about-us/about-us.component';
import { CookiePolicyComponent } from './main/cookie-policy/cookie-policy.component';
import { TvAppsComponent } from './main/tv-apps/tv-apps.component';
import { UploadVideoComponent } from './main/upload-video/upload-video.component';
import { RequestHelpComponent } from './main/request-help/request-help.component';
import { NgxCaptchaModule } from "ngx-captcha";
import { PrivacyPolicyComponent } from './main/privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './main/terms-and-condition/terms-and-condition.component';
import { DisqusComponent } from './disqus/disqus.component';
import { SeeAllComponent } from './main/seeAll/seeAll.component';
import { DateTimezonePipe } from './pipes/date-timezone.pipe';
import { CategoriesDialogComponent } from './main/categories/categories-dialog/categories-dialog.component';
import * as Sentry from "@sentry/angular";
import { UploadFileDirective } from './main/upload-video/upload-file/upload-file.directive';
import { ImageViewerComponent } from './main/video/video-content/image-viewer/image-viewer.component';
import { RecommendedVideosComponent } from './main/profile/videos-manager/recommended-videos/recommended-videos.component';
import { SharedModule } from './shared/shared.module';
import { CartComponent } from './main/cart/cart.component';
import { ClipExtractorComponent } from './main/video/clip-extractor/clip-extractor.component';
import { MyClipsComponent } from './main/profile/videos-manager/my-clips/my-clips.component';
import { DiscardClipComponent } from './main/video/discard-clip/discard-clip.component';
import { TaggingComponent } from './main/video/tagging/tagging.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AdsTesterComponent } from './ads-tester/ads-tester.component';
import { SnackPlayerComponent } from './snack-player/snack-player.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    RefundComponent,
    LoginComponent,
    SignupComponent,
    MainComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LanguageComponent,
    GroupVideosComponent,
    ThumbnailVideoComponent,
    VideoComponent,
    CategoryComponent,
    SubscriptionsComponent,
    HomepageComponent,
    SubscriptionContentComponent,
    CancelRenewSubscriptionComponent,
    ScheduleComponent,
    CategoriesComponent,
    SearchComponent,
    CategoryContentComponent,
    PaymentComponent,
    FiltersComponent,
    VideoContentComponent,
    VideoPlayerComponent,
    ProfileComponent,
    OverviewComponent,
    ShareVideoComponent,
    SubscriptionManagerComponent,
    UnlockVideosEditorBundlesComponent,
    UnlockVideosLivesStreamsComponent,
    UserSubscriptionsComponent,
    VideosManagerComponent,
    UserVideosComponent,
    FavoritedVideosComponent,
    AboutUsComponent,
    CookiePolicyComponent,
    TvAppsComponent,
    UploadVideoComponent,
    RequestHelpComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    DisqusComponent,
    SeeAllComponent,
    DateTimezonePipe,
    CategoriesDialogComponent,
    UploadFileDirective,
    ImageViewerComponent,
    RecommendedVideosComponent,
    CartComponent,
    ClipExtractorComponent,
    MyClipsComponent,
    DiscardClipComponent,
    TaggingComponent,
    AdsTesterComponent,
    SnackPlayerComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatStepperModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    RouterModule,
    CarouselModule,
    MatTooltipModule,
    InfiniteScrollModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ClipboardModule,
    NgxCaptchaModule,
    MatExpansionModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    HttpClient,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
