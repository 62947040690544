
<div class="inp-dv">
	<mat-icon *ngIf="isWarning && inpMode == 'end'">warning</mat-icon>
	<input type="text" [(ngModel)]="startTime" (change)="setTime($event,'left')" (keyup)="checkError($event,'start')" placeholder="Start Time">
	<input type="text" [(ngModel)]="endTime" (change)="setTime($event,'right')" (keyup)="checkError($event,'end')" placeholder="End Time">
	<mat-icon *ngIf="isWarning && inpMode == 'start'" class="icon-right">warning</mat-icon>
</div>


<div class="slider-section">
	<div class="timeline-container" #timelineContainer>
		<canvas #timelineCanvas></canvas>
	</div>
	<div class="drag-slider" #draggableDiv [style.width.px]="sliderWidth" [style.left.px]="sliderLeft">
		<div class="time-mark" [style.left.px]="markLeft"></div>
		<div class="handle left" (pointerdown)="startResize($event, 'left')"></div>
		<div class="handle right"  (pointerdown)="startResize($event, 'right')"></div>
	</div>
</div>