<div *ngIf="!downloadAvaliable" class='block-download-message'>
  <ng-container>  
    <p class='title'>{{'video.download-media' | translate}} ${{mediaData.price}}</p>
    <div class='button' (click)="getSubscription()">
      {{'video.download-now' | translate}}
    </div>

    <div class='button cart' (click)="addToCartClicked(mediaData)">
      <img class="cart-logo" src='../../assets/icons/cart.svg'>
      {{'video.add-to-cart' | translate}}
    </div>
  </ng-container>
</div>
<div *ngIf="downloadAvaliable">
  <a mat-menu-item *ngFor="let videoFile of mediaData.videoFiles" download (click)="incrementDownloadCounter(videoFile)">{{ videoFile.name }}</a>
</div>