import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  channel : any = null;

  constructor(private httpClient: HttpClient, private authService : AuthService) { }

  async getChannelBrand() {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-channel-branded`, {headers}).toPromise();
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getChannelAboutUs() {
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-channel-about-us`, {headers}).toPromise();
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getChannelTvApps(){
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-channel-tv-apps`, {headers}).toPromise();
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getCurrentChannel(){
    if(this.channel){
      return this.channel;
    }
    try {
      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-current-channel`, {headers}).toPromise();
      this.channel = response;
      return response;
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }

  async getCreateChannelUrl() {
    const channel = await this.getCurrentChannel();
    const hostName = channel.host_name;
    return `${environment.createChannelUrl}?origin=${hostName}`;
  }

  async allowCreateChildSites() {
    const channel = await this.getCurrentChannel();
    return channel.allow_create_child_sites && !channel.parent_id;
  }
}
