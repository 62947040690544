import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TaggingService } from 'src/app/services/tagging.service';

@Component({
  selector: 'app-tagging',
  templateUrl: './tagging.component.html',
  styleUrls: ['./tagging.component.scss']
})
export class TaggingComponent implements OnInit {

  @Input() gamePlays;
  @Input() containerHeight;
  expandedPanels: boolean[];
  playsHeight: number;

  constructor(private taggingService: TaggingService) { }

  ngOnInit() {
    // alert(this.containerHeight);
    
    
    this.expandedPanels = this.gamePlays.map((_, index) => index === 0); // Set the first panel to be expanded by default
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['containerHeight']) {
   
      this.playsHeight = this.containerHeight - (48*this.gamePlays.length)

    }
  }

  togglePanel(index: number) {
    this.expandedPanels = this.expandedPanels.map((_, i) => i === index);
  }

  jumpToVideo(timestampUtc: number) {
    this.taggingService.seekToDuration(timestampUtc);
  }


}
