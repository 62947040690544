import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface GamePlay {
  utcStart: string;
}

@Injectable({
  providedIn: 'root'
})
export class TaggingService {

  private seekTime = new BehaviorSubject<number>(0);
  seekToTime$ = this.seekTime.asObservable();

  constructor(private httpClient: HttpClient, private authService : AuthService) { }

  seekToDuration(duration: number) {
    this.seekTime.next(duration);
  }

  async getGamePlays(mediaId:string){
    try {
      const headers = await this.authService.getHeadersSession();
      var res: any = await this.httpClient.get(`${environment.api_vms_url}/tagging?media_id=${mediaId}`, {headers}).toPromise();

      var response = res.media_stats;
      response.sort((a: GamePlay, b: GamePlay) => {
        return new Date(a.utcStart).getTime() - new Date(b.utcStart).getTime();
      });

      return response;
    } catch (error) {

      return {error: true, code: error.status, errorMessage: error.error.errorMessage};
    }
  }

}
