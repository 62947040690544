<div class="video" [ngClass]="{'layout' : layout}">

  <a class="thumb" (click)='goToVideo($event)' href="{{mediaLink}}" (mouseout)="mouseOut()" (mouseover)="mouseOver()" [ngClass]="{'border' : !layout, 'layout' : layout}" [title]="data.title">
    <div class="category" *ngIf="categories.length">
      <ng-container *ngIf='categories.length <= 3 && !isMobile'>
        <div style='display: flex;'>
          <a class="category-image" (mouseenter)='checkOpacityFirstCategory(i)' (mouseleave)='checkOpacityFirstCategory(0)' [ngClass]='{"first": i == 0 && highlightFirstCategory}' (click)="goToCategoryDetails(category)" target="_blank" *ngFor='let category of categories; let i = index;' matTooltipClass="tooltip-class" [matTooltip]="category.category_name" matTooltipPosition="above">
            <div *ngIf="category.category_thumbnail_url" class="image" [ngStyle]="{'background-image': 'url(' + category.category_thumbnail_url + ')'}"></div>
            <div *ngIf="!category.category_thumbnail_url " class="no-image">{{category.categorySlug}}</div>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf='categories.length > 3 || isMobile'>
        <div class="no-image categories-length" (click)='openCategoriesModal()'>{{categories.length + (categories.length > 1 ? ' Categories' : ' Category')}}</div>
      </ng-container>
    </div>

    <div class='over' [ngClass]="{'layout' : layout}">
      <img class="video-content" #refEl [src]="data.thumbnail_url" onerror="this.src='../../assets/imgs/prism.png'" alt="" [ngClass]="{'layout' : layout}">
      <ng-container *ngIf='data.type != "EVENT"'>
        <div class='cut'  *ngIf="data.is_video_editor && (data.isPaid || isAdmin || (data.isFree && isAdmin) ) && data.source != 'CLIP' && user" (click)="clipVideo()" matTooltipClass="tooltip-class" matTooltip="Video cutter / Clip Extraction" matTooltipPosition="above"><mat-icon>movie</mat-icon></div>
        <div class='edit' *ngIf="data.is_video_editor && isAdmin" (click)="editVideo()" matTooltipClass="tooltip-class" matTooltip="Edit Video" matTooltipPosition="above"><mat-icon>edit</mat-icon></div>
        <div *ngIf='data.isSticky' class='sticky'>Pinned</div>
      </ng-container>
  
      <img class="video-player" src="../../assets/icons/md-play-circle.svg" alt="">

      <div class="sized-div">
        <div class="title-sized" *ngIf="!layout">{{data.title}}</div>
    
        <div class="tags-sized" *ngIf="!layout">
          <a *ngFor="let tag of data?.tags" class="tag">{{tag?.tag_name}}</a >
        </div>
      </div>
      
      <span class="status">
        <span class="type" *ngIf="data.type == 'EVENT' && data.online">{{'video.live' | translate}}</span>
        <span class="type" *ngIf="data.type == 'EVENT' && !data.online">{{'video.offline' | translate}}</span>
        <span class="type duration" *ngIf="data.duration && data.type != 'EVENT'">{{data.duration}}</span>
        <span *ngIf='data.type == "EVENT" && data.online == true' [ngStyle]="{'left': data.online ? '48px;' : '65px' }" class='viewers'>{{data.subscribers ? data.subscribers : '0'}} 
          <span *ngIf="data.subscribers > 1">{{'video.viewers' | translate}}</span>
          <span *ngIf="data.subscribers == 0 || data.subscribers == 1">{{'video.viewer' | translate}}</span>
        </span>
      </span>
    </div>
  </a>
  
  <a class="info" (click)='goToVideo($event)' [ngClass]="{'layout' : layout}" href="{{mediaLink}}">
    <div class="title">{{data.title}}</div>

    <div class='description' *ngIf="data.description">
      <p>{{data?.description}}</p>
    </div>

    <div id="tags" [ngClass]="{'expanded' : isExpanded}">
      <a [routerLink]="['/tag/' + tag.tag_slug]" *ngFor="let tag of data.tags" class="tag" [ngClass]="{'expanded' : isExpanded}">{{tag.tag_name}}</a >
    </div>

    <span class="tags-count" *ngIf="!isExpanded && data.tagsCount > 4" (click)="toggleTags()">+{{data.tagsCount - 4}} {{'video.tags' | translate}}</span>
    <span class="tags-count" *ngIf="isExpanded" (click)="toggleTags()">{{'video.show-less' | translate}}</span>

  </a>

</div>