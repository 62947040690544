import { AfterViewInit, Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { AuthService } from 'src/app/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/login/login.component';
import { VideoService } from '../video.service';
import { VideosService } from 'src/app/services/videos.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { ShareVideoComponent } from '../share-video/share-video.component';
import { DiscardClipComponent } from '../discard-clip/discard-clip.component';
import { SubscriptionService } from 'src/app/subscription.service';
import { Subscription, timer  } from 'rxjs';
import { filter, retryWhen, delayWhen, take } from 'rxjs/operators';
import { delay } from 'rxjs/internal/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';

//import { webSocket } from "rxjs/webSocket";
import { environment } from 'src/environments/environment';

import { CategoryService } from 'src/app/category.service';
import { AnalyticsService } from 'src/app/analytics.service';
import { CategoriesDialogComponent } from '../../categories/categories-dialog/categories-dialog.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { SignupComponent } from 'src/app/signup/signup.component';
import { ForgotPasswordComponent } from 'src/app/forgot-password/forgot-password.component';
import { ChannelService } from 'src/app/services/channel.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SnackAdsService } from 'src/app/snack-ads.service';


import * as ws from 'src/app/shared/websockets/websockets';
import { CartService } from '../../cart/cart.service';

import { ExtractionService } from 'src/app/services/extraction.service';
import { TaggingService } from 'src/app/services/tagging.service';

@Component({
  selector: 'app-video-content',
  templateUrl: './video-content.component.html',
  styleUrls: ['./video-content.component.scss']
})
export class VideoContentComponent implements OnInit {
  @ViewChild('videoContainer') videoContainer!: ElementRef;
  public player;
  containerHeight:number;
  isFavoriteMedia: boolean = false;
  showMoreFlag: boolean = false;
  isExpanded: boolean = false;
  highlightFirstCategory: boolean = true;
  showPhotos: boolean = false;



  mediaData: any;
  mediaSlug: string = "";
  mediaAvailable: boolean = false;
  showDownloadButton: boolean = false;

  loading: boolean = true;
  formLoading: boolean = false;
  isMobile: boolean = false;

  limitTags: number = 15;
  limitTagsMobile: number = 3;

  isToday: boolean = true;
  countDownText: string = "";

  isAdsEnabled:boolean = true;

  user: any;
  channel: any;
  hostName: any;
  mediaType: string = "";

  tags: any = [];
  tagsToShow: any = [];
  isLive: boolean;
  isAdmin: boolean = false;
  adblockActivated: boolean = false;
  subscribers: number;
  lives: any;
  relatedVideos: any;
  routeSubscription: Subscription
  viewerCounterSubscription: Subscription;

  isClipMode:boolean = false;
  isClipVideo:boolean = true;

  form: FormGroup;
  formSubmitted:boolean = false;
  isVideoLoaded:boolean = false;
  isAdPlaying:boolean = false;

  minVideoDuration:number = 300;
  // minVideoDuration:number = 5;

  clipBtnTitle:string = "";
  isNewHomepageUrl = /(onlysports\.live\/|usssalive\.com|iswim.tv|vewbie.com\/channel)/.test(window.location.href);
  redirectLocationHref = window.location.protocol + '//' + window.location.host + '/channel';

  inputError:boolean = false;
  btnDisabled:boolean = true;

  gamePlays: any = [];
  isPlaysMode:boolean = false;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private videoService: VideosService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private snackbar: MatSnackBar,
    private categoryService: CategoryService,
    private analyticsService : AnalyticsService,
    private channelService : ChannelService,
    private sanitized: DomSanitizer,
    private cartService: CartService,
    private extractionService: ExtractionService,
    private taggingService: TaggingService,
    private snackAdsService : SnackAdsService
  ) {

    this.isVideoLoaded = false;

    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)).subscribe(async () => {
        this.loadAds();
        this.getAllData();
        this.disableClipExtraction();
    });

    this.form = new FormGroup({
      title: new FormControl('', [Validators.required])
    });
  }

  validate(event:any) {
    if (event.target.value) {
      this.inputError = false;
      this.btnDisabled = false;
    } else {
      this.inputError = true;
      this.btnDisabled = true;
    }
  }

  ngOnDestroy(){
    if(this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if(this.viewerCounterSubscription) {
      this.viewerCounterSubscription.unsubscribe();
    }
  }

  async getCurrentChannel(){
    this.channel = await this.channelService.getCurrentChannel();
    this.hostName = this.channel?.host_name;

    if(!this.adblockActivated){
      this.adblockActivated = this.hostName != 'venkatesh' && this.hostName != 'usssa' && this.hostName != 'devmagic' && this.hostName != 'iswimtv'; //temporary condition
    }
  }

  enableClipExtraction() {

    this.isClipMode = true;
    this.extractionService.enableClipMode(true);
    this.extractionService.enableMarkers(true);
    this.extractionService.showControls(true);

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";

    this.extractionService.currentTime$.subscribe((time) => {
      this.extractionService.scrollTimeline(time);
    });
  }

  disableClipExtraction() {
    this.extractionService.enableClipMode(false);
    this.isClipMode = false;
    document.body.style.height = "auto";
    document.body.style.overflow = "auto";
  }

  async ngOnInit() {

    (window as any).setWssTime = this.setWssTime.bind(this);

    if(window.innerWidth <= 768) {
      this.isMobile = true;
    }

    if(!(<any>window).canRunAds){
      this.adblockActivated = true;
    }

    this.user = await this.authService.getSession();

    if(this.user && (this.user.user_admin_type == "ADMIN" || this.user.user_admin_type == "MANAGER")) {
      this.isAdmin = true;
    }

    this.extractionService.disableClip$.subscribe(() => {
      this.disableClipExtraction();
    });

    this.extractionService.totalVideoDuration$.subscribe((duration:boolean | number) => {
      if (duration == false) {
        this.isVideoLoaded = false;
        this.clipBtnTitle = "Clip extraction disabled due to video error";
      } else {
        if (duration > this.minVideoDuration) {
          this.isVideoLoaded = true;
          this.clipBtnTitle = "Clip Video";
        } else {
          this.isVideoLoaded = false;
        }
      }
    });

    this.extractionService.adStarted$.subscribe((adMode:boolean) => {
      this.isAdPlaying = adMode;
    });

    await this.verifyEmbed();

  }

  async getGamePlays(media_id:string) {

    var plays = await this.taggingService.getGamePlays(media_id);
    if (plays.length > 0) {
      this.isPlaysMode = true;
    }

    for (var i=0; i<plays.length; i++) {
      if (plays[i].utcEnd > this.mediaData.recording_end_utc_ts) {
        plays[i].utcEnd = this.mediaData.recording_end_utc_ts;
      }
    }
    
    this.gamePlays = plays;

  }

  reloadPage() {
    location.reload();
  };

  countLiveViewer() {
    try{
      if (environment.production && !this.channel.hide_live_viewers){
        let webSocketMediaId = this.mediaData.mediaId || '';
        webSocketMediaId = webSocketMediaId.replace(/-/g, '');

        initWebsocket(`${environment.pubsub_url}sub/${webSocketMediaId}`,null,5000,10,this).then((socket) => {}, function(){
          console.log('init of socket failed!');
        });
      }
    }
    catch(e){
      console.log("countLiveViewer", e);
    }
  }

  async verifyEmbed() {
    if(this.loading) {
      setTimeout(() => { this.verifyEmbed() },500);
    }else {
      let href = new URL(window.location.href);
      let isPayment = href.searchParams.get('payment');
      if(isPayment) {
        await this.getSubscription();
      }
    }
  }

  videoError(){
    this.openSnackBar(
      "Something went wrong, try again later!",
      "snack-bar-danger"
    );

    console.log("videoError  this.mediaType",  this.mediaType);
    console.log("videoError  this.mediaSlug",  this.mediaSlug);
    this.router.navigate(['']);
  }

  async getAllData() {
    this.loading = true;
    this.mediaSlug = this.route.snapshot.paramMap.get('mediaSlug');

    this.mediaType = this.router.url.split("/")[1];

    await this.getCurrentChannel();

    if(this.mediaType == 'live') {
      this.mediaData = await this.videoService.getLiveData(this.mediaSlug);

      if(!this.mediaData) {
        this.videoError();
        return
      }

      if (!this.mediaData.type) {
        this.mediaData.type = "EVENT";
      }
    } else {
      this.mediaData = await this.videoService.getVideoData(this.mediaSlug);

      this.setClipMode(this.mediaData.duration);

      if(!this.mediaData) {
        this.videoError();
        return
      }

      if (!this.mediaData.type) {
        this.mediaData.type = "VIDEO";
      }
    }

    if (this.mediaData.type == "EVENT") {
      this.countLiveViewer();

      this.isLive = true;
      this.limitTagsMobile = 2;
    } else {
      this.isLive = false;
    }

    if (this.user && (this.mediaData.isPaid || this.mediaData.isFree)) {
      this.getGamePlays(this.mediaData.mediaId);
    }

    if (this.mediaData.isFree) {
      this.isAdsEnabled = true;
    } else {
      this.isAdsEnabled = this.mediaData.isAds;
    }


    this.mediaData.description = this.sanitized.bypassSecurityTrustHtml(this.mediaData.description);

    this.mediaData.player_date = moment(this.mediaData.player_date).toDate();

    if(this.mediaData.statusCountDown) {
      this.countDownText =  moment(this.mediaData.player_date).fromNow();
    }

    // to ensure that a live outside of the broadcast time but online appears
    if(moment(this.mediaData.player_date).isAfter(moment()) && !this.mediaData.online) {
      this.isToday = false;
    }

    if(this.mediaData.categories?.length) { //new way to receive categories
      this.mediaData.categories.forEach(category => {
        if (!category.category_thumbnail_url && category.category_name) {
          category.categorySlug = this.categoryService.getInitials(category.category_name);
        }
      });
    }

    if (this.mediaData.photos && this.mediaData.photos.length) {
      this.showPhotos = true;
    }

    this.tags = _.cloneDeep(this.mediaData.mediaTags);

    this.tagsToShow = _.cloneDeep(this.tags).splice(0, this.limitTags);

    this.isMediaAvailable();
    this.isDownloadButtonAvaliable();

    let redirectToPurchase = await this.authService.getRedirectUserPurchase();

    if(!this.mediaAvailable && redirectToPurchase && this.user){
      await this.authService.setRedirectUserPurchase(false);
      await this.openSubscriptionsWindow();
    }

    this.loading = false;

    this.lives = await this.videoService.getAllLives(this.mediaData.stream);
    let filters: any = {};
    filters.videoId = this.mediaData.id;

    if(this.mediaData.categories.length) {
      filters.categories = JSON.stringify(this.mediaData.categories.map(category => category.category_slug));
    }

    this.relatedVideos = await this.videoService.getRelatedVideos(filters);

    if(this.isLive && !this.channel.hide_live_viewers) {
      this.subscribers = await this.videoService.getSubscribers(this.mediaData.mediaId);
    }
    else {
      this.subscribers = 0;
    }

    if(window.innerWidth <= 768) {
      this.containerHeight = 450;
    } else {
      this.containerHeight = this.videoContainer.nativeElement.offsetHeight; 
    }

  }

  setClipMode(duration:number) {
    if (duration > this.minVideoDuration) {
      this.isClipVideo = true;
      this.clipBtnTitle = "Video is not loaded yet. Please wait...";
    } else {
      this.isClipVideo = false;
    }
  }

  goToImageDetails(index){
    let photos = [];

    this.mediaData.photos.forEach(element => {
      photos.push({'url': element.photoUrl, 'description': element.description});
    });

    this.dialog.open(ImageViewerComponent, {
      panelClass: "image-viewer-dialog",
      data: {photos, index},
      autoFocus: false,
    });
  }

  preventSubmit(event: Event): void {
    event.preventDefault();
  }

  async createClip() {
    this.formSubmitted = true;
    if(!this.form.valid) {
      return;
    }

    this.formLoading = true;

    var startTime,duration;

    this.extractionService.videoTime$.subscribe(([newTime,newWidth]) => {
      startTime = newTime;
      duration = newWidth / 2;
    });


    var data = {
      "title" : this.form.value.title,
      "start_seconds" : Math.floor(startTime),
      "duration" : duration,
      "media_id" : this.mediaData.mediaId
    }

    let response = await this.extractionService.createClip(data);

    if (response?.error) {
      if (response.code == 402) {
        this.openSnackBar(
          `${response.errorMessage}.`,
          "snack-bar-danger"
        );
        this.openClipSubscriptionWindow(response.data);
        // this.disableClipExtraction();
      }
      if (response.errorMessage) {
        this.openSnackBar(
          `${response.errorMessage}.`,
          "snack-bar-danger"
          );
        return;
      }

      this.openSnackBar(
        `${response.errorMessage}.`,
        "snack-bar-danger"
        );

      return;
    }

    this.openSuccessSnackBar(
      `${response.message}. Click on "My Clips" to watch your generated clips`,
      "snack-bar-success"
    );

    this.formSubmitted = false;
    this.form.reset();
    this.formLoading = false;
    this.disableClipExtraction();
  }

  async openClipSubscriptionWindow(subscription_ids) {


    let user: any = await this.authService.getSession();

    let tokenData = {
      user_id: user.userId,
      channelId: this.mediaData.player_settings.channel_id,
      mediaId: null,
      ppvType: 'VE',
      subscription_ids: subscription_ids,
      method: 'subscriptions',
      payment_type: null,
      from: `/${this.mediaType}/${this.mediaSlug}`,
      fromList: true,
      fixedPrice: false,
      shouldGoBack: true,
      hideSubscriptions: false,
      content_access_type: "VIEW",
      domainUrl: "devmagic.develop.dashboard.vewbie.com"
    }

    // console.log(tokenData);
    // return;

    // this.analyticsService.trackingOpenSubcription(tokenData);
    let token = await this.subscriptionService.getPaymentToken(tokenData);
    this.router.navigateByUrl(`/payment/${token}`);
  }

  async openCategoriesModal() {
    this.dialog.open(CategoriesDialogComponent, {
      width: "50%",
      panelClass: "dialog-modal",
      data: this.mediaData.categories,
      autoFocus: false
    });
  }

  checkOpacityFirstCategory(index){
    if(index == 0) {
      this.highlightFirstCategory = true;
    }
    else {
      this.highlightFirstCategory = false;
    }
  }

  async cancelExtraction(){
    const dialogRef = this.dialog.open(DiscardClipComponent, {
      width: "300px"
    });
    let result = await dialogRef.afterClosed().toPromise();
    this.extractionService.showControls(false);

  }

  async shareVideo(){
    const dialogRef = this.dialog.open(ShareVideoComponent, {
      panelClass: "dialog-modal",
      width: "768px",
      data: {
        embedUrl: this.mediaData.embed
      },
      autoFocus: false
    });
    let result = await dialogRef.afterClosed().toPromise();
  }

  showMore() {
    this.showMoreFlag = !this.showMoreFlag;

    if(this.showMoreFlag) {
      this.tagsToShow = this.tags;
    } else {
      this.tagsToShow = _.cloneDeep(this.tags).splice(0, this.limitTags);
    }
  }

  async getSubscription(hideSubscriptions = false) {
    if(this.user) {
      this.openSubscriptionsWindow(hideSubscriptions);
    } else {
      this.openSignin();
      this.isMediaAvailable();
      this.isDownloadButtonAvaliable();
    }
  }

  async openSignin() {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {isModal: true},
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();


    if(result == 'success') {
      await this.authService.setRedirectUserPurchase(true);
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
    else if(result == 'signup') {
      this.openSignup();
    }
    else if(result == 'forgot') {
      this.openForgotPassword();
    }
  }

  async openSignup() {
    const dialogRef = this.dialog.open(SignupComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {isModal: true},
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if(result == 'success') {
      await this.authService.setRedirectUserPurchase(true)
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
    else if(result == 'signin') {
      this.openSignin();
    }
  }

  async openForgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: "40%",
      panelClass: "dialog-modal",
      data: {isModal: true},
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if(result == 'signin') {
      this.openSignin();
    }
  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }

  openSuccessSnackBar(message: string, color: string) {
    const snackbarRef = this.snackbar.open(message, 'My Clips', {
      duration: 30000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });

    snackbarRef.onAction().subscribe(() => {
      this.router.navigateByUrl('/user/my-clips');
      // this.router.navigate([`user/my-clips`])
    });
  }

  async openSubscriptionsWindow(hideSubscriptions = false) {

    if(!this.mediaData['buyByMedia'] && !this.mediaData['subscription_ids']?.length) {
      this.openSnackBar(
        "The media has no subscription.",
        "snack-bar-danger"
      );
      return;
    }

    let user: any = await this.authService.getSession();

    let tokenData = {
      user_id: user.userId,
      channelId: this.mediaData.player_settings.channel_id,
      mediaId: this.mediaData['buyByMedia'] ? this.mediaData.mediaId : null,
      ppvType: 'PPV',
      subscription_ids: this.mediaData['subscription_ids'],
      method: this.mediaData['subscription_ids']?.length ? 'subscriptions' : 'fixed',
      payment_type: this.mediaData['subscription_ids']?.length ? null : 'fixed',
      from: `/${this.mediaType}/${this.mediaSlug}`,
      fromList: true,
      fixedPrice: this.mediaData['buyByMedia'],
      shouldGoBack: true,
      hideSubscriptions,
      content_access_type: this.mediaData.content_access_type
    }
    this.analyticsService.trackingOpenSubcription(tokenData);
    let token = await this.subscriptionService.getPaymentToken(tokenData);
    this.router.navigateByUrl(`/payment/${token}`);
  }

  isDownloadButtonAvaliable() {
    /**
     * Only show download button when
     * DOWNLOAD - Pay for Download (Watch for free)
     * VIEW_DOWNLOAD - Pay for Watch and Download
     */
    let isViewAccessType = this.mediaData.content_access_type === 'VIEW'; // Pay to Watch (Disable Download)
    let isFreeLoginRequiredAccessType = this.mediaData.content_access_type === 'LOGIN_REQUIRED'; // free
    let isFreeNoLoginRequiredAccessType = this.mediaData.content_access_type === 'NO_LOGIN_REQUIRED'; // free
    let isPaid = +this.mediaData.price > 0;

    if (!isViewAccessType && !isFreeLoginRequiredAccessType && !isFreeNoLoginRequiredAccessType && isPaid) {
      this.showDownloadButton = this.mediaData.isVideo && this.mediaAvailable;
    }
  }

  async addToCart(mediaData: any) {

    if(!this.user) {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: "40%",
        panelClass: "dialog-modal",
        data: {isModal: true},
        autoFocus: false
      });

      let result = await dialogRef.afterClosed().toPromise();

      if(result == 'success') {
        window.location.reload();
      }

      return;
    }
    this.cartService.addItemToCart(mediaData);
  }

  isMediaAvailable() {

    this.mediaAvailable = false;

    if(this.mediaData.isFree) {
      if(this.mediaData.login_required && this.user) {
        this.mediaAvailable = true;
      } else if(!this.mediaData.login_required) {
        this.mediaAvailable = true;
      }
    } else if(this.mediaData.isPaid || +this.mediaData.price > 0 && +this.mediaData.price < 0.9 && !this.mediaData.subscription_ids.length) { // price < 0.9 referring to the old system: …\vewbie-web\www\application\views\player.php linha: 42.
      this.mediaAvailable = true;
    }

    this.mediaData.mediaAvailable = this.mediaAvailable;
  }

  edit() {
    let urlParam = "video";

    if(this.isLive) {
      urlParam = "events"
    }

    if(this.mediaData.source == "CLIP") {
      urlParam = "clip";
    }

    window.open(`${this.channel.oldDashChannelLink}/admin/${urlParam}/edit/${this.mediaSlug}`);
  }

  async favoriteMedia() {
    if(!this.user) {
      const dialogRef = this.dialog.open(LoginComponent, {
        width: "40%",
        panelClass: "dialog-modal",
        data: {isModal: true},
        autoFocus: false
      });

      let result = await dialogRef.afterClosed().toPromise();

      if(result == 'success') {
        window.location.reload();
      }

      return;
    }

    this.mediaData.isFav = this.mediaData.isFav ? 0 : 1;
    this.videoService.updateFavoriteMedia(this.mediaData.stream, this.mediaData.isFav);
  }

  clipVideo() {
    window.open(`${this.channel.newDashChannelLink}/clip-extraction/${this.mediaSlug}`);
  }

  goToCategoryDetails(category) {

    // usssalive.com or onlysports.live or iswim-tv.com or vewbie.com/channel/hostname
    if (
      this.isNewHomepageUrl &&
      category.subchannel_id &&
      category.subchannel_url &&
      category.subchannel_url.includes('vewbie.com')
    ) {
      let hostName = category.hostName ? category.hostName : category.host_name;
      let subchannelUrl = `${this.redirectLocationHref}/${hostName}`;
      window.open(`${subchannelUrl}`, '_blank');
      return;
    }

    if(category.redirect_category_url) {
      window.open(category.redirect_category_url, '_blank')
      return;
    }

    this.router.navigate([`categories/${category.category_slug}`]);
  }

  toggleTags() {
    if (!this.isExpanded) {
    document.getElementById('tags').scrollTop = 0;
    }
    this.isExpanded = !this.isExpanded;
  }


  incrementDownloadCounter(videoFile) {
    videoFile.countDownload++;
    if(this.mediaData.maximum_download_number >= videoFile.countDownload) {
      this.mediaData.downloadLinks.splice(this.mediaData.downloadLinks.indexOf(videoFile), 1);
    }
    this.videoService.updateDownloadCounter(this.mediaData.stream, videoFile.name);
  }

  loadAds(){
    setTimeout(()=>{
      this.snackAdsService.setupVideoContentAds();
    }, 2000);
  }

  setWssTime(time:number) {
    this.analyticsService.setWssTimestamp(time);
  }

  getLabelBtnForRestrictedVideo() {
    const getSufixContent = () => {
      const isOnlySubscription = !this.mediaData.buyByMedia
      const typeAccessMedia = this.mediaData.content_access_type

      if (typeAccessMedia == 'VIEW_DOWNLOAD' && !isOnlySubscription){
        return 'pay-and-download'
      }

      return 'get-subscription'
    }

    return 'video.'+ getSufixContent()
  }

}

/**
 * inits a websocket by a given url, returned promise resolves with initialized websocket, rejects after failure/timeout.
 *
 * @param url the websocket url to init
 * @param existingWebsocket if passed and this passed websocket is already open, this existingWebsocket is resolved, no additional websocket is opened
 * @param timeoutMs the timeout in milliseconds for opening the websocket
 * @param numberOfRetries the number of times initializing the socket should be retried, if not specified or 0, no retries are made
 *        and a failure/timeout causes rejection of the returned promise
 * @return {Promise}
 */
function initWebsocket(url, existingWebsocket, timeoutMs, numberOfRetries, context) {
  timeoutMs = timeoutMs ? timeoutMs : 5000;
  numberOfRetries = numberOfRetries ? numberOfRetries : 10;
  var hasReturned = false;
  var promise = new Promise((resolve, reject) => {
      setTimeout(function () {
          if(!hasReturned) {
              console.info('opening websocket timed out: ' + url);
              rejectInternal();
          }
      }, timeoutMs);
      if (!existingWebsocket || existingWebsocket.readyState != existingWebsocket.OPEN) {
          if (existingWebsocket) {
              existingWebsocket.close();
          }
          var websocket = new WebSocket(url);
          websocket.onopen = function () {
              if(hasReturned) {
                  websocket.close();
              } else {
                  console.info('websocket to opened! url: ' + url);
                  resolve(websocket);
              }
          };

          websocket.onmessage = function(msg) {
            let data = JSON.parse(msg.data);
            if(msg && data.type == "subscriber" && data.hasOwnProperty("subscribers")) {
              context.subscribers = data.subscribers;

              if (data.isLive && !context.mediaData.online) {
                context.reloadPage();
              };
            } else if (msg && data.type == "triggerAd" && data.hasOwnProperty("timestamp")) {
              context.timestamp = data.timestamp;
              (window as any).setWssTime(data.timestamp);
            }
          }

          websocket.onclose = function () {
              console.info('websocket closed! url: ' + url);
              rejectInternal();
              initWebsocket(url, null, timeoutMs, numberOfRetries,context).then(resolve, reject);
          };
          websocket.onerror = function () {
              console.info('websocket error! url: ' + url);
              rejectInternal();
          };
      } else {
          resolve(existingWebsocket);
      }

      function rejectInternal() {
          if(numberOfRetries <= 0) {
              reject();
          } else if(!hasReturned) {
              hasReturned = true;
              console.info('retrying connection to websocket! url: ' + url + ', remaining retries: ' + (numberOfRetries-1));
              initWebsocket(url, null, timeoutMs, numberOfRetries-1,this).then(resolve, reject);
          }
      }
  });
  promise.then(function () {hasReturned = true;}, function () {hasReturned = true;});
  return promise;
};
