import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/category.service';

@Component({
  selector: 'app-categories-dialog',
  templateUrl: './categories-dialog.component.html',
  styleUrls: ['./categories-dialog.component.scss']
})
export class CategoriesDialogComponent implements OnInit {

  categories: any;

  isNewHomepageUrl = /(onlysports\.live\/|usssalive\.com|iswim.tv|vewbie.com\/channel)/.test(window.location.href);
  redirectLocationHref = window.location.protocol + '//' + window.location.host + '/channel';

  constructor(
    public dialogRef: MatDialogRef<CategoriesDialogComponent>,
    private categoryService: CategoryService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.categories = data;
  }

  ngOnInit(): void {
    this.categories.forEach(category => {
      if (!category.category_thumbnail_url && category.category_name) {
        category.categorySlug = this.categoryService.getInitials(category.category_name);
      }
    });
  }

  close(){
    this.dialogRef.close(false);
  }

  goToCategoryDetails(category) {
    this.close();

    // usssalive.com or onlysports.live or iswim-tv.com or vewbie.com/channel/hostname
    if (
      this.isNewHomepageUrl &&
      category.subchannel_id &&
      category.subchannel_url &&
      category.subchannel_url.includes('vewbie.com')
    ) {
      let hostName = category.hostName ? category.hostName : category.host_name;
      let subchannelUrl = `${this.redirectLocationHref}/${hostName}`;
      window.open(`${subchannelUrl}`, '_blank');
      return;
    }

    if(category.redirect_category_url) {
      window.open(category.redirect_category_url, '_blank')
      return;
    }
    
    this.router.navigate([`categories/${category.category_slug}`]);
  }

}
