import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class RedirectService {

    constructor() { }

    goTo({ domainName, hostName, path }) {
        const splitUrlIn = '://'
        const baseNewUrl = environment.vewbie_new_url;

        let urlToRedirect: string;

        if (domainName) {
            urlToRedirect = 'https://' + domainName;
        } else {
            // whois is pattern ? 1 or 2
            const isOldPattern = window.location.href.includes('app.vewbie.com');
            if (isOldPattern) {
                const protocolAndPath = environment.vewbie_app_url.split(splitUrlIn);
                // After splitting, we need to insert the :// back
                protocolAndPath[0] += splitUrlIn;
                // after inserting the ://, we need add the host and dot
                protocolAndPath.splice(1, 0, hostName + ".");
                // join all parts
                urlToRedirect = protocolAndPath.join('');
            } else {
                urlToRedirect = `${baseNewUrl}/${hostName}`
            }
        }

        urlToRedirect += path ?? ''
        window.location.href = urlToRedirect;
    }
}