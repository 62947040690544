import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  loading: boolean = false;
  forgotPasswordForm: FormGroup;
  submitted: boolean = false;
  email: string = "";

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  async ngOnInit() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
    });
    await this.authService.createRoutine();
  }

  async submit() {
    
    if(this.forgotPasswordForm.invalid) return;
    this.email = this.forgotPasswordForm.get('email').value;
    this.loading = true;
    let response = await this.authService.forgotPassword(this.email);
    this.loading = false;
    if (response && !!response.error) {
      this.snackbar.open(
        "Error sending email please try again late",
        "", {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
          panelClass: "snack-bar-danger"
        }
      );
      this.goToLogin();
      return;
    }
    this.submitted = true;
  }

  goToLogin() {
    if(this.data.isModal) {
      this.dialogRef.close('signin');
      return;
    }
    this.router.navigateByUrl("/login");
  }

  close() {
    this.dialogRef.close();
  }

}
