import { Component, OnInit, ViewChild, ElementRef, Renderer2, HostListener, AfterViewInit } from '@angular/core';

import { VideoPlayerComponent } from 'src/app/video-player/video-player.component';

import { ExtractionService } from 'src/app/services/extraction.service';
import { Subject, interval } from 'rxjs';
import { takeWhile, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-clip-extractor',
  templateUrl: './clip-extractor.component.html',
  styleUrls: ['./clip-extractor.component.scss']
})
export class ClipExtractorComponent implements OnInit {

  @ViewChild('timelineCanvas', { static: true }) canvasRef: ElementRef;
  @ViewChild('timelineContainer', { static: true }) containerRef: ElementRef;
  @ViewChild('draggableDiv', { static: true }) draggableDivRef: ElementRef;

  private destroy$ = new Subject<void>();

  private ctx: CanvasRenderingContext2D;
  private containerWidth: number = 290; // Set your desired container width
  private totalDuration: number; // Set your total duration in seconds

  private isDragging: boolean = false;
  private dragStartX: number;
  private scrollStartX: number;


  private isDraggingDiv: boolean = false;
  private dragStartXDiv: number;

  private isDraggingHandle: boolean = false;
  private dragStartXHandle: number;

  sliderWidth:number = 60;
  minSliderWidth:number = 20; // Initial width
  sliderLeft:number = 0; // Initial width
  isResizing:boolean = false;
  resizeDirection: 'left' | 'right' | null = null;

  // scrollTimeout: any = 0;

  markLeft:number = 0;

  startTime:any;
  endTime:any;

  playInterval:any;

  isVideoPlaying = false;

  startX:number = 0;
  scrollX:number = 0;

  isWarning:boolean = false;
  inpMode:string = "";

  private regex: RegExp = new RegExp(/^[0-9:]*$/);


  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private extractionService: ExtractionService
  ) {}

  ngOnInit() {

    if(window.innerWidth <= 768) {
      this.containerWidth = window.innerWidth - 60;
    }



    this.startTime = this.formatTime(this.sliderLeft);
    this.endTime = this.formatTime(this.sliderLeft + this.sliderWidth);
    this.extractionService.totalVideoDuration$.subscribe((duration) => {
      console.log(duration);
      this.totalDuration = duration / 5;
      this.initTimeline(this.totalDuration);
    });

    this.extractionService.currentTime$.subscribe((time) => {
      // this.setTimelineSlider(time,true);
    });

    this.extractionService.currentTimeUpdate$.subscribe(([time,startTime]) => {
      this.markLeft = (time * 2) - (startTime * 2);
    });
  }

  checkError(event:any,mode:string) {

    let inputValue: string = (event.target as HTMLInputElement).value;
    inputValue = inputValue.replace(/[^0-9:]/g, '');
    const timeArray: string[] = inputValue.split(':');
    const formattedTime: string = timeArray.map(segment => segment.slice(0, 2)).join(':');
    (event.target as HTMLInputElement).value = formattedTime;

    if (this.timeToSeconds(event.target.value) > this.totalDuration * 5) {
      if (mode == "start") {
        this.isWarning = true;
        this.inpMode = "end";        
      } else {
        this.isWarning = true;
        this.inpMode = "start";
      }
    } else {
      if (mode == "start") {
        if (this.timeToSeconds(event.target.value) >= this.timeToSeconds(this.endTime)) {
          this.isWarning = true;
          this.inpMode = mode;
        }
      } else {
        if (this.timeToSeconds(event.target.value) <= this.timeToSeconds(this.startTime)) {
          this.isWarning = true;
          this.inpMode = mode;
        }
      }
    }
    
  }

  initTimeline(totalDuration:number) {
    this.ctx = this.canvasRef.nativeElement.getContext('2d');
    this.canvasRef.nativeElement.width = this.containerWidth; // Scale for better visibility
    this.canvasRef.nativeElement.height = 80; // Set your desired canvas height

    // this.ctx.scale(this.scaleFactor, this.scaleFactor);

    this.drawTimeline();

    this.extractionService.scrollTimelineLeft$.subscribe((left) => {
      this.setTimelineSlider(left);
    });

    this.canvasRef.nativeElement.addEventListener('pointerdown', this.onDragStart.bind(this));
    this.canvasRef.nativeElement.addEventListener('pointermove', this.onDrag.bind(this));
    this.canvasRef.nativeElement.addEventListener('pointerup', this.onDragEnd.bind(this));
    this.canvasRef.nativeElement.addEventListener('pointerleave', this.onDragEnd.bind(this));

    this.draggableDivRef.nativeElement.addEventListener('pointerdown', this.onDragStartDiv.bind(this));
    document.addEventListener('pointermove', this.onDragDiv.bind(this));
    document.addEventListener('pointerup', this.onDragEndDiv.bind(this));
  }

  drawTimeline() {
    // Clear the canvas
    this.ctx.clearRect(0, 0, this.canvasRef.nativeElement.width, this.canvasRef.nativeElement.height);

    const timeInterval = (10 * 10); // Scale for better visibility

    for (let time = 0; time <= this.totalDuration * 10; time += timeInterval) {
      const x =  time - this.scrollX;

      this.ctx.beginPath();
      this.ctx.moveTo(x, 0);
      this.ctx.lineTo(x, this.canvasRef.nativeElement.height);
      this.ctx.strokeStyle = '#666'; // Line color (you can customize this)
      this.ctx.stroke();
      this.ctx.closePath();

      const hours = Math.floor((time / 2) / 3600);
      const minutes = Math.floor(((time / 2) % 3600) / 60);
      const seconds = ((time / 2) % 3600) % 60;
      const label = `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
      
      this.ctx.font = '12px Arial';
      this.ctx.fillStyle = '#787878';
      this.ctx.fillText(label, x + 7, this.canvasRef.nativeElement.height - 60);
    }
  }

  padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }

  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 2 / 3600);
    const minutes = Math.floor((seconds / 2 % 3600) / 60);
    const remainingSeconds = Math.floor(seconds / 2 % 60);

    const hh = hours < 10 ? `0${hours}` : `${hours}`;
    const mm = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const ss = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

    return `${hh}:${mm}:${ss}`;
  }

  @HostListener('document:pointermove', ['$event'])

  onMouseMove(event: PointerEvent) {

    if (this.isResizing) {
      if ((this.sliderLeft + this.sliderWidth) < this.containerWidth) {
        if (this.resizeDirection === 'left') {

          this.sliderWidth -= event.movementX;
          this.sliderLeft += event.movementX;

          this.startTime = this.formatTime(this.sliderLeft + this.scrollX);
          this.endTime = this.formatTime(this.sliderLeft + this.sliderWidth + this.scrollX);

        } else if (this.resizeDirection === 'right') {
            this.sliderWidth += event.movementX;
            this.startTime = this.formatTime(this.sliderLeft + this.scrollX);
            this.endTime = this.formatTime(this.sliderLeft + this.sliderWidth + this.scrollX);            
        }
      }

      this.extractionService.setVideoTime((this.sliderLeft + this.scrollX)/2,this.sliderWidth);

      if (this.sliderWidth > 120) {
        this.sliderWidth = 120;
      }

      if (this.sliderWidth < this.minSliderWidth) {
        this.sliderWidth = this.minSliderWidth;
      }

      if (this.sliderLeft < 0) {
        this.sliderLeft = 0;
      }
    }
  }

  setTime(event:any, direction:string) {

    this.isWarning = false;
    this.inpMode = "";

    if (this.timeToSeconds(event.target.value) > this.totalDuration * 5) {
      this.startTime = this.formatTime(this.totalDuration * 10 - this.sliderWidth);
      this.endTime = this.formatTime(this.totalDuration * 10);
      this.scrollX = this.totalDuration * 10;
      this.sliderLeft = this.containerWidth - this.sliderWidth;
      this.extractionService.setVideoTime((this.totalDuration * 5) - (this.sliderWidth / 2),this.sliderWidth);

    } else if (this.timeToSeconds(event.target.value) > (this.totalDuration * 5) - (this.containerWidth / 2)) {
     
      if (direction == "right") {
        if (this.timeToSeconds(this.startTime) * 2 < this.containerWidth / 2) {
          this.sliderLeft = this.timeToSeconds(this.startTime) * 2;
        }
        if ((this.timeToSeconds(event.target.value) - this.timeToSeconds(this.startTime)) > 60) {
     
          this.startTime = this.formatTime((this.timeToSeconds(event.target.value) * 2) - 120);
          this.sliderWidth = 120;
        } else if (this.timeToSeconds(event.target.value) - this.timeToSeconds(this.startTime) < 10) {
          if (this.timeToSeconds(event.target.value) < 30) {
            this.startTime = this.formatTime(0);
            this.sliderWidth = this.timeToSeconds(event.target.value) * 2;
          } else {
            this.startTime = this.formatTime((this.timeToSeconds(event.target.value) * 2) - (this.sliderWidth));
          }
          
        } else {
          this.sliderWidth = (this.timeToSeconds(event.target.value) * 2) - (this.timeToSeconds(this.startTime) *2);
        }

          this.extractionService.setVideoTime(this.timeToSeconds(this.startTime),this.sliderWidth);

      
      } else if (direction == "left") {
        if (this.timeToSeconds(event.target.value) * 2 < this.containerWidth / 2) {

          this.sliderLeft = this.timeToSeconds(event.target.value) * 2;
        }
        if (this.timeToSeconds(this.endTime) - this.timeToSeconds(event.target.value) > 60) {

          this.endTime = this.formatTime(this.timeToSeconds(event.target.value) * 2 + this.sliderWidth);
        } else if (this.timeToSeconds(this.endTime) - this.timeToSeconds(event.target.value) < 10) {
          this.sliderWidth = 20;
          this.endTime = this.formatTime((this.timeToSeconds(event.target.value) * 2) + 20);
        } else {

          this.sliderWidth = (this.timeToSeconds(this.endTime) * 2) - (this.timeToSeconds(event.target.value) *2);
        }
        this.extractionService.setVideoTime(this.timeToSeconds(this.startTime),this.sliderWidth);
      }

      if (this.timeToSeconds(this.startTime) + this.sliderWidth > (this.containerWidth / 2)) {
        this.scrollX = (this.timeToSeconds(this.startTime) * 2) - this.sliderLeft;

      } else {
        this.scrollX = 0;
        this.sliderLeft = this.timeToSeconds(this.startTime) * 2;
      }

      if (this.timeToSeconds(event.target.value) > (this.totalDuration * 5) - 30) {
        if (direction == "right") {
          this.startTime = this.formatTime((this.timeToSeconds(event.target.value) * 2) - this.sliderWidth);
        } else {

          if (this.timeToSeconds(event.target.value) + (this.sliderWidth / 2) < this.totalDuration * 5) {
            this.endTime = this.formatTime(this.totalDuration * 10);
          } else {
            this.sliderWidth = 20;
            this.startTime = this.formatTime((this.totalDuration * 10) - 20)
            this.endTime = this.formatTime(this.totalDuration * 10);
          }
        }

        this.scrollX = this.totalDuration * 10;
        this.extractionService.setVideoTime((this.totalDuration * 5) - (this.sliderWidth / 2),this.sliderWidth);

      }

      this.scrollX = this.totalDuration * 10;
      this.sliderLeft = ((this.containerWidth / 2) - ((this.scrollX / 2) - this.timeToSeconds(this.startTime))) * 2;
      this.extractionService.setVideoTime(this.timeToSeconds(this.startTime) ,this.sliderWidth);

    } else {

      if (direction == "right") {
        if (this.timeToSeconds(this.startTime) * 2 < this.containerWidth / 2) {
          this.sliderLeft = this.timeToSeconds(this.startTime) * 2;
        }
        if ((this.timeToSeconds(event.target.value) - this.timeToSeconds(this.startTime)) > 60) {
     
          this.startTime = this.formatTime((this.timeToSeconds(event.target.value) * 2) - 120);
          this.sliderWidth = 120;
        } else if (this.timeToSeconds(event.target.value) - this.timeToSeconds(this.startTime) < 10) {
          if (this.timeToSeconds(event.target.value) < 30) {
            this.startTime = this.formatTime(0);
            this.sliderWidth = this.timeToSeconds(event.target.value) * 2;
          } else {
            this.startTime = this.formatTime((this.timeToSeconds(event.target.value) * 2) - (this.sliderWidth));
          }
          
        } else {
          this.sliderWidth = (this.timeToSeconds(event.target.value) * 2) - (this.timeToSeconds(this.startTime) *2);
        }

          this.extractionService.setVideoTime(this.timeToSeconds(this.startTime),this.sliderWidth);

      
      } else if (direction == "left") {
        if (this.timeToSeconds(event.target.value) * 2 < this.containerWidth / 2) {
          this.sliderLeft = this.timeToSeconds(event.target.value) * 2;
        }
        if (this.timeToSeconds(this.endTime) - this.timeToSeconds(event.target.value) > 60) {
          this.endTime = this.formatTime(this.timeToSeconds(event.target.value) * 2 + this.sliderWidth);
        } else if (this.timeToSeconds(this.endTime) - this.timeToSeconds(event.target.value) < 10) {
          this.sliderWidth = 20;
          this.endTime = this.formatTime((this.timeToSeconds(event.target.value) * 2) + 20);
        } else {
          this.sliderWidth = (this.timeToSeconds(this.endTime) * 2) - (this.timeToSeconds(event.target.value) *2);
        }
        this.extractionService.setVideoTime(this.timeToSeconds(this.startTime),this.sliderWidth);
      }

      if (this.timeToSeconds(this.startTime) + this.sliderWidth > (this.containerWidth / 2)) {
        this.scrollX = (this.timeToSeconds(this.startTime) * 2) - this.sliderLeft;

      } else {
        this.scrollX = 0;
        this.sliderLeft = this.timeToSeconds(this.startTime) * 2;
      }
      
    }

    this.scrollX = Math.min(Math.max(this.scrollX, 0), this.totalDuration * 10 - this.canvasRef.nativeElement.width);
    
    this.drawTimeline();
  }

  @HostListener('document:pointerup')
  onMouseUp() {
    this.isResizing = false;
    this.resizeDirection = null;
  }

  startResize(event: PointerEvent, direction: 'left' | 'right') {
    event.preventDefault();
    event.stopPropagation();
    this.isResizing = true;
    this.resizeDirection = direction;
  }

  onDragStartDiv(event: PointerEvent) {
    this.isDraggingDiv = true;
    this.dragStartXDiv = event.clientX;
  }

  onDragDiv(event: PointerEvent) {
    if (this.isDraggingDiv) {
      const deltaX = event.clientX - this.dragStartXDiv;
      const newLeft = this.draggableDivRef.nativeElement.offsetLeft + deltaX;
      
      if (newLeft >= 0 && newLeft + this.draggableDivRef.nativeElement.offsetWidth <= this.containerRef.nativeElement.offsetWidth) {
        this.draggableDivRef.nativeElement.style.left = newLeft + 'px';
        this.sliderLeft = newLeft;
      }

      this.dragStartXDiv = event.clientX;

      interval(200)
      .pipe(
        takeWhile(() => this.isDraggingDiv),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.scroll();
      });

    }
  }



  scroll() {

    if (this.sliderLeft < 5 && this.isDraggingDiv) {
      this.scrollX -= 3;
      this.scrollX = Math.min(Math.max(this.scrollX, 0), this.totalDuration * 10 - this.canvasRef.nativeElement.width);
      this.drawTimeline();
    } else if (this.sliderWidth + this.sliderLeft > this.containerWidth - 5 && this.isDraggingDiv) {
      this.scrollX += 3;
      this.scrollX = Math.min(Math.max(this.scrollX, 0), this.totalDuration * 10 - this.canvasRef.nativeElement.width);
      this.drawTimeline();
    }


    this.startTime = this.formatTime(this.sliderLeft + this.scrollX);
    this.endTime = this.formatTime(this.sliderLeft + this.sliderWidth + this.scrollX);
    this.extractionService.setVideoTime((this.sliderLeft + this.scrollX) / 2 , this.sliderWidth);
  }

  onDragEndDiv() {
    this.isDraggingDiv = false;

  }

  onDragStart(event: PointerEvent) {
    this.isDragging = true;
    this.startX = event.clientX;
    this.canvasRef.nativeElement.style.cursor = 'grabbing';
  }

  onDrag(event: PointerEvent) {

    if (this.isDragging) {
        const deltaX = event.clientX - this.startX;
        this.startX = event.clientX;
        this.scrollX -= deltaX;

        if (this.scrollX > 0) {
          this.startTime = this.formatTime(this.scrollX + this.sliderLeft);
          this.endTime = this.formatTime(this.scrollX + this.sliderLeft + this.sliderWidth);
          const videoCurrentTime = (this.scrollX + this.sliderLeft) / 2;
          this.extractionService.setVideoTime(videoCurrentTime, this.sliderWidth);
        }
        this.scrollX = Math.min(Math.max(this.scrollX, 0), this.totalDuration * 10 - this.canvasRef.nativeElement.width);
        this.drawTimeline();
      }

  }

  setTimelineSlider(time:any) {
    if (time < this.containerWidth / 2) {
      this.sliderLeft = time;

    } else if (time > (this.totalDuration * 5) - (this.containerWidth / 2)) {
      this.sliderLeft = time * 2 - ((this.totalDuration * 10) - (this.containerWidth));
    }
    if (time + (this.sliderWidth / 2) > this.totalDuration * 5) {
      this.scrollX = this.totalDuration * 10;
      this.startTime = this.formatTime((this.totalDuration * 10) - this.sliderWidth);
      this.endTime = this.formatTime(this.totalDuration * 10);
      this.sliderLeft = this.containerWidth - this.sliderWidth;
      this.extractionService.setVideoTime((this.totalDuration * 5) - (this.sliderWidth / 2),this.sliderWidth);
    } else {
      this.scrollX = (time * 2) - this.sliderLeft;
      this.startTime = this.formatTime(this.scrollX + this.sliderLeft);
      this.endTime = this.formatTime((this.scrollX + this.sliderLeft) + this.sliderWidth);
      this.extractionService.setVideoTime(time,this.sliderWidth);
    }

    this.scrollX = Math.min(Math.max(this.scrollX, 0), this.totalDuration * 10 - this.canvasRef.nativeElement.width);
    this.drawTimeline();

  }


  onDragEnd() {
    this.isDragging = false;
    this.canvasRef.nativeElement.style.cursor = 'grab';
  }

  timeToSeconds(timeString: string): number {
    const [hoursStr, minutesStr, secondsStr] = timeString.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    const seconds = parseInt(secondsStr, 10);

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    return totalSeconds;
  }

  ngOnDestroy() {
  }

}
