<div class="content">

  <div class="header">
		<div class='btns-mobile'>
      <button class='back-btn' mat-button>
        <mat-icon>arrow_back</mat-icon>
        <span (click)="backToProfile()">{{'profile.back-to-profile' | translate}}</span>
      </button>

      <button class='upload-video-btn-mobile' mat-button>
        <mat-icon>add_circle_outline</mat-icon>
      	<span>{{'profile.videos-content.upload-video' | translate}}</span>
      </button>
    </div>

    <div class="title-box">
      <span class="title">{{'profile.videos-content.title' | translate}}</span>
      <span class="sub-title">{{'profile.videos-content.subtitle' | translate}}</span>
    </div>
    <button class='upload-video-btn' mat-flat-button (click)="uploadVideo()">
			<mat-icon>add_circle_outline</mat-icon>
      <span>{{'profile.videos-content.upload-video' | translate}}</span>
    </button>
  </div>

	<div class='filter'>
		<mat-form-field appearance="outline" *ngIf='(selectedIndex == 0 && userMedias?.length) || (selectedIndex == 1 && userFavoritiesMedias.length)'>
			<mat-label>{{'filters.sort-by.title' | translate}}</mat-label>
			<mat-select [(value)]="orderBy" (selectionChange)="onFilter($event.value)" disableOptionCentering>
				<mat-option value="recent">{{'filters.sort-by.latest' | translate}}</mat-option>
        <mat-option value="older">{{'filters.sort-by.older' | translate}}</mat-option>
        <mat-option value="ascendingCharacters">{{'filters.sort-by.a-to-z' | translate}}</mat-option>
        <mat-option value="descendingCharacters">{{'filters.sort-by.z-to-a' | translate}}</mat-option>
			</mat-select>
		</mat-form-field>

		<div class="recommended-filter" *ngIf='selectedIndex == 2'>
			<img [src]="recommendedView == 'grid' ? './../../../../assets/icons/grid-fill.svg' : './../../../../assets/icons/grid-empty.svg'" (click)="recommendedView = 'grid'" alt="">
			<span [ngClass]='{"selected": recommendedView == "grid"}'>See All</span>
			<img [src]="recommendedView == 'columns' ? './../../../../assets/icons/columns-fill.svg' : './../../../../assets/icons/columns-empty.svg'" (click)="recommendedView = 'columns'" alt="">
			<span [ngClass]='{"selected": recommendedView == "columns"}'>By Category</span>
		</div>
	</div>

	<mat-tab-group (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="selectedIndex" disablePagination="true">
		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon class="material-icons-outlined" [ngClass]='{"tab-selected": selectedIndex == 0}'>file_upload</mat-icon>
				<span [ngClass]='{"tab-selected": selectedIndex == 0}'>{{'profile.videos-content.my-videos' | translate}}</span>
			</ng-template>

			<div class="example-loading-shade" *ngIf="loading">
				<mat-spinner></mat-spinner>
			</div>

			<app-user-videos [userMedias]='userMedias' *ngIf="!loading"></app-user-videos>
		</mat-tab>

		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon class="material-icons-outlined heart" [ngClass]='{"tab-selected": selectedIndex == 1}'>favorite_border</mat-icon>
				<span [ngClass]='{"tab-selected": selectedIndex == 1}'>{{'profile.videos-content.favorited-videos' | translate}}</span>
			</ng-template>

			<div class="example-loading-shade" *ngIf="loading">
				<mat-spinner></mat-spinner>
			</div>

			<app-favorited-videos [userFavoritiesMedias]='userFavoritiesMedias' [paginationLoading]="paginationLoading" (scrolled)="onScroll()"></app-favorited-videos>
		</mat-tab>

		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon class="material-icons-outlined heart" [ngClass]='{"tab-selected": selectedIndex == 2}'>thumb_up</mat-icon>
				<span class='recommended' [ngClass]='{"tab-selected": selectedIndex == 2}'>Recommended Videos</span>
			</ng-template>

			<ng-template matTabContent>
				<div class="example-loading-shade" *ngIf="loading">
					<mat-spinner></mat-spinner>
				</div>

				<div class='content-view-recommended' *ngIf="!loading && recentVideos.length">
					<app-recommended-videos [recommendedVideos]="recentVideos" *ngIf="recommendedView == 'columns'"></app-recommended-videos>
					<app-user-videos [userMedias]='recentVideos' *ngIf="recommendedView == 'grid'" ></app-user-videos>
				</div>
				<div class='no-events content-view-recommended' *ngIf="!loading && !recentVideos.length">
					{{'profile.no-results' | translate}}
				</div>
			</ng-template>

		</mat-tab>

		<mat-tab>
			<ng-template mat-tab-label>
				<mat-icon class="material-icons-outlined heart" [ngClass]='{"tab-selected": selectedIndex == 3}'>videocam</mat-icon>
				<span class='myclips' [ngClass]='{"tab-selected": selectedIndex == 3}'>My Clips</span>
			</ng-template>

			<ng-template matTabContent>
				<div class="example-loading-shade" *ngIf="loading">
					<mat-spinner></mat-spinner>
				</div>

				<div class='content-view-recommended' *ngIf="!loading">
					<app-user-videos [userMedias]='myClips' [paginationLoading]="paginationLoading" (scrolled)="onScroll()"></app-user-videos>
				</div>
			</ng-template>

		</mat-tab>
	</mat-tab-group>
</div>
