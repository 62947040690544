<div class="container-signup">

  <div class="content" [ngClass]='{"content-modal": data.isModal}'>

    <mat-icon class='close-button' (click)='close()'>close</mat-icon>

    <h1>{{'signup.title' | translate}}</h1>
    <h3>{{'signup.sub-title' | translate}} <a (click)='goToLogin()'>{{'signup.signin' | translate}}</a></h3>

    <form [formGroup]="signupForm" (ngSubmit)="signup()" autocomplete="off">

      <mat-form-field appearance="outline" autocomplete="off">
        <mat-label>{{'signup.name.text' | translate}}</mat-label>
        <input matInput type="text" formControlName="name">
        <mat-error *ngIf="signupForm.get('name').hasError('required')">
          {{'signup.name.name-is' | translate}} <strong>{{'signup.name.name-required' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" autocomplete="off">
        <mat-label>{{'signup.email.text' | translate}}</mat-label>
        <input matInput type="email" formControlName="email">
        <mat-error *ngIf="signupForm.get('email').hasError('required')">
          {{'signup.email.email-is' | translate}} <strong>{{'signup.email.email-required' | translate}}</strong>
        </mat-error>
        <mat-error *ngIf="signupForm.get('email').hasError('email') || signupForm.get('email').hasError('pattern')">
          {{'signup.email.must-be' | translate}} <strong>{{'signup.email.email' | translate}}</strong>
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="outline" autocomplete="off">
        <mat-label>{{'signup.password.text' | translate}}</mat-label>
        <input matInput type="password" formControlName="password">
        <mat-hint>{{'signup.password.hint' | translate}}</mat-hint>
        <mat-error *ngIf="signupForm.get('password').hasError('required')">
          {{'signup.password.password-is' | translate}} <strong>{{'signup.password.password-required' | translate}}</strong>
        </mat-error>
        <mat-error *ngIf="signupForm.get('password').hasError('minlength')">
          {{'signup.password.must-be' | translate}} <strong>{{'signup.password.char' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="policy" color="primary">{{'signup.policy' | translate}}</mat-checkbox>

      <app-recaptcha-v2 [submitted]="submitted" (captchaSuccess)="getCaptchaSuccess($event)"></app-recaptcha-v2>
      
      <button mat-flat-button class="signup-button" type="submit" *ngIf='!loading'>{{'signup.signup' | translate}}</button>
      <button mat-flat-button color='primary' class='loading signup-button' *ngIf='loading'>
        <mat-spinner diameter='30'></mat-spinner>
      </button>
    </form>

  </div>
  <div id="success" style="display: none;">{{'signup.success' | translate}}</div>
</div>
