import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-cancel-renew-subscription',
  templateUrl: './cancel-renew-subscription.component.html',
  styleUrls: ['./cancel-renew-subscription.component.scss']
})
export class CancelRenewSubscriptionComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataModal: any,
    public dialogRef: MatDialogRef<CancelRenewSubscriptionComponent>,
    public paymentService : PaymentService,
    private snackbar: MatSnackBar,

  ) { }

  loadingRequest : boolean = false;

  ngOnInit(): void {
    
  }

  billingFrequencyTreatment(plan){
    let frequency = plan.billing_frequency  || plan.offer_period;
    
    if(!frequency){
      return
    }

    switch (frequency.toUpperCase()) {
      case 'YEAR':
        return 'Annual';
      case 'MONTH':
        if(plan.billing_interval > 1) {
          return `${plan.billing_interval}-${plan.billing_frequency.toLowerCase()}`
        }
        return 'Month';
      case 'DAY':
        return 'Day';
      case 'WEEK':
        return 'Week';
    }

    if(plan.billing_interval > 1) {
      return `${plan.billing_interval} ${plan.billing_frequency.toLowerCase()}`
    }

  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 8000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  async action(){        
    
    if(this.loadingRequest){
      return
    }

    this.loadingRequest = true;

    let result = null;
    const subscription = this.dataModal.subscriptionData;
    

    if(!subscription || !subscription.agreement_id || !subscription.payment_method){

      this.openSnackBar(
        `There was a problem with the subscription`,
        "snack-bar-danger"
      );
      this.loadingRequest = false;
      this.dialogRef.close();
    }

    if(this.dataModal.fluxTitle == "Cancel"){
      result = await this.paymentService.cancelSubscription({
        agreement_id: subscription.agreement_id,
        payment_method: subscription.payment_method,
        immediately: false
      })
    }
    else if(this.dataModal.fluxTitle == "Renew"){
      result = await this.paymentService.renewSubscription({
        agreement_id: subscription.agreement_id,
        payment_method: subscription.payment_method
      })
    }    

    if(result.error){
      this.openSnackBar(
        `There was an error in the ${this.dataModal.fluxTitle} process, please try again.`,
        "snack-bar-danger"
      );
      this.dialogRef.close();
    }
    else{
      this.openSnackBar(
        `Operation ${this.dataModal.fluxTitle} performed successfully`,
        "snack-bar-success"
      );
      this.dialogRef.close(true);
    }

    this.loadingRequest = false;
  }

}
