import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  loading: boolean = false;
  resetPasswordForm: FormGroup;
  token: any;
  errorMessage: string = "";

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private snackbar: MatSnackBar,
    ) { 
    this.route.queryParams.subscribe(params => {
      this.token = params['reset_password_token'];
      if(!this.token) {
        this.router.navigateByUrl("/login");
      }
    });
    
  }

  async ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
    await this.authService.createRoutine();
  }

  async reset() {
    this.errorMessage = "";
    if(this.resetPasswordForm.invalid) return;

    if(!this.passwordIsTheSame(this.resetPasswordForm)) {
      this.errorMessage = "Passwords must be the same";
      return;
    }
    this.loading = true;
    try {
      let res = await this.authService.updatePassword(this.resetPasswordForm.get('password').value, this.token);
      if(!res.error) {
        this.openSnackBar(
          "Password Updated Successfully.",
          "snack-bar-success"
        );
        
        setTimeout(() => {
          this.router.navigateByUrl("/login");
        }, 2000);
      }  
    } catch (error: any) {
      this.errorMessage = error?.error ?? error.message;
      
    } finally {
      this.loading = false;
    }

  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }
  

  passwordIsTheSame(group: FormGroup) {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
  
    return password === confirmPassword ? true : false    
  }

}
