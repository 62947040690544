import { Component, OnInit } from '@angular/core';
import { ChannelService } from 'src/app/services/channel.service';

@Component({
  selector: 'app-tv-apps',
  templateUrl: './tv-apps.component.html',
  styleUrls: ['./tv-apps.component.scss']
})
export class TvAppsComponent implements OnInit {

  data: any;
  loading: boolean = true;

  constructor(
    private channelService: ChannelService
  ) { }

  async ngOnInit() {
    this.data = await this.channelService.getChannelTvApps();
    this.loading = false;
  }

}
