<div class='content-bundle' *ngIf='bundleEditor.length'>
	<div *ngFor='let bundle of bundleEditor' class='bundle'>
		<div class='details'>
			<span class='title'>{{bundle.subscription_name}}</span>
		</div>
	
		<div class='clips-info'>
			<span>{{'profile.subscription-content.unlock-videos-bundles.total-clips' | translate}} {{bundle.subscription_total_clips}} | {{'profile.subscription-content.unlock-videos-bundles.used-clips' | translate}} {{bundle.used_clips}} | ${{bundle.price}}</span>
		</div>
	</div>
</div>

<div class='no-events profile' *ngIf='!bundleEditor.length'>
	{{'profile.no-results' | translate}}
</div>