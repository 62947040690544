<div class="content">
  
  <div class="header" *ngIf="!loading">
    <div class='btns-mobile'>
      <button class='back-btn' (click)="backToProfile()" mat-button>
        <mat-icon>arrow_back</mat-icon>
        <span>{{'profile.back-to-profile' | translate}}</span>
      </button>

      <button class='save-btn-mobile' (click)="saveChanges()" mat-button *ngIf='!lodash.isEqual(profileFormClone, profileForm.value) || hasFile'>
        <img src="../../../../assets/icons/check-lightblue.svg" alt="">
        <span>{{'profile.overview-content.save' | translate}}</span>
      </button>
    </div>
    <div class="title-box">
      <span class="title">{{'profile.overview-content.title' | translate}}</span>
      <span class="sub-title">{{'profile.overview-content.subtitle' | translate}}</span>
    </div>
    <button class='save-btn' (click)="saveChanges()" mat-flat-button *ngIf='!lodash.isEqual(profileFormClone, profileForm.value) || hasFile'>
      <img src="../../../../assets/icons/check-white.svg" alt="">
      <span>{{'profile.overview-content.save' | translate}}</span>
    </button>
  </div>

  <div class="content-info" *ngIf="!loading">
    <div class="fields">
      <form [formGroup]="profileForm" autocomplete="off">

        <span class="title first">{{'profile.overview-content.public-info' | translate}}</span>
        <mat-form-field appearance="standard">
          <mat-label>{{'profile.overview-content.name' | translate}}</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>{{'profile.overview-content.username' | translate}}</mat-label>
          <input matInput type="text" autocomplete="off" formControlName="username">          
          <mat-error *ngIf="username.hasError('pattern') || username.hasError('required')">{{'profile.overview-content.invalid-username' | translate}}</mat-error>
        </mat-form-field>
        
        <mat-form-field appearance="standard">
          <mat-label>{{'profile.overview-content.email-address' | translate}}</mat-label>
          <input matInput formControlName="email" [readonly]='data?.connected_through == "Facebook"'>
          <mat-error *ngIf="email.hasError('pattern') || email.hasError('required')">{{'profile.overview-content.invalid-email' | translate}}</mat-error>
        </mat-form-field>

        <ng-container *ngIf='data?.connected_through != "Facebook"'>
          <span class="title">{{'profile.overview-content.security' | translate}}</span>
          <mat-form-field appearance="standard">
            <mat-label>{{'profile.overview-content.current-password' | translate}}</mat-label>
            <input type="password" matInput formControlName="currentPassword" placeholder="Enter Current Password">
            <mat-error *ngIf="currentPassword.hasError('minlength')">{{'profile.overview-content.password-minlength' | translate}}</mat-error>
            <mat-error *ngIf="currentPassword.hasError('required')">{{'profile.overview-content.current-password-is' | translate}} <strong>{{'profile.overview-content.required' | translate}}</strong></mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="standard">
            <mat-label>{{'profile.overview-content.new-password' | translate}}</mat-label>
            <input type="password" matInput formControlName="newPassword" (input)="verifyPassword()" placeholder="Enter Password">
            <mat-error *ngIf="newPassword.hasError('minlength')">{{'profile.overview-content.password-minlength' | translate}}</mat-error>
          </mat-form-field>
          
          <mat-form-field appearance="standard">
            <mat-label>{{'profile.overview-content.confirm-password' | translate}}</mat-label>
            <input type="password" matInput formControlName="confirmPassword" (input)="verifyPassword()" placeholder="Re-ype Password">
            <mat-error *ngIf="confirmPassword.hasError('different') || confirmPassword.hasError('required')">{{'profile.overview-content.password-are' | translate}} <strong>{{'profile.overview-content.different' | translate}}</strong></mat-error>
          </mat-form-field>
        </ng-container>

        <span class="title">{{'profile.overview-content.timezone' | translate}}</span>
        <mat-form-field appearance="standard">
          <mat-label>{{'profile.overview-content.select-timezone' | translate}}</mat-label>
          <mat-select formControlName="timezone" name="time_zone">
            <mat-option *ngFor="let singleTimezone of timezones" [value]="singleTimezone">{{singleTimezone}}</mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf='data?.connected_through != "Facebook"'>
          <span class="title">{{'profile.overview-content.profile-picture' | translate}}</span>
          <mat-form-field class="profile-form" appearance="standard" (click)="fileInput.click()">
            <mat-label>{{'profile.overview-content.custom-profile-picture' | translate}}</mat-label>
            <input matInput readonly placeholder="Placeholder" [value]="fileName">
            <mat-icon matSuffix>file_upload</mat-icon>
            <mat-hint>{{'profile.overview-content.picture-description' | translate}}</mat-hint>
          </mat-form-field>
        </ng-container>
      </form>

      <div class="file-input">
        <input style="display: none;" (change)="onFileChanged(fileInput)" (click)='cleanFileValue($event)' type="file" #fileInput name="avatar" accept="image/png, image/jpeg">
      </div>


    </div>

    <div class="details">
      <div class="box">
        <span class="title">{{'profile.overview-content.access-details' | translate}}</span>

        <div class="sub">
          <div class="type">{{'profile.overview-content.connected-through' | translate}}</div>
          <span class="value">{{data?.connected_through}}</span>
        </div>
        <div class="sub">
          <div class="type">{{'profile.overview-content.logged-in' | translate}}</div>
          <span class="value">{{data?.total_login}} <span *ngIf='data?.total_login > 1'>{{'profile.overview-content.times' | translate}}</span> <span *ngIf='data?.total_login == 0 || data?.total_login == 1'>{{'profile.overview-content.time' | translate}}</span></span> 
          <!-- When the user creates an account, the backend counts as if he had logged in for the first time. So we need to remove 1 of total login -->
        </div>
        <div class="sub">
          <div class="type">{{'profile.overview-content.last-login' | translate}}</div>
          <span class="value">{{data.last_login | dateTimezone: 'yyyy-MM-dd h:mm:ss a'}}</span>
        </div>

      </div>
      <div class="box channels" *ngIf="data && data.channels && data.channels.length">
        <span class="title">{{'profile.overview-content.channels' | translate}}</span>

        <div *ngFor="let channel of data?.channels" class="channel">
          <div class="name">{{channel?.channelName}}</div>
          <span class="link">{{channel?.urlChannel}}</span>
        </div>

      </div>
    </div>
  </div>

  <div class="example-loading-shade" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>
