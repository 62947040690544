import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private httpClient: HttpClient,
    private authService: AuthService) { }


  async getScheduleEvents(limit, offset) {
    try {

      const headers = await this.authService.getHeadersSession();
      let response: any = await this.httpClient.get(`${environment.api_vms_url}/get-schedule-events?limit=${limit}&offset=${offset}`,{headers}).toPromise();
      return response || [];
      
    } catch (error) {
      this.authService.verifySession(error);
      return error;
    }
  }
}
