import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservableService {

  private currentSessionSubject = new Subject <any>();
  private changeCarouselByArrow = new Subject <boolean>();

  constructor() { }

  getCurrentSessionObservable(){
    return this.currentSessionSubject;
  }

  setCurrentSessionObservable(data){
    this.currentSessionSubject.next(data);
  }

  getChangeCarouselByArrowObservable(){
    return this.changeCarouselByArrow;
  }

  setChangeCarouselByArrowObservable(data){
    this.changeCarouselByArrow.next(data);
  }
}
