import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private httpClient: HttpClient,
    private authService: AuthService) { }

  async getCategories(filters, limit, offset) {
    const headers = await this.authService.getHeadersSession();
    let response: any = await this.httpClient.get(`${environment.api_vms_url}/list-categories-patterns?limit=${limit}&offset=${offset}`, {
      headers,
      params : filters
    }).toPromise();
    return response;
  }

  async getPopularTags() {
    const headers = await this.authService.getHeadersSession();
    let response: any = await this.httpClient.get(`${environment.api_vms_url}/list-popular-tags`, {headers}).toPromise();
    return response;
  }

  async getPopularSubCategories(categorySlug, limit) {
    let filters = {
      columnOrder: 'videos',
      order: 'DESC',
      limit: limit,
      offset: 0
    }
    const headers = await this.authService.getHeadersSession();
    let response: any = await this.httpClient.get(`${environment.api_vms_url}/sub-categories/${categorySlug}`,{
      headers,
      params : filters
    }).toPromise();
    
    return response;
  }
}
