import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-user-videos',
  templateUrl: './user-videos.component.html',
  styleUrls: ['./user-videos.component.scss']
})
export class UserVideosComponent {
  @Input() userMedias: any[] = [];
  @Input() paginationLoading: boolean = false;
  @Output() scrolled = new EventEmitter<void>();

  onScroll(event: any): void {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      this.scrolled.emit();
    }
  }
}
