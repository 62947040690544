<div id='homepage-content'>
  <section class="main-slider" id='main-slider' [ngClass]='{"no-slider" : !videos?.length}'>

    <!-- Snack Ads -->
    <div [ngClass]='isMobile ? "snack-mldb" : "snack-ldb"' *ngIf="!loading && !adblockActivated && (hostName == 'usssa' || hostName == 'iswimtv')" class='banner'>
      <div id='snack_ldb' *ngIf="!isMobile"></div>
      <div id='snack_mob_top' *ngIf="isMobile"></div>
    </div>
    <!-- Snack Ads -->

    <ng-container *ngIf="!loading && videos && videos.length">

      <owl-carousel-o #mainCarousel [options]="customOptions" (changed)="getSlide($event)">
          <ng-template carouselSlide id="slide-{{i}}" *ngFor="let video of videos; let i = index">
            <app-video [data]='video' (click)="goToStep(i)"></app-video>
          </ng-template>
      </owl-carousel-o>

      <img class="previous-arrow" *ngIf="steps.length > 1" src="../../../assets/icons/arrow-left.svg" title="Go to previous" (click)="mainCarousel.prev()">
      <img class="next-arrow" *ngIf="steps.length > 1" src="../../../assets/icons/arrow-right.svg" title="Go to next" (click)="mainCarousel.next()">
    </ng-container>
    <ng-container>
      <div class="example-loading-shade" *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>

    <div class='steps' *ngIf="steps.length > 1 && !loading">
      <div class='step' (click)="goToStep(step.number)" [ngStyle]="{'background-color': step.active ? '#010570' : '#e4e4ee'}" *ngFor="let step of steps"></div>
    </div>
  </section>

  <div class='homepage-content'>
    <div class='row'>
      <div class='videos' [ngClass]='{"no-ad": (hostName != "usssa" && hostName != "iswimtv")}'>
        
        <!-- Snack Ads -->
        <div [ngClass]='isMobile ? "snack-mldb" : "snack-ldb"' *ngIf="!loading && !adblockActivated && (hostName == 'usssa' || hostName == 'iswimtv')" class='banner'>
          <div id='snack_dex2' *ngIf="!isMobile"></div>
          <div id='snack_mex2' *ngIf="isMobile"></div>
        </div>
        <!-- Snack Ads -->

        <div *ngIf='lives && lives.length && !loading' class='content-group'>
          <div class='group-videos'>
            <app-group-videos title="Live Now" [hideLiveViewers]="channel.hide_live_viewers" seeAllUrl="/see-all/live" [videos]="lives"></app-group-videos>
          </div>
        </div>

        <div *ngIf='eventsSchedule && eventsSchedule.length && !loading' class='content-group'>
          <div class='group-videos'>
            <app-group-videos title="Upcoming" seeAllUrl="/schedule" [videos]="eventsSchedule" context="home"></app-group-videos>
          </div>
        </div>

        <div *ngIf='categoriesGroup && categoriesGroup.length && !loading' class='content-group'>
          <div class='group-videos' (click)="trackingCategoryPromotedCarousel()">
            <app-group-videos  [title]="categorySelectedId ? 'Subcategories' : 'Categories'" seeAllUrl="/categories" subtitle="We think you'll like" [videos]="categoriesGroup" [hasThumbnail]="false"></app-group-videos>
          </div>
        </div>

        <!-- Snack Ads -->
        <div [ngClass]='isMobile ? "snack-mldb" : "snack-ldb"' *ngIf="!loading && !adblockActivated && (hostName == 'usssa' || hostName == 'iswimtv')" class='banner'>
          <div id='snack_dex3' *ngIf="!isMobile"></div>
          <div id='snack_mex3' *ngIf="isMobile"></div>
        </div>
        <!-- Snack Ads -->

        <div *ngIf='mostViewedVideos && mostViewedVideos.length && !loading' class='content-group'>
          <div class='group-videos'>
            <app-group-videos title="Most Viewed Videos" seeAllUrl="/see-all/most-viewed" [videos]="mostViewedVideos"></app-group-videos>
          </div>
        </div>

        <div *ngIf='recentVideos && recentVideos.length && !loading' class='content-group'>
          <div class='group-videos'>
            <app-group-videos title="Recent Videos" seeAllUrl="/see-all/recent-videos" [videos]="recentVideos"></app-group-videos>
          </div>
        </div>

        <!-- Snack Ads -->
        <div [ngClass]='isMobile ? "snack-mldb" : "snack-ldb"' *ngIf="!loading && !adblockActivated && (hostName == 'usssa' || hostName == 'iswimtv')" class='banner'>

          <div id='snack_dex4' *ngIf="!isMobile && !loading && !adblockActivated && (hostName == 'usssa' || hostName == 'iswimtv')"></div>        
          <div id='snack_dex4' *ngIf="isMobile && !loading && !adblockActivated && (hostName == 'usssa' || hostName == 'iswimtv')"></div> 

        </div>       
        <!-- Snack Ads -->
        
        <div *ngIf='groupedVideos && groupedVideos.length && !loading' id='group-videos'>
          <ng-container *ngFor="let group of groupedVideos">
            <div class='group-videos promoted'>
              <app-group-videos [title]="group?.category_title" [videos]="group?.media" [layout]="group?.layout" [seeAllUrl]="group.seeAll" [subchannelId]='group.subchannel_id' [subchannelLogo]='group.subchannel_logo' [subchannelUrl]='group.subchannel_url'></app-group-videos>

              <!-- Snack Ads -->

              <div [ngClass]='isMobile ? "snack-mldb" : "snack-ldb"' *ngIf="(group.index % 2) == 1 && !adblockActivated && (hostName == 'usssa' || hostName == 'iswimtv')" class='banner'>
                <div [id]="snackDesktopAds[floor(group.index / 2)] || 'snack_desktop_undefined'" *ngIf="ceil(group.index/2)  <= snackDesktopAds.length && !isMobile"></div>
                <div [id]="snackMobileAds[floor(group.index / 2)] || 'snack_mobile_undefined'" *ngIf="ceil(group.index/2) <= snackMobileAds.length && isMobile"></div>
              </div>
              <!-- Snack Ads -->

            </div>
          </ng-container>
        </div>

        <div
          class="search-results"
          infiniteScroll
          [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50"
          (scrolled)="onScroll()"
        ></div>
        <ng-container>
          <div class="categories-loading" *ngIf="loadingCategories && !loading">
            <mat-spinner></mat-spinner>
          </div>
        </ng-container>

        <div class='no-content' *ngIf="!loading && !videos?.length && !lives?.length && !categoriesGroup?.length && !mostViewedVideos?.length && !recentVideos?.length && !groupedVideos?.length">
          {{'main.no-content' | translate}}
        </div>
      </div>

      <div class='ad-side' *ngIf="!adblockActivated && hostName == 'usssa' || hostName == 'iswimtv'">
        <div class='fixed'>

          <!-- Snack Ads -->

          <div [ngClass]='isMobile ? "snack-mpu" : "snack-dpmu"' class='banner'>

            <div id="snack_dmpu" *ngIf="!isMobile"></div>
            <div id="snack_mob_bot" *ngIf="isMobile"></div>
          </div>
          <!-- Snack Ads -->
        </div>
      </div>
    </div>
  </div>
</div>
