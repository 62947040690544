<div class='video' (mouseout)="mouseOut()" (mouseover)="mouseOver()" [title]="videoData?.description ? videoData?.description : videoData.title"   [ngClass]='{"full-width": fullWidth, "purchased": videoData.channel_name || recommended, "columns-view": columnsView}' >
  <div class='thumbnail'>
    <div class="category" *ngIf="categories.length">
      <ng-container *ngIf='categories.length <= 3 && !isMobile'>
        <div style='display: flex;'>
          <a class="category-image" (mouseenter)='checkOpacityFirstCategory(i)' (mouseleave)='checkOpacityFirstCategory(0)' [ngClass]='{"first": i == 0 && highlightFirstCategory}' (click)="goToCategoryDetails(category)" target="_blank" *ngFor='let category of categories; let i = index;' matTooltipClass="tooltip-class" [matTooltip]="category?.category_name" matTooltipPosition="above">
            <div *ngIf="category.category_thumbnail_url" class="image" [ngStyle]="{'background-image': 'url(' + category.category_thumbnail_url + ')'}"></div>
            <div *ngIf="!category.category_thumbnail_url " class="no-image">{{category.categorySlug}}</div>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf='categories.length > 3 || isMobile'>
        <div class="no-image categories-length" [ngClass]='{"recommended": recommended}' (click)='openCategoriesModal()'>{{categories.length + (categories.length > 1 ? ' Categories' : ' Category')}}</div>
      </ng-container>
    </div>

    <a href="{{mediaLink}}" (click)='goToVideo($event)'>
      <img *ngIf='videoData.thumbnail_url && videoData.thumbnail_url.indexOf("noImage") < 0 && videoData.thumbnail_url.indexOf("imgtest1.jpg") < 0' #refEl [src]="videoData.thumbnail_url" 
      onerror="this.src='../../assets/imgs/prism.png'"  [ngClass]='{"full-width": fullWidth, "purchased": videoData.channel_name || recommended, "video-content": context == "video-content", "columns-view": columnsView}'>
      <img src="../../../assets/imgs/prism.png" #refEl class='prism'  [ngClass]='{"full-width": fullWidth, "purchased": videoData.channel_name || recommended, "video-content": context == "video-content", "columns-view": columnsView}' *ngIf='!videoData.thumbnail_url || videoData.thumbnail_url.indexOf("noImage") >= 0 || videoData.thumbnail_url.indexOf("imgtest1.jpg") >= 0 '>
      <div *ngIf='(videoData.type == "EVENT" || fromSchedule) && context != "home"' class='live' [ngClass]="{'schedule' : fromSchedule}">{{videoData.online ? 'Live' : "Offline"}} </div>
      <div *ngIf='videoData.isSticky' class='sticky'>Pinned</div>
      <div *ngIf='videoData.type == "EVENT" && videoData.online && !hideLiveViewers' [ngStyle]="{'left': videoData.online ? '48px;' : '65px' }" class='viewers'>{{videoData.subscribers ? videoData.subscribers : '0'}} viewer<ng-container *ngIf="videoData.subscribers > 1">s</ng-container></div>
      <div *ngIf='videoData.type != "EVENT" && !fromSchedule' class='duration'>{{videoData.duration}}</div>
      
      <div *ngIf='fromSchedule && videoData.schedule_type == "free"' class='paid'><img src="../../../assets/icons/unlock.svg" alt="">Free</div>
      <div *ngIf='fromSchedule && videoData.schedule_type == "paid"' class='paid'><img src="../../../assets/icons/lock.svg" alt="">Paid</div>
     
      <div *ngIf="!fromSchedule && videoData.start_datetime" class='date-mobile' [ngClass]='{"recommended": recommended}'>{{ videoData.start_datetime | dateTimezone: 'MM/dd/yyyy h:mm a'}}</div>
      <div *ngIf="fromSchedule && videoData.start_datetime" class='date-mobile' [ngClass]='{"recommended": recommended}'>{{videoData.start_datetime | dateTimezone:'hh:mm aa'}}</div>

      <ng-container *ngIf='videoData.type != "EVENT"'>
        <div class='cut' *ngIf="videoData.is_video_editor && !fromSchedule && (videoData.isPaid || isAdmin || (videoData.isFree && isAdmin)) && videoData.source != 'CLIP' && user" (click)="clipVideo()" matTooltipClass="tooltip-class" matTooltip="Video cutter / Clip Extraction" matTooltipPosition="above"><mat-icon>movie</mat-icon></div>
      </ng-container>
      
      <div class='edit' *ngIf="videoData.is_video_editor && !fromSchedule && isAdmin" (click)="edit()" matTooltipClass="tooltip-class" matTooltip="Edit {{ videoData.type == 'EVENT' ? 'Live' : 'Video'}}" matTooltipPosition="above"><mat-icon>edit</mat-icon></div>
      <button class="processing-btn" *ngIf="videoData.source == 'CLIP' && videoData.media_processing_status == 'PROCESSING'"><i class="fa fa-spinner fa-spin"></i> Processing</button>
    </a>
  </div>

  <div class='video-data' *ngIf='!videoData.channel_name'>
    <a href="{{mediaLink}}" (click)='goToVideo($event)' class='title link-paragraphs'>{{videoData.title}}</a>
    <a href="{{mediaLink}}" (click)='goToVideo($event)' class='date link-paragraphs' *ngIf="!fromSchedule">{{ videoData.start_datetime || videoData.timestamp | dateTimezone: 'MM/dd/yyyy h:mm a'}}</a>
    <div class='schedule-info'>
      <span class='hour' *ngIf="fromSchedule"><mat-icon>schedule</mat-icon> {{videoData.start_datetime | dateTimezone:'hh:mm aa'}}</span>
      <a class='event-button' *ngIf="videoData.info_button_url" target="_blank" [href]="videoData.info_button_url">{{videoData.info_button_description ? videoData.info_button_description : 'Explore more'}} <mat-icon>open_in_new</mat-icon></a>
    </div>

    <div class='tags' *ngIf="videoData.tags">
      <a [routerLink]="['/tag/' + tag.tag_slug]" class='tag' [ngClass]='{"truncate": videoData.tags.length > 2}' (click)="goToTagDetails(tag, $event)" *ngFor='let tag of videoData.tags'>{{tag.tag_name}}</a>
      <span class='tag tags-count' (click)="goToTagDetails()" *ngIf='videoData.tagsCount > 3' matTooltipClass="tooltip-tags" [matTooltip]="tooltipTags" matTooltipPosition="below">+ {{videoData.tagsCount - 3}} tags</span>
    </div>
  </div>

  <div class='video-data' *ngIf='videoData.channel_name'>
    <a href="{{mediaLink}}" (click)='goToVideo($event)' class='title link-paragraphs'>{{videoData.title}}</a>
    <a href="{{mediaLink}}" (click)='goToVideo($event)' class='purchased link-paragraphs'><span>{{videoData.txn_datetime ? 'Purchased  on ' : (videoData.video_key ? 'Uploaded on ' : '')}}</span>{{videoData.txn_datetime || videoData.timestamp | dateTimezone: 'MM/dd/yyyy h:mm a'}}</a>
    <p class='from'>From <a (click)='goToChannel()' target="_blank">{{videoData.channel_name}}</a></p>
    <a href="{{videoData.downloadUrl}}" *ngIf="videoData.source == 'CLIP' && videoData.media_processing_status != 'PROCESSING'" class='title link-paragraphs' download><i class="fa fa-download"></i> Download</a>
  </div>
</div>

