import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ProfileService } from '../profile.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, AfterViewInit {

  @Output() selectedOption: EventEmitter<any> =  new EventEmitter();

  profileForm: FormGroup;
  profileFormClone: any;
  lodash = _;
  data: any;
  timezones: any = [];
  file: any;
  fileName: any = "Select a File";
  loading: boolean = true;
  hasFile: boolean = false;

  constructor(
    private profileService: ProfileService,
    private snackbar: MatSnackBar,
    public authService: AuthService,
    private el: ElementRef,
    private ref: ChangeDetectorRef
  ) { 

    this.profileForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){1,18}[a-zA-Z0-9]$/)]),
      email: new FormControl({value: '', disabled: this.data?.connected_through == "Facebook"},  [Validators.email, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), Validators.required]),
      currentPassword: new FormControl('', [Validators.minLength(8)]),
      newPassword: new FormControl('', [Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.minLength(8)]),
      timezone: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {

  }

  async ngAfterViewInit() {
    await this.getUserData();
    this.loading = false;
  }

  async getUserData() {
    this.data = await this.profileService.getUserData();
    this.timezones = this.profileService.getTimeZones();

    this.profileForm.patchValue({
      name: this.data.name ? this.data.name : '',
      username: this.data.userName ? this.data.userName : '',
      email: this.data.email ? this.data.email : '',
      timezone: !this.data.timezone ? null : this.data.timezone
    });

    this.profileFormClone = _.cloneDeep(this.profileForm.value);
  }

  async saveChanges() {
    this.checkPasswordValidation();
    this.profileForm.markAllAsTouched();

    if(!this.profileForm.valid) {
      setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 100);
      return;
    }

    Object.keys(this.profileForm.controls).forEach(key => {
      if(key == 'timezone') return;
      encodeURI(this[key].value);
    });

    this.loading = true;

    let response = await this.profileService.updateUserData(this.profileForm.value, this.file);

    if (response?.error) {

      if (response.error.message) {
        this.openSnackBar(
          `${response.error.message}.`,
          "snack-bar-danger"
        );

        this.loading = false;
        return;
      }
      
      this.openSnackBar(
        "Please fill out the fields correctly.",
        "snack-bar-danger"
      );

      this.loading = false;
      return;
    }

    if (response.message) {
      this.openSnackBar(
        `${response.message}`,
        "snack-bar-success"
      );

      await this.profileService.updateProfilePicture(response.data.signed_url, this.file);
      await this.setCurrentUserSession(response.data);

      this.file = null;
      this.fileName = 'Select a File';
      this.hasFile = false;
      this.loading = false;
      this.ref.detectChanges();
      this.profileFormClone = this.profileForm.value;
    }

  }

  async setCurrentUserSession(data) {
    let session: any = await this.authService.getSession();
    let updatedContent: any = {};

    updatedContent.full_name = data.full_name;
    updatedContent.username = data.user_name;
    updatedContent.email = data.email;
    updatedContent.time_zone = data.time_zone;

    if(data.profile_picture) {
      updatedContent.profile_picture = data.profile_picture;
    }

    await this.authService.setSession({
      ...session,
      ...updatedContent
    });
  }

  cleanFileValue(event) {
    event.target.value = '';
  }

  backToProfile() {
    this.selectedOption.emit("");
  }

  onFileChanged(fileInput) {
    if (!fileInput.files.length)
      return;

    let file: File = fileInput.files[0];
    let pattern = /image-*/;

    if (!file.type.match(pattern)) {
      return this.openSnackBar(
        `File ${file.name} is not an image.`,
        "snack-bar-danger"
      );
    }

    this.file = file;
    this.fileName = file.name;
    this.hasFile = true;
  }

  verifyPassword() {
    if(this.confirmPassword.value) {
      this.confirmPassword.setValidators(this.matchValidator());
      this.confirmPassword.updateValueAndValidity();
    }
  }

  matchValidator(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (this.newPassword.value != this.confirmPassword.value)
        return {'different': true};
      else
        return null;
    }
  }

  get name(): AbstractControl {
    return this.profileForm.get('name');
  }

  get username(): AbstractControl {
    return this.profileForm.get('username');
  }

  get email(): AbstractControl {
    return this.profileForm.get('email');
  }

  get currentPassword(): AbstractControl {
    return this.profileForm.get('currentPassword');
  }

  get newPassword(): AbstractControl {
    return this.profileForm.get('newPassword');
  }

  get confirmPassword(): AbstractControl {
    return this.profileForm.get('confirmPassword');
  }

  get timezone(): AbstractControl {
    return this.profileForm.get('timezone');
  }

  checkPasswordValidation() {
    if (this.currentPassword.value || this.newPassword.value || this.confirmPassword.value) {
      this.currentPassword.setValidators([Validators.required]);
      this.newPassword.setValidators([Validators.required]);
      this.confirmPassword.setValidators([Validators.required, this.matchValidator()]);
    } else {
      this.currentPassword.clearValidators();
      this.newPassword.clearValidators();
      this.confirmPassword.clearValidators();
    }

    this.currentPassword.updateValueAndValidity();
    this.newPassword.updateValueAndValidity();
    this.confirmPassword.updateValueAndValidity();
  }

  openSnackBar(message: string, color: string) {
    this.snackbar.open(message, '', {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: color
    });
  }

  scrollToFirstInvalidControl(){
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
    firstInvalidControl.scrollIntoView({behavior: 'smooth'});
  }
}
