import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './analytics.service';
import { ChannelService } from './services/channel.service';
import { TitleService } from './title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vewbie-app';
  constructor(
    private translateService: TranslateService,
    private analyticsService : AnalyticsService,
    private channelService : ChannelService,

  ){
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('lang') || 'en');
    this.analyticsService.trackingOpenHomePage();
    this.getCurrentChannel();

  }

  ngOnInit(){
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  async getCurrentChannel(){
    let channel = await this.channelService.getCurrentChannel();
    if(!channel){
      return;
    }

    if(channel.ga_code){
      this.loadGoogleAnalytics(channel.ga_code);
    }

    //TODO - we should check some config on the channel info instead of doing if's here to enable diDNA ads
    if(channel.host_name == 'venkatesh' || channel.host_name == 'usssa' || channel.host_name == 'iswimtv'){
      //this.loadUSSSADidnaScript(channel.host_name);
      this.loadUSSSAGooglePageAd();
    }

  }


  // private loadUSSSADidnaScript(channelHostName){
  //   let didnaScript = document.createElement('script');
  //   didnaScript.setAttribute('async', 'true');
  //   didnaScript.setAttribute('src', `//storage.googleapis.com/didna_hb/skoresheet/${channelHostName}/didna_config.js`);
  //   document.documentElement.firstChild.appendChild(didnaScript);
  // }

  private loadGoogleAnalytics(trackingID: string): void {

    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);
  
    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;
  
    let gaScript3 = document.createElement('noscript');
    gaScript3.innerText = `<iframe src="https://www.googletagmanager.com/ns.html?id=${ trackingID }" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  
    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
    document.body.appendChild(gaScript3);
  }
  
  private loadUSSSAGooglePageAd(){
    let script = document.createElement('script');
    script.setAttribute('async', 'true');
    script.setAttribute('src', `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3590908160751907`);
    document.documentElement.firstChild.appendChild(script);
  }
}
