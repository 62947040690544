import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StripeService } from '../stripe.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../subscription.service';
import { Location } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
// import { PayPalService } from '../paypal.service';
import jwt_decode from "jwt-decode";
import { RefundComponent } from './refund/refund.component';
import { MatDialog } from '@angular/material/dialog';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;

  couponForm: FormGroup;
  purchaseForm: FormGroup;

  isCoupon: boolean = false;
  invalidCoupon: boolean = false;
  offerSelected: boolean = false;
  loading: boolean = false;
  loadingSave: boolean = false;

  selectedPayment: any = '';

  planSelected: any = null;
  hasError: boolean = false;
  subscriptionFinished: boolean = false;

  plans: any = [];
  paymentType = 'subscription';
  mediaId: any;
  channelId: any;
  payment_type: any;
  ppvType: any;
  subscriptionSlug: any;
  user_id: any;
  token: any;
  coupon: any = null;
  discount_price = null;
  couponData = null;
  title = '';
  couponDisabled = false;
  description = null;
  status = '';
  alternativePurchaseMode = false;
  fullDiscountUrl = false;
  from: string = "";
  fromList : boolean = false;
  fixedPrice: boolean = false;
  shouldGoBack: boolean = false;
  media: any = null;
  method: any = null;
  hideSubscriptions: boolean = false;
  subscriptionsIds: any = [];
  hideSelectAnotherSubscription: boolean = false;
  content_access_type = '';

  constructor(
    private route: ActivatedRoute,
    private readonly subscriptionService : SubscriptionService,
    public stripeService: StripeService,
    // public paypalService: PayPalService,
    private router: Router,
    private location: Location,
    private el: ElementRef,
    private dialog: MatDialog
  ) {
    this.route.queryParams.subscribe(params => {
      this.mediaId = params['mid'];
    });
  }

  async ngOnInit()  {    

    let params = this.route.snapshot.params;

    if(!params.token){
      throw 'invalid token';
    }

    let {
      method,
      channelId,
      mediaId,
      fromList,
      description,
      payment_type,
      redeemCoupon,
      ppvType,
      fullDiscountUrl,
      subscriptionSlug,
      user_id, coupon_code,
      discount_price,
      subscription_ids,
      hideSelectAnotherSubscription,
      from,
      fixedPrice,
      shouldGoBack,
      hideSubscriptions,
      content_access_type
    } : any = jwt_decode( decodeURIComponent(params.token));

    this.fullDiscountUrl = fullDiscountUrl;
    this.status = params.status;
    this.fromList = fromList;
    this.token = params.token;
    this.channelId = channelId;
    this.mediaId = mediaId;
    this.from = from;
    this.fixedPrice = fixedPrice;
    this.payment_type = payment_type;
    this.shouldGoBack = shouldGoBack;
    this.method = method;
    this.hideSubscriptions = hideSubscriptions;
    this.content_access_type = content_access_type

    if(hideSelectAnotherSubscription) {
      this.hideSelectAnotherSubscription = hideSelectAnotherSubscription;
    }

    this.ppvType = ppvType;
    this.subscriptionSlug = subscriptionSlug;
    this.user_id = user_id;
    this.coupon = coupon_code;
    this.subscriptionsIds = subscription_ids;
    this.description = description;
    this.debugLog(`>>> description ${description}`);
    this.debugLog(`>>> payment_type ${payment_type}`);
    this.debugLog(`>>> subscriptionSlug ${subscriptionSlug}`);
    this.debugLog(`>>> method ${method}`);
    this.loading = true;

    if(this.fullDiscountUrl){
      this.alternativePurchaseMode = true;
    }

    if(this.status == 'success' && !this.subscriptionSlug && this.mediaId && this.fixedPrice) {
      method = 'fixed';
    }

    if(method == 'season'){
      const subscription = await this.subscriptionService.getSubscriptionBySlug({
        slug: this.subscriptionSlug,
        channelId,
        token: this.token
      });

      if(!subscription){
        throw new Error("invalid slug preparar uma tela para isso");
      }

      this.planSelected = subscription;
      this.offerSelected = true;
      this.getForm();

      await this.getPlans(channelId, ppvType, this.mediaId);

    }

    if(method == 'fixed'){

      if(Array.isArray(this.mediaId)) {        
        
        const medias = await this.subscriptionService.getMediaByMediaIdsArray({
          mediaId,
          token: this.token
        });

        if(!medias || (medias && !medias.length)){
          throw new Error("Media is empty or not an array");
        }

      } else {
        const media = await this.subscriptionService.getMediaByMediaId({
          mediaId: this.mediaId,
          token: this.token
        });
        
        if(!media || (media && !media.id)){
          throw new Error("invalid mediaId preparar uma tela para isso");
        }
        this.planSelected = media;
        this.offerSelected = true;
      }
      this.getForm();
    }

    if(method == 'subscription'){
      redeemCoupon = true;
      const subscription = await this.subscriptionService.getSubscriptionBySlug({
        slug: this.subscriptionSlug,
        channelId,
        token: this.token
      });

      if(!subscription){
        throw new Error("invalid slug preparar uma tela para isso");
      }

      this.planSelected = subscription;
      this.offerSelected = true;
      this.getForm();

      await this.getPlans(channelId, ppvType, this.mediaId);

    }

    if(method == 'subscriptions'){
      this.offerSelected = false;
      if(this.subscriptionSlug){
        const subscription = await this.subscriptionService.getSubscriptionBySlug({
          slug: this.subscriptionSlug,
          channelId,
          token: this.token
        });

        if(!subscription){
          throw new Error("invalid slug preparar uma tela para isso");
        }

        this.planSelected = subscription;
        this.offerSelected = true;
        this.getForm();
      }

      await this.getPlans(channelId, ppvType, this.mediaId);
    }

    this.debugLog(`>>> redeemCoupon ${redeemCoupon}`);
    // if(!redeemCoupon){
    //   this.couponDisabled = true;
    // }
    this.debugLog(`>>> subscriptionFinished ${this.subscriptionFinished}`);
    this.debugLog(`>>> this.coupon ${this.coupon}`);
    if(this.coupon){
      // await this.activateCoupon();
      this.invalidCoupon = false;
      this.isCoupon = false;
      this.discount_price = discount_price;
    }

    this.loading = false;
    this.statusTreatment()
    this.changePayment('stripe');
  }

  selectMedia(media) {
    this.couponDisabled = true;
    this.planSelected = media;
    this.payment_type = 'fixed';

    this.offerSelected = true;
    this.getForm();
  }

  billingFrequencyTreatment(plan){
    let frequency = plan.billing_frequency  || plan.offer_period;

    if(!frequency){
      return
    }

    if(plan.billing_interval == 1) {
      if(frequency.toUpperCase() == 'YEAR'){
        return 'annually';
      }
      else if(frequency.toUpperCase() == 'MONTH'){
        return 'monthly';
      }
      else if(frequency.toUpperCase() == 'DAY'){
        return 'daily'
      }
      else if(frequency.toUpperCase() == 'WEEK'){
        return 'weekly'
      }
    }

    if(plan.billing_interval > 1) {
      return `${plan.billing_interval} ${plan.billing_frequency.toLowerCase()}`
    }


  }

  getTitle(){
    if(this.payment_type == 'fixed'){
      return 'Video/Livestream';
    }
    else if(this.ppvType == 'VE'){
      return 'Video Editor';
    }
    else if(this.ppvType == 'PPV'){
      return 'Subscription';
    }
    else{
      return 'Subscription';
    }
  }

  getRenewed(plan){
    let frequency = plan.billing_frequency  || plan.offer_period;

    if(!frequency){
      return
    }

    if(frequency.toUpperCase() == 'YEAR'){
      return 'yearly';
    }
    else if(frequency.toUpperCase() == 'MONTH'){
      return 'monthly';
    }
    else if(frequency.toUpperCase() == 'DAY'){
      return 'diary'
    }
    else if(frequency.toUpperCase() == 'WEEK'){
      return 'weekly'
    }
  }

  statusTreatment(){
    if(!this.status || !this.planSelected){
      return
    }

    this.offerSelected = true;
    this.loading = false;
    if(this.status == 'success') {
      this.subscriptionFinished = true;
      setTimeout(() => {
        this.stepper.next();

        setTimeout(() => {
          const element: HTMLElement = this.el.nativeElement.querySelector(".done");
          element.scrollIntoView({behavior: 'smooth'});
        }, 1000);
      }, 1);
    }
    else if(this.status == 'error') {
      this.hasError = true;

      setTimeout(() => {
        const element: HTMLElement = this.el.nativeElement.querySelector(".error-form");
        element.scrollIntoView({behavior: 'smooth'});
      }, 1000);
    }
  }

  getForm() {
    this.purchaseForm = new FormGroup({
      payment : new FormControl('stripe', [Validators.required]),
      agreeTerms: new FormControl(false, [Validators.requiredTrue]),
      agreePlan: new FormControl(false,  this.planSelected && this.planSelected.is_recurring == "1" ? [Validators.requiredTrue] : null)
    });
  }

  async getPlans(channelId, ppvType, mediaId) {

    // if(this.payment_type == 'fixed'){
    //   return
    // }

    this.getForm();

    this.loading = true;

    if(mediaId && !this.fixedPrice){
      this.plans = await this.subscriptionService.getSubscriptionsByMediaId({
        token: this.token,
        mediaId : this.mediaId
      });

    }
    else{
      this.plans = await this.subscriptionService.getAllSubscriptions(channelId, ppvType, this.token, this.subscriptionsIds);


      if(mediaId && this.fixedPrice) {
        this.media = await this.subscriptionService.getMediaByMediaId({
          mediaId,
          token: this.token
        });
      }
    }

    this.loading = false;
  }

  reedemCoupon() {
    this.isCoupon = true;
    this.invalidCoupon = false;
  }

  removeCoupon() {
    this.invalidCoupon = false;
    this.discount_price = null;
    this.alternativePurchaseMode = false;
    this.coupon = null;
  }

  async activateCoupon() {

    this.discount_price = null;
    this.couponData = null;
    this.alternativePurchaseMode = false;

    if(!this.planSelected){
      return;
    }

    if (!this.coupon) {
      this.invalidCoupon = true;
      return;
    }

    let cuponValidate = await this.subscriptionService.validateCupon({
      coupon_code: this.coupon,
      id: this.planSelected.id,
      type: this.payment_type,
      userId: this.user_id,
      channelId: this.channelId,
      token: this.token
    });

    if (cuponValidate && cuponValidate.error) {
      this.invalidCoupon = true;
      return;
    }

    this.discount_price = cuponValidate.discount_price;
    this.couponData = cuponValidate;
    
    if(this.discount_price == 0 && this.coupon && !this.status){
      this.alternativePurchaseMode = true
    }

    this.invalidCoupon = false;
    this.isCoupon = false;
  }

  changePayment(method) {
    this.selectedPayment = method;
    this.purchaseForm.get('payment').setValue(method);
  }

  async save() {
    this.loadingSave = true;
    if(this.selectedPayment == 'stripe') {
      await this.stripeService.loadStripeInstance();
      const stripeSession = await this.stripeService.createStripeSession({
        slug: this.planSelected.subscription_slug,
        subscription_id: this.planSelected.id,
        payment_type : this.payment_type,
        media_id: this.mediaId,
        token: this.token,
        channelId: this.channelId,
        ppvType: this.ppvType,
        user_id: this.user_id + '',
        coupon_code: this.discount_price ? this.coupon  : null
      });
      await this.stripeService.redirectToCheckout(stripeSession);
    } else {

      // const paypalSession = await this.paypalService.createPaypalSession({
      //   slug: this.planSelected.subscription_slug,
      //   payment_type : this.payment_type,
      //   media_id: this.mediaId,
      //   token: this.token,
      //   channelId: this.channelId,
      //   ppvType: this.ppvType,
      //   user_id: this.user_id + '',
      //   coupon_code: this.discount_price ? this.coupon  : null,
      //   plan_id: this.planSelected.id
      // });

      // window.open(paypalSession.checkout_link, "_self")
    }
    this.loadingSave = false;
  }

  async buySubscription(){
    await this.subscriptionService.buySubscription(
      {
        payment_type : this.payment_type,
        token: this.token,
        channelId: this.channelId,
        user_id: this.user_id + '',
        coupon_code: this.discount_price ? this.coupon  : null,
        id: this.planSelected.id,
        method: this.payment_type,
        subscriptionSlug: this.planSelected.subscription_slug
      }
    );

  }

  async selectOffer(plan) {
    this.offerSelected = true;
    this.discount_price = null;
    this.alternativePurchaseMode = false;
    this.coupon = null;
    this.couponData = null;
    this.planSelected = plan;
    this.couponDisabled = true;

    if(this.ppvType == 'VE' || this.planSelected.is_recurring == "0" ){
      this.payment_type = 'season';
    }
    else if(this.planSelected.is_recurring == "1"){
      this.payment_type = 'subscription';
    }

    if(this.planSelected.is_recurring == "1" || (this.ppvType == 'PPV' && this.planSelected.is_recurring == "0")){
      this.couponDisabled = false;
    }

    this.getForm();
  }

  resetAll() {
    this.couponForm.reset();
    this.purchaseForm.reset();

    this.selectedPayment = "";
    this.hasError = false;
    this.offerSelected = false;
    this.isCoupon = false;
  }

  closePopUp(success: string){
    if(this.from) {
      this.router.navigate([this.from],{queryParams: {success: success}});
    }

    window.parent.close();
  }

  returnListingPlans(){

    if(this.shouldGoBack && (this.from) && !(this.fromList && this.subscriptionsIds && this.subscriptionsIds.length > 0  )) {
      this.router.navigate([this.from]);
      // this.location.back();
      return
    }

    this.offerSelected = false;
    this.planSelected = null;
    this.removeCoupon();
  }

  goBack(){
    this.router.navigate([this.from]);
  }

  tryAgain() {
    this.router.navigate([`payment/${this.token}`]);
    this.getForm();
  }


  getTitleOfDownloadButton(){
    return 'payment.download.title'
  }
  getDescriptionOfDownloadButton(){
    switch (this.content_access_type){
      case 'VIEW':
        return 'payment.download.description.view'
      case 'VIEW_DOWNLOAD':
        return 'payment.download.description.view-download'
      default:
        return 'payment.download.description.download'
    }
  }
  async openRefund() {
    const dialogRef = this.dialog.open(RefundComponent, {
      maxHeight: "80vh",
      maxWidth: "70vw",
      panelClass: "refund-policy",
	    autoFocus : false
    });

    let result = await dialogRef.afterClosed().toPromise();

    if (!result) {
      this.purchaseForm.get('agreeTerms').setValue(false);
    }
  }

  debugLog(message){
    if(environment.debug){
      console.log(message)
    }
  }
}
