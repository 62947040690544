import { Component, OnInit } from '@angular/core';
import { ScheduleService } from './schedule.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  loading: boolean = false;
  loadingMore: boolean = false;

  events: any = [];
  groupedEvents: any = [];
  groupedDates: any = [];
  
  limit: number = 10;
  offset: number = 0;
  blockScroll: boolean = false;
  isMobile: boolean;
  
  media_slug: string = "";

  constructor(
    private scheduleService: ScheduleService
  ) { }

  async ngOnInit() {
    if(window.innerWidth <= 768) {
      this.isMobile = true;
    }

    this.loading = true;
    await this.getEvents();
    this.loading = false;
  }

  async getEvents(onScroll: boolean = false) {
    this.offset += this.limit;

    if(!onScroll) {
      this.offset = 0;
      this.blockScroll = false;
    }

    let newEvents = await this.scheduleService.getScheduleEvents(this.limit, this.offset); 

    newEvents.forEach(event => {
      event.type = "EVENT"
    })

    if(!newEvents || newEvents.length < this.limit) {
      this.blockScroll = true;
    }

    this.events = this.events.concat(newEvents);

    this.groupedEvents = _.groupBy(this.events, event => {
      return event.start_datetime;
    });

    this.groupedDates = Object.keys(_.cloneDeep(this.groupedEvents));
    this.groupedEvents = Object.values(_.cloneDeep(this.groupedEvents));
  }

  async onScroll() {
    if(!this.blockScroll && !this.loadingMore) {
      this.loadingMore = true;
      await this.getEvents(true);
      this.loadingMore = false;
    }
  }

}
