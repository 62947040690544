<div class='content'>
  <mat-icon class='close-button' (click)='close()'>close</mat-icon>

  <div class='filter'>
    <mat-form-field class="search">
      <mat-label>{{'filters.search' | translate}}</mat-label>
      <input matInput type="text" placeholder="Find Your Video / Event" [value]="searchValue" (keyup)="onSearch($event.target.value)">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
 
    <mat-form-field class='category-filter' appearance="standard" *ngIf="data?.category?.subcategories || data?.categories?.length">
      <mat-label>{{'filters.categories' | translate}}</mat-label>
      <mat-select multiple (selectionChange)="onFilter($event.value)" [(ngModel)]="subCategoriesSelected">
        <mat-option *ngFor="let subCategory of data?.subCategories" [value]="subCategory.category_slug">{{subCategory.category_name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class='date-filter' appearance="standard" *ngIf="data?.simpleFilter != 'live' && data?.simpleFilter != 'most-viewed'">
      <mat-label>{{'filters.enter-date' | translate}}</mat-label>
      <mat-date-range-input
        [rangePicker]="campaignOnePicker"
        [comparisonStart]="startDateRange"
        [comparisonEnd]="endDateRange"
        (click)="campaignOnePicker.open()">
        <input matStartDate placeholder="Start date" readonly [(ngModel)]="startDateRange">
        <input matEndDate placeholder="End date" readonly [(ngModel)]="endDateRange" (dateChange)="changeDate('change', $event)">
      </mat-date-range-input>
      <mat-datepicker-toggle disabled="false" matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
      <mat-date-range-picker  disabled="false" #campaignOnePicker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="outline" class='sort-filter' *ngIf="data?.simpleFilter != 'recent-videos'">
      <mat-label>{{'filters.sort-by.title' | translate}}</mat-label>
      <mat-select [(value)]="orderBy" (selectionChange)="onSort($event.value)" disableOptionCentering>
        <mat-option *ngIf="!data?.simpleFilter" value="recent">{{'filters.sort-by.latest' | translate}}</mat-option>
        <mat-option *ngIf="!data?.simpleFilter" value="older">{{'filters.sort-by.older' | translate}}</mat-option>
        <mat-option value="ascendingCharacters">{{'filters.sort-by.a-to-z' | translate}}</mat-option>
        <mat-option value="descendingCharacters">{{'filters.sort-by.z-to-a' | translate}}</mat-option>
        <mat-option *ngIf="data?.simpleFilter == 'most-viewed'" value="total_unique_views">Most Viewed</mat-option>
      </mat-select>
    </mat-form-field>

    <div class='buttons'>
      <div class='reset-button' (click)="search(true)">{{'filters.reset' | translate}}</div>
      <div class='search-button' (click)="search()">{{'filters.search' | translate}}</div>
    </div>
  </div>
</div>