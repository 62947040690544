import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { CategoryService } from 'src/app/category.service';
import { VideosService } from 'src/app/services/videos.service';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics.service';
import { ChannelService } from 'src/app/services/channel.service';
import { ObservableService } from 'src/app/services/observable.service';
import { ScheduleService } from '../schedule/schedule.service';
import * as moment from 'moment';
import { SnackAdsService } from 'src/app/snack-ads.service';

declare function killSlot(adSlots: string[]): void;
declare function refreshBid(adSlots: string[]): void;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, AfterViewInit {
  @ViewChild("mainCarousel") carousel: any;

  isLogged: boolean = false;
  loading: boolean = true;
  loadingCategories: boolean = false;

  bannerCounter: number = 3;

  allowChangeCarousel: boolean = true;

  groupedVideos: any = [];

  indexArray: any = [];

  selectedLang: any = 'en';

  steps: any = [];
  currentStep: number = 0;

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    nav: false,
    navSpeed: 400,
    margin: 0,
    rewind: true,
    stagePadding: 0,
    mouseDrag: false,
    responsive: {
      0: {
        items: 1.05,
        mouseDrag: true,
      },
      1024: {
        items: 2,
      },
      1280: {
        items: 3,
      },
      1366: {
        items: 3,
      },
      1440: {
        items: 3,
      },
      1600: {
        items: 3,
      },
      1680: {
        items: 3,
      },
      1920: {
        items: 3,
      }
    },
    center: true,
  }

  videos: any[];
  lives: any = [];
  eventsSchedule: any = [];

  categorySlug: any;

  hostName: any;

  channel: any;

  categoriesPromotedIds: any = [];
  mostViewedVideos: any = [];
  recentVideos: any = [];
  categoriesGroup: any = [];
  categorySelectedId: number;
  isMobile: boolean = false;
  categoriesCount: number = 0;
  blockLoadingCategories: boolean = false;
  adblockActivated: boolean = false;
  routeSubscription: Subscription;
  observableServiceSubscription: Subscription;

  snackMobileAds: any = ['snack_mex8', 'snack_mex9', 'snack_mex10', 'snack_mex11', 'snack_mex12'];
  snackDesktopAds: any = ['snack_dex8', 'snack_dex9', 'snack_dex10', 'snack_dex11', 'snack_dex12'];


  constructor(
    public authService: AuthService,
    private videosService: VideosService,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService : AnalyticsService,
    private channelService : ChannelService,
    private observableService : ObservableService,
    private scheduleService : ScheduleService,
    private snackAdsService : SnackAdsService
  ) {
    this.observableServiceSubscription = this.observableService.getChangeCarouselByArrowObservable().subscribe((data)=>{
      this.allowChangeCarousel = data;
    });
  }

  async ngOnInit() {
    if(window.innerWidth <= 768) {
      this.isMobile = true;
    }

    if(!(<any>window).canRunAds){
      this.adblockActivated = true;
    }
    
    this.getCurrentChannel();

    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.blockLoadingCategories = false;
        this.getData();        
      }
    });

    await this.authService.createRoutine();
    const session : any = await this.authService.getSession();

    this.isLogged = session;
  }

  async ngAfterViewInit() {
    this.keyboardNavigation();

    await this.getData();
    

    if(this.hostName != 'usssa' && this.hostName != 'iswimtv') {
      this.loadGoogleAds();
    }
  }
  
  loadGoogleAds() {
    let adScript = document.createElement('script');
    adScript.innerText = `setTimeout(() => {
      [].forEach.call(document.querySelectorAll('.adsbygoogle'), function(){
        (adsbygoogle = window.adsbygoogle || []).push({});
      });
    }, 10000);`;
    document.body.appendChild(adScript);
   
  }

  async getCurrentChannel(){
    this.channel = await this.channelService.getCurrentChannel();
    this.hostName = this.channel?.host_name;

    if(!this.adblockActivated){
      this.loadAds();
    }
    
  }


  keyboardNavigation() {
    document.getElementById('search-input').addEventListener("focus", event => {
      this.allowChangeCarousel = false;
    })
    document.getElementById('search-input').addEventListener("focusout", event => {
      this.allowChangeCarousel = true;
    })

    document.addEventListener("keyup", event => {
      if(this.allowChangeCarousel) {
        if (event.code === "ArrowLeft") {
          this.carousel.prev();
        } else if (event.code === "ArrowRight") {
          this.carousel.next();
        }
      }
    })
  }

  async getData() {
    this.loading = true;
    this.steps = [];
    this.currentStep = 0;

    this.categorySlug = this.route.snapshot.paramMap.get('categorySlug');

    if(this.categorySlug) {
      this.categorySelectedId = await this.categoryService.getCategoryIdBySlug(this.categorySlug);

      if(!this.categorySelectedId) {
        this.router.navigateByUrl("");
      }

      this.groupedVideos = await this.videosService.getAllPromotedMediaFromCategory([{id: this.categorySelectedId}]);

      if(this.groupedVideos && this.groupedVideos.length) {
        this.groupedVideos.forEach(video => {
          video.seeAll = `/categories/${video.category_slug}`
        })
      } else if(!this.groupedVideos || !this.groupedVideos.length) {
        await this.getGroupedVideos();
      }
    }

    let firstPromises = [this.getBanner(), this.getLives(), this.getScheduleEvents()];
    await Promise.all(firstPromises);
    let promises = [this.getAllCategoriesGroup(), this.getMostViewedVideos(), this.getRecenteVideos()];
    if(!this.categorySlug) {
      promises.push(this.getPromotedCategories());
    }
    await Promise.all(promises);

    let hasContent = [this.lives?.length, this.mostViewedVideos?.length, this.recentVideos?.length]

    this.loading = false;
    
    if(hasContent.filter(content => content).length < 2 && !this.blockLoadingCategories){
      await this.getGroupedVideos();
    }
  }

  ngOnDestroy() {
    if(this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if(this.observableServiceSubscription) {
      this.observableServiceSubscription.unsubscribe();
    }
  }

  async getAllCategoriesGroup() {
    try {
      this.categoriesGroup = await this.categoryService.getAllCategories('priority', 'asc');
  
      if(this.categorySelectedId) {
        let categorySelected = this.categoriesGroup.filter(category => category.id == this.categorySelectedId);
        this.categoriesGroup = categorySelected[0].sub_categories;
      } else if(this.categoriesGroup) {
        if (this.categoriesGroup.length > 5) {
          if(this.isMobile) {
            this.categoriesGroup = this.categoriesGroup.slice(5, 11);
          } else {
            this.categoriesGroup = this.categoriesGroup.slice(5);
          }
        }
      }
    } 
    catch (err) {
      console.log(err);
    }
  }

  async getPromotedCategories() {
    try {
      this.categoriesPromotedIds = await this.videosService.getAllPublicPromotedCategories();
    }
    catch(err) {
      console.log("err", err);
    }
  }

  async getScheduleEvents() {
    try {
      let eventsSchedule = await this.scheduleService.getScheduleEvents(20, 0);
  
      if(eventsSchedule && Array.isArray(eventsSchedule)) {
        eventsSchedule = eventsSchedule.filter(e =>  {
          if(!e.online ||  ( moment(e.start_datetime).isAfter() && e.online) ){
            return e;
          }
        });
  
        this.eventsSchedule = eventsSchedule;
      }
    }
    catch(err) {
      console.log(err);
    }
  }

  async getLives() {
    try {
      if(this.categorySelectedId) {
        this.lives = await this.videosService.getAllLivesByCategory(this.categorySelectedId);
      } else {
        this.lives = await this.videosService.getAllLives();
      }
    }
    catch(err){
      console.log("err", err);
    }
  }

  async getMostViewedVideos() {
    try {
      if(this.categorySelectedId) {
        this.mostViewedVideos = await this.videosService.getMostViewedVideosByCategory(this.categorySelectedId);
      } else {
        this.mostViewedVideos = await this.videosService.getMostViewedVideos(4, 0);
      }
    }
    catch(err){
      console.log("err", err);
    }
  }

  async getRecenteVideos() {
    try {
      if(this.categorySelectedId) {
        this.recentVideos = await this.videosService.getRecentVideosByCategory(this.categorySelectedId);
      } else {
        this.recentVideos = await this.videosService.getRecentVideos();
      }
    } 
    catch (err) {
      console.log(err)      
    }
  }

  async getBanner() {
    try {
      if(this.categorySelectedId) {
        this.videos = await this.videosService.getAllPromotedMediasByCategory(this.categorySelectedId);
      } else {
        this.videos = await this.videosService.getAllPromotedMedias();
      }
  
      for (let index = 0; index < this.videos?.length; index++) {
        this.steps.push({
          'number': index,
          'active': index == 0 ? true : false
        })
      }
    }
    catch(err) {
      console.log(err);
    }
  }

  async goToStep(stepNumber) {
    this.carousel.to(`slide-${stepNumber}`);
  }

  getSlide(event: SlidesOutputData) {
    this.steps[this.currentStep].active = false;
    this.currentStep = event.startPosition;
    this.steps[this.currentStep].active = true;

    let mainCarousel = document.getElementById('main-slider');

    setTimeout(() => {
      let previous = this.currentStep - 1, next = this.currentStep + 1;

      if (this.currentStep == 0) {
        previous = this.steps.length - 1;
      } else if(this.currentStep == this.steps.length - 1) {
        next = 0;
      }

      let items = mainCarousel.querySelectorAll(".owl-item:not(.cloned)");

      items.forEach(item => item.classList.remove("opacity-class"))

      items[previous].classList.add("opacity-class");
      items[next].classList.add("opacity-class");

    }, 10);
  }

  trackingCategoryPromotedCarousel(){
    this.analyticsService.trackingCategoryPromotedCarousel()
  }

  async getGroupedVideos() {
    let firstIndex = this.categoriesCount;
    let lastIndex = this.categoriesCount += 2;
    this.loadingCategories = true;
    let videos = [];
    if(this.categorySelectedId) {
      let sub_videos = await this.videosService.getAllPromotedMediaFromSubCategories(this.categorySlug);
      if(sub_videos) {
        this.groupedVideos = this.groupedVideos.concat(sub_videos);
      }
      this.blockLoadingCategories = true;
    } else {
      if(this.categoriesPromotedIds.categories){
        let categoriesId: any = this.categoriesPromotedIds.categories.slice(firstIndex, lastIndex);
        if(categoriesId && categoriesId.length) {
          videos = await this.videosService.getAllPromotedMediaFromCategory(categoriesId);
        }
      }
    }

    if(videos) {
      videos.forEach(video => {
        video.seeAll = `/categories/${video.category_slug}`
      })

      this.groupedVideos = this.groupedVideos.concat(videos);
    }
    else {
      this.blockLoadingCategories = true;
    }

    this.groupedVideos.forEach((group, index) => {
      group.index = index;
    });

    this.loadingCategories = false;
  }

  async onScroll() {
    if(!this.loading && !this.blockLoadingCategories && !this.loadingCategories) {
      await this.getGroupedVideos();

      /* if(this.adblockActivated){
        return;
      }
      // generate banner dynamically
      let didna = (<any> window).didna;
      let adUnits =  (<any>window).adUnits || [];
      let player = (<any>window).player;

      this.groupedVideos.forEach(group => {
        let count = group.index + 4;
        if((group.index % 2) == 1 && this.indexArray.indexOf(count) == -1) {
          this.indexArray.push(count);
          didna.removeAdUnits()

          adUnits.push({
            adUnitPath: '/21807752792/usssa/live_728',
            id: `ad_unit_homepage_header_usssa_${group.index + 4}`,
            size: [[728, 90],[320,100]],
            sizeMap: player
          })

          didna.createAdUnits(adUnits);
        }
      }); */

      this.groupedVideos.forEach(group => {
        let count = group.index;
        let adIndex = Math.floor(group.index / 2);
        if(Math.ceil(count/2) <= this.snackMobileAds.length && (group.index % 2) == 1 && adIndex < this.snackMobileAds.length){
          setTimeout(() => {
            if (this.isMobile){
              killSlot([this.snackMobileAds[adIndex]]);
              refreshBid([this.snackMobileAds[adIndex]]);
            } else {
              killSlot([this.snackDesktopAds[adIndex]]);
              refreshBid([this.snackDesktopAds[adIndex]]);
            }
          },  1000 );
        }

      });
    }
  }

  ceil(value: number): number {
    return Math.ceil(value);
  }

  floor(value: number): number {
    return Math.floor(value);
  }

  loadAds(){
    setTimeout(()=>{
      this.snackAdsService.setupHomePageAds();
    }, 2000);
  }
}
