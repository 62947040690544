import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { SearchService } from './search.service';
import * as moment from 'moment-timezone';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FiltersComponent } from 'src/app/filters/filters.component';
import { AuthService } from 'src/app/auth/auth.service';
import { ChannelService } from 'src/app/services/channel.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {

  private playerSearchSubject: Subject<string> = new Subject();
  private playerSearchSubscription: Subscription;

  searchTagInput = new FormControl('');
  nextRequestSearch = null;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  
  orderBy: string = 'recent';
  orderByCategory: string = 'all';
  filter: any = {
    columnOrder: 'timestamp',
    order: 'DESC'
  };

  loading: boolean = true;
  loadingMore: boolean = false;
  allLoading: boolean = false;
  livematchesLoading: boolean = false;
  videosLoading: boolean = false;

  filtered: any = "";
  selectedCategory: any = "";
  searchValue: string = "";

  currentTab: string = "all";

  limit: number = 12;

  //offset
  allPage: number = 0;
  livematchesPage: number = 0;
  videosPage: number = 0;

  blockScroll: boolean = false;
  allBlockScroll: boolean = false;
  livematchesBlockScroll: boolean = false;
  videosBlockScroll: boolean = false;

  onlyVideo : boolean = false;
  noMedias: boolean = false;

  channel: any;

  allData: any = [];
  livesData: any = [];
  videosData: any = [];
  categories: any = [];
  fullWidth: boolean;
  filterActive: boolean;
  categoriesSelected: any = [];

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private dialog: MatDialog,
    private authService: AuthService,
    private channelService : ChannelService,
  ) { }

  async ngOnInit() {

    this.channel = await this.channelService.getCurrentChannel();

    if(window.innerWidth <= 768) {
      this.fullWidth = true;
    }

    this.playerSearchSubscription = this.playerSearchSubject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {
      this.search(searchTextValue);
    });

    this.route.queryParams.subscribe(params => {
      this.searchValue = params['search_query'];
      this.searchTagInput.setValue(this.searchValue);
      this.getData(false);
    });

    if(this.searchValue) {
      this.searchTagInput.setValue(this.searchValue);
    }
  }

  ngOnDestroy() {
    if (this.playerSearchSubscription) {
      this.playerSearchSubscription.unsubscribe();
    }
  }

  async ngAfterViewInit() {
    let promises = [this.getCategories()];
    await Promise.all(promises);
    this.loading = false;
  }

  async getData(onScroll: boolean, changeTab: boolean = false) {
    if(!onScroll) {
      this.allPage = 0;
      this.livematchesPage = 0;
      this.videosPage = 0;

      this.allBlockScroll = false;
      this.livematchesBlockScroll = false;
      this.videosBlockScroll = false;

      this.noMedias = false;

      this.onlyVideo = false;

      this.allData = [];
      this.livesData = [];
      this.videosData = [];
    } else if(!changeTab) {
      this[`${this.currentTab}Page`] += this.limit;
    }

    switch (this.currentTab) {
      case 'all':
          this.allLoading = true;
        break;
      case 'livematches':
          this.livematchesLoading = true;
        break;
      case 'videos':  
          this.videosLoading = true;
        break;
    }

    let date_start = null;
    let date_end = null;
    if (this.range.value.start && this.range.value.end) {
      date_start = moment(this.range.value.start).startOf('day').toISOString();
      date_end = moment(this.range.value.end).endOf('day').toISOString();
      let userTimezone = this.authService.getTimezone();
      
      if (userTimezone) {
        date_start = moment.tz(moment(this.range.value.start).format('DD/MM/YYYY') + ' 00:00:00', 'DD/MM/YYYY HH:mm:ss', userTimezone).toISOString();
        date_end = moment.tz(moment(this.range.value.start).format('DD/MM/YYYY') + ' 23:59:59', 'DD/MM/YYYY HH:mm:ss', userTimezone).toISOString();
      }
    }

    let filters = {
      columnFilter: this.currentTab, 
      columnOrder: this.filter.columnOrder, 
      order: this.filter.order, 
      categories_slug: this.categoriesSelected.length ? JSON.stringify(this.categoriesSelected) : [],
      start_date: date_start,
      end_date: date_end,
      search: this.searchValue
    }

    this[`${this.currentTab}Loading`] = true;
    let data = await this.searchService.getSearchedData(filters, this.limit, this[`${this.currentTab}Page`]);
    

    switch (this.currentTab) {
      case 'all':
          this.allLoading = false;
        break;
      case 'livematches':
          this.livematchesLoading = false;
        break;
      case 'videos':  
          this.videosLoading = false;
        break;
    }

    if(!data || data.length == 1 || data.length < this.limit) {
      this[`${this.currentTab}BlockScroll`] = true;
    }
    // const existLiveOnline = data.find(media => media.online);

    // if(this.currentTab == 'all' && !existLiveOnline ) {
    //   this.onlyVideo = !existLiveOnline;
    //   this.currentTab = 'videos';
    // }
    let tempAllData = [];
    switch (this.currentTab) {
      case "all":
        tempAllData = this.allData.concat(data); 
        this.allData = this.orderByMedias(tempAllData);
        break;
      case "livematches":
        tempAllData = this.livesData.concat(data); 
        this.livesData = this.orderByMedias(tempAllData);
        break;
      case "videos":
        tempAllData = this.videosData.concat(data); 
        this.videosData = this.orderByMedias(tempAllData);
        break;
    }

    this[`${this.currentTab}Loading`] = false;
  }

  orderByMedias(medias){
    if(this.filter.columnOrder == 'timestamp' ){    
      medias = medias.sort((current, next) => {
        if(this.filter.order == 'DESC'){
          return next.timestamp > current.timestamp ? 1 : -1;
        }
        
        if(this.filter.order == 'ASC'){
          return next.timestamp > current.timestamp || (!current.timestamp && next.timestamp) ? -1 : 1;
        }
        
      })
    }
    
    return medias;
  }

  async getCategories() {
    this.loading = true;
    this.categories = await this.searchService.getCategories();
    this.loading = false;
  }

  onSearch(searchTextValue){
    this.playerSearchSubject.next(searchTextValue);
  }

  async search(searchTextValue){
    this.searchValue = searchTextValue;
    clearTimeout(this.nextRequestSearch); 
    this.nextRequestSearch = setTimeout(async ()=> {
      await this.getData(false);
    }, 1000);
  }

  async onFilter(value: string, mobile?: boolean) {
    this.orderBy = value;

    if (value == 'recent' || value == 'older') {
      this.filter.columnOrder = 'timestamp';
    }
    else {
      this.filter.columnOrder = 'title';
    }

    if (value == 'ascendingCharacters' || value == 'older') {
      this.filter.order = 'ASC';
    } 
    else {
      this.filter.order = 'DESC';
    }

    if (!mobile) {
      clearTimeout(this.nextRequestSearch); 
      this.nextRequestSearch = setTimeout(async ()=> {
        await this.getData(false);
      }, 1000);
    }
  }

  async onFilterCategories(value) {
    this.categoriesSelected = value;
    clearTimeout(this.nextRequestSearch); 
    this.nextRequestSearch = setTimeout(async ()=> {
      await this.getData(false);
    }, 1000);

  }

  async changeDate(event, value) {
    clearTimeout(this.nextRequestSearch); 
    this.nextRequestSearch = setTimeout(async () => {
      if( this.range.value.start && this.range.value.end) {
        await this.getData(false);
      }
    }, 400);
  }

  async setCurrentTab(tab) {
    let blockScroll: boolean;
    switch (tab.index) {
      case 0:
        this.currentTab = 'all';
        blockScroll = this.allBlockScroll;
        break;
      case 1:
        if(!this.onlyVideo) {
          this.currentTab = 'livematches';
          blockScroll = this.livematchesBlockScroll;
        } else {
          this.currentTab = 'videos';
          blockScroll = this.videosBlockScroll;
        }
        break;
      case 2:
        this.currentTab = 'videos';
        blockScroll = this.videosBlockScroll;
        break;
    }
    
    if(!blockScroll) {
      clearTimeout(this.nextRequestSearch); 
      this.nextRequestSearch = setTimeout(async ()=> {
        await this.getData(false, true);
      }, 1000);
      
    }
  }

  async onScroll() {
    let blockScroll:boolean = this[`${this.currentTab}BlockScroll`];

    if(!blockScroll && !(this.allLoading || this.livematchesLoading || this.videosLoading) ) {
      await this.getData(true);
    }
  }

  async openFilterModal() {
    const dialogRef = this.dialog.open(FiltersComponent, {
      panelClass: "dialog-modal",
      data: {
        category: [],
        subCategories: [],
        categories: this.categories,
        filters: {
          searchValue: this.searchValue,
          endDateRange: this.range.value.end,
          startDateRange: this.range.value.start,
          subCategories: this.categoriesSelected,
          orderBy: this.orderBy,
        }
      },
      autoFocus: false
    });

    let result = await dialogRef.afterClosed().toPromise();  

    if(result) {
      this.filterActive = true;

      this.searchValue = result.searchValue;
      this.range.value.end = result.endDateRange;
      this.range.value.start = result.startDateRange;
      this.categoriesSelected = result.subCategories;
      this.selectedCategory = result.selectedCategory;
      this.orderBy = result.orderBy;

      await this.onFilter(this.orderBy, true);

      clearTimeout(this.nextRequestSearch); 
      this.nextRequestSearch = setTimeout(async ()=> {
        await this.getData(false);
      }, 1000);

      if(result.reset) {
        this.filterActive = false;
      }
    }
  }

}
